/**
 * PostgreSQL Client Service
 * 
 * This service provides a client for interacting with the PostgreSQL database via the API.
 * It replaces the Supabase client functionality.
 */

import axios from 'axios';

// Get API URL from environment variables
const apiUrlFromEnv = import.meta.env.VITE_API_URL;
// Use the API URL as provided in the environment variable (HTTPS)
const API_URL = apiUrlFromEnv;

// Log the API URL for debugging
console.log('PostgresClient: Using API URL:', API_URL);

// Types for database entities
export interface UserProfile {
  profile_id: string;
  user_name: string | null;
  email?: string | null;
  name?: string | null;
  phone?: string | null;
  wallet_address?: string | null;
  created_at_privy?: string | null;
  created_at?: string;
  updated_at?: string;
}

export interface Organization {
  organization_id: string;
  organization_name: string;
  created_at?: string;
  updated_at?: string;
}

export interface OrganizationMember {
  id: string;
  organization_id: string;
  profile_id: string;
  role: string;
  created_at?: string;
  updated_at?: string;
}

export interface OrganizationInvitation {
  invitation_id: string;
  organization_id: string;
  organization_name: string;
  inviter_profile_id: string;
  invitee_email: string;
  invitee_profile_id?: string;
  role: string;
  status: string;
  invitation_token?: string;
  created_at: string;
  updated_at?: string;
  expires_at: string;
}

export interface Document {
  document_id: string;
  organization_id: string;
  profile_id: string;
  document_hash: string;
  file_name: string | null;
  document_name: string | null;
  file_type: string | null;
  blockchain: string | null;
  blockchain_tx: string | null;
  ref_id: string | null;
  registration_date: string;
  created_at?: string;
  updated_at?: string;
  integra_id: string | null;
  controller?: string | null;
  integra_hash?: string | null;
  block_number?: number | null;
  tx_timestamp?: string | null;
  reference_id?: string | null;
  gas_used?: string | null;
  workflow_id?: string | null;
  document_url?: string | null;
  user_id?: string | null;
}

/**
 * PostgreSQL Client that handles API requests to the backend
 */
export class PostgresClient {
  private baseUrl: string;
  private headers: Record<string, string>;

  constructor() {
    this.baseUrl = API_URL;
    this.headers = {
      'Content-Type': 'application/json',
    };
  }

  /**
   * Set the authentication token for API requests
   * @param profileId The profile ID to use for authentication
   */
  setAuthProfile(profileId: string): void {
    this.headers['X-Profile-ID'] = profileId;
  }

  /**
   * Set the current organization for API requests
   * @param organizationId The organization ID to use for requests
   */
  setOrganization(organizationId: string): void {
    this.headers['X-Organization-ID'] = organizationId;
  }

  /**
   * Make a GET request to the API
   * @param endpoint The API endpoint
   * @param params Query parameters
   * @returns The response data
   */
  async get<T>(endpoint: string, params: Record<string, any> = {}): Promise<T> {
    try {
      const response = await axios.get(`${this.baseUrl}${endpoint}`, {
        headers: this.headers,
        params,
      });
      return response.data;
    } catch (error) {
      console.error(`Error in GET request to ${endpoint}:`, error);
      throw error;
    }
  }

  /**
   * Make a POST request to the API
   * @param endpoint The API endpoint
   * @param data The request body
   * @returns The response data
   */
  async post<T>(endpoint: string, data: Record<string, any> = {}): Promise<T> {
    try {
      console.log(`Making POST request to ${this.baseUrl}${endpoint} with headers:`, this.headers);
      console.log(`Request data:`, data);
      
      const response = await axios.post(`${this.baseUrl}${endpoint}`, data, {
        headers: this.headers,
      });
      return response.data;
    } catch (error) {
      console.error(`Error in POST request to ${endpoint}:`, error);
      
      // Log more detailed information about the error
      if (axios.isAxiosError(error)) {
        console.error('Detailed error information:', {
          message: error.message,
          code: error.code,
          config: {
            url: error.config?.url,
            baseURL: error.config?.baseURL,
            method: error.config?.method,
            headers: error.config?.headers,
            data: error.config?.data
          },
          response: error.response ? {
            status: error.response.status,
            statusText: error.response.statusText,
            data: error.response.data,
            headers: error.response.headers
          } : 'No response received'
        });
        
        // Log detailed error information if available
        if (error.response?.data) {
          console.error('PostgresClient: Error response data:', JSON.stringify(error.response.data, null, 2));
          
          // If the error contains a detail message, log it separately
          if (error.response.data.detail) {
            console.error('PostgresClient: Error detail:', error.response.data.detail);
          }
        }
      } else {
        console.error('PostgresClient: Non-Axios error in login:', error);
      }
      throw error;
    }
  }

  /**
   * Make a PUT request to the API
   * @param endpoint The API endpoint
   * @param data The request body
   * @returns The response data
   */
  async put<T>(endpoint: string, data: Record<string, any> = {}): Promise<T> {
    try {
      const response = await axios.put(`${this.baseUrl}${endpoint}`, data, {
        headers: this.headers,
      });
      return response.data;
    } catch (error) {
      console.error(`Error in PUT request to ${endpoint}:`, error);
      throw error;
    }
  }

  /**
   * Make a DELETE request to the API
   * @param endpoint The API endpoint
   * @returns The response data
   */
  async delete<T>(endpoint: string): Promise<T> {
    try {
      const response = await axios.delete(`${this.baseUrl}${endpoint}`, {
        headers: this.headers,
      });
      return response.data;
    } catch (error) {
      console.error(`Error in DELETE request to ${endpoint}:`, error);
      throw error;
    }
  }

  // User profile methods
  async createOrUpdateProfile(profileId: string, userName: string | null): Promise<UserProfile> {
    return this.post<UserProfile>('/api/profiles', {
      profile_id: profileId,
      user_name: userName,
    });
  }

  async getProfile(profileId: string): Promise<UserProfile> {
    return this.get<UserProfile>(`/api/profiles/${profileId}`);
  }

  // Authentication methods
  async login(
    privyToken: string, 
    userData?: {
      email?: string | null;
      name?: string | null;
      phone?: string | null;
      wallet_address?: string | null;
      created_at_privy?: string | null;
    }
  ): Promise<{
    profile_id: string;
    user_name: string | null;
    organization_id: string;
    organization_name: string;
    created_at?: string;
    updated_at?: string;
    email?: string | null;
    name?: string | null;
    phone?: string | null;
    wallet_address?: string | null;
    created_at_privy?: string | null;
  }> {
    try {
      console.log('PostgresClient: Calling login endpoint with token', privyToken ? 'Token exists' : 'No token');
      console.log('PostgresClient: API_URL is:', this.baseUrl);
      
      if (!privyToken) {
        throw new Error('No Privy token provided');
      }
      
      // Ensure the token is properly formatted as a Bearer token
      const formattedToken = privyToken.startsWith('Bearer ') ? privyToken : `Bearer ${privyToken}`;
      console.log('PostgresClient: Using formatted token', formattedToken.substring(0, 15) + '...');
      
      // Log token details for debugging
      const tokenParts = formattedToken.split(' ');
      console.log(`PostgresClient: Token format check: prefix="${tokenParts[0]}", length=${tokenParts[1]?.length || 0}`);
      
      // Check if the token is a valid JWT format (header.payload.signature)
      const jwtParts = tokenParts[1]?.split('.') || [];
      console.log(`PostgresClient: JWT parts count: ${jwtParts.length}`);
      if (jwtParts.length === 3) {
        console.log('PostgresClient: Token appears to be in valid JWT format');
      } else {
        console.warn('PostgresClient: Token may not be in valid JWT format');
      }
      
      // Use the class's post method instead of axios directly
      // This ensures consistent header handling
      const tempHeaders = { ...this.headers };
      this.headers = {
        ...this.headers,
        'Authorization': formattedToken
      };
      
      // Include additional user data from Privy if available
      const payload = userData ? { privy_user_data: userData } : {};
      console.log('PostgresClient: Sending user data to backend:', payload);
      
      const response = await this.post<{
        profile_id: string;
        user_name: string | null;
        organization_id: string;
        organization_name: string;
        created_at?: string;
        updated_at?: string;
        email?: string | null;
        name?: string | null;
        phone?: string | null;
        wallet_address?: string | null;
        created_at_privy?: string | null;
      }>('/api/auth/login', payload);
      
      // Restore original headers
      this.headers = tempHeaders;
      
      console.log('PostgresClient: Login response:', response);
      
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('PostgresClient: Axios error in login:', {
          status: error.response?.status,
          statusText: error.response?.statusText,
          data: error.response?.data,
          headers: error.response?.headers,
          config: {
            url: error.config?.url,
            method: error.config?.method,
            headers: error.config?.headers
          }
        });
        
        // Log detailed error information if available
        if (error.response?.data) {
          console.error('PostgresClient: Error response data:', JSON.stringify(error.response.data, null, 2));
          
          // If the error contains a detail message, log it separately
          if (error.response.data.detail) {
            console.error('PostgresClient: Error detail:', error.response.data.detail);
          }
        }
      } else {
        console.error('PostgresClient: Non-Axios error in login:', error);
      }
      throw error;
    }
  }

  // Organization methods
  async getUserOrganizations(profileId: string): Promise<Organization[]> {
    // Temporarily set the auth profile to match the requested profile ID
    // This ensures the X-Profile-ID header matches the profile ID in the URL
    const originalProfileId = this.headers['X-Profile-ID'];
    this.setAuthProfile(profileId);
    
    try {
      const result = await this.get<Organization[]>(`/api/profiles/${profileId}/organizations`);
      return result;
    } catch (error) {
      console.error(`Error getting organizations for profile ${profileId}:`, error);
      throw error;
    } finally {
      // Restore the original profile ID if it existed
      if (originalProfileId) {
        this.setAuthProfile(originalProfileId);
      }
    }
  }

  async createOrganization(name: string): Promise<Organization> {
    return this.post<Organization>('/api/organizations', {
      organization_name: name,
    });
  }

  async addUserToOrganization(organizationId: string, profileId: string, role: string): Promise<OrganizationMember> {
    return this.post<OrganizationMember>(`/api/organizations/${organizationId}/members`, {
      profile_id: profileId,
      role,
    });
  }

  // Organization members methods
  async getOrganizationMembers(organizationId: string): Promise<OrganizationMember[]> {
    return this.get<OrganizationMember[]>(`/api/organizations/${organizationId}/members`);
  }

  async updateMemberRole(organizationId: string, profileId: string, role: string): Promise<OrganizationMember> {
    return this.put<OrganizationMember>(`/api/organizations/${organizationId}/members/${profileId}/role`, {
      role,
    });
  }

  async removeMember(organizationId: string, profileId: string): Promise<void> {
    return this.delete<void>(`/api/organizations/${organizationId}/members/${profileId}`);
  }

  // Organization invitation methods
  async createInvitation(organizationId: string, email: string, role: string): Promise<OrganizationInvitation> {
    return this.post<OrganizationInvitation>(`/api/organizations/${organizationId}/invitations`, {
      invitee_email: email,
      role,
    });
  }

  async getOrganizationInvitations(organizationId: string, status?: string): Promise<OrganizationInvitation[]> {
    const params: Record<string, any> = {};
    if (status) {
      params.status = status;
    }
    return this.get<OrganizationInvitation[]>(`/api/organizations/${organizationId}/invitations`, params);
  }

  async getPendingInvitationsByEmail(email: string): Promise<OrganizationInvitation[]> {
    return this.get<OrganizationInvitation[]>(`/api/organizations/invitations/pending`, { email });
  }

  async acceptInvitation(invitationId: string): Promise<OrganizationMember> {
    return this.post<OrganizationMember>(`/api/organizations/invitations/${invitationId}/accept`, {});
  }

  async declineInvitation(invitationId: string): Promise<OrganizationInvitation> {
    return this.post<OrganizationInvitation>(`/api/organizations/invitations/${invitationId}/decline`, {});
  }

  async cancelInvitation(invitationId: string): Promise<void> {
    return this.delete<void>(`/api/organizations/invitations/${invitationId}`);
  }

  async verifyInvitationToken(token: string): Promise<OrganizationInvitation> {
    return this.get<OrganizationInvitation>(`/api/organizations/invitations/${token}/verify`);
  }

  // Document methods
  async getOrganizationDocuments(
    organizationId: string,
    page = 1,
    pageSize = 20
  ): Promise<{ documents: Document[]; count: number; hasMore: boolean; currentPage: number; totalPages: number }> {
    const response = await this.get<{
      documents: Document[];
      count: number;
      hasMore: boolean;
      currentPage: number;
      totalPages: number;
    }>(`/api/organizations/${organizationId}/documents`, {
      page,
      page_size: pageSize,
    });
    
    return response;
  }

  /**
   * Check if a document exists in the database by its hash
   * @param organizationId The organization ID
   * @param documentHash The document hash to check
   * @returns Promise resolving to the document if found, null otherwise
   */
  async checkDocumentByHash(
    organizationId: string,
    documentHash: string
  ): Promise<Document | null> {
    try {
      const response = await this.get<Document[]>(`/api/organizations/${organizationId}/documents/hash/${documentHash}`);
      
      // If we get a document back, return it
      if (response && response.length > 0) {
        console.log('Document found in database:', response[0]);
        return response[0];
      }
      
      // No document found
      console.log('No document found in database with hash:', documentHash);
      return null;
    } catch (error) {
      console.error(`Error checking document by hash ${documentHash}:`, error);
      throw error;
    }
  }

  async registerDocument(
    organizationId: string,
    documentData: {
      document_id: string;
      document_hash: string;
      profile_id: string;
      file_name?: string;
      document_name?: string;
      file_type?: string;
      blockchain?: string;
      blockchain_tx?: string;
      ref_id?: string;
      integra_id?: string;
      integra_hash?: string;
      block_number?: number;
      tx_timestamp?: string;
      reference_id?: string;
      gas_used?: string;
      workflow_id?: string;
    }
  ): Promise<Document> {
    return this.post<Document>(`/api/organizations/${organizationId}/documents`, documentData);
  }
}

// Create a singleton instance
const postgresClient = new PostgresClient();

export default postgresClient;
