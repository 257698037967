import React, { useState, useEffect } from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  HStack,
  Badge,
  Spinner,
  useColorModeValue,
  Card,
  CardBody,
  SimpleGrid,
  Icon,
  Flex,
  Button,
  Select,
  Input,
  InputGroup,
  InputLeftElement,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Collapse,
} from '@chakra-ui/react';
import { FiActivity, FiSearch, FiFilter, FiRefreshCw, FiAlertTriangle } from 'react-icons/fi';
import { useEnhancedAuth } from '../providers/EnhancedPostgresAuthProvider';
import { format } from 'date-fns';
import { useDocumentActivities } from '../hooks/useDocumentActivities';
import { DocumentActivity } from '../services/DocumentActivityService';

/**
 * DocumentActivities Component
 * 
 * Displays a list of document activities for the logged-in user
 */
const DocumentActivities: React.FC = () => {
  const { userProfile, currentOrganizationId } = useEnhancedAuth();
  const [filter, setFilter] = useState<string>('all');
  const [searchTerm, setSearchTerm] = useState<string>('');

  // Use the document activities hook
  const { 
    activities, 
    loading, 
    error, 
    refreshActivities,
    hasError,
    isServerError 
  } = useDocumentActivities(
    userProfile?.profile_id || '', 
    currentOrganizationId || ''
  );

  // Colors
  const cardBg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const mutedColor = useColorModeValue('gray.600', 'gray.400');

  // Activity type to badge color mapping
  const activityColors: Record<string, string> = {
    'registration': 'green',
    'verification': 'blue',
    'tokenization': 'purple',
    'update': 'orange',
    'view': 'teal',
    'default': 'gray'
  };

  // Helper function to safely stringify activity details
  const getActivityDetailsString = (details: any): string => {
    if (!details) return '';
    
    if (typeof details === 'string') {
      return details;
    }
    
    if (typeof details === 'object') {
      // If we have an activity_title, use that
      if (details.activity_title) {
        return details.activity_title;
      }
      
      // Try to extract meaningful information from the object
      const detailsArr = [];
      
      // Common fields that might contain useful information
      if (details.document_hash) detailsArr.push(`Doc: ${details.document_hash.substring(0, 10)}...`);
      if (details.verification_type) detailsArr.push(`Type: ${details.verification_type}`);
      if (details.verified !== undefined) detailsArr.push(`Verified: ${details.verified ? 'Yes' : 'No'}`);
      
      if (detailsArr.length > 0) {
        return detailsArr.join(' | ');
      }
      
      // Fallback to a generic message with JSON stringification
      try {
        return `Activity details: ${JSON.stringify(details).substring(0, 50)}...`;
      } catch (e) {
        return 'Activity details available';
      }
    }
    
    return 'Unknown activity details';
  };

  // Filter activities
  const filteredActivities = activities.filter(activity => {
    // Apply type filter
    const typeMatch = filter === 'all' || activity.activity_type === filter;
    
    // Get activity details as string for searching
    const activityDetailsStr = getActivityDetailsString(activity.activity_details);
    
    // Apply search filter
    const searchMatch = 
      activity.integra_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
      activityDetailsStr.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (activity.reference_id && activity.reference_id.toLowerCase().includes(searchTerm.toLowerCase()));
    
    return typeMatch && searchMatch;
  });

  // Format date for display
  const formatDate = (dateString: string) => {
    try {
      return format(new Date(dateString), 'MMM d, yyyy h:mm a');
    } catch (e) {
      return dateString;
    }
  };

  return (
    <Box p={5} maxW="1200px" mx="auto">
      <Flex justifyContent="space-between" alignItems="center" mb={6}>
        <HStack>
          <Icon as={FiActivity} boxSize={6} color="blue.500" />
          <Heading size="lg">Document Activities</Heading>
        </HStack>
        <Button 
          leftIcon={<FiRefreshCw />} 
          onClick={refreshActivities}
          isLoading={loading}
          colorScheme="blue"
          variant="outline"
        >
          Refresh
        </Button>
      </Flex>

      {/* Error Alert */}
      <Collapse in={hasError} animateOpacity>
        <Alert 
          status={isServerError ? "warning" : "error"} 
          mb={4} 
          borderRadius="md"
        >
          <AlertIcon />
          <Box flex="1">
            <AlertTitle>
              {isServerError 
                ? "Server Communication Issue" 
                : "Error Loading Activities"}
            </AlertTitle>
            <AlertDescription display="block">
              {isServerError 
                ? "We're experiencing issues connecting to the activity server. Your activities will be displayed when the connection is restored." 
                : error?.message || "There was an error loading your document activities."}
            </AlertDescription>
          </Box>
          <Button 
            size="sm" 
            colorScheme={isServerError ? "yellow" : "red"} 
            onClick={refreshActivities} 
            ml={2}
          >
            Try Again
          </Button>
        </Alert>
      </Collapse>

      {/* Filters */}
      <Flex 
        mb={6} 
        direction={{ base: 'column', md: 'row' }} 
        gap={4}
        align={{ base: 'stretch', md: 'center' }}
      >
        <InputGroup maxW={{ base: '100%', md: '60%' }}>
          <InputLeftElement pointerEvents="none">
            <Icon as={FiSearch} color="gray.400" />
          </InputLeftElement>
          <Input
            placeholder="Search activities..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            borderRadius="md"
          />
        </InputGroup>
        
        <HStack spacing={2} align="center">
          <Icon as={FiFilter} color="gray.500" />
          <Select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            maxW="200px"
            borderRadius="md"
          >
            <option value="all">All Activities</option>
            <option value="registration">Registration</option>
            <option value="verification">Verification</option>
            <option value="tokenization">Tokenization</option>
            <option value="update">Updates</option>
            <option value="view">Views</option>
          </Select>
        </HStack>
      </Flex>

      {/* Activities List */}
      {loading ? (
        <Flex justify="center" align="center" py={10}>
          <Spinner size="xl" color="blue.500" thickness="4px" />
        </Flex>
      ) : filteredActivities.length > 0 ? (
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={5}>
          {filteredActivities.map((activity) => (
            <Card 
              key={activity.id} 
              bg={cardBg} 
              borderWidth="1px" 
              borderColor={borderColor}
              borderRadius="lg"
              overflow="hidden"
              boxShadow="sm"
              transition="all 0.2s"
              _hover={{ boxShadow: 'md', transform: 'translateY(-2px)' }}
            >
              <CardBody>
                <VStack align="stretch" spacing={3}>
                  <HStack justify="space-between">
                    <Badge 
                      colorScheme={activityColors[activity.activity_type] || activityColors.default}
                      borderRadius="full"
                      px={2}
                      py={1}
                      fontSize="xs"
                      textTransform="capitalize"
                    >
                      {activity.activity_type}
                    </Badge>
                    <Text fontSize="xs" color={mutedColor}>
                      {formatDate(activity.created_at)}
                    </Text>
                  </HStack>
                  
                  <Text fontWeight="bold" color={textColor} noOfLines={1}>
                    {activity.integra_id}
                  </Text>
                  
                  {/* Render activity details properly */}
                  <Text fontSize="sm" color={mutedColor} noOfLines={2}>
                    {getActivityDetailsString(activity.activity_details)}
                  </Text>
                  
                  {activity.reference_id && (
                    <Text fontSize="xs" color={mutedColor}>
                      Ref: {activity.reference_id}
                    </Text>
                  )}
                  
                  {activity.transaction_hash && (
                    <Text fontSize="xs" color={mutedColor} noOfLines={1}>
                      Tx: {activity.transaction_hash}
                    </Text>
                  )}
                </VStack>
              </CardBody>
            </Card>
          ))}
        </SimpleGrid>
      ) : (
        <Box 
          textAlign="center" 
          py={10} 
          px={6} 
          borderWidth="1px" 
          borderRadius="lg" 
          borderColor={borderColor}
          bg={cardBg}
        >
          <Icon as={FiAlertTriangle} boxSize={10} color="gray.400" mb={4} />
          <Heading as="h3" size="md" mb={2}>
            No Activities Found
          </Heading>
          <Text color={mutedColor}>
            {searchTerm || filter !== 'all' 
              ? "Try adjusting your search or filter criteria." 
              : "No document activities found for your account."}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default DocumentActivities;
