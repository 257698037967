import { PDFDocument, rgb, PDFName, PDFString } from 'pdf-lib';

/**
 * Places a QR code on a PDF document with a hyperlink to the specified URL
 * @param pdfBytes The PDF document as a Uint8Array
 * @param qrCodeBytes The QR code image as a Uint8Array
 * @param placement The placement of the QR code ('right', 'left', or 'cover')
 * @param url The URL to link the QR code to
 * @returns The modified PDF document as a Uint8Array
 */
export async function placeQRCodeOnDocument(
  pdfBytes: Uint8Array,
  qrCodeBytes: Uint8Array,
  placement: 'right' | 'left' | 'cover',
  url: string
): Promise<Uint8Array> {
  // Load the PDF document
  const pdfDoc = await PDFDocument.load(pdfBytes);
  
  // Get the first page
  const pages = pdfDoc.getPages();
  const firstPage = pages[0];
  
  // Embed the QR code image
  const qrCodeImage = await pdfDoc.embedPng(qrCodeBytes);
  
  // Get page dimensions
  const { width, height } = firstPage.getSize();
  
  // Set QR code dimensions based on placement
  const qrSize = placement === 'cover' ? 150 : 75;
  
  // Place the QR code based on the specified placement
  switch (placement) {
    case 'right':
      // Top-right corner
      firstPage.drawImage(qrCodeImage, {
        x: width - qrSize,
        y: height - qrSize,
        width: qrSize,
        height: qrSize,
      });
      
      // Create hyperlink annotation for the QR code
      const rightLinkAnnotation = pdfDoc.context.register(
        pdfDoc.context.obj({
          Type: 'Annot',
          Subtype: 'Link',
          Rect: [width - qrSize, height - qrSize, width, height],
          Border: [0, 0, 0],
          A: {
            Type: 'Action',
            S: 'URI',
            URI: PDFString.of(url),
          },
        })
      );
      
      // Add the annotation to the page
      const rightExistingAnnots = firstPage.node.get(PDFName.of('Annots'));
      if (rightExistingAnnots) {
        rightExistingAnnots.push(rightLinkAnnotation);
      } else {
        firstPage.node.set(PDFName.of('Annots'), pdfDoc.context.obj([rightLinkAnnotation]));
      }
      break;
      
    case 'left':
      // Top-left corner
      firstPage.drawImage(qrCodeImage, {
        x: 0,
        y: height - qrSize,
        width: qrSize,
        height: qrSize,
      });
      
      // Create hyperlink annotation for the QR code
      const leftLinkAnnotation = pdfDoc.context.register(
        pdfDoc.context.obj({
          Type: 'Annot',
          Subtype: 'Link',
          Rect: [0, height - qrSize, qrSize, height],
          Border: [0, 0, 0],
          A: {
            Type: 'Action',
            S: 'URI',
            URI: PDFString.of(url),
          },
        })
      );
      
      // Add the annotation to the page
      const leftExistingAnnots = firstPage.node.get(PDFName.of('Annots'));
      if (leftExistingAnnots) {
        leftExistingAnnots.push(leftLinkAnnotation);
      } else {
        firstPage.node.set(PDFName.of('Annots'), pdfDoc.context.obj([leftLinkAnnotation]));
      }
      break;
      
    case 'cover':
      // Create a new page for the cover
      const coverPage = pdfDoc.insertPage(0);
      
      // Set cover page dimensions to match the first page
      coverPage.setSize(width, height);
      
      // Fill with white background
      coverPage.drawRectangle({
        x: 0,
        y: 0,
        width,
        height,
        color: rgb(1, 1, 1),
      });
      
      // Calculate QR code position
      const qrX = (width - qrSize) / 2;
      const qrY = (height - qrSize) / 2 + 50; // Slightly above center
      
      // Draw QR code centered on the cover page
      coverPage.drawImage(qrCodeImage, {
        x: qrX,
        y: qrY,
        width: qrSize,
        height: qrSize,
      });
      
      // Create hyperlink annotation for the QR code
      const coverLinkAnnotation = pdfDoc.context.register(
        pdfDoc.context.obj({
          Type: 'Annot',
          Subtype: 'Link',
          Rect: [qrX, qrY, qrX + qrSize, qrY + qrSize],
          Border: [0, 0, 0],
          A: {
            Type: 'Action',
            S: 'URI',
            URI: PDFString.of(url),
          },
        })
      );
      
      // Add the annotation to the page
      coverPage.node.set(PDFName.of('Annots'), pdfDoc.context.obj([coverLinkAnnotation]));
      
      // Add text below the QR code
      const fontSize = 12;
      coverPage.drawText('Integra Smart Document', {
        x: (width - 150) / 2,
        y: (height - qrSize) / 2 + 20, // Below the QR code
        size: fontSize,
        color: rgb(0, 0, 0),
      });
      
      coverPage.drawText('Scan this QR code to verify document authenticity', {
        x: (width - 250) / 2,
        y: (height - qrSize) / 2, // Below the title
        size: fontSize - 2,
        color: rgb(0.3, 0.3, 0.3),
      });
      break;
  }
  
  // Save the modified PDF
  return await pdfDoc.save();
}
