/**
 * usePDFOperations Hook
 * 
 * A custom hook for PDF operations:
 * - Loading and processing PDF files
 * - Extracting metadata
 * - Creating smart documents
 * - Signing PDFs
 * 
 * This hook is part of the V3 architecture that separates concerns
 * and improves maintainability.
 */

import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { usePDFStore } from '../store/pdfStore';
import pdfService, { PDFMetadata, SmartDocConfig } from '../services/PDFService';
import qrCodeService from '../services/QRCodeService';
import { arrayBufferToHex } from '../utils/conversion';

// Crypto subtle is available in secure contexts
const crypto = window.crypto;

/**
 * Hook for PDF operations
 * @returns PDF operations and state
 */
export function usePDFOperations() {
  // Get PDF store state and actions
  const {
    pdfFile,
    fileHash,
    originalHash,
    metadata,
    isProcessing,
    processingStep,
    error,
    setPDFFile,
    setFileHash,
    setOriginalHash,
    setMetadata,
    setProcessing,
    setError,
    updateMetadata,
    reset
  } = usePDFStore();

  /**
   * Load a PDF file and extract metadata
   * @param file PDF file to load
   */
  const loadPDF = useCallback(async (file: File) => {
    try {
      // Reset state
      reset();
      
      // Set loading state
      setProcessing(true, 'extracting');
      
      // Get basic file info
      const fileExtension = file.name.split('.').pop()?.toLowerCase() || '';
      const basicMetadata = {
        fileName: file.name,
        fileSize: file.size,
        fileType: file.type,
        extension: fileExtension
      };
      
      // Validate file type
      if (fileExtension !== 'pdf' && file.type !== 'application/pdf') {
        throw new Error('File is not a PDF');
      }
      
      // Update state with file and basic metadata
      setPDFFile(file);
      setMetadata(basicMetadata);
      
      // Extract detailed metadata
      try {
        const metadata = await pdfService.extractMetadata(file);
        setMetadata(metadata);
      } catch (error) {
        console.error('Error extracting PDF metadata:', error);
        
        // Don't throw here, just log the error and continue
        // This allows the PDF to still be viewed even if metadata extraction fails
        setError(error instanceof Error ? error.message : 'Failed to extract PDF metadata');
      }
      
      // Calculate file hash
      setProcessing(true, 'hashing');
      const hash = await pdfService.calculateFileHash(file);
      setFileHash(hash);
      setOriginalHash(hash);
      
      // Update metadata with hash
      updateMetadata({ fileHash: hash });
      
      // Set success state
      setProcessing(false);
      
      return {
        file: pdfFile,
        metadata: metadata,
        hash: fileHash
      };
    } catch (error) {
      console.error('Error loading PDF:', error);
      
      // Set error state
      setProcessing(false);
      setError(error instanceof Error ? error.message : 'Failed to load PDF');
      
      throw error;
    }
  }, [reset, setPDFFile, setProcessing, setMetadata, setFileHash, setOriginalHash, updateMetadata, setError, metadata, fileHash]);

  /**
   * Calculate SHA-256 hash of a file
   * @param file File to hash
   * @returns Hex string of the hash
   */
  const calculateFileHash = useCallback(async (file: File): Promise<string> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
      const hashHex = arrayBufferToHex(hashBuffer);
      return `0x${hashHex}`;
    } catch (error) {
      console.error('Error calculating file hash:', error);
      throw new Error('Failed to calculate file hash');
    }
  }, []);

  /**
   * Create a smart document with metadata and QR code
   * @param options Smart document options
   * @returns Promise resolving to the smart document as Uint8Array
   */
  const createSmartDocument = useCallback(async ({
    metadata = {},
    qrCodeData,
    integraEndpoint,
    customEndpoint,
    version,
    signingEnabled = false,
    visibleSignature = false,
    logoUrl
  }: {
    metadata?: PDFMetadata;
    qrCodeData: string;
    integraEndpoint: string;
    customEndpoint: string;
    version: string;
    signingEnabled?: boolean;
    visibleSignature?: boolean;
    logoUrl?: string;
  }) => {
    if (!pdfFile) {
      setError('No PDF file loaded');
      return null;
    }

    try {
      setProcessing(true, 'creating');
      
      // Generate a UUID if not provided
      const uuid = metadata.integraId || uuidv4();
      
      // Read the PDF file
      const pdfBytes = await pdfService.readAsArrayBuffer(pdfFile);
      
      // Generate QR code as data URL
      const qrCodeDataUrl = await qrCodeService.generateQRCodeWithLogo({
        data: qrCodeData || integraEndpoint,
        size: 200,
        logo: logoUrl,
        logoScale: 0.25,
        errorCorrection: 'H'
      });
      
      // Convert data URL to Uint8Array
      const base64Data = qrCodeDataUrl.split(',')[1];
      const binaryString = atob(base64Data);
      const qrCodeBytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        qrCodeBytes[i] = binaryString.charCodeAt(i);
      }
      
      // Create smart document config
      const smartDocConfig: SmartDocConfig = {
        pdfBytes: new Uint8Array(pdfBytes),
        jsonData: metadata,
        qrCodeBytes,
        integraEndpoint,
        customEndpoint,
        version,
        uuid,
        signingEnabled,
        visibleSignature
      };
      
      // Create smart document
      const smartDocBytes = await pdfService.createSmartDoc(smartDocConfig);
      
      // Calculate new hash
      const smartDocBlob = new Blob([smartDocBytes], { type: 'application/pdf' });
      const smartDocFile = new File([smartDocBlob], pdfFile.name, { type: 'application/pdf' });
      const newHash = await calculateFileHash(smartDocFile);
      
      // Update state
      setPDFFile(smartDocFile);
      setFileHash(newHash);
      updateMetadata({ ...metadata, integraId: uuid });
      
      // Finish processing
      setProcessing(false);
      
      return {
        bytes: smartDocBytes,
        file: smartDocFile,
        hash: newHash,
        uuid
      };
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Unknown error creating smart document';
      setError(errorMessage);
      console.error('Error creating smart document:', error);
      return null;
    }
  }, [pdfFile, setPDFFile, setFileHash, updateMetadata, setProcessing, setError, calculateFileHash]);

  /**
   * Sign a PDF document with a P12 certificate
   * @param p12Buffer P12 certificate buffer
   * @param password P12 certificate password
   * @returns Promise resolving to the signed PDF as Uint8Array
   */
  const signPDF = useCallback(async (p12Buffer: Buffer, password: string = '') => {
    if (!pdfFile) {
      setError('No PDF file loaded');
      return null;
    }

    try {
      setProcessing(true, 'signing');
      
      // Read the PDF file
      const pdfBytes = await pdfService.readAsArrayBuffer(pdfFile);
      
      // Sign the PDF
      const signedPdfBytes = await pdfService.signPDF({
        pdfBytes: new Uint8Array(pdfBytes),
        p12Buffer,
        p12Password: password
      });
      
      // Create a new file from the signed PDF
      const signedPdfBlob = new Blob([signedPdfBytes], { type: 'application/pdf' });
      const signedPdfFile = new File([signedPdfBlob], pdfFile.name, { type: 'application/pdf' });
      
      // Calculate new hash
      const newHash = await calculateFileHash(signedPdfFile);
      
      // Update state
      setPDFFile(signedPdfFile);
      setFileHash(newHash);
      updateMetadata({ isSigned: true });
      
      // Finish processing
      setProcessing(false);
      
      return {
        bytes: signedPdfBytes,
        file: signedPdfFile,
        hash: newHash
      };
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Unknown error signing PDF';
      setError(errorMessage);
      console.error('Error signing PDF:', error);
      return null;
    }
  }, [pdfFile, setPDFFile, setFileHash, updateMetadata, setProcessing, setError, calculateFileHash]);

  return {
    // State
    pdfFile,
    fileHash,
    originalHash,
    metadata,
    isProcessing,
    processingStep,
    error,
    
    // Actions
    loadPDF,
    calculateFileHash,
    createSmartDocument,
    signPDF,
    updateMetadata,
    reset
  };
}

export default usePDFOperations;
