import React, { useEffect, useState } from 'react';
import { usePrivy } from '@privy-io/react-auth';
import axios from 'axios';
import { useEnhancedAuth } from '../../providers/EnhancedPostgresAuthProvider';

/**
 * A test component to verify Privy authentication
 */
const PrivyAuthTest: React.FC = () => {
  const { user, authenticated, ready, getAccessToken } = usePrivy();
  const { userProfile } = useEnhancedAuth();
  const [token, setToken] = useState<string | null>(null);
  const [tokenDetails, setTokenDetails] = useState<any>(null);
  const [testResult, setTestResult] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [organizationId, setOrganizationId] = useState<string>('');
  const [profileId, setProfileId] = useState<string>('');

  // Get the organization ID from localStorage
  useEffect(() => {
    const storedOrgId = localStorage.getItem('organizationId');
    if (storedOrgId) {
      setOrganizationId(storedOrgId);
    }
    
    // If we have a user profile, get the profile ID
    if (userProfile) {
      setProfileId(userProfile.profile_id || '');
    } else if (user) {
      // Use Privy ID as a fallback
      setProfileId(user.id || '');
    }
  }, [userProfile, user]);

  useEffect(() => {
    const fetchToken = async () => {
      if (authenticated && user) {
        try {
          const accessToken = await getAccessToken();
          setToken(accessToken);
          console.log('Privy token retrieved:', accessToken ? 'Token exists' : 'No token');
          if (accessToken) {
            console.log('Token preview:', accessToken.substring(0, 20) + '...');
            console.log('Token length:', accessToken.length);
            
            // Analyze token structure
            try {
              // Check if it's a JWT token
              const parts = accessToken.split('.');
              if (parts.length === 3) {
                // It's likely a JWT
                const header = JSON.parse(atob(parts[0]));
                const payload = JSON.parse(atob(parts[1]));
                setTokenDetails({
                  type: 'JWT',
                  header,
                  payload,
                  algorithm: header.alg || 'Not specified',
                  keyId: header.kid || 'Not specified',
                  issuer: payload.iss || 'Not specified',
                  audience: payload.aud || 'Not specified',
                  subject: payload.sub || 'Not specified',
                  expiresAt: payload.exp ? new Date(payload.exp * 1000).toISOString() : 'N/A',
                  issuedAt: payload.iat ? new Date(payload.iat * 1000).toISOString() : 'N/A'
                });
              } else {
                setTokenDetails({
                  type: 'Unknown',
                  format: 'Not a standard JWT'
                });
              }
            } catch (err) {
              console.error('Error analyzing token:', err);
              setTokenDetails({
                type: 'Error',
                error: err instanceof Error ? err.message : String(err)
              });
            }
          }
        } catch (err) {
          console.error('Error getting Privy token:', err);
          setError(`Error getting Privy token: ${err instanceof Error ? err.message : String(err)}`);
        }
      }
    };

    if (ready) {
      fetchToken();
    }
  }, [authenticated, user, ready, getAccessToken]);

  const testDirectAuth = async () => {
    if (!token) {
      setError('No token available to test');
      return;
    }

    try {
      setTestResult('Testing...');
      
      // Format the token as a Bearer token
      const formattedToken = token.startsWith('Bearer ') ? token : `Bearer ${token}`;
      
      // Make a direct request to the backend
      const response = await axios.get('https://comms.integraledger.com/api/health', {
        headers: {
          'Authorization': formattedToken,
          'Content-Type': 'application/json'
        }
      });
      
      setTestResult(`Success! Response: ${JSON.stringify(response.data)}`);
      console.log('Direct auth test successful:', response.data);
    } catch (err) {
      console.error('Error testing direct auth:', err);
      let errorMessage = 'Unknown error';
      
      if (axios.isAxiosError(err)) {
        errorMessage = `Status: ${err.response?.status}, Message: ${err.message}`;
        if (err.response?.data) {
          errorMessage += `, Data: ${JSON.stringify(err.response.data)}`;
        }
      } else if (err instanceof Error) {
        errorMessage = err.message;
      }
      
      setTestResult(`Failed! Error: ${errorMessage}`);
    }
  };

  const testLoginEndpoint = async () => {
    if (!token) {
      setError('No token available to test');
      return;
    }

    try {
      setTestResult('Testing login endpoint...');
      
      // Format the token as a Bearer token
      const formattedToken = token.startsWith('Bearer ') ? token : `Bearer ${token}`;
      
      // Make a request to the login endpoint
      const response = await axios.post('https://comms.integraledger.com/api/auth/login', {}, {
        headers: {
          'Authorization': formattedToken,
          'Content-Type': 'application/json'
        }
      });
      
      setTestResult(`Login endpoint test successful! Response: ${JSON.stringify(response.data)}`);
      console.log('Login endpoint test successful:', response.data);
    } catch (err) {
      console.error('Error testing login endpoint:', err);
      let errorMessage = 'Unknown error';
      
      if (axios.isAxiosError(err)) {
        errorMessage = `Status: ${err.response?.status}, Message: ${err.message}`;
        if (err.response?.data) {
          errorMessage += `, Data: ${JSON.stringify(err.response.data)}`;
        }
      } else if (err instanceof Error) {
        errorMessage = err.message;
      }
      
      setTestResult(`Login endpoint test failed! Error: ${errorMessage}`);
    }
  };

  const testV3Endpoint = async () => {
    if (!token) {
      setError('No token available to test');
      return;
    }

    if (!organizationId || !profileId) {
      setError('Organization ID or Profile ID not available. Please ensure you are logged in properly.');
      return;
    }

    try {
      setTestResult('Testing V3 API endpoint...');
      
      // Format the token as a Bearer token
      const formattedToken = token.startsWith('Bearer ') ? token : `Bearer ${token}`;
      
      // Make a request to a V3 API endpoint with the required headers
      const response = await axios.post('https://comms.integraledger.com/v3/get-documents-by-documenthash', {
        document_hash: "test-hash", // Placeholder value
        workflow_id: "test-workflow-id",
        organization_id: organizationId,
        profile_id: profileId
      }, {
        headers: {
          'Authorization': formattedToken,
          'Content-Type': 'application/json',
          'x-profile-id': profileId,
          'x-organization-id': organizationId
        }
      });
      
      setTestResult(`V3 API test successful! Response: ${JSON.stringify(response.data)}`);
      console.log('V3 API test successful:', response.data);
    } catch (err) {
      console.error('Error testing V3 API:', err);
      let errorMessage = 'Unknown error';
      
      if (axios.isAxiosError(err)) {
        errorMessage = `Status: ${err.response?.status}, Message: ${err.message}`;
        if (err.response?.data) {
          errorMessage += `, Data: ${JSON.stringify(err.response.data)}`;
        }
      } else if (err instanceof Error) {
        errorMessage = err.message;
      }
      
      setTestResult(`V3 API test failed! Error: ${errorMessage}`);
    }
  };

  const testWithProfileHeaders = async () => {
    if (!token) {
      setError('No token available to test');
      return;
    }

    if (!organizationId || !profileId) {
      setError('Organization ID or Profile ID not available. Please ensure you are logged in properly.');
      return;
    }

    try {
      setTestResult('Testing login with profile headers...');
      
      // Format the token as a Bearer token
      const formattedToken = token.startsWith('Bearer ') ? token : `Bearer ${token}`;
      
      // Make a request to the login endpoint with additional headers
      const response = await axios.post('https://comms.integraledger.com/api/auth/login', 
        { privy_user_data: { id: user?.id } }, // Include user data in the request body
        {
          headers: {
            'Authorization': formattedToken,
            'Content-Type': 'application/json',
            'x-profile-id': profileId,
            'x-organization-id': organizationId
          }
        }
      );
      
      setTestResult(`Login with profile headers successful! Response: ${JSON.stringify(response.data)}`);
      console.log('Login with profile headers successful:', response.data);
    } catch (err) {
      console.error('Error testing login with profile headers:', err);
      let errorMessage = 'Unknown error';
      
      if (axios.isAxiosError(err)) {
        errorMessage = `Status: ${err.response?.status}, Message: ${err.message}`;
        if (err.response?.data) {
          errorMessage += `, Data: ${JSON.stringify(err.response.data)}`;
        }
      } else if (err instanceof Error) {
        errorMessage = err.message;
      }
      
      setTestResult(`Login with profile headers failed! Error: ${errorMessage}`);
    }
  };

  const testTokenFormats = async () => {
    if (!token) {
      setError('No token available to test');
      return;
    }

    try {
      setTestResult('Testing different token formats...');
      
      // Test different token formats
      const formats = [
        { name: 'Raw token (no Bearer)', token: token },
        { name: 'Bearer with space', token: `Bearer ${token}` },
        { name: 'bearer with lowercase', token: `bearer ${token}` },
        { name: 'Token with space', token: `Token ${token}` }
      ];
      
      const results = [];
      
      for (const format of formats) {
        try {
          const response = await axios.post('https://comms.integraledger.com/api/auth/login', {}, {
            headers: {
              'Authorization': format.token,
              'Content-Type': 'application/json'
            }
          });
          
          results.push(`${format.name}: Success - ${response.status}`);
        } catch (err) {
          if (axios.isAxiosError(err)) {
            const statusCode = err.response?.status || 'unknown';
            const errorDetail = err.response?.data?.detail || err.message;
            results.push(`${format.name}: Failed - ${statusCode} - ${errorDetail}`);
          } else {
            results.push(`${format.name}: Failed - ${err instanceof Error ? err.message : String(err)}`);
          }
        }
      }
      
      setTestResult(`Token format tests completed:\n${results.join('\n')}`);
    } catch (err) {
      console.error('Error testing token formats:', err);
      setTestResult(`Token format tests failed: ${err instanceof Error ? err.message : String(err)}`);
    }
  };

  return (
    <div className="p-4 bg-white rounded shadow-sm">
      <h2 className="text-xl font-semibold mb-4">Privy Authentication Test</h2>
      
      <div className="mb-4">
        <p><strong>Status:</strong> {authenticated ? 'Authenticated' : 'Not Authenticated'}</p>
        <p><strong>User:</strong> {user ? `ID: ${user.id}` : 'No user'}</p>
        <p><strong>User Profile:</strong> {userProfile ? `ID: ${userProfile.profile_id}` : 'No user profile'}</p>
        <p><strong>Organization ID:</strong> {organizationId || 'Not set'}</p>
        <p><strong>Profile ID:</strong> {profileId || 'Not set'}</p>
      </div>
      
      {token && (
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Token Information</h3>
          <p><strong>Token Length:</strong> {token.length} characters</p>
          <p><strong>Token Preview:</strong> {token.substring(0, 20)}...</p>
          
          {tokenDetails && tokenDetails.type === 'JWT' && (
            <div className="mt-2">
              <h4 className="font-semibold">JWT Details</h4>
              <p><strong>Algorithm:</strong> {tokenDetails.algorithm}</p>
              <p><strong>Key ID:</strong> {tokenDetails.keyId}</p>
              <p><strong>Issuer:</strong> {tokenDetails.issuer}</p>
              <p><strong>Audience:</strong> {tokenDetails.audience}</p>
              <p><strong>Subject:</strong> {tokenDetails.subject}</p>
              <p><strong>Expires At:</strong> {tokenDetails.expiresAt}</p>
              <p><strong>Issued At:</strong> {tokenDetails.issuedAt}</p>
              
              <div className="mt-2">
                <h5 className="font-semibold">Header</h5>
                <pre className="bg-gray-100 p-2 rounded text-xs overflow-auto max-h-40">
                  {JSON.stringify(tokenDetails.header, null, 2)}
                </pre>
              </div>
              
              <div className="mt-2">
                <h5 className="font-semibold">Payload</h5>
                <pre className="bg-gray-100 p-2 rounded text-xs overflow-auto max-h-40">
                  {JSON.stringify(tokenDetails.payload, null, 2)}
                </pre>
              </div>
            </div>
          )}
          
          {tokenDetails && tokenDetails.type !== 'JWT' && (
            <div className="mt-2">
              <p><strong>Token Type:</strong> {tokenDetails.type}</p>
              <p><strong>Format:</strong> {tokenDetails.format}</p>
              {tokenDetails.error && <p><strong>Error:</strong> {tokenDetails.error}</p>}
            </div>
          )}
        </div>
      )}
      
      <div className="flex flex-wrap gap-2 mb-4">
        <button 
          onClick={testDirectAuth}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Test Health Endpoint
        </button>
        <button 
          onClick={testLoginEndpoint}
          className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
        >
          Test Login Endpoint
        </button>
        <button 
          onClick={testV3Endpoint}
          className="px-4 py-2 bg-purple-500 text-white rounded hover:bg-purple-600"
        >
          Test V3 API
        </button>
        <button 
          onClick={testWithProfileHeaders}
          className="px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600"
        >
          Test With Profile Headers
        </button>
        <button 
          onClick={testTokenFormats}
          className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
        >
          Test Different Token Formats
        </button>
      </div>
      
      {testResult && (
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Test Result</h3>
          <pre className="bg-gray-100 p-4 rounded text-sm overflow-auto max-h-60 whitespace-pre-wrap">
            {testResult}
          </pre>
        </div>
      )}
      
      {error && (
        <div className="mb-4 p-4 bg-red-100 text-red-700 rounded">
          <h3 className="font-semibold mb-2">Error</h3>
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export default PrivyAuthTest;
