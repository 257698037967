/**
 * Document Verification Service
 * 
 * This service handles document verification against the blockchain.
 * It makes API calls to the backend V3 workflows and processes the results.
 */

import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { V3BlockchainService } from './V3BlockchainService';
import { isDocumentVerified, parseVerificationResult, extractBackendFormattedDocuments, prepareDocumentForSync, StandardizedDocument } from '../utils/blockchainResultParser';
import { usePDFStore, BlockchainDocument } from '../store/pdfStore';

// Define API base URL
const API_BASE_URL = `${import.meta.env.VITE_API_URL || ''}/v3`;

// Define verification result interface
interface VerificationResult {
  verified: boolean;
  documentHash: string;
  workflowId: string;
  documents: StandardizedDocument[];
  status: string;
  message?: string;
  rawResponse?: any;
  error?: any;
}

// Define synchronization result interface
interface SyncResult {
  success: boolean;
  message: string;
  recordsProcessed: number;
  recordsCreated: number;
  workflowId: string;
}

/**
 * Document Verification Service
 */
class DocumentVerificationService {
  /**
   * Verify a document by its hash
   * 
   * @param documentHash - The hash of the document to verify
   * @param profileId - The profile ID of the user
   * @param organizationId - The organization ID of the user
   * @returns A verification result object
   */
  async verifyDocumentByHash(
    documentHash: string,
    profileId: string,
    organizationId: string,
    workflowId: string = uuidv4()
  ): Promise<any> {
    try {
      console.log(`[DocumentVerificationService] Starting verification for hash: ${documentHash}`);
      console.log(`[DocumentVerificationService] Profile ID: ${profileId}, Organization ID: ${organizationId}`);
      
      // Make API request to the backend to start the workflow
      const response = await axios.post(
        `${API_BASE_URL}/get-documents-by-documenthash`,
        {
          document_hash: documentHash,
          workflow_id: workflowId,
          organization_id: organizationId,
          profile_id: profileId
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-profile-id': profileId,
            'x-organization-id': organizationId
          }
        }
      );
      
      console.log('Initial workflow response:', response.data);
      
      // Check if the workflow started successfully
      if (!response.data.success) {
        console.error('Failed to start verification workflow:', response.data);
        return {
          verified: false,
          documentHash,
          workflowId,
          documents: [],
          status: 'ERROR',
          message: response.data.message || 'Failed to start verification workflow',
          rawResponse: response.data
        };
      }
      
      // Poll for the workflow result
      const workflowResult = await this.pollWorkflowResult(workflowId, profileId, organizationId);
      
      console.log('Final workflow result:', workflowResult);
      
      // Determine if the document was verified successfully
      const verified = isDocumentVerified(workflowResult);
      console.log(`[DocumentVerificationService] Document verified: ${verified}`);
      
      // Parse the verification result to extract standardized documents
      const standardizedDocuments = parseVerificationResult(workflowResult);
      console.log(`[DocumentVerificationService] Parsed ${standardizedDocuments.length} standardized documents`);
      
      // Extract documents in the format needed by the backend
      const backendFormattedDocuments = extractBackendFormattedDocuments(workflowResult);
      console.log(`[DocumentVerificationService] Extracted ${backendFormattedDocuments.length} backend-formatted documents`);
      console.log(`[DocumentVerificationService] Backend formatted documents:`, JSON.stringify(backendFormattedDocuments, null, 2));
      
      // Save the blockchain documents to the PDFStore
      usePDFStore.getState().setBlockchainDocuments(backendFormattedDocuments);
      console.log(`[DocumentVerificationService] Saved ${backendFormattedDocuments.length} blockchain documents to PDFStore`);
      console.log(`[DocumentVerificationService] PDFStore blockchain documents:`, JSON.stringify(usePDFStore.getState().blockchainDocuments, null, 2));
      
      // Log the PDFStore state to see what's stored
      console.log(`[DocumentVerificationService] Full PDFStore state:`, {
        fileHash: usePDFStore.getState().fileHash,
        fileName: usePDFStore.getState().fileName,
        documentName: usePDFStore.getState().metadata?.title,
        blockchainDocumentsCount: usePDFStore.getState().blockchainDocuments?.length || 0
      });
      
      // Return the verification result
      return {
        ...workflowResult,
        verified,
        standardizedDocuments,
        blockchain_documents: backendFormattedDocuments
      };
    } catch (error) {
      console.error('[DocumentVerificationService] Error verifying document:', error);
      throw error;
    }
  }
  
  /**
   * Poll for the workflow result
   * 
   * @param workflowId - The ID of the workflow to poll
   * @param profileId - The profile ID of the user
   * @param organizationId - The organization ID of the user
   * @returns The workflow result
   */
  private async pollWorkflowResult(workflowId: string, profileId: string, organizationId: string): Promise<any> {
    // Maximum number of polling attempts
    const maxAttempts = 10;
    
    // Delay between polling attempts (in milliseconds)
    const pollingDelay = 1000;
    
    // Poll for the workflow result
    for (let attempt = 0; attempt < maxAttempts; attempt++) {
      try {
        // Make API request to get the workflow result
        const response = await axios.get(
          `${API_BASE_URL}/workflow-status/${workflowId}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'x-profile-id': profileId,
              'x-organization-id': organizationId
            }
          }
        );
        
        console.log(`Polling attempt ${attempt + 1}/${maxAttempts}:`, response.data);
        
        // Check if the workflow is complete
        if (response.data.data?.status === 'COMPLETED') {
          // Return the result object which contains the actual verification data
          return response.data.data.result;
        }
        
        // Wait before the next polling attempt
        await new Promise(resolve => setTimeout(resolve, pollingDelay));
      } catch (error) {
        console.error(`Error polling workflow result (attempt ${attempt + 1}/${maxAttempts}):`, error);
        
        // Wait before the next polling attempt
        await new Promise(resolve => setTimeout(resolve, pollingDelay));
      }
    }
    
    // Return a timeout error if the maximum number of attempts is reached
    return {
      success: false,
      status: 'TIMEOUT',
      error: 'Workflow polling timed out',
      workflow_id: workflowId
    };
  }

  /**
   * Synchronize document records with the backend
   * 
   * @param documentHash Document hash
   * @param verificationResult Verification result from blockchain
   * @param profileId User profile ID
   * @param organizationId Organization ID
   * @returns Promise resolving to sync result
   */
  async syncDocumentRecord(
    documentHash: string,
    verificationResult: VerificationResult,
    profileId: string,
    organizationId: string
  ): Promise<SyncResult> {
    try {
      console.log(`[DocumentVerificationService] Synchronizing document with hash: ${documentHash}`);
      
      // Generate a workflow ID
      const workflowId = `sync-${documentHash.substring(0, 8)}-${Date.now()}`;
      
      // Use the blockchain result parser to prepare the verification result for sync
      const formattedVerificationResult = prepareDocumentForSync(documentHash, verificationResult);
      
      console.log(`[DocumentVerificationService] Formatted verification result:`, {
        hasBlockchainDocuments: !!formattedVerificationResult.blockchain_documents,
        blockchainDocumentsCount: formattedVerificationResult.blockchain_documents?.length || 0,
        resultKeys: Object.keys(formattedVerificationResult)
      });
      
      // Make API request to the backend
      const response = await axios.post(
        `${API_BASE_URL}/sync-document-record`,
        {
          workflow_id: workflowId,
          organization_id: organizationId,
          profile_id: profileId,
          document_hash: documentHash,
          blockchain_documents: formattedVerificationResult.blockchain_documents,
          document_name: formattedVerificationResult.document_name,
          file_name: formattedVerificationResult.file_name,
          file_type: formattedVerificationResult.file_type,
          blockchain: formattedVerificationResult.blockchain,
          blockchain_tx: formattedVerificationResult.blockchain_tx,
          source_organization_id: formattedVerificationResult.source_organization_id,
          integra_id: formattedVerificationResult.integra_id
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-profile-id': profileId,
            'x-organization-id': organizationId
          }
        }
      );
      
      console.log('Document synchronization response:', response.data);
      
      // Return the synchronization result
      return {
        success: response.data?.success || false,
        message: response.data?.sync_result?.message || 'Synchronization completed',
        recordsProcessed: response.data?.sync_result?.records_processed || 0,
        recordsCreated: response.data?.sync_result?.records_created || 0,
        workflowId: response.data?.workflow_id || workflowId
      };
    } catch (error) {
      console.error('Error synchronizing document:', error);
      throw error;
    }
  }
}

// Create and export a singleton instance
const documentVerificationService = new DocumentVerificationService();
export default documentVerificationService;
