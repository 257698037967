/**
 * Workflow Store
 * 
 * A Zustand store for managing workflow selection and progression:
 * - Selected workflow (verify, register, tokenize, communicate)
 * - Workflow state
 * 
 * This store is focused solely on workflow concerns, separate from
 * document data, verification, or token concerns.
 */

import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

// Define workflow type
export type WorkflowType = 'verify' | 'register' | 'tokenize' | 'communicate' | null;

// Define workflow state interface
export interface WorkflowState {
  // Workflow data
  selectedWorkflow: WorkflowType;
  previousWorkflow: WorkflowType;
  
  // Actions
  setSelectedWorkflow: (workflow: WorkflowType) => void;
  setPreviousWorkflow: (workflow: WorkflowType) => void;
  
  reset: () => void;
}

// Create the workflow store
export const useWorkflowStore = create<WorkflowState>()(
  devtools(
    persist(
      (set) => ({
        // Initial state
        selectedWorkflow: null,
        previousWorkflow: null,
        
        // Actions
        setSelectedWorkflow: (workflow) => set((state) => ({ 
          selectedWorkflow: workflow,
          previousWorkflow: state.selectedWorkflow
        })),
        setPreviousWorkflow: (workflow) => set({ previousWorkflow: workflow }),
        
        reset: () => set({
          selectedWorkflow: null,
          previousWorkflow: null
        })
      }),
      {
        name: 'workflow-store',
        // Only persist these fields
        partialize: (state) => ({
          selectedWorkflow: state.selectedWorkflow,
          previousWorkflow: state.previousWorkflow
        })
      }
    )
  )
);
