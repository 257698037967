import React from 'react';
import { 
  Box, 
  Container, 
  Heading, 
  SimpleGrid, 
  Text, 
  Button, 
  Flex, 
  Icon, 
  Stat, 
  StatLabel, 
  StatNumber, 
  StatHelpText,
  useColorModeValue
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FiFileText, FiCheckCircle, FiUpload, FiSearch } from 'react-icons/fi';
import PendingInvitations from '../components/PendingInvitations';
import { useEnhancedAuth } from '../providers/EnhancedPostgresAuthProvider';

/**
 * Dashboard component
 * 
 * Main landing page for the application that provides quick access
 * to core functionality and displays summary statistics.
 */
const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const { userProfile } = useEnhancedAuth();
  
  // Colors
  const cardBg = useColorModeValue('white', 'gray.700');
  const cardBorder = useColorModeValue('gray.200', 'gray.600');
  const statBg = useColorModeValue('blue.50', 'blue.900');
  
  return (
    <Container maxW="container.xl" py={8}>
      <Heading as="h1" size="xl" mb={8} textAlign="center">
        Integra Ledger Dashboard
      </Heading>
      
      {/* Display pending invitations if the user has an email */}
      {userProfile?.email && (
        <PendingInvitations 
          email={userProfile.email} 
          onAcceptInvitation={() => console.log('Invitation accepted')} 
        />
      )}
      
      {/* Quick Action Cards */}
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8} mb={12}>
        <Box 
          p={6} 
          borderRadius="lg" 
          boxShadow="md" 
          bg={cardBg} 
          borderWidth="1px" 
          borderColor={cardBorder}
          transition="all 0.3s"
          _hover={{ transform: 'translateY(-5px)', boxShadow: 'lg' }}
        >
          <Flex direction="column" align="center" textAlign="center">
            <Icon as={FiSearch} boxSize={12} color="blue.500" mb={4} />
            <Heading as="h3" size="md" mb={2}>
              Verify Document
            </Heading>
            <Text mb={4} color="gray.600">
              Verify if a document has been registered on the blockchain
            </Text>
            <Button 
              colorScheme="blue" 
              leftIcon={<FiCheckCircle />}
              onClick={() => navigate('/verify')}
              size="lg"
              width="full"
            >
              Verify Document
            </Button>
          </Flex>
        </Box>
        
        <Box 
          p={6} 
          borderRadius="lg" 
          boxShadow="md" 
          bg={cardBg} 
          borderWidth="1px" 
          borderColor={cardBorder}
          transition="all 0.3s"
          _hover={{ transform: 'translateY(-5px)', boxShadow: 'lg' }}
        >
          <Flex direction="column" align="center" textAlign="center">
            <Icon as={FiUpload} boxSize={12} color="green.500" mb={4} />
            <Heading as="h3" size="md" mb={2}>
              Register Document
            </Heading>
            <Text mb={4} color="gray.600">
              Register a new document on the blockchain
            </Text>
            <Button 
              colorScheme="green" 
              leftIcon={<FiFileText />}
              onClick={() => navigate('/register')}
              size="lg"
              width="full"
            >
              Register Document
            </Button>
          </Flex>
        </Box>
      </SimpleGrid>
      
      {/* Statistics */}
      <Heading as="h2" size="lg" mb={4}>
        Your Documents
      </Heading>
      
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6} mb={8}>
        <Stat bg={statBg} p={4} borderRadius="md" boxShadow="sm">
          <StatLabel>Total Documents</StatLabel>
          <StatNumber>0</StatNumber>
          <StatHelpText>All documents</StatHelpText>
        </Stat>
        
        <Stat bg={statBg} p={4} borderRadius="md" boxShadow="sm">
          <StatLabel>Verified Documents</StatLabel>
          <StatNumber>0</StatNumber>
          <StatHelpText>Blockchain verified</StatHelpText>
        </Stat>
        
        <Stat bg={statBg} p={4} borderRadius="md" boxShadow="sm">
          <StatLabel>Pending Documents</StatLabel>
          <StatNumber>0</StatNumber>
          <StatHelpText>Awaiting verification</StatHelpText>
        </Stat>
      </SimpleGrid>
      
      {/* Document Management Button */}
      <Flex justify="center" mt={8}>
        <Button 
          size="lg" 
          colorScheme="blue" 
          variant="outline"
          leftIcon={<FiFileText />}
          onClick={() => navigate('/documents')}
        >
          Manage Documents
        </Button>
      </Flex>
    </Container>
  );
};

export default Dashboard;
