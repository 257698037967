import React from 'react';
import { Container, ContainerProps } from '@chakra-ui/react';

interface StandardPageLayoutProps extends ContainerProps {
  children: React.ReactNode;
}

/**
 * StandardPageLayout Component
 * 
 * A standardized layout component used across all pages in the application.
 * Ensures consistent width, padding, and styling for all content.
 * 
 * Uses Chakra UI's Container with maxW="container.lg" by default.
 */
const StandardPageLayout: React.FC<StandardPageLayoutProps> = ({
  children,
  maxW = "container.lg",
  py = 8,
  ...rest
}) => {
  return (
    <Container maxW={maxW} py={py} {...rest}>
      {children}
    </Container>
  );
};

export default StandardPageLayout;
