/**
 * Workflow Routing Service
 * 
 * This service handles navigation between different workflows in the application.
 * It provides a clean interface for transitioning between verification, registration,
 * tokenization, and communication workflows.
 */

import { NavigateFunction } from 'react-router-dom';
import { WorkflowType } from '../store/workflowStore';

/**
 * Workflow Routing Service
 */
class WorkflowRoutingService {
  /**
   * Navigate to a specific workflow
   * 
   * @param workflow - The workflow to navigate to
   * @param navigate - The navigate function from react-router-dom
   * @param options - Additional options for navigation
   */
  navigateToWorkflow(
    workflow: WorkflowType,
    navigate: NavigateFunction,
    options: {
      replace?: boolean;
      state?: any;
    } = {}
  ): void {
    if (!workflow) {
      console.warn('No workflow specified for navigation');
      return;
    }
    
    let path = '/';
    
    switch (workflow) {
      case 'verify':
        path = '/verify';
        break;
      case 'register':
        path = '/register';
        break;
      case 'tokenize':
        path = '/tokenize';
        break;
      case 'communicate':
        path = '/communicate';
        break;
      default:
        console.warn(`Unknown workflow: ${workflow}`);
        break;
    }
    
    // Navigate to the workflow
    navigate(path, {
      replace: options.replace,
      state: options.state
    });
  }
  
  /**
   * Navigate to the verification result page
   * 
   * @param navigate - The navigate function from react-router-dom
   * @param options - Additional options for navigation
   */
  navigateToVerificationResult(
    navigate: NavigateFunction,
    options: {
      replace?: boolean;
      state?: any;
    } = {}
  ): void {
    navigate('/verification-result', {
      replace: options.replace,
      state: options.state
    });
  }
  
  /**
   * Navigate to the home page
   * 
   * @param navigate - The navigate function from react-router-dom
   * @param options - Additional options for navigation
   */
  navigateToHome(
    navigate: NavigateFunction,
    options: {
      replace?: boolean;
      state?: any;
    } = {}
  ): void {
    navigate('/', {
      replace: options.replace,
      state: options.state
    });
  }
  
  /**
   * Navigate back to the previous page
   * 
   * @param navigate - The navigate function from react-router-dom
   */
  navigateBack(navigate: NavigateFunction): void {
    navigate(-1);
  }
  
  /**
   * Get the route for a specific workflow
   * 
   * @param workflow - The workflow to get the route for
   * @returns The route for the workflow
   */
  getWorkflowRoute(workflow: WorkflowType): string {
    switch (workflow) {
      case 'verify':
        return '/verify';
      case 'register':
        return '/register';
      case 'tokenize':
        return '/tokenize';
      case 'communicate':
        return '/communicate';
      default:
        return '/';
    }
  }
}

// Create and export a singleton instance
const workflowRoutingService = new WorkflowRoutingService();
export default workflowRoutingService;
