/**
 * PDFViewer Component
 * 
 * A component for viewing PDF documents:
 * - Renders PDF pages
 * - Handles navigation
 * - Supports fullscreen mode
 * - Displays loading and error states
 * 
 * This component is part of the V3 architecture that separates concerns
 * and improves maintainability.
 */

import { useState, useEffect, useMemo, useRef } from 'react';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import {
  Box,
  VStack,
  Flex,
  Text,
  IconButton,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Center,
  useBreakpointValue,
  useDisclosure,
  Alert,
  AlertIcon
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { ArrowsPointingOutIcon } from '@heroicons/react/24/outline';
import { Icon } from '@chakra-ui/react';

// PDF viewer props interface
export interface PDFViewerProps {
  /** PDF file to display */
  file: File | Uint8Array | string | null;
  
  /** Initial page number */
  initialPage?: number;
  
  /** Width of the PDF viewer */
  width?: number | string;
  
  /** Height of the PDF viewer */
  height?: number | string;
  
  /** Whether to show page controls */
  showControls?: boolean;
  
  /** Whether to show fullscreen button */
  showFullscreenButton?: boolean;
  
  /** Whether to render text layer */
  renderTextLayer?: boolean;
  
  /** Whether to render annotation layer */
  renderAnnotationLayer?: boolean;
  
  /** Scale factor for the PDF (1.0 = 100%) */
  scale?: number;
  
  /** QR code placement option */
  qrCodePlacement?: 'right' | 'left' | 'cover' | null;
  
  /** Callback when document is loaded successfully */
  onDocumentLoadSuccess?: (numPages: number) => void;
  
  /** Callback when document fails to load */
  onDocumentLoadError?: (error: Error) => void;
  
  /** Callback when page changes */
  onPageChange?: (pageNumber: number) => void;
}

/**
 * PDF Viewer Component
 */
export function PDFViewer({
  file,
  initialPage = 1,
  width = '100%',
  height = '600px',
  showControls = true,
  showFullscreenButton = true,
  renderTextLayer = true,
  renderAnnotationLayer = true,
  scale = 1.0,
  qrCodePlacement = null,
  onDocumentLoadSuccess,
  onDocumentLoadError,
  onPageChange
}: PDFViewerProps) {
  // State
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(initialPage);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  
  // Modal state for fullscreen view
  const { isOpen: isModalOpen, onOpen: openModal, onClose: closeModal } = useDisclosure();
  
  // Responsive width
  const viewerWidth = useBreakpointValue({ base: '100%', md: width });
  
  // Convert string width to number for calculations
  const numericViewerWidth = useMemo(() => {
    if (typeof viewerWidth === 'number') {
      return viewerWidth;
    }
    
    if (typeof viewerWidth === 'string' && viewerWidth.endsWith('px')) {
      return parseInt(viewerWidth, 10);
    }
    
    return undefined;
  }, [viewerWidth]);
  
  // State for container dimensions
  const [containerDimensions, setContainerDimensions] = useState({ width: 0, height: 0 });
  const containerRef = useRef<HTMLDivElement>(null);
  
  // Update container dimensions on resize
  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        setContainerDimensions({
          width: containerRef.current.clientWidth,
          height: containerRef.current.clientHeight
        });
      }
    };
    
    // Initial measurement
    updateDimensions();
    
    // Add resize listener
    window.addEventListener('resize', updateDimensions);
    
    // Cleanup
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);
  
  // Memoize PDF options to avoid unnecessary reloads
  const pdfOptions = useMemo(() => ({
    cMapUrl: 'https://unpkg.com/pdfjs-dist@3.11.174/cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'https://unpkg.com/pdfjs-dist@3.11.174/standard_fonts/'
  }), []);

  // Reset page number when file changes
  useEffect(() => {
    setPageNumber(initialPage);
    setLoading(true);
    setError(null);
  }, [file, initialPage]);
  
  // Notify parent when page changes
  useEffect(() => {
    onPageChange?.(pageNumber);
  }, [pageNumber, onPageChange]);
  
  /**
   * Handle document load success
   * @param pdf PDF document
   */
  const handleDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setLoading(false);
    setError(null);
    onDocumentLoadSuccess?.(numPages);
  };
  
  /**
   * Handle document load error
   * @param error Error object
   */
  const handleDocumentLoadError = (error: Error) => {
    console.error('Error loading PDF:', error);
    setError(error.message || 'Failed to load PDF');
    setLoading(false);
    
    // Check for specific errors
    if (error.message.includes('version') && error.message.includes('does not match')) {
      setError('PDF.js version mismatch. Please check that the API and Worker versions match.');
    } else if (error.message.includes('worker')) {
      setError('PDF.js worker failed to load. Please check your internet connection.');
    }
    
    // Call the onDocumentLoadError callback if provided
    onDocumentLoadError?.(error);
  };
  
  /**
   * Navigate to previous page
   */
  const goToPreviousPage = () => {
    setPageNumber((prev) => Math.max(prev - 1, 1));
  };
  
  /**
   * Navigate to next page
   */
  const goToNextPage = () => {
    setPageNumber((prev) => Math.min(prev + 1, numPages));
  };

  // If no file is provided, show a message
  if (!file) {
    return (
      <Center height={height} width={width} bg="gray.100" borderRadius="md">
        <Text color="gray.500">No PDF file selected</Text>
      </Center>
    );
  }

  return (
    <VStack spacing={4} width={viewerWidth} align="center">
      {/* PDF Viewer */}
      <Box
        position="relative"
        width="100%"
        height={height}
        bg="gray.100"
        borderRadius="md"
        overflow="hidden"
      >
        {/* Loading Spinner */}
        {loading && (
          <Center position="absolute" top={0} left={0} right={0} bottom={0} zIndex={1}>
            <Spinner size="xl" color="blue.500" thickness="4px" />
          </Center>
        )}

        {/* Error Message */}
        {error && (
          <Center position="absolute" top={0} left={0} right={0} bottom={0} zIndex={1} p={4}>
            <Alert status="error" borderRadius="md">
              <AlertIcon />
              {error}
            </Alert>
          </Center>
        )}

        {/* PDF Document */}
        <Center 
          height="100%" 
          width="100%" 
          overflow="auto" 
          alignItems="flex-start" 
          pt={2} 
          position="relative"
          ref={containerRef}
        >
          <Document
            file={file}
            onLoadSuccess={handleDocumentLoadSuccess}
            onLoadError={handleDocumentLoadError}
            loading={null} // We handle loading state ourselves
            options={pdfOptions}
          >
            {!loading && !error && (
              <Page
                pageNumber={pageNumber}
                width={containerDimensions.width > 0 ? containerDimensions.width - 40 : undefined}
                renderTextLayer={renderTextLayer}
                renderAnnotationLayer={renderAnnotationLayer}
                scale={numericViewerWidth ? (numericViewerWidth / 800) * scale : scale}
              >
                {/* QR Code Overlay */}
                {qrCodePlacement && pageNumber === 1 && (
                  <>
                    {qrCodePlacement === 'right' && (
                      <Box
                        position="absolute"
                        top="0"
                        right="0"
                        width="75px"
                        height="75px"
                        bg="white"
                        borderRadius="0 0 0 md"
                        border="1px solid"
                        borderColor="gray.300"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        boxShadow="lg"
                        zIndex={10}
                        p={0}
                      >
                        <Box as="img" src="/sample-qr.png" alt="QR Code" width="60px" height="60px" />
                      </Box>
                    )}
                    
                    {qrCodePlacement === 'left' && (
                      <Box
                        position="absolute"
                        top="0"
                        left="0"
                        width="75px"
                        height="75px"
                        bg="white"
                        borderRadius="0 0 md 0"
                        border="1px solid"
                        borderColor="gray.300"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        boxShadow="lg"
                        zIndex={10}
                        p={0}
                      >
                        <Box as="img" src="/sample-qr.png" alt="QR Code" width="60px" height="60px" />
                      </Box>
                    )}
                  </>
                )}
              </Page>
            )}
          </Document>

          {/* Cover Page QR Code Overlay */}
          {qrCodePlacement === 'cover' && pageNumber === 1 && !loading && !error && (
            <Box
              position="absolute"
              top="0"
              left="0"
              right="0"
              bottom="0"
              bg="white"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              zIndex={10}
            >
              <Box
                width="150px"
                height="150px"
                bg="white"
                borderRadius="md"
                border="1px solid"
                borderColor="gray.300"
                display="flex"
                alignItems="center"
                justifyContent="center"
                boxShadow="md"
                mb={4}
              >
                <Box as="img" src="/sample-qr.png" alt="QR Code" width="130px" height="130px" />
              </Box>
              <Text fontWeight="bold" fontSize="lg" mb={2}>Integra Smart Document</Text>
              <Text color="gray.600">Scan this QR code to verify document authenticity</Text>
            </Box>
          )}
        </Center>

        {/* Page Controls */}
        {showControls && numPages > 0 && (
          <Flex
            position="absolute"
            bottom={4}
            left={0}
            right={0}
            justifyContent="center"
            alignItems="center"
            zIndex={1}
          >
            <Flex
              bg="blackAlpha.600"
              color="white"
              borderRadius="full"
              px={4}
              py={2}
              alignItems="center"
              boxShadow="md"
            >
              <IconButton
                aria-label="Previous page"
                icon={<ChevronLeftIcon />}
                size="sm"
                variant="ghost"
                colorScheme="whiteAlpha"
                isDisabled={pageNumber <= 1}
                onClick={goToPreviousPage}
              />
              <Text mx={4} fontSize="sm">
                {pageNumber} / {numPages}
              </Text>
              <IconButton
                aria-label="Next page"
                icon={<ChevronRightIcon />}
                size="sm"
                variant="ghost"
                colorScheme="whiteAlpha"
                isDisabled={pageNumber >= numPages}
                onClick={goToNextPage}
              />
            </Flex>
          </Flex>
        )}

        {/* Fullscreen Button */}
        {showFullscreenButton && (
          <IconButton
            aria-label="Fullscreen"
            icon={<Icon as={ArrowsPointingOutIcon} />}
            size="sm"
            position="absolute"
            top={4}
            right={4}
            zIndex={1}
            colorScheme="blackAlpha"
            onClick={openModal}
          />
        )}
      </Box>

      {/* Fullscreen Modal */}
      <Modal isOpen={isModalOpen} onClose={closeModal} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton zIndex={2} color="white" />
          <ModalBody p={0}>
            <Box height="calc(100vh - 80px)" width="100%" overflow="auto">
              <Center height="100%" width="100%" alignItems="flex-start" pt={2}>
                <Document
                  file={file}
                  options={pdfOptions}
                >
                  <Page
                    pageNumber={pageNumber}
                    width={typeof window !== 'undefined' ? window.innerWidth * 0.8 : 800}
                    renderTextLayer={renderTextLayer}
                    renderAnnotationLayer={renderAnnotationLayer}
                    scale={scale}
                  />
                </Document>
              </Center>
            </Box>
            {/* Modal Page Controls */}
            {numPages > 0 && (
              <Flex
                position="absolute"
                bottom={8}
                left={0}
                right={0}
                justifyContent="center"
                alignItems="center"
              >
                <Flex
                  bg="blackAlpha.700"
                  color="white"
                  borderRadius="full"
                  px={6}
                  py={3}
                  alignItems="center"
                  boxShadow="dark-lg"
                >
                  <IconButton
                    aria-label="Previous page"
                    icon={<ChevronLeftIcon />}
                    size="md"
                    variant="ghost"
                    colorScheme="whiteAlpha"
                    isDisabled={pageNumber <= 1}
                    onClick={goToPreviousPage}
                  />
                  <Text mx={6} fontSize="md">
                    {pageNumber} / {numPages}
                  </Text>
                  <IconButton
                    aria-label="Next page"
                    icon={<ChevronRightIcon />}
                    size="md"
                    variant="ghost"
                    colorScheme="whiteAlpha"
                    isDisabled={pageNumber >= numPages}
                    onClick={goToNextPage}
                  />
                </Flex>
              </Flex>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  );
}

export default PDFViewer;
