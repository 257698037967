import { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  Button,
  useToast,
  Flex,
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { useEnhancedAuth } from '../providers/EnhancedPostgresAuthProvider';
import { OrganizationInvitation } from '../lib/postgres-client';
import postgresClient from '../lib/postgres-client';

const InvitationAccept = () => {
  const { token } = useParams<{ token: string }>();
  const { userProfile, acceptInvitation } = useEnhancedAuth();
  const [invitation, setInvitation] = useState<OrganizationInvitation | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyToken = async () => {
      if (!token) {
        setError('Invalid invitation link');
        setLoading(false);
        return;
      }

      try {
        const verifiedInvitation = await postgresClient.verifyInvitationToken(token);
        setInvitation(verifiedInvitation);
      } catch (error) {
        console.error('Error verifying invitation:', error);
        setError('This invitation is invalid or has expired');
      } finally {
        setLoading(false);
      }
    };

    verifyToken();
  }, [token]);

  const handleAcceptInvitation = async () => {
    if (!invitation || !userProfile) return;
    
    setProcessing(true);
    try {
      const success = await acceptInvitation(invitation.invitation_id);
      if (success) {
        toast({
          title: 'Invitation accepted',
          description: `You have joined ${invitation.organization_name}`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        navigate('/dashboard');
      }
    } catch (error) {
      toast({
        title: 'Error accepting invitation',
        description: error instanceof Error ? error.message : 'Unknown error',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setProcessing(false);
    }
  };

  const handleDeclineInvitation = () => {
    navigate('/dashboard');
  };

  if (loading) {
    return (
      <Flex justify="center" align="center" h="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (error || !invitation) {
    return (
      <Container maxW="container.md" py={8}>
        <Alert status="error" borderRadius="md" mb={4}>
          <AlertIcon />
          <AlertTitle mr={2}>Invalid Invitation</AlertTitle>
          <AlertDescription>{error || 'This invitation is invalid or has expired'}</AlertDescription>
        </Alert>
        <Button colorScheme="blue" onClick={() => navigate('/dashboard')}>
          Go to Dashboard
        </Button>
      </Container>
    );
  }

  if (invitation.status !== 'pending') {
    return (
      <Container maxW="container.md" py={8}>
        <Alert status="info" borderRadius="md" mb={4}>
          <AlertIcon />
          <AlertTitle mr={2}>Invitation Already Processed</AlertTitle>
          <AlertDescription>
            This invitation has already been {invitation.status}.
          </AlertDescription>
        </Alert>
        <Button colorScheme="blue" onClick={() => navigate('/dashboard')}>
          Go to Dashboard
        </Button>
      </Container>
    );
  }

  return (
    <Container maxW="container.md" py={8}>
      <Box p={6} borderWidth="1px" borderRadius="lg" boxShadow="md">
        <Heading size="lg" mb={4}>
          Organization Invitation
        </Heading>
        <Text fontSize="md" mb={6}>
          You have been invited to join <strong>{invitation.organization_name}</strong> as a{' '}
          <strong>{invitation.role}</strong>.
        </Text>

        <Flex gap={4} mt={4}>
          <Button
            colorScheme="green"
            isLoading={processing}
            onClick={handleAcceptInvitation}
            flex="1"
          >
            Accept Invitation
          </Button>
          <Button
            colorScheme="gray"
            variant="outline"
            onClick={handleDeclineInvitation}
            flex="1"
          >
            Decline
          </Button>
        </Flex>
      </Box>
    </Container>
  );
};

export default InvitationAccept;
