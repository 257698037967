import { extendTheme, ThemeConfig } from '@chakra-ui/react';

// Color mode config
const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false, // Set to false to use our custom toggle
};

// Integra theme colors
const colors = {
  integra: {
    // Primary colors
    blue: '#0066FF', // Primary brand color
    lightBlue: '#4D94FF', // Lighter blue for hover states
    darkBlue: '#0055DD', // Darker blue for active states
    
    // Light mode colors
    light: {
      background: '#FFFFFF',
      surface: '#F8F9FA',
      border: '#E2E8F0',
      text: {
        primary: '#1A202C',
        secondary: '#4A5568',
        tertiary: '#718096',
      },
    },
    
    // Dark mode colors
    dark: {
      background: '#000000', // Pure black background
      surface: '#111111', // Slightly lighter than background for cards
      border: '#333333', // Border color for dark mode
      text: {
        primary: '#FFFFFF',
        secondary: '#CBD5E0',
        tertiary: '#A0AEC0',
      },
    },
    
    // Status colors (same for both modes)
    success: '#38A169', // Green
    warning: '#DD6B20', // Orange
    error: '#E53E3E', // Red
    info: '#3182CE', // Blue
  },
};

// Font configuration
const fonts = {
  body: 'Inter, system-ui, sans-serif',
  heading: 'Inter, system-ui, sans-serif',
};

// Component style overrides
const components = {
  Button: {
    baseStyle: {
      fontWeight: 'medium',
      borderRadius: 'md',
    },
    variants: {
      solid: (props: any) => ({
        bg: props.colorMode === 'dark' ? 'integra.blue' : 'integra.blue',
        color: 'white',
        _hover: {
          bg: props.colorMode === 'dark' ? 'integra.lightBlue' : 'integra.lightBlue',
        },
        _active: {
          bg: props.colorMode === 'dark' ? 'integra.darkBlue' : 'integra.darkBlue',
        },
      }),
      outline: (props: any) => ({
        borderColor: props.colorMode === 'dark' ? 'integra.blue' : 'integra.blue',
        color: props.colorMode === 'dark' ? 'integra.blue' : 'integra.blue',
        _hover: {
          bg: props.colorMode === 'dark' ? 'rgba(0, 102, 255, 0.1)' : 'rgba(0, 102, 255, 0.1)',
        },
      }),
      ghost: (props: any) => ({
        color: props.colorMode === 'dark' ? 'integra.dark.text.primary' : 'integra.light.text.primary',
        _hover: {
          bg: props.colorMode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)',
        },
      }),
      link: (props: any) => ({
        color: props.colorMode === 'dark' ? 'integra.blue' : 'integra.blue',
        _hover: {
          textDecoration: 'underline',
        },
      }),
    },
    defaultProps: {
      colorScheme: 'integra',
    },
  },
  Heading: {
    baseStyle: (props: any) => ({
      fontWeight: 'bold',
      color: props.colorMode === 'dark' ? 'integra.dark.text.primary' : 'integra.light.text.primary',
    }),
  },
  Text: {
    baseStyle: (props: any) => ({
      color: props.colorMode === 'dark' ? 'integra.dark.text.primary' : 'integra.light.text.primary',
    }),
  },
  Card: {
    baseStyle: (props: any) => ({
      container: {
        bg: props.colorMode === 'dark' ? 'integra.dark.surface' : 'integra.light.surface',
        borderRadius: 'lg',
        borderWidth: '1px',
        borderColor: props.colorMode === 'dark' ? 'integra.dark.border' : 'integra.light.border',
      },
    }),
  },
  Link: {
    baseStyle: (props: any) => ({
      color: props.colorMode === 'dark' ? 'integra.blue' : 'integra.blue',
      _hover: {
        textDecoration: 'underline',
      },
    }),
  },
  Alert: {
    variants: {
      solid: (props: any) => ({
        container: {
          bg: props.colorMode === 'dark' ? 'transparent' : 'white',
          borderWidth: '1px',
          borderColor: `${props.status}.500`,
        },
      }),
      subtle: (props: any) => ({
        container: {
          bg: props.colorMode === 'dark' ? 'transparent' : `${props.status}.50`,
          borderWidth: '1px',
          borderColor: `${props.status}.500`,
        },
      }),
    },
  },
};

// Breakpoints
const breakpoints = {
  sm: '30em', // 480px
  md: '48em', // 768px
  lg: '62em', // 992px
  xl: '80em', // 1280px
  '2xl': '96em', // 1536px
};

// Create the theme
const theme = extendTheme({
  config,
  colors,
  fonts,
  components,
  breakpoints,
  styles: {
    global: (props: any) => ({
      body: {
        bg: props.colorMode === 'dark' ? 'integra.dark.background' : 'integra.light.background',
        color: props.colorMode === 'dark' ? 'integra.dark.text.primary' : 'integra.light.text.primary',
      },
      '#root': {
        bg: props.colorMode === 'dark' ? 'integra.dark.background' : 'integra.light.background',
        minHeight: '100vh',
      },
    }),
  },
});

export default theme;
