/**
 * Verification Not Found
 * 
 * This component displays when a document is not found on the blockchain.
 * It provides options for next steps, such as registering the document.
 */

import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  VStack,
  HStack,
  Icon,
  Button,
  Badge,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { 
  FiAlertCircle, 
  FiUpload,
  FiCodesandbox,
  FiChevronRight
} from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { usePrivy } from '@privy-io/react-auth';

// Import layouts
import { ContentLayout } from '../../layouts';

// Import services
import workflowManagerService from '../../services/WorkflowManagerService';
import workflowRoutingService from '../../services/WorkflowRoutingService';

// Import stores
import { usePDFStore } from '../../store/pdfStore';
import { useWorkflowStore, WorkflowType } from '../../store/workflowStore';

// Import authentication
import { useEnhancedAuth } from '../../providers/EnhancedPostgresAuthProvider';

// Define component props
interface VerificationNotFoundProps {
  onWorkflowSelect: (workflow: WorkflowType) => void;
  onContinue: () => void;
  isAuthenticated: boolean;
}

/**
 * Verification Not Found Component
 */
const VerificationNotFound: React.FC<VerificationNotFoundProps> = ({
  onWorkflowSelect,
  onContinue,
  isAuthenticated
}) => {
  // Hooks
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { login } = usePrivy();  // Use Privy for login functionality
  const { userProfile } = useEnhancedAuth(); // Use EnhancedPostgresAuthProvider for authentication state
  
  // Get PDF store state
  const { fileName, fileHash } = usePDFStore();
  
  // Get workflow store state and actions
  const { setSelectedWorkflow } = useWorkflowStore();
  
  // Theme colors
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('red.200', 'red.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const badgeBgColor = useColorModeValue('red.100', 'red.700');
  const badgeTextColor = useColorModeValue('red.800', 'white');
  
  // Handle workflow selection
  const handleWorkflowSelect = (workflow: WorkflowType) => {
    if (!isAuthenticated) {
      console.log("Authentication Required");
      login(); // Open login modal
      return;
    }
    
    // Set the selected workflow
    setSelectedWorkflow(workflow);
    
    // Log the navigation
    console.log(`Navigating to ${workflow} workflow`);
    
    // Navigate to the selected workflow
    workflowRoutingService.navigateToWorkflow(workflow, navigate);
  };
  
  return (
    <ContentLayout>
      <Box
        p={6}
        borderWidth="1px"
        borderRadius="lg"
        borderColor={borderColor}
        bg={bgColor}
        boxShadow="md"
        width="100%"
      >
        <VStack spacing={6} align="stretch">
          <Flex
            width="100%"
            direction="column"
            align="center"
            justify="center"
            textAlign="center"
            mb={4}
          >
            <Icon as={FiAlertCircle} color="orange.500" boxSize={12} mb={4} />
            <Heading as="h2" size="xl" mb={2} color={textColor}>
              Document Not Found
            </Heading>
            <Text fontSize="md" color="gray.500">
              This document has not been registered on the blockchain.
            </Text>
            <Badge
              mt={2}
              px={3}
              py={1}
              borderRadius="full"
              bg={badgeBgColor}
              color={badgeTextColor}
            >
              Not Registered
            </Badge>
          </Flex>
          
          {fileHash && (
            <Box p={4} bg="gray.50" borderRadius="md">
              <VStack spacing={3} align="stretch">
                <HStack>
                  <Icon as={FiUpload} color="blue.500" />
                  <Text fontWeight="bold">File Name:</Text>
                  <Text>{fileName || 'Unknown'}</Text>
                </HStack>
                
                <HStack>
                  <Icon as={FiUpload} color="blue.500" />
                  <Text fontWeight="bold">File Hash:</Text>
                  <Text isTruncated>{fileHash ? `${fileHash.substring(0, 8)}...${fileHash.substring(fileHash.length - 8)}` : 'Unknown'}</Text>
                </HStack>
              </VStack>
            </Box>
          )}
          
          <Text fontSize="md" textAlign="center">
            Would you like to register this document on the blockchain?
          </Text>
          
          <HStack spacing={4} justify="center">
            <Button
              leftIcon={<FiUpload />}
              colorScheme="blue"
              onClick={() => handleWorkflowSelect('register')}
            >
              Register Document
            </Button>
            
            <Button
              leftIcon={<FiCodesandbox />}
              variant="outline"
              colorScheme="purple"
              onClick={() => handleWorkflowSelect('tokenize')}
            >
              Tokenize Document
            </Button>
          </HStack>
          
          {!isAuthenticated && (
            <Text mt={2} fontSize="sm" color="gray.500" textAlign="center">
              You need to log in to continue with any of these options.
            </Text>
          )}
        </VStack>
      </Box>
    </ContentLayout>
  );
};

export default VerificationNotFound;
