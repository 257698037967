/**
 * Document Table
 * 
 * This component displays a table of document records that have been
 * synchronized with the local database.
 */

import React from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Badge,
  Icon,
  Tooltip,
  Link,
  HStack,
  useColorModeValue,
  Skeleton,
  Heading
} from '@chakra-ui/react';
import { 
  FiFileText, 
  FiCalendar, 
  FiHash, 
  FiExternalLink
} from 'react-icons/fi';

// Import types
import { documentSyncService } from '../../services/DocumentSyncService';

// Define document record interface
interface DocumentRecord {
  document_id?: string;
  document_name?: string;
  file_name?: string;
  document_hash?: string;
  blockchain_tx?: string;
  created_at?: string;
  integra_id?: string;
}

// Define component props
interface DocumentTableProps {
  documents: DocumentRecord[];
  isLoading?: boolean;
}

/**
 * Document Table Component
 * 
 * Displays a table of document records with key information.
 */
const DocumentTable: React.FC<DocumentTableProps> = ({
  documents,
  isLoading = false
}) => {
  // Theme colors
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const headerBgColor = useColorModeValue('gray.50', 'gray.700');
  
  // Format date for display
  const formatDate = (dateString: string | undefined) => {
    if (!dateString) return 'Not available';
    try {
      const date = new Date(dateString);
      return date.toLocaleString();
    } catch (error) {
      return dateString;
    }
  };
  
  // Format hash for display
  const formatHash = (hash: string | undefined) => {
    if (!hash) return 'Not available';
    if (hash.length <= 16) return hash;
    return `${hash.substring(0, 8)}...${hash.substring(hash.length - 8)}`;
  };
  
  // Get explorer URL
  const getExplorerUrl = (hash: string | undefined) => {
    if (!hash) return '';
    return `https://polygonscan.com/tx/${hash}`;
  };
  
  // Loading state
  if (isLoading) {
    return (
      <Box 
        borderWidth="1px" 
        borderRadius="md" 
        borderColor={borderColor}
        overflow="hidden"
      >
        <Skeleton height="40px" />
        <Skeleton height="52px" mt="4px" />
        <Skeleton height="52px" mt="4px" />
      </Box>
    );
  }
  
  // Empty state
  if (!documents || documents.length === 0) {
    return (
      <Box 
        borderWidth="1px" 
        borderRadius="md" 
        borderColor={borderColor}
        p={4}
        textAlign="center"
      >
        <Text color={textColor}>No document records found</Text>
      </Box>
    );
  }
  
  // Display document records
  return (
    <Box 
      borderWidth="1px" 
      borderRadius="md" 
      borderColor={borderColor}
      overflow="hidden"
      p={4}
    >
      <Heading as="h4" size="sm" mb={3}>Document Records</Heading>
      {documents.map((document, index) => (
        <Box 
          key={document.document_id || index} 
          mb={index < documents.length - 1 ? 3 : 0}
          p={2}
          borderRadius="md"
          _hover={{ bg: useColorModeValue('gray.50', 'gray.700') }}
        >
          <HStack spacing={2} alignItems="flex-start">
            <Icon as={FiFileText} color="blue.500" mt={1} />
            <Box flex="1">
              <Text fontWeight="semibold">{document.document_name || 'Unnamed Document'}</Text>
              <Text fontSize="sm" color="gray.500">{document.file_name || 'No filename'}</Text>
              
              <HStack mt={2} spacing={4}>
                <Box>
                  <Text fontSize="xs" fontWeight="bold" color="gray.500">DOCUMENT HASH</Text>
                  <Text fontSize="xs" fontFamily="mono">{formatHash(document.document_hash)}</Text>
                </Box>
                
                <Box>
                  <Text fontSize="xs" fontWeight="bold" color="gray.500">INTEGRA ID</Text>
                  <Text fontSize="xs" fontFamily="mono">{formatHash(document.integra_id)}</Text>
                </Box>
              </HStack>
              
              {document.blockchain_tx ? (
                <Box mt={2}>
                  <Text fontSize="xs" fontWeight="bold" color="gray.500">BLOCKCHAIN TX</Text>
                  <HStack spacing={2}>
                    <Text fontSize="xs" fontFamily="mono">{formatHash(document.blockchain_tx)}</Text>
                    <Link 
                      href={getExplorerUrl(document.blockchain_tx)} 
                      isExternal
                      color="blue.500"
                      fontSize="xs"
                    >
                      <HStack spacing={1}>
                        <Text>View on Explorer</Text>
                        <Icon as={FiExternalLink} boxSize={3} />
                      </HStack>
                    </Link>
                  </HStack>
                </Box>
              ) : (
                <Box mt={2}>
                  <Text fontSize="xs" fontWeight="bold" color="gray.500">BLOCKCHAIN TX</Text>
                  <Text fontSize="xs" color="gray.500">Not yet registered on blockchain</Text>
                </Box>
              )}
              
              <Text fontSize="xs" color="gray.500" mt={2}>
                Created: {formatDate(document.created_at)}
              </Text>
            </Box>
          </HStack>
        </Box>
      ))}
    </Box>
  );
};

export default DocumentTable;
