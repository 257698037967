import React from 'react';
import { Box, Button, Heading, Text, VStack, Icon, useColorModeValue } from '@chakra-ui/react';
import { FiAlertCircle, FiHome } from 'react-icons/fi';
import { Link as RouterLink } from 'react-router-dom';

/**
 * NotFound Component
 * 
 * A page displayed when a route is not found
 */
const NotFound: React.FC = () => {
  const bgColor = useColorModeValue('integra.light.surface', 'integra.dark.surface');
  const textColor = useColorModeValue('integra.light.text.primary', 'integra.dark.text.primary');
  const borderColor = useColorModeValue('integra.light.border', 'integra.dark.border');
  
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="calc(100vh - 70px)"
      bg={useColorModeValue('integra.light.background', 'integra.dark.background')}
    >
      <Box
        p={8}
        borderRadius="lg"
        bg={bgColor}
        borderWidth="1px"
        borderColor={borderColor}
        maxW="container.md"
        mx="auto"
        color={textColor}
      >
        <VStack spacing={6} align="center" textAlign="center">
          <Icon as={FiAlertCircle} boxSize={16} color="red.500" />
          
          <Heading as="h1" size="xl" color={textColor}>
            404 - Page Not Found
          </Heading>
          
          <Text fontSize="lg" color={textColor}>
            The page you are looking for might have been removed, had its name changed, 
            or is temporarily unavailable.
          </Text>
          
          <Button
            as={RouterLink}
            to="/"
            colorScheme="blue"
            size="lg"
            leftIcon={<FiHome />}
          >
            Go to Home
          </Button>
        </VStack>
      </Box>
    </Box>
  );
};

export default NotFound;
