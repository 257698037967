/**
 * V3 Workflow Types
 * 
 * This file contains type definitions for the V3 blockchain workflows.
 * These types match the backend API structure exactly.
 */

/**
 * Register Document Params
 */
export interface RegisterDocumentParams {
  workflow_id?: string;
  organization_id: string;
  profile_id: string;
  document_hash: string;
  integra_id: string;
  reference_id?: string;
  encrypted_data?: string;
  tokens_to_reserve?: TokenReservation[];
}

/**
 * Token Reservation
 */
export interface TokenReservation {
  label: string;
  optionalData?: string;
  amount: number;
}

/**
 * Tokenize Document Params
 */
export interface TokenizeDocumentParams {
  workflow_id?: string;
  organization_id: string;
  profile_id: string;
  document_hash: string;
  integra_id: string;
  reference_id?: string;
  encrypted_data?: string;
  tokens_to_reserve?: TokenReservation[];
}

/**
 * Get Documents By Document Hash Params
 */
export interface GetDocumentsByDocumentHashParams {
  workflow_id?: string;
  organization_id: string;
  profile_id: string;
  document_hash: string;
}

/**
 * Verify Document Params (alias for GetDocumentsByDocumentHashParams)
 */
export interface VerifyDocumentParams extends GetDocumentsByDocumentHashParams {}

/**
 * Get Documents By Integra ID Params
 */
export interface GetDocumentsByIntegraIdParams {
  workflow_id?: string;
  organization_id: string;
  profile_id: string;
  integra_id: string;
}

/**
 * Get Documents By Reference ID Params
 */
export interface GetDocumentsByReferenceIdParams {
  workflow_id?: string;
  organization_id: string;
  profile_id: string;
  reference_id: string;
}

/**
 * Document Registration Result
 */
export interface DocumentRegistrationResult {
  integra_hash: string;
  transaction_hash: string;
  document_hash: string;
  integra_id: string;
  reference_id?: string;
  encrypted_data?: string;
  tokens?: TokenInfo[];
  status: string;
  timestamp: string;
}

/**
 * Token Info
 */
export interface TokenInfo {
  token_id: string;
  label: string;
  data?: string;
  amount: number;
}

/**
 * Document Verification Result
 */
export interface DocumentVerificationResult {
  documents: DocumentInfo[];
  count: number;
}

/**
 * Document Info
 */
export interface DocumentInfo {
  integra_hash: string;
  transaction_hash: string;
  document_hash: string;
  integra_id: string;
  reference_id?: string;
  encrypted_data?: string;
  status: string;
  timestamp: string;
}

/**
 * Workflow Status
 */
export interface WorkflowStatus {
  success: boolean;
  message: string;
  data: {
    status: string;
    result?: {
      workflow_id: string;
      organization_id: string;
      profile_id: string;
      status: string;
      success: boolean;
      query_result: {
        success: boolean;
        documents?: {
          success: boolean;
          data: Array<{
            integraHash: string;
            documentHash: string;
            referenceHash?: string;
            issuer?: string;
            encryptedData?: string;
            txHash?: string;
          }>;
          timestamp: string;
        };
        tokens?: {
          success: boolean;
          reservedTokens: Array<{
            label: string;
            optionalData: string;
            integraHash: string;
            amount: string;
            holder: string;
            tokenId: string;
          }>;
          timestamp: string;
        };
        timestamp?: string;
      };
      error?: string;
    };
  };
  errors?: any;
}

/**
 * Workflow Response
 * 
 * This is the initial response when starting a workflow
 */
export interface WorkflowResponse {
  success: boolean;
  message: string;
  data: {
    workflow_id: string;
  };
  errors: any;
}

/**
 * V3 Workflow Map
 * 
 * Maps workflow names to their parameter and result types
 */
export interface V3WorkflowMap {
  'register-document': {
    params: RegisterDocumentParams;
    result: WorkflowResponse;
  };
  'tokenize-document': {
    params: TokenizeDocumentParams;
    result: WorkflowResponse;
  };
  'get-documents-by-documenthash': {
    params: GetDocumentsByDocumentHashParams;
    result: WorkflowResponse;
  };
  'get-documents-by-integraid': {
    params: GetDocumentsByIntegraIdParams;
    result: WorkflowResponse;
  };
  'get-documents-by-referenceid': {
    params: GetDocumentsByReferenceIdParams;
    result: WorkflowResponse;
  };
  'get-token-balance': {
    params: GetTokenBalanceParams;
    result: WorkflowResponse;
  };
  'get-reserved-tokens': {
    params: GetReservedTokensParams;
    result: GetReservedTokensResult;
  };
  'send-signal-payment-request': {
    params: SendSignalPaymentRequestParams;
    result: WorkflowResponse;
  };
  'send-signal-message': {
    params: SendSignalMessageParams;
    result: WorkflowResponse;
  };
  'get-signal-messages': {
    params: GetSignalMessagesParams;
    result: WorkflowResponse;
  };
  'get-signal-payment-requests': {
    params: GetSignalPaymentRequestsParams;
    result: WorkflowResponse;
  };
  'accept-signal-payment-request': {
    params: AcceptSignalPaymentRequestParams;
    result: WorkflowResponse;
  };
  'reject-signal-payment-request': {
    params: RejectSignalPaymentRequestParams;
    result: WorkflowResponse;
  };
  'cancel-signal-payment-request': {
    params: CancelSignalPaymentRequestParams;
    result: WorkflowResponse;
  };
  'sync-document-record': {
    params: SyncDocumentRecordParams;
    result: SyncDocumentRecordResult;
  };
}

/**
 * Sync Document Record Parameters
 */
export interface SyncDocumentRecordParams {
  workflow_id?: string;
  organization_id: string;
  profile_id: string;
  document_hash?: string;
  verification_result?: any;
  document_name?: string | null;
  file_name?: string | null;
  file_type?: string | null;
  blockchain?: string | null;
  blockchain_tx?: string | null;
  source_organization_id?: string | null;
  integra_id?: string | null;
  blockchain_documents?: Array<{
    integra_hash: string;
    document_hash: string;
    tx_hash?: string;
    reference_hash?: string;
    encrypted_data?: string;
  }>;
}

/**
 * Sync Document Record Result
 */
export interface SyncDocumentRecordResult {
  success: boolean;
  message: string;
  data?: {
    workflow_id: string;
    local_documents?: Array<{
      document_id: string;
      organization_id: string;
      profile_id: string;
      document_name: string;
      file_name: string;
      file_type: string;
      document_hash: string;
      blockchain: string;
      blockchain_tx: string;
      integra_id: string;
      created_at: string;
      updated_at: string;
    }>;
  };
  errors?: any;
}

/**
 * Get Token Balance Parameters
 */
export interface GetTokenBalanceParams {
  workflow_id: string;
  profile_id: string;
  organization_id: string;
  address: string;
}

/**
 * Get Reserved Tokens Parameters
 */
export interface GetReservedTokensParams {
  integra_hash: string;
}

/**
 * Get Reserved Tokens Result
 */
export interface GetReservedTokensResult {
  success: boolean;
  message: string;
  data?: {
    workflow_id: string;
    tokens?: Array<{
      label: string;
      description?: string;
      optionalData?: string;
      integraHash: string;
      amount: string;
      tokenId: string;
      id?: string;
    }>;
    result?: {
      tokens?: Array<any>;
      query_result?: {
        tokens?: {
          reservedTokens?: Array<any>;
        };
      };
    };
  };
  errors?: any;
}

/**
 * Send Signal Payment Request Parameters
 */
export interface SendSignalPaymentRequestParams {
  workflow_id: string;
  profile_id: string;
  organization_id: string;
  integra_id: string;
  amount: number;
  payment_token: string;
  optional_data?: string;
}

/**
 * Send Signal Message Parameters
 */
export interface SendSignalMessageParams {
  workflow_id: string;
  profile_id: string;
  organization_id: string;
  integra_id: string;
  message: string;
  optional_data?: string;
}

/**
 * Get Signal Messages Parameters
 */
export interface GetSignalMessagesParams {
  workflow_id: string;
  profile_id: string;
  organization_id: string;
  integra_id: string;
}

/**
 * Get Signal Payment Requests Parameters
 */
export interface GetSignalPaymentRequestsParams {
  workflow_id: string;
  profile_id: string;
  organization_id: string;
  integra_id: string;
}

/**
 * Accept Signal Payment Request Parameters
 */
export interface AcceptSignalPaymentRequestParams {
  workflow_id: string;
  profile_id: string;
  organization_id: string;
  payment_request_id: string;
}

/**
 * Reject Signal Payment Request Parameters
 */
export interface RejectSignalPaymentRequestParams {
  workflow_id: string;
  profile_id: string;
  organization_id: string;
  payment_request_id: string;
}

/**
 * Cancel Signal Payment Request Parameters
 */
export interface CancelSignalPaymentRequestParams {
  workflow_id: string;
  profile_id: string;
  organization_id: string;
  payment_request_id: string;
}

/**
 * V3 Workflow Names
 * 
 * Enum of all V3 workflow names
 */
export enum V3WorkflowNames {
  REGISTER_DOCUMENT = 'register-document',
  TOKENIZE_DOCUMENT = 'tokenize-document',
  GET_DOCUMENTS_BY_DOCUMENTHASH = 'get-documents-by-documenthash',
  GET_DOCUMENTS_BY_INTEGRAID = 'get-documents-by-integraid',
  GET_DOCUMENTS_BY_REFERENCEID = 'get-documents-by-referenceid',
  GET_TOKEN_BALANCE = 'get-token-balance',
  GET_RESERVED_TOKENS = 'get-reserved-tokens',
  SEND_SIGNAL_PAYMENT_REQUEST = 'send-signal-payment-request',
  SEND_SIGNAL_MESSAGE = 'send-signal-message',
  GET_SIGNAL_MESSAGES = 'get-signal-messages',
  GET_SIGNAL_PAYMENT_REQUESTS = 'get-signal-payment-requests',
  ACCEPT_SIGNAL_PAYMENT_REQUEST = 'accept-signal-payment-request',
  REJECT_SIGNAL_PAYMENT_REQUEST = 'reject-signal-payment-request',
  CANCEL_SIGNAL_PAYMENT_REQUEST = 'cancel-signal-payment-request',
  SYNC_DOCUMENT_RECORD = 'sync-document-record'
}

/**
 * V3 Workflow Method
 */
export type V3WorkflowMethod = 'GET' | 'POST';

/**
 * V3 Workflow Config
 * 
 * Configuration for a V3 workflow
 */
export interface V3WorkflowConfig {
  name: string;
  endpoint: string;
  method: 'GET' | 'POST';
  idempotent: boolean;
  description: string;
}

/**
 * Create V3 Workflow Configs
 */
export const V3WorkflowConfigs: Record<string, V3WorkflowConfig> = {
  [V3WorkflowNames.REGISTER_DOCUMENT]: {
    name: 'register-document',
    endpoint: '/register-document',
    method: 'POST',
    idempotent: false,
    description: 'Register a document on the blockchain'
  },
  [V3WorkflowNames.TOKENIZE_DOCUMENT]: {
    name: 'tokenize-document',
    endpoint: '/tokenize-document',
    method: 'POST',
    idempotent: false,
    description: 'Tokenize a document on the blockchain'
  },
  [V3WorkflowNames.GET_DOCUMENTS_BY_DOCUMENTHASH]: {
    name: 'get-documents-by-documenthash',
    endpoint: '/get-documents-by-documenthash',
    method: 'POST',
    idempotent: true,
    description: 'Get documents by document hash'
  },
  [V3WorkflowNames.GET_DOCUMENTS_BY_INTEGRAID]: {
    name: 'get-documents-by-integraid',
    endpoint: '/get-documents-by-integraid',
    method: 'POST',
    idempotent: true,
    description: 'Get documents by Integra ID'
  },
  [V3WorkflowNames.GET_DOCUMENTS_BY_REFERENCEID]: {
    name: 'get-documents-by-referenceid',
    endpoint: '/get-documents-by-referenceid',
    method: 'POST',
    idempotent: true,
    description: 'Get documents by reference ID'
  },
  [V3WorkflowNames.GET_TOKEN_BALANCE]: {
    name: 'get-token-balance',
    endpoint: '/get-token-balance',
    method: 'POST',
    idempotent: true,
    description: 'Get token balance'
  },
  [V3WorkflowNames.GET_RESERVED_TOKENS]: {
    name: 'get-reserved-tokens',
    endpoint: '/get-reserved-tokens',
    method: 'POST',
    idempotent: true,
    description: 'Get reserved tokens'
  },
  [V3WorkflowNames.SEND_SIGNAL_PAYMENT_REQUEST]: {
    name: 'send-signal-payment-request',
    endpoint: '/send-signal-payment-request',
    method: 'POST',
    idempotent: false,
    description: 'Send signal payment request'
  },
  [V3WorkflowNames.SEND_SIGNAL_MESSAGE]: {
    name: 'send-signal-message',
    endpoint: '/send-signal-message',
    method: 'POST',
    idempotent: false,
    description: 'Send signal message'
  },
  [V3WorkflowNames.GET_SIGNAL_MESSAGES]: {
    name: 'get-signal-messages',
    endpoint: '/get-signal-messages',
    method: 'POST',
    idempotent: true,
    description: 'Get signal messages'
  },
  [V3WorkflowNames.GET_SIGNAL_PAYMENT_REQUESTS]: {
    name: 'get-signal-payment-requests',
    endpoint: '/get-signal-payment-requests',
    method: 'POST',
    idempotent: true,
    description: 'Get signal payment requests'
  },
  [V3WorkflowNames.ACCEPT_SIGNAL_PAYMENT_REQUEST]: {
    name: 'accept-signal-payment-request',
    endpoint: '/accept-signal-payment-request',
    method: 'POST',
    idempotent: false,
    description: 'Accept signal payment request'
  },
  [V3WorkflowNames.REJECT_SIGNAL_PAYMENT_REQUEST]: {
    name: 'reject-signal-payment-request',
    endpoint: '/reject-signal-payment-request',
    method: 'POST',
    idempotent: false,
    description: 'Reject signal payment request'
  },
  [V3WorkflowNames.CANCEL_SIGNAL_PAYMENT_REQUEST]: {
    name: 'cancel-signal-payment-request',
    endpoint: '/cancel-signal-payment-request',
    method: 'POST',
    idempotent: false,
    description: 'Cancel signal payment request'
  },
  [V3WorkflowNames.SYNC_DOCUMENT_RECORD]: {
    name: 'sync-document-record',
    endpoint: '/sync-document-record',
    method: 'POST',
    idempotent: true,
    description: 'Synchronize blockchain documents with local database records'
  }
};
