import React from 'react';
import { Box, Flex, FlexProps } from '@chakra-ui/react';
import AppLayout from './AppLayout';

/**
 * SplitPageLayout Component
 * 
 * A specialized layout for pages that display content in a 50/50 split,
 * typically used for pages that show a document preview on one side
 * and interactive content on the other.
 * 
 * This component builds on the AppLayout to ensure consistent outer
 * container styling while providing the split layout structure.
 */
interface SplitPageLayoutProps {
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
  flexProps?: FlexProps;
  fullHeight?: boolean;
}

const SplitPageLayout: React.FC<SplitPageLayoutProps> = ({
  leftContent,
  rightContent,
  flexProps,
  fullHeight = true
}) => {
  return (
    <AppLayout fullHeight={fullHeight}>
      <Flex
        direction={{ base: 'column', lg: 'row' }}
        gap={{ base: 4, md: 6 }}
        width="100%"
        justify="space-between"
        align="flex-start"
        {...flexProps}
      >
        {/* Left Column */}
        <Box 
          width={{ base: '100%', lg: '48%' }}
          flexShrink={0}
        >
          {leftContent}
        </Box>
        
        {/* Right Column */}
        <Box 
          width={{ base: '100%', lg: '48%' }}
          flexShrink={0}
        >
          {rightContent}
        </Box>
      </Flex>
    </AppLayout>
  );
};

export default SplitPageLayout;
