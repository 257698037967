import React from 'react';
import { Box, BoxProps, useColorModeValue } from '@chakra-ui/react';

/**
 * ContentLayout Component
 * 
 * A standardized container for content sections within pages.
 * Provides consistent styling for content areas with proper
 * padding, border, and background.
 * 
 * This component should be used for all main content areas to
 * ensure a consistent look and feel throughout the application.
 */
interface ContentLayoutProps extends BoxProps {
  children: React.ReactNode;
}

const ContentLayout: React.FC<ContentLayoutProps> = ({ 
  children, 
  ...rest 
}) => {
  // Use Integra's custom theme colors
  const bgColor = useColorModeValue('integra.light.surface', 'integra.dark.surface');
  const borderColor = useColorModeValue('integra.light.border', 'integra.dark.border');
  
  return (
    <Box
      bg={bgColor}
      borderRadius="md"
      border="1px solid"
      borderColor={borderColor}
      p={{ base: 3, sm: 4, md: 6 }}
      width="100%"
      display="flex"
      flexDirection="column"
      overflow="hidden"
      {...rest}
    >
      {children}
    </Box>
  );
};

export default ContentLayout;
