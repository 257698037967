import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Text,
  VStack,
  HStack,
  Avatar,
  FormControl,
  FormLabel,
  Input,
  Divider,
  useColorModeValue,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Badge,
  Icon,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  useToast
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { 
  FiArrowLeft, 
  FiUser, 
  FiMail, 
  FiLock, 
  FiSave, 
  FiFileText,
  FiShield,
  FiKey,
  FiCreditCard
} from 'react-icons/fi';

/**
 * UserProfile Page
 * 
 * This component displays and allows editing of user profile information
 */
const UserProfile: React.FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  
  // Mock user data
  const [userData, setUserData] = useState({
    name: 'John Doe',
    email: 'john.doe@example.com',
    organization: 'Acme Inc.',
    role: 'Administrator',
    avatar: 'https://bit.ly/broken-link', // Placeholder
    joinDate: '2024-06-15'
  });
  
  // Form state
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({ ...userData });
  
  // Colors
  const cardBg = useColorModeValue('white', 'gray.700');
  const statBg = useColorModeValue('blue.50', 'blue.900');
  
  /**
   * Handle form input change
   */
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };
  
  /**
   * Handle form submission
   */
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    // In a real implementation, this would call an API to update the user profile
    setUserData(formData);
    setIsEditing(false);
    
    toast({
      title: 'Profile Updated',
      description: 'Your profile has been successfully updated.',
      status: 'success',
      duration: 3000,
      isClosable: true,
      position: 'top'
    });
  };
  
  /**
   * Format date
   */
  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString();
  };
  
  return (
    <Container maxW="container.lg" py={8}>
      <VStack spacing={8} align="stretch">
        {/* Header */}
        <Flex justify="space-between" align="center">
          <Button 
            leftIcon={<FiArrowLeft />} 
            variant="ghost" 
            onClick={() => navigate('/')}
          >
            Back to Dashboard
          </Button>
          
          <Heading as="h1" size="xl" textAlign="center" flex="1">
            User Profile
          </Heading>
          
          <Box width="40px" /> {/* Spacer for alignment */}
        </Flex>
        
        {/* Profile Overview */}
        <Card bg={cardBg} boxShadow="md">
          <CardHeader>
            <Flex justify="space-between" align="center">
              <Heading size="md">Profile Overview</Heading>
              
              {!isEditing && (
                <Button 
                  leftIcon={<FiUser />} 
                  colorScheme="blue" 
                  variant="outline"
                  onClick={() => setIsEditing(true)}
                >
                  Edit Profile
                </Button>
              )}
            </Flex>
          </CardHeader>
          
          <CardBody>
            <Flex 
              direction={{ base: 'column', md: 'row' }} 
              gap={6}
              align={{ base: 'center', md: 'flex-start' }}
            >
              <Box textAlign="center">
                <Avatar 
                  size="2xl" 
                  name={userData.name} 
                  src={userData.avatar}
                  mb={4}
                />
                
                <Badge colorScheme="blue" fontSize="sm" p={1}>
                  {userData.role}
                </Badge>
                
                <Text fontSize="sm" color="gray.500" mt={2}>
                  Member since {formatDate(userData.joinDate)}
                </Text>
              </Box>
              
              <Divider orientation="vertical" />
              
              <Box flex="1">
                {isEditing ? (
                  <form onSubmit={handleSubmit}>
                    <VStack spacing={4} align="stretch">
                      <FormControl>
                        <FormLabel>Full Name</FormLabel>
                        <Input 
                          name="name"
                          value={formData.name}
                          onChange={handleInputChange}
                        />
                      </FormControl>
                      
                      <FormControl>
                        <FormLabel>Email Address</FormLabel>
                        <Input 
                          name="email"
                          type="email"
                          value={formData.email}
                          onChange={handleInputChange}
                        />
                      </FormControl>
                      
                      <FormControl>
                        <FormLabel>Organization</FormLabel>
                        <Input 
                          name="organization"
                          value={formData.organization}
                          onChange={handleInputChange}
                        />
                      </FormControl>
                      
                      <HStack justify="flex-end" spacing={3} pt={4}>
                        <Button 
                          variant="outline" 
                          onClick={() => {
                            setFormData({ ...userData });
                            setIsEditing(false);
                          }}
                        >
                          Cancel
                        </Button>
                        
                        <Button 
                          type="submit" 
                          colorScheme="blue"
                          leftIcon={<FiSave />}
                        >
                          Save Changes
                        </Button>
                      </HStack>
                    </VStack>
                  </form>
                ) : (
                  <VStack spacing={4} align="stretch">
                    <HStack>
                      <Icon as={FiUser} color="blue.500" />
                      <Text fontWeight="bold" width="150px">Name:</Text>
                      <Text>{userData.name}</Text>
                    </HStack>
                    
                    <HStack>
                      <Icon as={FiMail} color="blue.500" />
                      <Text fontWeight="bold" width="150px">Email:</Text>
                      <Text>{userData.email}</Text>
                    </HStack>
                    
                    <HStack>
                      <Icon as={FiFileText} color="blue.500" />
                      <Text fontWeight="bold" width="150px">Organization:</Text>
                      <Text>{userData.organization}</Text>
                    </HStack>
                  </VStack>
                )}
              </Box>
            </Flex>
          </CardBody>
        </Card>
        
        {/* Activity and Settings */}
        <Tabs colorScheme="blue" variant="enclosed">
          <TabList>
            <Tab>Activity</Tab>
            <Tab>Security</Tab>
            <Tab>API Keys</Tab>
            <Tab>Billing</Tab>
          </TabList>
          
          <TabPanels>
            {/* Activity Tab */}
            <TabPanel>
              <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6} mb={8}>
                <Stat bg={statBg} p={4} borderRadius="md" boxShadow="sm">
                  <StatLabel>Documents Verified</StatLabel>
                  <StatNumber>12</StatNumber>
                  <StatHelpText>Last 30 days</StatHelpText>
                </Stat>
                
                <Stat bg={statBg} p={4} borderRadius="md" boxShadow="sm">
                  <StatLabel>Documents Registered</StatLabel>
                  <StatNumber>8</StatNumber>
                  <StatHelpText>Last 30 days</StatHelpText>
                </Stat>
                
                <Stat bg={statBg} p={4} borderRadius="md" boxShadow="sm">
                  <StatLabel>API Calls</StatLabel>
                  <StatNumber>156</StatNumber>
                  <StatHelpText>Last 30 days</StatHelpText>
                </Stat>
              </SimpleGrid>
              
              <Card bg={cardBg}>
                <CardHeader>
                  <Heading size="md">Recent Activity</Heading>
                </CardHeader>
                <CardBody>
                  <Text color="gray.500">No recent activity to display.</Text>
                </CardBody>
              </Card>
            </TabPanel>
            
            {/* Security Tab */}
            <TabPanel>
              <VStack spacing={6} align="stretch">
                <Card bg={cardBg}>
                  <CardHeader>
                    <Flex justify="space-between" align="center">
                      <HStack>
                        <Icon as={FiLock} color="blue.500" />
                        <Heading size="md">Password</Heading>
                      </HStack>
                      
                      <Button 
                        colorScheme="blue" 
                        variant="outline"
                        size="sm"
                      >
                        Change Password
                      </Button>
                    </Flex>
                  </CardHeader>
                  <CardBody>
                    <Text>Your password was last changed 30 days ago.</Text>
                  </CardBody>
                </Card>
                
                <Card bg={cardBg}>
                  <CardHeader>
                    <Flex justify="space-between" align="center">
                      <HStack>
                        <Icon as={FiShield} color="blue.500" />
                        <Heading size="md">Two-Factor Authentication</Heading>
                      </HStack>
                      
                      <Button 
                        colorScheme="green" 
                        variant="outline"
                        size="sm"
                      >
                        Enable 2FA
                      </Button>
                    </Flex>
                  </CardHeader>
                  <CardBody>
                    <Text>Enhance your account security by enabling two-factor authentication.</Text>
                  </CardBody>
                </Card>
              </VStack>
            </TabPanel>
            
            {/* API Keys Tab */}
            <TabPanel>
              <Card bg={cardBg}>
                <CardHeader>
                  <Flex justify="space-between" align="center">
                    <HStack>
                      <Icon as={FiKey} color="blue.500" />
                      <Heading size="md">API Keys</Heading>
                    </HStack>
                    
                    <Button 
                      colorScheme="blue" 
                      size="sm"
                    >
                      Generate New Key
                    </Button>
                  </Flex>
                </CardHeader>
                <CardBody>
                  <Text color="gray.500">No API keys have been created yet.</Text>
                </CardBody>
              </Card>
            </TabPanel>
            
            {/* Billing Tab */}
            <TabPanel>
              <Card bg={cardBg}>
                <CardHeader>
                  <HStack>
                    <Icon as={FiCreditCard} color="blue.500" />
                    <Heading size="md">Billing Information</Heading>
                  </HStack>
                </CardHeader>
                <CardBody>
                  <Text color="gray.500">No billing information available.</Text>
                </CardBody>
                <CardFooter>
                  <Button 
                    colorScheme="blue" 
                    variant="outline"
                    size="sm"
                  >
                    Update Billing Info
                  </Button>
                </CardFooter>
              </Card>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </Container>
  );
};

export default UserProfile;
