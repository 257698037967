/**
 * ChaCha20 Encryption Implementation
 * 
 * This is a simplified implementation of the ChaCha20 encryption algorithm
 * for use in the Integra document system. It provides a way to encrypt
 * endpoint URLs using a document's SHA256 hash as the key.
 * 
 * Note: This is a simplified implementation for demonstration purposes.
 * In a production environment, you would use a well-tested library.
 */

/**
 * Performs a left rotation (circular shift) on a 32-bit number
 */
function rotl(v: number, c: number): number {
  return ((v << c) | (v >>> (32 - c))) >>> 0;
}

/**
 * The ChaCha20 quarter round function
 */
function quarterRound(state: Uint32Array, a: number, b: number, c: number, d: number): void {
  state[a] = (state[a] + state[b]) >>> 0;
  state[d] = rotl(state[d] ^ state[a], 16);
  
  state[c] = (state[c] + state[d]) >>> 0;
  state[b] = rotl(state[b] ^ state[c], 12);
  
  state[a] = (state[a] + state[b]) >>> 0;
  state[d] = rotl(state[d] ^ state[a], 8);
  
  state[c] = (state[c] + state[d]) >>> 0;
  state[b] = rotl(state[b] ^ state[c], 7);
}

/**
 * Initialize the ChaCha20 state with key, nonce, and counter
 */
function initState(key: Uint8Array, nonce: Uint8Array, counter: number = 0): Uint32Array {
  // ChaCha20 constants "expand 32-byte k"
  const constants = new Uint32Array([
    0x61707865, 0x3320646e, 0x79622d32, 0x6b206574
  ]);
  
  // Initialize state with constants
  const state = new Uint32Array(16);
  state.set(constants, 0);
  
  // Add key (8 words = 32 bytes)
  for (let i = 0; i < 8; i++) {
    state[i + 4] = (
      key[i * 4] |
      (key[i * 4 + 1] << 8) |
      (key[i * 4 + 2] << 16) |
      (key[i * 4 + 3] << 24)
    ) >>> 0;
  }
  
  // Add counter (1 word = 4 bytes)
  state[12] = counter >>> 0;
  
  // Add nonce (3 words = 12 bytes)
  for (let i = 0; i < 3; i++) {
    state[i + 13] = (
      nonce[i * 4] |
      (nonce[i * 4 + 1] << 8) |
      (nonce[i * 4 + 2] << 16) |
      (nonce[i * 4 + 3] << 24)
    ) >>> 0;
  }
  
  return state;
}

/**
 * Perform the ChaCha20 block function
 */
function chacha20Block(state: Uint32Array): Uint32Array {
  // Create a copy of the state
  const workingState = new Uint32Array(state);
  
  // Perform 20 rounds (10 column rounds + 10 diagonal rounds)
  for (let i = 0; i < 10; i++) {
    // Column round
    quarterRound(workingState, 0, 4, 8, 12);
    quarterRound(workingState, 1, 5, 9, 13);
    quarterRound(workingState, 2, 6, 10, 14);
    quarterRound(workingState, 3, 7, 11, 15);
    
    // Diagonal round
    quarterRound(workingState, 0, 5, 10, 15);
    quarterRound(workingState, 1, 6, 11, 12);
    quarterRound(workingState, 2, 7, 8, 13);
    quarterRound(workingState, 3, 4, 9, 14);
  }
  
  // Add the working state to the initial state
  for (let i = 0; i < 16; i++) {
    workingState[i] = (workingState[i] + state[i]) >>> 0;
  }
  
  return workingState;
}

/**
 * Convert a block (Uint32Array) to bytes (Uint8Array)
 */
function blockToBytes(block: Uint32Array): Uint8Array {
  const bytes = new Uint8Array(64);
  
  for (let i = 0; i < 16; i++) {
    const word = block[i];
    bytes[i * 4] = word & 0xff;
    bytes[i * 4 + 1] = (word >>> 8) & 0xff;
    bytes[i * 4 + 2] = (word >>> 16) & 0xff;
    bytes[i * 4 + 3] = (word >>> 24) & 0xff;
  }
  
  return bytes;
}

/**
 * Derive a 32-byte key from a SHA256 hash
 */
function deriveKeyFromHash(hash: string): Uint8Array {
  // Remove 0x prefix if present
  const cleanHash = hash.startsWith('0x') ? hash.slice(2) : hash;
  
  // Convert hex string to bytes
  const key = new Uint8Array(32);
  for (let i = 0; i < 32; i++) {
    key[i] = parseInt(cleanHash.substring(i * 2, i * 2 + 2), 16);
  }
  
  return key;
}

/**
 * Encrypt data using ChaCha20
 * 
 * @param data Data to encrypt
 * @param key Encryption key (32 bytes)
 * @param nonce Nonce (12 bytes)
 * @returns Encrypted data
 */
export function chacha20Encrypt(data: Uint8Array, key: Uint8Array, nonce: Uint8Array): Uint8Array {
  const result = new Uint8Array(data.length);
  let counter = 0;
  
  for (let i = 0; i < data.length; i += 64) {
    // Generate keystream block
    const state = initState(key, nonce, counter++);
    const keyStreamBlock = blockToBytes(chacha20Block(state));
    
    // XOR data with keystream
    const blockSize = Math.min(64, data.length - i);
    for (let j = 0; j < blockSize; j++) {
      result[i + j] = data[i + j] ^ keyStreamBlock[j];
    }
  }
  
  return result;
}

/**
 * Decrypt data using ChaCha20
 * 
 * @param data Encrypted data
 * @param key Decryption key (32 bytes)
 * @param nonce Nonce (12 bytes)
 * @returns Decrypted data
 */
export function chacha20Decrypt(data: Uint8Array, key: Uint8Array, nonce: Uint8Array): Uint8Array {
  // Encryption and decryption are the same operation in ChaCha20
  return chacha20Encrypt(data, key, nonce);
}

/**
 * Encrypt a string using ChaCha20 with a SHA256 hash as the key
 * 
 * @param text Text to encrypt
 * @param sha256Hash SHA256 hash to use as the key
 * @param nonce Optional nonce (will be generated if not provided)
 * @returns Object containing the encrypted data and nonce
 */
export function encryptString(text: string, sha256Hash: string, nonce?: Uint8Array): { 
  encrypted: Uint8Array; 
  nonce: Uint8Array;
} {
  // Convert text to bytes
  const encoder = new TextEncoder();
  const data = encoder.encode(text);
  
  // Derive key from hash
  const key = deriveKeyFromHash(sha256Hash);
  
  // Generate or use provided nonce
  const nonceToUse = nonce || window.crypto.getRandomValues(new Uint8Array(12));
  
  // Encrypt data
  const encrypted = chacha20Encrypt(data, key, nonceToUse);
  
  return {
    encrypted,
    nonce: nonceToUse
  };
}

/**
 * Decrypt a string using ChaCha20 with a SHA256 hash as the key
 * 
 * @param encrypted Encrypted data
 * @param sha256Hash SHA256 hash to use as the key
 * @param nonce Nonce used for encryption
 * @returns Decrypted text
 */
export function decryptString(encrypted: Uint8Array, sha256Hash: string, nonce: Uint8Array): string {
  // Derive key from hash
  const key = deriveKeyFromHash(sha256Hash);
  
  // Decrypt data
  const decrypted = chacha20Decrypt(encrypted, key, nonce);
  
  // Convert bytes to text
  const decoder = new TextDecoder();
  return decoder.decode(decrypted);
}

/**
 * Utility function to convert a hex string to bytes
 */
export function hexToBytes(hex: string): Uint8Array {
  const cleanHex = hex.startsWith('0x') ? hex.slice(2) : hex;
  const bytes = new Uint8Array(cleanHex.length / 2);
  
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = parseInt(cleanHex.substring(i * 2, i * 2 + 2), 16);
  }
  
  return bytes;
}

/**
 * Utility function to convert bytes to a hex string
 */
export function bytesToHex(bytes: Uint8Array): string {
  return '0x' + Array.from(bytes)
    .map(b => b.toString(16).padStart(2, '0'))
    .join('');
}

/**
 * Utility function to convert bytes to base64
 */
export function bytesToBase64(bytes: Uint8Array): string {
  // Convert bytes to binary string
  let binary = '';
  for (let i = 0; i < bytes.length; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  
  // Use btoa to convert binary to base64
  return btoa(binary);
}

/**
 * Utility function to convert base64 to bytes
 */
export function base64ToBytes(base64: string): Uint8Array {
  // Use atob to convert base64 to binary
  const binary = atob(base64);
  
  // Convert binary to bytes
  const bytes = new Uint8Array(binary.length);
  for (let i = 0; i < binary.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  
  return bytes;
}
