import { PrivyProvider, PrivyProviderProps } from '@privy-io/react-auth';
import { EnhancedAuthProvider } from './EnhancedPostgresAuthProvider.tsx';
import IntegraThemeProvider from './ThemeProvider';

/**
 * Define props interface to ensure type safety
 */
interface EnhancedProvidersProps {
  children: React.ReactNode;
  delayedExecution?: boolean;
  [key: string]: any; // Allow for other props
}

/**
 * Enhanced Providers Component
 * 
 * This component wraps the application with all necessary providers:
 * - PrivyProvider for authentication
 * - IntegraThemeProvider for theming and UI components
 * - EnhancedAuthProvider for user profile and organization management
 * 
 * @param children The child components to be wrapped
 * @param delayedExecution Optional flag to delay execution of the Privy provider
 * @returns The wrapped component tree
 */
export default function EnhancedProviders({ children, delayedExecution, ...restProps }: EnhancedProvidersProps) {
  // Get Privy app ID from environment variables
  const appId = import.meta.env.VITE_PRIVY_APP_ID;
  
  // Handle missing Privy app ID
  if (!appId) {
    throw new Error('VITE_PRIVY_APP_ID is not set in environment variables');
  }
  
  // Filter out any props that might cause issues with ChakraProvider
  const safeProps = Object.keys(restProps).reduce((acc, key) => {
    if (key !== 'theme' && key !== 'resetCSS' && key !== 'portalZIndex') {
      acc[key] = (restProps as any)[key];
    }
    return acc;
  }, {} as Record<string, any>);

  // Return the provider tree
  return (
    <IntegraThemeProvider {...safeProps}>
      <PrivyProvider
        appId={appId}
        config={{
          loginMethods: [
            'google',      // Social - Google
            'github',      // Social - GitHub
            'twitter',     // Social - X (Privy uses 'twitter' as the identifier)
            'email',       // Email
            'sms',         // SMS
            'wallet',      // Wallet (includes MetaMask, Coinbase Wallet)
            'passkey'      // Passkey
          ],
          appearance: {
            theme: 'light',  
            accentColor: '#0066FF',  // Buttons and accents
            showWalletLoginFirst: false,
            walletList: [
              'metamask',           // Explicitly including MetaMask
              'coinbase_wallet',    // Explicitly including Coinbase Wallet
              'detected_wallets'    // Other detected wallets
            ]
          },
          embeddedWallets: {
            createOnLogin: 'users-without-wallets',
          },
          defaultChain: {
            id: 1,
            name: 'Ethereum',
            nativeCurrency: {
              name: 'Ether',
              symbol: 'ETH',
              decimals: 18
            },
            rpcUrls: {
              default: {
                http: ['https://eth.llamarpc.com']
              }
            }
          }
        }}
      >
        <EnhancedAuthProvider>
          {children}
        </EnhancedAuthProvider>
      </PrivyProvider>
    </IntegraThemeProvider>
  );
}
