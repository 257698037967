import React, { createContext, useContext, useState, useEffect } from 'react';
import { ChakraProvider, useColorMode, ColorModeScript } from '@chakra-ui/react';
import theme from '../theme';

// Define the context type
interface ThemeContextType {
  toggleTheme: () => void;
  currentTheme: 'light' | 'dark';
}

// Create the context with default values
const ThemeContext = createContext<ThemeContextType>({
  toggleTheme: () => {},
  currentTheme: 'dark',
});

// Custom hook to use the theme context
export const useThemeContext = () => useContext(ThemeContext);

interface ThemeProviderProps {
  children: React.ReactNode;
}

/**
 * IntegraThemeProvider Component
 * 
 * This component provides theme context to the entire application.
 * It handles theme switching between Integra Light and Integra Dark themes.
 * 
 * @param children The child components to be wrapped
 */
export const IntegraThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  // Use Chakra's color mode
  const { colorMode, toggleColorMode } = useColorMode();
  const [currentTheme, setCurrentTheme] = useState<'light' | 'dark'>(
    colorMode as 'light' | 'dark'
  );

  // Sync our state with Chakra's colorMode
  useEffect(() => {
    setCurrentTheme(colorMode as 'light' | 'dark');
  }, [colorMode]);

  // Toggle between light and dark themes
  const toggleTheme = () => {
    toggleColorMode();
    const newTheme = currentTheme === 'light' ? 'dark' : 'light';
    setCurrentTheme(newTheme);
  };

  return (
    <ThemeContext.Provider value={{ toggleTheme, currentTheme }}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <ChakraProvider theme={theme}>
        {children}
      </ChakraProvider>
    </ThemeContext.Provider>
  );
};

export default IntegraThemeProvider;
