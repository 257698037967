import React from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import { Box, Fade } from '@chakra-ui/react';
import { usePrivy } from '@privy-io/react-auth';
import { EnhancedAuthProvider } from './providers/EnhancedPostgresAuthProvider';

// Import layout
import MainLayout from './components/Layout/MainLayout';

// Import routes
import AppRoutes from './routes/AppRoutes';

// Import components
import ErrorBoundary from './components/ErrorBoundary';
import LoadingSpinner from './components/LoadingSpinner';

// Import new pages
import OrganizationSettings from './pages/OrganizationSettings';
import InvitationAccept from './pages/InvitationAccept';

/**
 * Main App component
 * 
 * Handles routing and authentication
 */
const App: React.FC = () => {
  const { ready } = usePrivy();
  
  // Show loading spinner during initialization
  if (!ready) {
    return (
      <Box 
        height="100vh" 
        display="flex" 
        alignItems="center" 
        justifyContent="center"
        bg="integra.dark.background"
      >
        <LoadingSpinner text="Loading application..." fullScreen />
      </Box>
    );
  }
  
  return (
    <ErrorBoundary>
      <EnhancedAuthProvider>
        <Fade in={ready} transition={{ enter: { duration: 0.3 } }}>
          <Router>
            <Routes>
              <Route 
                path="/*" 
                element={
                  <MainLayout>
                    <AppRoutes />
                  </MainLayout>
                } 
              />
            </Routes>
          </Router>
        </Fade>
      </EnhancedAuthProvider>
    </ErrorBoundary>
  );
};

export default App;
