import { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  Text,
  Textarea,
  VStack,
  HStack,
  useSteps,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  SimpleGrid,
  Icon,
  useColorModeValue,
  Radio,
  RadioGroup,
  Stack,
  Progress,
  Badge,
  IconButton,
} from '@chakra-ui/react';
import { FiArrowLeft, FiArrowRight, FiCheck, FiFileText, FiHome, FiPlus, FiTrash2 } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { usePDFStore } from '../store/pdfStore';
import StandardPageLayout from '../components/StandardPageLayout';
import { usePrivy } from '@privy-io/react-auth';
import { useEnhancedAuth } from '../providers/EnhancedPostgresAuthProvider';
import v3BlockchainService from '../services/V3BlockchainService';
import pdfService from '../services/PDFService';
import { PDFViewer } from '../components/PDFViewer';
import { placeQRCodeOnDocument } from '../utils/qrCodeUtils';
import createQrCode from '../assets/utils/pdf/createQrCode';
import integraQrIcon from '../assets/Integra QR icon.png';
import { calculateKeccak256String } from '../lib/hash';
import { encryptString, bytesToBase64 } from '../lib/chacha20';
import { calculateSHA256 } from '../lib/encryption';
import { STANDARD_CHACHA20_NONCE } from '../lib/encryption-constants';

/**
 * Tokenize Document Page
 * 
 * This page allows users to tokenize a document on the blockchain:
 * - Upload a document
 * - Enter document metadata
 * - Add token details
 * - Tokenize the document
 * - View tokenization confirmation
 */
export function TokenizeDocument() {
  // Hooks
  const navigate = useNavigate();
  const { authenticated } = usePrivy();
  const { userProfile, currentOrganizationId } = useEnhancedAuth();
  
  // Color mode values
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const confirmationBgColor = useColorModeValue("gray.50", "gray.700");
  const gray400 = useColorModeValue("gray.400", "gray.400");
  const gray500 = useColorModeValue("gray.500", "gray.500");
  const white = useColorModeValue("white", "gray.800");
  
  // Get PDF store actions
  const {
    pdfFile,
    fileHash,
    fileName,
    fileDataUrl,
    metadata,
    registrationData,
    tokenSets,
    originalHash,
    setRegistrationData,
    setTokenSets,
    setSmartDocHash,
    setSmartDocumentUrl,
    setSmartDocumentFile,
    setIntegraId,
    setFileHash,
    setOriginalHash,
    setProcessing,
    setError
  } = usePDFStore();
  
  // State for tokenization-specific QR code placement
  const [tokenQrCodePlacement, setTokenQrCodePlacement] = useState<'right' | 'left' | 'cover' | null>('right');
  
  // Initialize token sets if empty
  useEffect(() => {
    if (tokenSets.length === 0) {
      setTokenSets([{ id: uuidv4(), tokenName: '', tokenSymbol: '', amount: 1 }]);
    }
  }, [tokenSets, setTokenSets]);
  
  // State for document tokenization
  const [isTokenizing, setIsTokenizing] = useState<boolean>(false);
  const [tokenizationResult, setTokenizationResult] = useState<any>(null);
  const [tokenizationError, setTokenizationError] = useState<string | null>(null);
  const [hashProgress, setHashProgress] = useState<number>(0);
  const [isCalculatingHash, setIsCalculatingHash] = useState<boolean>(false);
  const [tokenizationComplete, setTokenizationComplete] = useState(false);
  const [tokenizationSuccess, setTokenizationSuccess] = useState(false);
  const [tokenizedIntegraId, setTokenizedIntegraId] = useState<string>('');
  const [processingState, setProcessingState] = useState<'idle' | 'tokenizing' | 'creating-smart-document' | 'tokenizing-blockchain'>('idle');
  
  // State for smart document
  const [smartDocumentUrl, setLocalSmartDocumentUrl] = useState<string | null>(null);
  
  // Steps for the tokenization process
  const steps = [
    { title: 'QR Code Placement', description: 'Select placement' },
    { title: 'Document Details', description: 'Add metadata' },
    { title: 'Token Details', description: 'Add tokens' },
    { title: 'Tokenize', description: 'Confirm & tokenize' },
  ];
  
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });
  
  // Calculate document hash if file is available but hash is not
  useEffect(() => {
    const calculateHash = async () => {
      if (pdfFile && !fileHash) {
        try {
          setIsCalculatingHash(true);
          
          // Calculate hash with progress updates
          const hash = await pdfService.calculateFileHash(pdfFile);
          
          // Update hash
          setFileHash(hash);
          
          setIsCalculatingHash(false);
        } catch (error) {
          console.error('Error calculating hash:', error);
          setIsCalculatingHash(false);
        }
      }
    };
    
    calculateHash();
  }, [pdfFile, fileHash, setFileHash]);
  
  // Redirect to home if no document data is available
  useEffect(() => {
    console.log('TokenizeDocument mounted, checking data:', {
      pdfFile: !!pdfFile,
      fileHash
    });
    
    // Check if we have document data
    if (!pdfFile && !fileHash) {
      console.log('No document data, redirecting to home');
      navigate('/');
      return;
    }
    
    // Initialize tokenization data
    setRegistrationData({
      documentName: metadata?.title || '',
      documentType: 'General',
      documentDescription: ''
    });
    
    console.log('Tokenization data initialized');
  }, [pdfFile, fileHash, navigate, metadata, setRegistrationData]);
  
  // Handle form field changes
  const handleFormChange = (field: string, value: string) => {
    setRegistrationData({
      ...registrationData,
      [field]: value
    });
  };
  
  // Handle token set changes
  const handleTokenSetChange = (id: string, field: string, value: string | number) => {
    setTokenSets(tokenSets.map(token => 
      token.id === id ? { ...token, [field]: value } : token
    ));
  };
  
  // Add a new token set
  const handleAddTokenSet = () => {
    setTokenSets([...tokenSets, { id: uuidv4(), tokenName: '', tokenSymbol: '', amount: 1 }]);
  };
  
  // Remove a token set
  const handleRemoveTokenSet = (id: string) => {
    setTokenSets(tokenSets.filter(token => token.id !== id));
  };
  
  // Handle step navigation
  const handleNextStep = () => {
    setActiveStep(activeStep + 1);
  };
  
  const handlePrevStep = () => {
    setActiveStep(activeStep - 1);
  };
  
  // Handle document tokenization
  const handleTokenizeDocument = async () => {
    try {
      // Validate required data
      if (!pdfFile || !fileHash || !registrationData) {
        console.error('Missing required document data');
        return;
      }

      // Validate token sets
      if (tokenSets.length === 0 || tokenSets.some(token => !token.tokenName.trim())) {
        setTokenizationError('Please add at least one token with a name');
        return;
      }
      
      // Set processing state
      setIsTokenizing(true);
      setTokenizationError(null);
      setProcessingState('tokenizing');
      
      // 1) Generate a unique Integra ID and store it in the PDFStore
      const integraId = uuidv4();
      setIntegraId(integraId);
      console.log('Generated Integra ID:', integraId);
      
      // Generate the keccak256 hash of the Integra ID for QR code and metadata
      const integraIdHash = calculateKeccak256String(integraId);
      console.log('Generated Integra ID Hash:', integraIdHash);
      
      // Save the original hash if not already saved
      if (!originalHash) {
        setOriginalHash(fileHash);
      }
      
      // Variable to hold the hash that will be tokenized
      let documentHashToTokenize = fileHash;
      
      // Process the PDF to create a smart document with QR code
      try {
        setProcessingState('creating-smart-document');
        
        // Create QR code URL with the Integra ID hash
        const qrCodeUrl = `https://console.integraledger.com/qr/${integraIdHash}`;
        
        // Generate QR code
        const qrCodeBytes = await createQrCode({
          url: qrCodeUrl,
          size: 150,
          logoUrl: integraQrIcon,
          logoScale: 0.23
        });
        
        // Get the PDF bytes
        const fileArrayBuffer = await pdfService.readAsArrayBuffer(pdfFile);
        const pdfBytes = new Uint8Array(fileArrayBuffer);
        
        // Prepare JSON data for embedding
        const jsonData = {
          document_name: registrationData.documentName,
          document_type: registrationData.documentType,
          document_description: registrationData.documentDescription || '',
          integra_id: integraIdHash,
          original_hash: fileHash,
          tokenization_date: new Date().toISOString(),
          tokens: tokenSets.map(token => ({
            name: token.tokenName,
            description: token.tokenSymbol,
            amount: token.amount,
            id: token.id
          }))
        };
        
        // Create the verification URL for QR code
        const verificationUrl = `https://integraledger.com/qr/${originalHash}`;
        
        // First add QR code to the document
        const docWithQrCode = await placeQRCodeOnDocument(
          pdfBytes,
          qrCodeBytes,
          tokenQrCodePlacement as 'right' | 'left' | 'cover',
          verificationUrl
        );
        
        // Then embed metadata into the document
        const smartDocBytes = await pdfService.createSmartDoc({
          pdfBytes: docWithQrCode,
          jsonData,
          qrCodeBytes: undefined, // QR code already added
          integraEndpoint: verificationUrl,
          customEndpoint: '',
          version: '1.0',
          uuid: integraIdHash,
        });
        
        // Calculate hash of the modified document and save it in the PDFStore
        const smartDocBlob = new Blob([smartDocBytes], { type: 'application/pdf' });
        const smartDocFile = new File([smartDocBlob], pdfFile.name, { type: 'application/pdf' });
        const smartDocHash = await pdfService.calculateFileHash(smartDocFile);
        
        // Update the hash in the store
        setSmartDocHash(smartDocHash);
        console.log('Smart document hash calculated:', smartDocHash);
        
        // Save the smart document for download
        const smartDocUrl = URL.createObjectURL(smartDocBlob);
        setLocalSmartDocumentUrl(smartDocUrl);
        setSmartDocumentUrl(smartDocUrl); // Also update the store
        setSmartDocumentFile(smartDocFile);
        
        // Use the smart document hash for tokenization
        documentHashToTokenize = smartDocHash;
        
        // Trigger automatic download of the Tokenized Document
        const downloadLink = document.createElement('a');
        downloadLink.href = smartDocUrl;
        
        // Get the filename without extension
        const fileNameParts = pdfFile.name.split('.');
        const extension = fileNameParts.pop() || 'pdf';
        const baseName = fileNameParts.join('.');
        
        // Create the new filename with _Tokenized_Doc suffix
        downloadLink.download = `${baseName}_Tokenized_Doc.${extension}`;
        
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } catch (error) {
        console.error('Error creating tokenized document:', error);
        setTokenizationError('Failed to create tokenized document. Please try again.');
        setProcessingState('idle');
        setIsTokenizing(false);
        return;
      }

      // Create the URL to encrypt
      const connectUrl = `https://comms.integraledger.com/connect/${currentOrganizationId}`;
      
      // Tokenize the document with the blockchain using Integra ID hash and hash
      setProcessingState('tokenizing-blockchain');
      console.log('Tokenizing document with hash:', documentHashToTokenize);
      console.log('Using Integra ID Hash:', integraIdHash);
      
      // Prepare token data for the API
      const tokensToReserve = tokenSets.map(token => ({
        label: token.tokenName,
        optional_data: token.tokenSymbol,
        amount: token.amount
      }));

      const result = await v3BlockchainService.tokenizeDocument(
        {
          document_hash: documentHashToTokenize,
          integra_id: integraIdHash,
          encrypted_data: bytesToBase64(encryptString(connectUrl, documentHashToTokenize, STANDARD_CHACHA20_NONCE).encrypted),
          tokens_to_reserve: tokensToReserve
        },
        userProfile?.profile_id || 'anonymous',
        currentOrganizationId || ''
      );

      // Update result
      setTokenizationResult(result);
      console.log('Tokenization result:', result);

      // Update state to show completion
      setTokenizedIntegraId(integraIdHash);
      setTokenizationComplete(true);
      setTokenizationSuccess(true);
      setActiveStep(steps.length - 1); // Move to the last step
      
      // Reset processing state
      setProcessingState('idle');
      setIsTokenizing(false);
      
    } catch (error: any) {
      console.error('Error tokenizing document:', error);
      
      // Handle error
      setTokenizationError(error.message || 'Failed to tokenize document. Please try again.');
      setTokenizationComplete(true);
      setTokenizationSuccess(false);
      
      // Reset processing state
      setProcessingState('idle');
      setIsTokenizing(false);
    }
  };
  
  // Handle navigation to home
  const handleGoHome = () => {
    navigate('/');
  };
  
  // Handle navigation to verification result
  const handleViewVerification = () => {
    navigate('/verify-result');
  };
  
  // Render step content based on active step
  const renderStepContent = () => {
    // If tokenization is complete, show the confirmation
    if (tokenizationComplete) {
      return renderTokenizationConfirmation();
    }
    
    switch (activeStep) {
      case 0:
        return renderQRCodePlacementStep();
      case 1:
        return renderMetadataStep();
      case 2:
        return renderTokenDetailsStep();
      case 3:
        return renderConfirmationStep();
      default:
        return null;
    }
  };
  
  // Render QR code placement step
  const renderQRCodePlacementStep = () => {
    return (
      <VStack spacing={6} align="stretch" w="100%">
        <Heading size="md">QR Code Placement</Heading>
        <Text>Select where you would like the QR code to appear on your document.</Text>
        
        <RadioGroup
          onChange={(value) => setTokenQrCodePlacement(value as 'right' | 'left' | 'cover')}
          value={tokenQrCodePlacement || 'right'}
          mt={4}
        >
          <Stack spacing={4}>
            <Radio value="right">Top Right Corner</Radio>
            <Radio value="left">Top Left Corner</Radio>
            <Radio value="cover">Cover Page</Radio>
          </Stack>
        </RadioGroup>
        
        <HStack spacing={4} justify="flex-end" mt={4}>
          <Button leftIcon={<FiHome />} onClick={handleGoHome} variant="outline">
            Cancel
          </Button>
          <Button rightIcon={<FiArrowRight />} onClick={handleNextStep} colorScheme="blue">
            Next
          </Button>
        </HStack>
      </VStack>
    );
  };
  
  // Render metadata step
  const renderMetadataStep = () => {
    return (
      <VStack spacing={6} align="stretch" w="100%">
        <Heading size="md">Document Details</Heading>
        <Text>Enter the metadata for your document.</Text>
        
        <FormControl isRequired>
          <FormLabel>Document Name</FormLabel>
          <Input
            value={registrationData?.documentName || ''}
            onChange={(e) => handleFormChange('documentName', e.target.value)}
            placeholder="Enter document name"
          />
        </FormControl>
        
        <FormControl isRequired>
          <FormLabel>Document Type</FormLabel>
          <Select
            value={registrationData?.documentType || 'General'}
            onChange={(e) => handleFormChange('documentType', e.target.value)}
          >
            <option value="General">General</option>
            <option value="Contract">Contract</option>
            <option value="Agreement">Agreement</option>
            <option value="Legal">Legal Document</option>
            <option value="Financial">Financial Document</option>
            <option value="Other">Other</option>
          </Select>
        </FormControl>
        
        <FormControl>
          <FormLabel>Document Description (Optional)</FormLabel>
          <Textarea
            value={registrationData?.documentDescription || ''}
            onChange={(e) => handleFormChange('documentDescription', e.target.value)}
            placeholder="Enter a description of the document"
            rows={3}
          />
        </FormControl>
        
        <HStack spacing={4} justify="flex-end" mt={4}>
          <Button leftIcon={<FiArrowLeft />} onClick={handlePrevStep} variant="outline">
            Back
          </Button>
          <Button rightIcon={<FiArrowRight />} onClick={handleNextStep} colorScheme="blue">
            Next
          </Button>
        </HStack>
      </VStack>
    );
  };
  
  // Render token details step
  const renderTokenDetailsStep = () => {
    return (
      <VStack spacing={6} align="stretch" w="100%">
        <Heading size="md">Token Details</Heading>
        <Text>Add one or more tokens to your document.</Text>
        
        {tokenSets.map((tokenSet, index) => (
          <Box key={tokenSet.id} p={4} borderWidth="1px" borderRadius="md" position="relative">
            <FormControl isRequired mb={3}>
              <FormLabel>Token Name</FormLabel>
              <Input
                value={tokenSet.tokenName}
                onChange={(e) => handleTokenSetChange(tokenSet.id, 'tokenName', e.target.value)}
                placeholder="Enter token name"
              />
            </FormControl>
            
            <FormControl>
              <FormLabel>Token Symbol (Optional)</FormLabel>
              <Input
                value={tokenSet.tokenSymbol}
                onChange={(e) => handleTokenSetChange(tokenSet.id, 'tokenSymbol', e.target.value)}
                placeholder="Enter token symbol"
              />
            </FormControl>
            
            {tokenSets.length > 1 && (
              <IconButton
                aria-label="Remove token"
                icon={<FiTrash2 />}
                size="sm"
                position="absolute"
                top={2}
                right={2}
                colorScheme="red"
                variant="ghost"
                onClick={() => handleRemoveTokenSet(tokenSet.id)}
              />
            )}
          </Box>
        ))}
        
        <Button
          leftIcon={<FiPlus />}
          onClick={handleAddTokenSet}
          variant="outline"
          colorScheme="blue"
          alignSelf="flex-start"
        >
          Add Another Token
        </Button>
        
        <HStack spacing={4} justify="flex-end" mt={4}>
          <Button leftIcon={<FiArrowLeft />} onClick={handlePrevStep} variant="outline">
            Back
          </Button>
          <Button rightIcon={<FiArrowRight />} onClick={handleNextStep} colorScheme="blue">
            Next
          </Button>
        </HStack>
      </VStack>
    );
  };
  
  // Render confirmation step
  const renderConfirmationStep = () => {
    return (
      <VStack spacing={6} align="stretch" w="100%">
        <Heading size="md">Tokenize Document</Heading>
        <Text>Review your information and tokenize your document.</Text>
        
        <Alert status="info" borderRadius="md">
          <AlertIcon />
          <Box>
            <AlertTitle>Ready to Tokenize</AlertTitle>
            <AlertDescription>
              Your document will be tokenized with the following details:
            </AlertDescription>
          </Box>
        </Alert>
        
        <Box p={4} borderWidth="1px" borderRadius="md" bg={confirmationBgColor}>
          <SimpleGrid columns={2} spacing={4}>
            <Box>
              <Text fontWeight="bold">Document Name:</Text>
              <Text>{registrationData?.documentName}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Document Type:</Text>
              <Text>{registrationData?.documentType}</Text>
            </Box>
          </SimpleGrid>
          
          <Box mt={4}>
            <Text fontWeight="bold">Tokens:</Text>
            {tokenSets.map((token, index) => (
              <Box key={token.id} mt={2} p={2} borderWidth="1px" borderRadius="md">
                <Text fontWeight="semibold">{token.tokenName || 'Unnamed Token'}</Text>
                {token.tokenSymbol && <Text fontSize="sm">{token.tokenSymbol}</Text>}
              </Box>
            ))}
          </Box>
        </Box>
        
        {tokenizationError && (
          <Alert status="error" borderRadius="md">
            <AlertIcon />
            <AlertTitle>Error:</AlertTitle>
            <AlertDescription>{tokenizationError}</AlertDescription>
          </Alert>
        )}
        
        <HStack spacing={4} justify="flex-end" mt={4}>
          <Button leftIcon={<FiArrowLeft />} onClick={handlePrevStep} variant="outline" isDisabled={isTokenizing}>
            Back
          </Button>
          <Button
            rightIcon={isTokenizing ? undefined : <FiCheck />}
            onClick={handleTokenizeDocument}
            colorScheme="blue"
            isLoading={isTokenizing}
            loadingText={
              processingState === 'creating-smart-document'
                ? 'Creating Smart Document'
                : processingState === 'tokenizing-blockchain'
                ? 'Tokenizing on Blockchain'
                : 'Tokenizing'
            }
            isDisabled={isTokenizing}
          >
            Tokenize Document
          </Button>
        </HStack>
      </VStack>
    );
  };
  
  // Render tokenization confirmation
  const renderTokenizationConfirmation = () => {
    return (
      <VStack spacing={6} align="stretch" w="100%">
        {tokenizationSuccess ? (
          <>
            <Alert status="success" borderRadius="md">
              <AlertIcon />
              <Box>
                <AlertTitle>Document Tokenized Successfully!</AlertTitle>
                <AlertDescription>
                  Your document has been tokenized on the blockchain.
                </AlertDescription>
              </Box>
            </Alert>
            
            <Box p={4} borderWidth="1px" borderRadius="md" bg={confirmationBgColor}>
              <SimpleGrid columns={2} spacing={4}>
                <Box>
                  <Text fontWeight="bold">Document Name:</Text>
                  <Text>{registrationData?.documentName}</Text>
                </Box>
                <Box>
                  <Text fontWeight="bold">Document Type:</Text>
                  <Text>{registrationData?.documentType}</Text>
                </Box>
                <Box>
                  <Text fontWeight="bold">Integra ID:</Text>
                  <Text isTruncated>{tokenizedIntegraId}</Text>
                </Box>
                <Box>
                  <Text fontWeight="bold">Tokenization Date:</Text>
                  <Text>{new Date().toLocaleString()}</Text>
                </Box>
              </SimpleGrid>
              
              <Box mt={4}>
                <Text fontWeight="bold">Tokens Created:</Text>
                {tokenSets.map((token, index) => (
                  <Box key={token.id} mt={2} p={2} borderWidth="1px" borderRadius="md">
                    <Text fontWeight="semibold">{token.tokenName || 'Unnamed Token'}</Text>
                    {token.tokenSymbol && <Text fontSize="sm">{token.tokenSymbol}</Text>}
                  </Box>
                ))}
              </Box>
              
              {smartDocumentUrl && (
                <Button
                  mt={4}
                  as="a"
                  href={smartDocumentUrl}
                  download={`${pdfFile?.name.split('.')[0]}_Tokenized_Doc.pdf`}
                  colorScheme="blue"
                  variant="outline"
                  leftIcon={<FiFileText />}
                >
                  Download Tokenized Document
                </Button>
              )}
            </Box>
          </>
        ) : (
          <Alert status="error" borderRadius="md">
            <AlertIcon />
            <Box>
              <AlertTitle>Tokenization Failed</AlertTitle>
              <AlertDescription>
                {tokenizationError || 'There was an error tokenizing your document. Please try again.'}
              </AlertDescription>
            </Box>
          </Alert>
        )}
        
        <HStack spacing={4} justify="flex-end" mt={4}>
          <Button leftIcon={<FiHome />} onClick={handleGoHome} variant="outline">
            Go Home
          </Button>
        </HStack>
      </VStack>
    );
  };
  
  // Main render
  return (
    <StandardPageLayout>
      <Container maxW="container.xl" py={8}>
        {/* Stepper */}
        <Stepper index={activeStep} mb={8} size="sm">
          {steps.map((step, index) => (
            <Step key={index}>
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>
              <Box flexShrink="0">
                <StepTitle>{step.title}</StepTitle>
                <StepDescription>{step.description}</StepDescription>
              </Box>
              <StepSeparator />
            </Step>
          ))}
        </Stepper>
        
        {/* Main content */}
        <Flex 
          direction={{ base: "column", md: "row" }} 
          gap={6}
          justify="space-between"
          align="stretch"
          width="100%"
        >
          {/* Left column - Document Preview */}
          <Box 
            width={{ base: "100%", md: "48%" }}
            borderRadius="md" 
            overflow="hidden" 
            height={{ base: "400px", md: "600px" }}
            border="1px solid" 
            borderColor={borderColor}
            flexShrink={0}
            display="flex"
            alignItems="flex-start"
            justifyContent="center"
            bg={bgColor}
            p={0}
            position="relative"
          >
            {/* PDF Viewer */}
            {pdfFile && (
              <PDFViewer
                file={pdfFile}
                height="100%"
                width="100%"
                showControls={true}
                renderTextLayer={false}
                scale={0.7}
                qrCodePlacement={tokenQrCodePlacement || null}
              />
            )}
            
            {!pdfFile && (
              <Flex 
                height="100%" 
                width="100%"
                alignItems="center" 
                justifyContent="center" 
                bg="transparent"
                direction="column"
              >
                <VStack spacing={4}>
                  <Icon as={FiFileText} boxSize={16} color={gray400} />
                  <Text color={gray500}>No document preview available</Text>
                </VStack>
              </Flex>
            )}
          </Box>
          
          {/* Right column - Registration Content */}
          <Box 
            width={{ base: "100%", md: "48%" }}
            borderRadius="md"
            border="1px solid"
            borderColor={borderColor}
            p={6}
            flexShrink={0}
            bg={white}
          >
            {/* Hash calculation progress */}
            {isCalculatingHash && (
              <Box mb={6}>
                <Text mb={2}>Calculating document hash...</Text>
                <Progress value={hashProgress} size="sm" colorScheme="blue" />
              </Box>
            )}
            
            {/* Step content */}
            {renderStepContent()}
          </Box>
        </Flex>
      </Container>
    </StandardPageLayout>
  );
}

export default TokenizeDocument;
