/**
 * Verification Error
 * 
 * This component displays the error state when document verification fails.
 * It shows the error message and provides options for next steps.
 */

import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  VStack,
  HStack,
  Icon,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useColorModeValue,
  Code,
} from '@chakra-ui/react';
import { 
  FiAlertCircle, 
  FiRefreshCw,
  FiUpload
} from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

// Import services
import workflowRoutingService from '../../services/WorkflowRoutingService';

// Import stores
import { usePDFStore } from '../../store/pdfStore';
import { useWorkflowStore } from '../../store/workflowStore';
import { ContentLayout } from '../../layouts';

// Define component props
interface VerificationErrorProps {
  errorMessage: string;
  onRetry?: () => void;
}

/**
 * Verification Error Component
 */
const VerificationError: React.FC<VerificationErrorProps> = ({
  errorMessage,
  onRetry
}) => {
  // Hooks
  const navigate = useNavigate();
  
  // Get PDF store state and actions
  const { 
    fileName, 
    fileHash, 
    setVerificationStatus, 
    setVerificationError, 
    setVerificationResult 
  } = usePDFStore();
  
  // Get workflow store state and actions
  const { setSelectedWorkflow } = useWorkflowStore();
  
  // Theme colors
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('red.200', 'red.700');
  const textColor = useColorModeValue('gray.800', 'white');
  
  // Handle retry
  const handleRetry = () => {
    if (onRetry) {
      // Use the provided onRetry callback if available
      onRetry();
    } else {
      // Default retry behavior
      // Reset verification state but keep the file data
      setVerificationStatus('idle');
      setVerificationError(null);
      setVerificationResult(null);
      
      // Refresh the page
      navigate(0);
    }
  };
  
  // Handle register
  const handleRegister = () => {
    // Set the selected workflow
    setSelectedWorkflow('register');
    
    // Log the navigation
    console.log('Navigating to registration workflow');
    
    // Navigate to the registration workflow
    workflowRoutingService.navigateToWorkflow('register', navigate);
  };
  
  return (
    <ContentLayout>
      <Box
        p={6}
        borderWidth="1px"
        borderRadius="lg"
        borderColor={borderColor}
        bg={bgColor}
        boxShadow="md"
        width="100%"
      >
        <VStack spacing={6} align="stretch">
          <Flex
            width="100%"
            direction="column"
            align="center"
            justify="center"
            textAlign="center"
            mb={4}
          >
            <Icon as={FiAlertCircle} color="red.500" boxSize={12} mb={4} />
            <Heading as="h2" size="xl" mb={2} color={textColor}>
              Verification Error
            </Heading>
            <Text fontSize="md" color="gray.500">
              There was an error verifying this document.
            </Text>
          </Flex>
          
          <Alert status="error" borderRadius="md">
            <AlertIcon />
            <Box flex="1">
              <AlertTitle>Error Details</AlertTitle>
              <AlertDescription display="block">
                <Code p={2} mt={2} width="100%" borderRadius="md" overflowX="auto">
                  {errorMessage}
                </Code>
              </AlertDescription>
            </Box>
          </Alert>
          
          {fileHash && (
            <Box p={4} bg="gray.50" borderRadius="md">
              <VStack spacing={3} align="stretch">
                <HStack>
                  <Icon as={FiUpload} color="blue.500" />
                  <Text fontWeight="bold">File Name:</Text>
                  <Text>{fileName || 'Unknown'}</Text>
                </HStack>
                
                <HStack>
                  <Icon as={FiUpload} color="blue.500" />
                  <Text fontWeight="bold">File Hash:</Text>
                  <Text isTruncated>{fileHash ? `${fileHash.substring(0, 8)}...${fileHash.substring(fileHash.length - 8)}` : 'Unknown'}</Text>
                </HStack>
              </VStack>
            </Box>
          )}
          
          <HStack spacing={4} justify="center">
            <Button
              leftIcon={<FiRefreshCw />}
              colorScheme="blue"
              onClick={handleRetry}
            >
              Retry Verification
            </Button>
            
            <Button
              leftIcon={<FiUpload />}
              variant="outline"
              colorScheme="blue"
              onClick={handleRegister}
            >
              Register Document
            </Button>
          </HStack>
        </VStack>
      </Box>
    </ContentLayout>
  );
};

export default VerificationError;
