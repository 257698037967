import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Text,
  VStack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Select,
  Divider,
  useColorModeValue,
  Card,
  CardHeader,
  CardBody,
  Icon,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useToast,
  Radio,
  RadioGroup,
  Stack,
  Textarea
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { 
  FiArrowLeft, 
  FiSave, 
  FiSettings, 
  FiGlobe, 
  FiMoon, 
  FiBell,
  FiShield,
  FiDatabase,
  FiServer
} from 'react-icons/fi';

/**
 * Settings Page
 * 
 * This component allows users to configure application settings
 */
const Settings: React.FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  
  // Mock settings data
  const [settings, setSettings] = useState({
    // General
    language: 'en',
    theme: 'light',
    timezone: 'UTC',
    
    // Notifications
    emailNotifications: true,
    documentAlerts: true,
    weeklyDigest: false,
    
    // Privacy
    shareUsageData: true,
    storeDocumentMetadata: true,
    
    // Advanced
    apiEndpoint: 'https://api.example.com/v1',
    maxConcurrentUploads: '3',
    defaultDocumentFormat: 'pdf',
    customCss: ''
  });
  
  // Form state
  const [formData, setFormData] = useState({ ...settings });
  
  // Colors
  const cardBg = useColorModeValue('white', 'gray.700');
  
  /**
   * Handle form input change
   */
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };
  
  /**
   * Handle switch toggle
   */
  const handleSwitchChange = (name: string, value: boolean) => {
    setFormData(prev => ({ ...prev, [name]: value }));
  };
  
  /**
   * Handle radio change
   */
  const handleRadioChange = (name: string, value: string) => {
    setFormData(prev => ({ ...prev, [name]: value }));
  };
  
  /**
   * Handle form submission
   */
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    // In a real implementation, this would call an API to update settings
    setSettings(formData);
    
    toast({
      title: 'Settings Saved',
      description: 'Your settings have been successfully updated.',
      status: 'success',
      duration: 3000,
      isClosable: true,
      position: 'top'
    });
  };
  
  return (
    <Container maxW="container.lg" py={8}>
      <VStack spacing={8} align="stretch">
        {/* Header */}
        <Flex justify="space-between" align="center">
          <Button 
            leftIcon={<FiArrowLeft />} 
            variant="ghost" 
            onClick={() => navigate('/')}
          >
            Back to Dashboard
          </Button>
          
          <Heading as="h1" size="xl" textAlign="center" flex="1">
            Settings
          </Heading>
          
          <Box width="40px" /> {/* Spacer for alignment */}
        </Flex>
        
        {/* Settings Form */}
        <form onSubmit={handleSubmit}>
          <Tabs colorScheme="blue" variant="enclosed">
            <TabList>
              <Tab><HStack><Icon as={FiSettings} /><Text>General</Text></HStack></Tab>
              <Tab><HStack><Icon as={FiBell} /><Text>Notifications</Text></HStack></Tab>
              <Tab><HStack><Icon as={FiShield} /><Text>Privacy</Text></HStack></Tab>
              <Tab><HStack><Icon as={FiServer} /><Text>Advanced</Text></HStack></Tab>
            </TabList>
            
            <TabPanels>
              {/* General Settings */}
              <TabPanel>
                <Card bg={cardBg} mb={6}>
                  <CardHeader>
                    <HStack>
                      <Icon as={FiGlobe} color="blue.500" />
                      <Heading size="md">Localization</Heading>
                    </HStack>
                  </CardHeader>
                  <CardBody>
                    <VStack spacing={4} align="stretch">
                      <FormControl>
                        <FormLabel>Language</FormLabel>
                        <Select 
                          name="language" 
                          value={formData.language}
                          onChange={handleInputChange}
                        >
                          <option value="en">English</option>
                          <option value="es">Spanish</option>
                          <option value="fr">French</option>
                          <option value="de">German</option>
                          <option value="ja">Japanese</option>
                        </Select>
                      </FormControl>
                      
                      <FormControl>
                        <FormLabel>Timezone</FormLabel>
                        <Select 
                          name="timezone" 
                          value={formData.timezone}
                          onChange={handleInputChange}
                        >
                          <option value="UTC">UTC</option>
                          <option value="America/New_York">Eastern Time (ET)</option>
                          <option value="America/Chicago">Central Time (CT)</option>
                          <option value="America/Denver">Mountain Time (MT)</option>
                          <option value="America/Los_Angeles">Pacific Time (PT)</option>
                          <option value="Europe/London">London</option>
                          <option value="Europe/Paris">Paris</option>
                          <option value="Asia/Tokyo">Tokyo</option>
                        </Select>
                      </FormControl>
                    </VStack>
                  </CardBody>
                </Card>
                
                <Card bg={cardBg}>
                  <CardHeader>
                    <HStack>
                      <Icon as={FiMoon} color="blue.500" />
                      <Heading size="md">Appearance</Heading>
                    </HStack>
                  </CardHeader>
                  <CardBody>
                    <FormControl>
                      <FormLabel>Theme</FormLabel>
                      <RadioGroup 
                        value={formData.theme}
                        onChange={(value) => handleRadioChange('theme', value)}
                      >
                        <Stack direction="row" spacing={5}>
                          <Radio value="light">Light</Radio>
                          <Radio value="dark">Dark</Radio>
                          <Radio value="system">System Default</Radio>
                        </Stack>
                      </RadioGroup>
                    </FormControl>
                  </CardBody>
                </Card>
              </TabPanel>
              
              {/* Notification Settings */}
              <TabPanel>
                <Card bg={cardBg}>
                  <CardHeader>
                    <HStack>
                      <Icon as={FiBell} color="blue.500" />
                      <Heading size="md">Notification Preferences</Heading>
                    </HStack>
                  </CardHeader>
                  <CardBody>
                    <VStack spacing={4} align="stretch">
                      <FormControl display="flex" alignItems="center">
                        <FormLabel mb="0">
                          Email Notifications
                        </FormLabel>
                        <Switch 
                          colorScheme="blue" 
                          isChecked={formData.emailNotifications}
                          onChange={(e) => handleSwitchChange('emailNotifications', e.target.checked)}
                          ml="auto"
                        />
                      </FormControl>
                      
                      <Divider />
                      
                      <FormControl display="flex" alignItems="center">
                        <FormLabel mb="0">
                          Document Status Alerts
                        </FormLabel>
                        <Switch 
                          colorScheme="blue" 
                          isChecked={formData.documentAlerts}
                          onChange={(e) => handleSwitchChange('documentAlerts', e.target.checked)}
                          ml="auto"
                        />
                      </FormControl>
                      
                      <Divider />
                      
                      <FormControl display="flex" alignItems="center">
                        <FormLabel mb="0">
                          Weekly Activity Digest
                        </FormLabel>
                        <Switch 
                          colorScheme="blue" 
                          isChecked={formData.weeklyDigest}
                          onChange={(e) => handleSwitchChange('weeklyDigest', e.target.checked)}
                          ml="auto"
                        />
                      </FormControl>
                    </VStack>
                  </CardBody>
                </Card>
              </TabPanel>
              
              {/* Privacy Settings */}
              <TabPanel>
                <Card bg={cardBg}>
                  <CardHeader>
                    <HStack>
                      <Icon as={FiShield} color="blue.500" />
                      <Heading size="md">Privacy Settings</Heading>
                    </HStack>
                  </CardHeader>
                  <CardBody>
                    <VStack spacing={4} align="stretch">
                      <FormControl display="flex" alignItems="center">
                        <Box>
                          <FormLabel mb="0">
                            Share Usage Data
                          </FormLabel>
                          <Text fontSize="sm" color="gray.500">
                            Help us improve by sharing anonymous usage data
                          </Text>
                        </Box>
                        <Switch 
                          colorScheme="blue" 
                          isChecked={formData.shareUsageData}
                          onChange={(e) => handleSwitchChange('shareUsageData', e.target.checked)}
                          ml="auto"
                        />
                      </FormControl>
                      
                      <Divider />
                      
                      <FormControl display="flex" alignItems="center">
                        <Box>
                          <FormLabel mb="0">
                            Store Document Metadata
                          </FormLabel>
                          <Text fontSize="sm" color="gray.500">
                            Store metadata about your documents for faster verification
                          </Text>
                        </Box>
                        <Switch 
                          colorScheme="blue" 
                          isChecked={formData.storeDocumentMetadata}
                          onChange={(e) => handleSwitchChange('storeDocumentMetadata', e.target.checked)}
                          ml="auto"
                        />
                      </FormControl>
                    </VStack>
                  </CardBody>
                </Card>
              </TabPanel>
              
              {/* Advanced Settings */}
              <TabPanel>
                <Card bg={cardBg}>
                  <CardHeader>
                    <HStack>
                      <Icon as={FiDatabase} color="blue.500" />
                      <Heading size="md">Advanced Settings</Heading>
                    </HStack>
                  </CardHeader>
                  <CardBody>
                    <VStack spacing={4} align="stretch">
                      <FormControl>
                        <FormLabel>API Endpoint</FormLabel>
                        <Input 
                          name="apiEndpoint" 
                          value={formData.apiEndpoint}
                          onChange={handleInputChange}
                        />
                      </FormControl>
                      
                      <FormControl>
                        <FormLabel>Maximum Concurrent Uploads</FormLabel>
                        <Select 
                          name="maxConcurrentUploads" 
                          value={formData.maxConcurrentUploads}
                          onChange={handleInputChange}
                        >
                          <option value="1">1</option>
                          <option value="3">3</option>
                          <option value="5">5</option>
                          <option value="10">10</option>
                        </Select>
                      </FormControl>
                      
                      <FormControl>
                        <FormLabel>Default Document Format</FormLabel>
                        <Select 
                          name="defaultDocumentFormat" 
                          value={formData.defaultDocumentFormat}
                          onChange={handleInputChange}
                        >
                          <option value="pdf">PDF</option>
                          <option value="docx">DOCX</option>
                          <option value="txt">TXT</option>
                        </Select>
                      </FormControl>
                      
                      <FormControl>
                        <FormLabel>Custom CSS</FormLabel>
                        <Textarea
                          name="customCss"
                          value={formData.customCss}
                          onChange={handleInputChange}
                          placeholder="Enter custom CSS rules here"
                          rows={4}
                        />
                      </FormControl>
                    </VStack>
                  </CardBody>
                </Card>
              </TabPanel>
            </TabPanels>
          </Tabs>
          
          <Flex justify="flex-end" mt={6}>
            <Button 
              type="submit" 
              colorScheme="blue"
              leftIcon={<FiSave />}
              size="lg"
            >
              Save Settings
            </Button>
          </Flex>
        </form>
      </VStack>
    </Container>
  );
};

export default Settings;
