/**
 * PDFTools Page
 * 
 * A demonstration page for PDF and QR code tools:
 * - PDF uploading and viewing
 * - Metadata extraction and display
 * - QR code generation
 * - Smart document creation
 * 
 * This page is part of the V3 architecture that separates concerns
 * and improves maintainability.
 */

import { useState, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  VStack,
  HStack,
  Heading,
  Text,
  Button,
  Divider,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useToast,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter
} from '@chakra-ui/react';

// Import components
import PDFUploader from '../components/PDFUploader';
import PDFViewer from '../components/PDFViewer';
import PDFMetadataViewer from '../components/PDFMetadataViewer';
import QRCodeGenerator from '../components/QRCodeGenerator';
import StandardPageLayout from '../components/StandardPageLayout';

// Import hooks and services
import { usePDFOperations } from '../hooks/usePDFOperations';
import { PDFMetadata } from '../services/PDFService';
import usePreventBrowserDragBehavior from '../hooks/usePreventBrowserDragBehavior';

/**
 * PDF Tools Page Component
 */
export function PDFTools() {
  // Prevent browser from opening files when dragged onto the page
  usePreventBrowserDragBehavior();
  
  // State
  const [qrCodeData, setQrCodeData] = useState<string>('');
  const [qrCodeLogo, setQrCodeLogo] = useState<string>('');
  const [integraEndpoint, setIntegraEndpoint] = useState<string>('https://api.integraledger.com/v1/documents/');
  const [customEndpoint, setCustomEndpoint] = useState<string>('');
  const [addQrCode, setAddQrCode] = useState<boolean>(true);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  
  // PDF operations hook
  const {
    pdfFile,
    fileHash,
    originalHash,
    metadata,
    isProcessing,
    processingStep,
    error,
    loadPDF,
    createSmartDocument
  } = usePDFOperations();
  
  // Toast notifications
  const toast = useToast();
  
  // Modal for smart document creation
  const { isOpen, onOpen, onClose } = useDisclosure();
  
  /**
   * Handle file upload
   * @param file Uploaded file
   */
  const handleFileUpload = useCallback(async (file: File) => {
    // Reset success message
    setSuccessMessage(null);
    
    try {
      // Load PDF and extract metadata
      await loadPDF(file);
      
      // Set QR code data to file hash if available
      if (fileHash) {
        setQrCodeData(fileHash);
      }
      
      // Show success message
      setSuccessMessage('PDF loaded successfully');
      
      // Auto-hide success message after 3 seconds
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
    } catch (error) {
      console.error('Error loading PDF:', error);
      // Error is already set in the pdfStore by the loadPDF function
    }
  }, [loadPDF, fileHash, setSuccessMessage, setQrCodeData]);
  
  /**
   * Create smart document
   */
  const handleCreateSmartDocument = useCallback(async () => {
    // Generate a UUID if not available in metadata
    const uuid = metadata?.integraId || uuidv4();
    
    // Create QR code data if not set
    const qrData = qrCodeData || `${integraEndpoint}${uuid}`;
    
    // Create smart document
    const result = await createSmartDocument({
      metadata: {
        ...metadata,
        integraId: uuid
      },
      qrCodeData: qrData,
      integraEndpoint: `${integraEndpoint}${uuid}`,
      customEndpoint: customEndpoint || `${window.location.origin}/documents/${uuid}`,
      version: '1.0',
      logoUrl: qrCodeLogo || undefined
    });
    
    if (result) {
      // Show success toast
      toast({
        title: 'Smart Document Created',
        description: `Document created with Integra ID: ${uuid}`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      
      // Close modal
      onClose();
      
      // Create download link
      const url = URL.createObjectURL(result.file);
      const link = document.createElement('a');
      link.href = url;
      link.download = `smart_${result.file.name}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  }, [
    metadata,
    qrCodeData,
    qrCodeLogo,
    integraEndpoint,
    customEndpoint,
    createSmartDocument,
    toast,
    onClose
  ]);

  return (
    <StandardPageLayout maxW="container.xl">
      <VStack spacing={8} align="stretch">
        {/* Header */}
        <Box>
          <Heading as="h1" size="xl" mb={2}>PDF Tools</Heading>
          <Text color="gray.600">
            Upload, view, and create smart documents with embedded metadata and QR codes
          </Text>
          <Divider mt={4} />
        </Box>
        
        {/* Main Content */}
        <Tabs variant="enclosed" colorScheme="blue">
          <TabList>
            <Tab>Upload & View</Tab>
            <Tab>Metadata</Tab>
            <Tab>QR Code</Tab>
            <Tab>Smart Document</Tab>
          </TabList>
          
          <TabPanels>
            {/* Upload & View Tab */}
            <TabPanel>
              <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={8}>
                {/* PDF Uploader */}
                <Card>
                  <CardHeader>
                    <Heading size="md">Upload PDF</Heading>
                  </CardHeader>
                  <CardBody>
                    <PDFUploader
                      onFileSelected={handleFileUpload}
                      isLoading={isProcessing}
                      error={error}
                      successMessage={successMessage}
                    />
                    
                    <Divider my={4} />
                    
                    <Text fontSize="sm" color="gray.600" mb={2}>
                      For testing, you can use our sample PDF:
                    </Text>
                    <Button 
                      size="sm" 
                      colorScheme="blue" 
                      variant="outline"
                      onClick={async () => {
                        try {
                          const response = await fetch('/sample.pdf');
                          const blob = await response.blob();
                          const file = new File([blob], 'sample.pdf', { type: 'application/pdf' });
                          handleFileUpload(file);
                        } catch (error) {
                          console.error('Error loading sample PDF:', error);
                        }
                      }}
                    >
                      Load Sample PDF
                    </Button>
                  </CardBody>
                </Card>
                
                {/* PDF Viewer */}
                <Card>
                  <CardHeader>
                    <Heading size="md">PDF Preview</Heading>
                  </CardHeader>
                  <CardBody>
                    <PDFViewer
                      file={pdfFile}
                      height="500px"
                      showControls
                      showFullscreenButton
                    />
                  </CardBody>
                </Card>
              </SimpleGrid>
            </TabPanel>
            
            {/* Metadata Tab */}
            <TabPanel>
              <Card>
                <CardHeader>
                  <Heading size="md">Document Metadata</Heading>
                </CardHeader>
                <CardBody>
                  {pdfFile ? (
                    <PDFMetadataViewer
                      metadata={metadata}
                      showAllFields
                      showEmptyFields={false}
                    />
                  ) : (
                    <Text color="gray.500">Upload a PDF to view its metadata</Text>
                  )}
                </CardBody>
              </Card>
            </TabPanel>
            
            {/* QR Code Tab */}
            <TabPanel>
              <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={8}>
                {/* QR Code Settings */}
                <Card>
                  <CardHeader>
                    <Heading size="md">QR Code Settings</Heading>
                  </CardHeader>
                  <CardBody>
                    <VStack spacing={4} align="stretch">
                      <FormControl>
                        <FormLabel>QR Code Data</FormLabel>
                        <Input
                          value={qrCodeData}
                          onChange={(e) => setQrCodeData(e.target.value)}
                          placeholder="Enter data to encode in QR code"
                        />
                      </FormControl>
                      
                      <FormControl>
                        <FormLabel>Logo URL (optional)</FormLabel>
                        <Input
                          value={qrCodeLogo}
                          onChange={(e) => setQrCodeLogo(e.target.value)}
                          placeholder="Enter URL for logo in center of QR code"
                        />
                      </FormControl>
                      
                      <Button
                        size="sm"
                        colorScheme="blue"
                        variant="outline"
                        onClick={() => setQrCodeLogo('/sample-qr.png')}
                      >
                        Use Sample Logo
                      </Button>
                      
                      <Text fontSize="sm" color="gray.500">
                        The QR code will be generated with the data you provide.
                        If you leave it empty, it will use the document hash.
                      </Text>
                    </VStack>
                  </CardBody>
                </Card>
                
                {/* QR Code Preview */}
                <Card>
                  <CardHeader>
                    <Heading size="md">QR Code Preview</Heading>
                  </CardHeader>
                  <CardBody>
                    <Flex justify="center" align="center" height="100%">
                      <QRCodeGenerator
                        data={qrCodeData || fileHash || 'https://integraledger.com'}
                        size={200}
                        logoUrl={qrCodeLogo || undefined}
                        showDownloadButton
                        showCopyButton
                        showData
                      />
                    </Flex>
                  </CardBody>
                </Card>
              </SimpleGrid>
            </TabPanel>
            
            {/* Smart Document Tab */}
            <TabPanel>
              <Card>
                <CardHeader>
                  <Heading size="md">Create Smart Document</Heading>
                </CardHeader>
                <CardBody>
                  <VStack spacing={4} align="stretch">
                    <Text>
                      Create a smart document with embedded metadata and QR code.
                      The document will be signed with Integra's digital signature.
                    </Text>
                    
                    {pdfFile ? (
                      <Button
                        colorScheme="blue"
                        onClick={onOpen}
                        isDisabled={isProcessing}
                        isLoading={isProcessing}
                        loadingText={`Processing (${processingStep})`}
                      >
                        Create Smart Document
                      </Button>
                    ) : (
                      <Text color="orange.500">
                        Please upload a PDF document first
                      </Text>
                    )}
                  </VStack>
                </CardBody>
              </Card>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
      
      {/* Smart Document Modal */}
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Smart Document</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} align="stretch">
              <FormControl>
                <FormLabel>Integra Endpoint</FormLabel>
                <Input
                  value={integraEndpoint}
                  onChange={(e) => setIntegraEndpoint(e.target.value)}
                  placeholder="Enter Integra API endpoint"
                />
              </FormControl>
              
              <FormControl>
                <FormLabel>Custom Endpoint (optional)</FormLabel>
                <Input
                  value={customEndpoint}
                  onChange={(e) => setCustomEndpoint(e.target.value)}
                  placeholder="Enter custom endpoint"
                />
              </FormControl>
              
              <FormControl>
                <Checkbox
                  isChecked={addQrCode}
                  onChange={(e) => setAddQrCode(e.target.checked)}
                >
                  Add QR code to document
                </Checkbox>
              </FormControl>
              
              <Divider />
              
              <Box>
                <Heading size="sm" mb={2}>Document Information</Heading>
                <SimpleGrid columns={2} spacing={2}>
                  <Box>
                    <Text fontWeight="medium">File Name:</Text>
                    <Text color="gray.600">{pdfFile?.name}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="medium">File Size:</Text>
                    <Text color="gray.600">
                      {pdfFile ? `${(pdfFile.size / 1024).toFixed(2)} KB` : '-'}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontWeight="medium">Original Hash:</Text>
                    <Text color="gray.600" isTruncated>
                      {originalHash || '-'}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontWeight="medium">Integra ID:</Text>
                    <Text color="gray.600">
                      {metadata?.integraId || 'Will be generated'}
                    </Text>
                  </Box>
                </SimpleGrid>
              </Box>
            </VStack>
          </ModalBody>
          
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleCreateSmartDocument}
              isLoading={isProcessing}
              loadingText={`Processing (${processingStep})`}
            >
              Create & Download
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </StandardPageLayout>
  );
}

export default PDFTools;
