/**
 * PDF Preview Component
 * 
 * This component displays a PDF preview using the document data from the PDF store.
 * It handles both file data URLs and smart document URLs.
 */

import React from 'react';
import {
  Box,
  Flex,
  Text,
  VStack,
  Icon,
  useColorModeValue,
  Spinner
} from '@chakra-ui/react';
import { FiFileText } from 'react-icons/fi';

// Import stores
import { usePDFStore } from '../store/pdfStore';

// Import PDF viewer component
import { PDFViewer } from './PDFViewer';

/**
 * PDF Preview Component
 */
const PDFPreview: React.FC = () => {
  // Get PDF store state
  const { 
    fileDataUrl, 
    smartDocumentUrl,
    fileName,
    isProcessing
  } = usePDFStore();
  
  // Theme colors
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  
  // Determine which URL to use for the PDF viewer
  const pdfUrl = smartDocumentUrl || fileDataUrl;
  
  // If no PDF URL is available, show a placeholder
  if (!pdfUrl) {
    return (
      <Box
        height="100%"
        minHeight="600px"
        borderWidth="1px"
        borderRadius="lg"
        borderColor={borderColor}
        bg={bgColor}
        p={6}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <VStack spacing={4}>
          <Icon as={FiFileText} fontSize="6xl" />
          <Text>No document available for preview</Text>
        </VStack>
      </Box>
    );
  }
  
  // If the document is processing, show a loading state
  if (isProcessing) {
    return (
      <Box
        height="100%"
        minHeight="600px"
        borderWidth="1px"
        borderRadius="lg"
        borderColor={borderColor}
        bg={bgColor}
        p={6}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <VStack spacing={4}>
          <Spinner size="xl" />
          <Text>Processing document...</Text>
        </VStack>
      </Box>
    );
  }
  
  return (
    <Box
      height="100%"
      minHeight="600px"
      borderWidth="1px"
      borderRadius="lg"
      borderColor={borderColor}
      bg={bgColor}
      overflow="hidden"
    >
      <Flex direction="column" height="100%">
        {/* Document name header */}
        <Box
          p={4}
          borderBottomWidth="1px"
          borderColor={borderColor}
          bg={useColorModeValue('gray.50', 'gray.900')}
        >
          <Text fontWeight="bold" isTruncated>
            {fileName || 'Document Preview'}
          </Text>
        </Box>
        
        {/* PDF Viewer */}
        <Box flex="1" minHeight="500px">
          <PDFViewer file={pdfUrl} />
        </Box>
      </Flex>
    </Box>
  );
};

export default PDFPreview;
