import React from 'react';
import {
  Box,
  Spinner,
  Text,
  VStack,
  useColorModeValue
} from '@chakra-ui/react';

interface LoadingSpinnerProps {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  thickness?: string;
  speed?: string;
  text?: string;
  fullScreen?: boolean;
}

/**
 * LoadingSpinner Component
 * 
 * A reusable loading spinner component with optional text
 */
const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  size = 'xl',
  thickness = '4px',
  speed = '0.8s',
  text,
  fullScreen = false
}) => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const spinnerColor = useColorModeValue('blue.500', 'blue.300');
  const textColor = useColorModeValue('gray.600', 'gray.300');
  
  const content = (
    <VStack spacing={4}>
      <Spinner
        size={size}
        thickness={thickness}
        speed={speed}
        color={spinnerColor}
      />
      {text && (
        <Text color={textColor} fontWeight="medium">
          {text}
        </Text>
      )}
    </VStack>
  );
  
  if (fullScreen) {
    return (
      <Box
        position="fixed"
        top="0"
        left="0"
        right="0"
        bottom="0"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bg={bgColor}
        zIndex="overlay"
      >
        {content}
      </Box>
    );
  }
  
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      py={8}
      width="100%"
    >
      {content}
    </Box>
  );
};

export default LoadingSpinner;
