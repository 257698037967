/**
 * useDocumentActivities Hook
 * 
 * This hook provides a convenient interface for fetching document activities.
 * It handles loading states, error handling, and provides methods for fetching activities.
 */

import { useState, useEffect, useCallback } from 'react';
import { documentActivityService, DocumentActivity } from '../services/DocumentActivityService';

/**
 * Document Activities Hook Result
 */
export interface UseDocumentActivitiesResult {
  activities: DocumentActivity[];
  loading: boolean;
  error: Error | null;
  refreshActivities: () => Promise<void>;
  hasError: boolean;
  isServerError: boolean;
}

/**
 * useDocumentActivities Hook
 * 
 * @param profileId Profile ID
 * @param organizationId Organization ID
 * @returns Hook result with activities, loading state, error, and refresh function
 */
export function useDocumentActivities(
  profileId: string,
  organizationId: string
): UseDocumentActivitiesResult {
  const [activities, setActivities] = useState<DocumentActivity[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const [hasError, setHasError] = useState<boolean>(false);
  const [isServerError, setIsServerError] = useState<boolean>(false);
  const [retryCount, setRetryCount] = useState<number>(0);

  /**
   * Fetch document activities
   */
  const fetchActivities = useCallback(async () => {
    if (!profileId || !organizationId) {
      setActivities([]);
      setLoading(false);
      setHasError(false);
      setIsServerError(false);
      return;
    }

    setLoading(true);
    setError(null);
    setHasError(false);
    setIsServerError(false);

    try {
      console.log('Fetching document activities for:', { profileId, organizationId });
      
      const result = await documentActivityService.getDocumentActivities(
        profileId,
        organizationId
      );
      
      console.log('Document activities received:', {
        count: result?.length || 0,
        activities: result,
        timestamp: new Date().toISOString()
      });
      
      setActivities(result || []);
      
      // Reset retry count on success
      if (retryCount > 0) {
        setRetryCount(0);
      }
    } catch (err: any) {
      console.error('Error fetching document activities:', err);
      
      // Determine if it's a server error (500)
      const isServer500Error = err?.response?.status === 500;
      setIsServerError(isServer500Error);
      
      // Set the error state
      setError(err instanceof Error ? err : new Error(String(err)));
      setHasError(true);
      
      // Keep the previous activities if we have them, otherwise set to empty array
      if (activities.length === 0) {
        setActivities([]);
      }
    } finally {
      setLoading(false);
    }
  }, [profileId, organizationId, activities.length, retryCount]);

  /**
   * Refresh activities
   */
  const refreshActivities = useCallback(async () => {
    setRetryCount(prev => prev + 1);
    await fetchActivities();
  }, [fetchActivities]);

  // Fetch activities on mount and when dependencies change
  useEffect(() => {
    fetchActivities();
  }, [fetchActivities]);

  // Auto-retry once after 5 seconds if we have a server error
  useEffect(() => {
    let retryTimer: number | undefined;
    
    if (isServerError && retryCount < 2) {
      console.log(`Server error detected. Will retry in 5 seconds (attempt ${retryCount + 1})...`);
      retryTimer = window.setTimeout(() => {
        refreshActivities();
      }, 5000);
    }
    
    return () => {
      if (retryTimer) {
        clearTimeout(retryTimer);
      }
    };
  }, [isServerError, refreshActivities, retryCount]);

  return {
    activities,
    loading,
    error,
    refreshActivities,
    hasError,
    isServerError
  };
}

export default useDocumentActivities;
