/**
 * V3 Blockchain Service
 * 
 * This service is responsible for interacting with V3 blockchain workflows.
 * It provides a unified interface for all V3 blockchain operations,
 * handling authentication, organization context, and workflow IDs.
 */

import { v4 as uuidv4 } from 'uuid';
import {
  RegisterDocumentParams,
  TokenizeDocumentParams,
  GetDocumentsByDocumentHashParams,
  GetDocumentsByIntegraIdParams,
  GetDocumentsByReferenceIdParams,
  V3WorkflowMap,
  V3WorkflowConfig,
  V3WorkflowConfigs,
  DocumentRegistrationResult,
  DocumentVerificationResult,
  WorkflowStatus,
  V3WorkflowNames,
  SyncDocumentRecordParams
} from '../types/v3Workflows';
import { extractDocumentsFromVerificationResult } from '../utils/blockchainResultParser';

// API Configuration
const API_TIMEOUT = 30000; // 30 seconds
// Use production server with v3 path prefix
const API_BASE_URL = 'https://comms.integraledger.com/v3'; // Production API endpoint with v3 path
// const API_BASE_URL = 'http://localhost:8000'; // Local development API endpoint

// Enable debug mode for development
const DEBUG_MODE = true;

// Test Integra hash for development
const TEST_INTEGRA_HASH = '0xdc8e47fd4cbc165b5e32ef36caa41d06bda71f4acd2e55815ac6e63cff7a60aa';

/**
 * API Error
 */
export class APIError extends Error {
  constructor(
    message: string,
    public statusCode: number,
    public code?: string
  ) {
    super(message);
    this.name = 'APIError';
  }
}

/**
 * Network Error
 */
export class NetworkError extends APIError {
  constructor(message = 'Network error occurred') {
    super(message, 0, 'NETWORK_ERROR');
    this.name = 'NetworkError';
  }
}

/**
 * Timeout Error
 */
export class TimeoutError extends APIError {
  constructor(message = 'Request timed out') {
    super(message, 408, 'TIMEOUT');
    this.name = 'TimeoutError';
  }
}

/**
 * V3 Blockchain Service
 */
export class V3BlockchainService {
  private apiBaseUrl: string;
  private workflowConfigs: Record<string, V3WorkflowConfig>;
  private authToken: string | null = null;
  
  constructor(apiBaseUrl: string = API_BASE_URL) {
    this.apiBaseUrl = apiBaseUrl;
    this.workflowConfigs = V3WorkflowConfigs;
  }
  
  /**
   * Set the authentication token for API requests
   * 
   * @param token The authentication token to use for requests
   */
  public setAuthToken(token: string): void {
    // Ensure the token is properly formatted as a Bearer token
    this.authToken = token.startsWith('Bearer ') ? token : `Bearer ${token}`;
    console.log(`[V3BlockchainService] Auth token set: ${this.authToken ? 'Token exists' : 'No token'}`);
  }
  
  /**
   * Execute a GET workflow
   * 
   * @param workflowName Name of the workflow to execute
   * @param params Parameters for the workflow
   * @param profileId Profile ID from auth context
   * @param organizationId Organization ID from auth context
   * @param workflowId Workflow ID (generated if not provided)
   * @returns Promise resolving to the workflow result
   */
  async executeGet<T extends keyof V3WorkflowMap>(
    workflowName: T,
    params: V3WorkflowMap[T]['params'],
    profileId: string,
    organizationId: string,
    workflowId: string = uuidv4()
  ): Promise<V3WorkflowMap[T]['result']> {
    try {
      const config = this.workflowConfigs[workflowName];
      
      if (!config) {
        throw new Error(`Workflow ${workflowName} not found`);
      }
      
      if (config.method !== 'GET') {
        throw new Error(`Workflow ${workflowName} is not a GET workflow`);
      }
      
      // Add workflow ID, profile ID, and organization ID to params
      const fullParams = {
        ...params,
        workflow_id: workflowId,
        profile_id: profileId,
        organization_id: organizationId
      };
      
      // Build URL with query parameters
      const url = new URL(`${this.apiBaseUrl}${config.endpoint}`);
      
      // Add query parameters
      Object.entries(fullParams).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
          url.searchParams.append(key, String(value));
        }
      });
      
      // Debug log
      if (DEBUG_MODE) {
        console.log(`[V3BlockchainService] Executing GET workflow: ${workflowName}`);
        console.log(`[V3BlockchainService] URL: ${url.toString()}`);
        console.log(`[V3BlockchainService] Params:`, fullParams);
      }
      
      // Set up the request headers
      const headers: Record<string, string> = {
        'Content-Type': 'application/json',
        'x-profile-id': profileId,
        'x-organization-id': organizationId
      };
      
      // Add Authorization header if we have an auth token
      if (this.authToken) {
        // Ensure the token is properly formatted as a Bearer token
        const formattedToken = this.authToken.startsWith('Bearer ') ? this.authToken : `Bearer ${this.authToken}`;
        headers['Authorization'] = formattedToken;
      }
      
      // Execute the request
      const response = await this.fetchWithTimeout(url.toString(), {
        method: 'GET',
        headers
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        let errorData;
        
        try {
          errorData = JSON.parse(errorText);
        } catch (e) {
          errorData = { message: errorText };
        }
        
        if (DEBUG_MODE) {
          console.error(`[V3BlockchainService] Error response:`, {
            status: response.status,
            statusText: response.statusText,
            errorData
          });
        }
        
        throw new APIError(
          errorData.message || response.statusText || 'Unknown API error',
          response.status,
          errorData.code
        );
      }
      
      // Try to parse the response as JSON
      let data;
      try {
        const responseText = await response.text();
        if (responseText.trim() === '') {
          data = {};
        } else {
          data = JSON.parse(responseText);
        }
      } catch (error: any) {
        console.error(`[V3BlockchainService] Error parsing response:`, error);
        throw new Error(`Failed to parse API response: ${error.message}`);
      }
      
      if (DEBUG_MODE) {
        console.log(`[V3BlockchainService] Response data:`, data);
      }
      
      return data;
    } catch (error) {
      if (error instanceof APIError) {
        throw error;
      }
      
      if (error instanceof TypeError && error.message.includes('fetch')) {
        throw new NetworkError();
      }
      
      throw new APIError(
        error instanceof Error ? error.message : String(error),
        500
      );
    }
  }
  
  /**
   * Execute a POST workflow
   * 
   * @param workflowName Name of the workflow to execute
   * @param params Parameters for the workflow
   * @param profileId Profile ID from auth context
   * @param organizationId Organization ID from auth context
   * @param workflowId Workflow ID (generated if not provided)
   * @returns Promise resolving to the workflow ID
   */
  async executePost<T extends keyof V3WorkflowMap>(
    workflowName: T,
    params: V3WorkflowMap[T]['params'],
    profileId: string,
    organizationId: string,
    workflowId: string = uuidv4()
  ): Promise<string> {
    try {
      const config = this.workflowConfigs[workflowName];
      
      if (!config) {
        throw new Error(`Workflow ${workflowName} not found`);
      }
      
      if (config.method !== 'POST') {
        throw new Error(`Workflow ${workflowName} is not a POST workflow`);
      }
      
      // Add workflow ID, profile ID, and organization ID to params
      const fullParams = {
        ...params,
        workflow_id: workflowId,
        profile_id: profileId,
        organization_id: organizationId
      };
      
      // Debug log
      if (DEBUG_MODE) {
        console.log(`[V3BlockchainService] Executing POST workflow: ${workflowName}`);
        console.log(`[V3BlockchainService] URL: ${this.apiBaseUrl}${config.endpoint}`);
        console.log(`[V3BlockchainService] Params:`, fullParams);
      }
      
      // Set up the request headers
      const headers: Record<string, string> = {
        'Content-Type': 'application/json',
        'x-profile-id': profileId,
        'x-organization-id': organizationId
      };
      
      // Add Authorization header if we have an auth token
      if (this.authToken) {
        // Ensure the token is properly formatted as a Bearer token
        const formattedToken = this.authToken.startsWith('Bearer ') ? this.authToken : `Bearer ${this.authToken}`;
        headers['Authorization'] = formattedToken;
        console.log(`[V3BlockchainService] Including auth token: ${this.authToken.substring(0, 15)}...`);
        
        // Log the token format for debugging
        const tokenParts = this.authToken.split(' ');
        console.log(`[V3BlockchainService] Token format check: prefix="${tokenParts[0]}", length=${tokenParts[1]?.length || 0}`);
      } else {
        console.log('[V3BlockchainService] No auth token available for request');
      }
      
      console.log('[V3BlockchainService] Request headers:', headers);
      console.log('[V3BlockchainService] Request payload:', fullParams);
      
      // Execute the request
      const response = await this.fetchWithTimeout(`${this.apiBaseUrl}${config.endpoint}`, {
        method: 'POST',
        headers,
        body: JSON.stringify(fullParams)
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        let errorData;
        
        try {
          errorData = JSON.parse(errorText);
        } catch (e) {
          errorData = { message: errorText };
        }
        
        if (DEBUG_MODE) {
          console.error(`[V3BlockchainService] Error response:`, {
            status: response.status,
            statusText: response.statusText,
            errorData
          });
        }
        
        throw new APIError(
          errorData.message || response.statusText || 'Unknown API error',
          response.status,
          errorData.code
        );
      }
      
      // Try to parse the response as JSON
      let data;
      try {
        const responseText = await response.text();
        if (responseText.trim() === '') {
          data = {};
        } else {
          data = JSON.parse(responseText);
        }
      } catch (error: any) {
        console.error(`[V3BlockchainService] Error parsing response:`, error);
        // Even if parsing fails, we still return the workflow ID
      }
      
      if (DEBUG_MODE && data) {
        console.log(`[V3BlockchainService] Response data:`, data);
      }
      
      return workflowId;
    } catch (error) {
      if (error instanceof APIError) {
        throw error;
      }
      
      if (error instanceof TypeError && error.message.includes('fetch')) {
        throw new NetworkError();
      }
      
      throw new APIError(
        error instanceof Error ? error.message : String(error),
        500
      );
    }
  }
  
  /**
   * Execute a POST query workflow (for read operations)
   * 
   * @param workflowName Name of the workflow to execute
   * @param params Parameters for the workflow
   * @param profileId Profile ID from auth context
   * @param organizationId Organization ID from auth context
   * @param workflowId Workflow ID (generated if not provided)
   * @returns Promise resolving to the workflow result
   */
  async executePostQuery<T extends keyof V3WorkflowMap>(
    workflowName: T,
    params: V3WorkflowMap[T]['params'],
    profileId: string,
    organizationId: string,
    workflowId: string = uuidv4()
  ): Promise<V3WorkflowMap[T]['result']> {
    try {
      const config = this.workflowConfigs[workflowName];
      
      if (!config) {
        throw new Error(`Workflow ${workflowName} not found`);
      }
      
      if (config.method !== 'POST') {
        throw new Error(`Workflow ${workflowName} is not a POST workflow`);
      }
      
      // Add workflow ID, profile_id, and organization ID to params
      const fullParams = {
        ...params,
        workflow_id: workflowId,
        organization_id: organizationId
      };
      
      // Add profile_id to params if not already present
      // This is needed because some workflow params like GetReservedTokensParams don't include profile_id
      if (!('profile_id' in fullParams)) {
        (fullParams as any).profile_id = profileId;
      }
      
      // Debug log
      console.log(`[V3BlockchainService] Executing POST query workflow: ${workflowName}`);
      console.log(`[V3BlockchainService] URL: ${this.apiBaseUrl}${config.endpoint}`);
      console.log(`[V3BlockchainService] Params keys:`, Object.keys(fullParams));
      
      // Log blockchain documents if present
      if ('blockchain_documents' in fullParams && Array.isArray(fullParams.blockchain_documents)) {
        console.log(`[V3BlockchainService] Blockchain documents count:`, fullParams.blockchain_documents.length);
        if (fullParams.blockchain_documents.length > 0) {
          console.log(`[V3BlockchainService] First blockchain document:`, JSON.stringify(fullParams.blockchain_documents[0], null, 2));
        }
      } else {
        console.warn(`[V3BlockchainService] No blockchain_documents in params!`);
      }
      
      // Execute the request
      const response = await this.fetchWithTimeout(`${this.apiBaseUrl}${config.endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-profile-id': profileId,
          'x-organization-id': organizationId,
          ...(this.authToken ? {
            'Authorization': this.authToken.startsWith('Bearer ') ? this.authToken : `Bearer ${this.authToken}`
          } : {})
        },
        body: JSON.stringify(fullParams)
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        let errorData;
        
        try {
          errorData = JSON.parse(errorText);
        } catch (e) {
          errorData = { message: errorText };
        }
        
        console.error(`[V3BlockchainService] Error response:`, {
          status: response.status,
          statusText: response.statusText,
          errorData
        });
        
        throw new APIError(
          errorData.message || response.statusText || 'Unknown API error',
          response.status,
          errorData.code
        );
      }
      
      // Try to parse the response as JSON
      let data;
      try {
        const responseText = await response.text();
        console.log(`[V3BlockchainService] Raw response text:`, responseText);
        
        if (responseText.trim() === '') {
          data = {};
        } else {
          data = JSON.parse(responseText);
        }
      } catch (error: any) {
        console.error(`[V3BlockchainService] Error parsing response:`, error);
        throw new Error(`Failed to parse API response: ${error.message}`);
      }
      
      console.log(`[V3BlockchainService] Parsed response data:`, data);
      
      return data;
    } catch (error) {
      if (error instanceof APIError) {
        throw error;
      }
      
      if (error instanceof TypeError && error.message.includes('fetch')) {
        throw new NetworkError();
      }
      
      throw new APIError(
        error instanceof Error ? error.message : String(error),
        500
      );
    }
  }
  
  /**
   * Get workflow status
   * 
   * @param workflowId Workflow ID to get status for
   * @returns Promise resolving to the workflow status
   */
  async getWorkflowStatus(workflowId: string): Promise<WorkflowStatus> {
    try {
      console.log(`[V3BlockchainService] Getting workflow status for: ${workflowId}`);
      
      // Use direct GET request to the workflow-status endpoint
      const url = `${API_BASE_URL}/workflow-status/${workflowId}`;
      console.log(`[V3BlockchainService] Workflow status URL: ${url}`);
      
      const response = await this.fetchWithTimeout(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      
      const responseText = await response.text();
      console.log(`[V3BlockchainService] Workflow status raw response:`, responseText);
      
      if (!response.ok) {
        throw new APIError(`Error getting workflow status: ${response.statusText}`, response.status);
      }
      
      const status = JSON.parse(responseText);
      console.log(`[V3BlockchainService] Workflow status response:`, status);
      return status;
    } catch (error) {
      console.error(`[V3BlockchainService] Error getting workflow status:`, error);
      throw error;
    }
  }
  
  /**
   * Register a document
   * 
   * @param params Document registration parameters
   * @param profileId Profile ID from auth context
   * @param organizationId Organization ID from auth context
   * @param workflowId Workflow ID (generated if not provided)
   * @returns Promise resolving to an object with workflow_id and success status
   */
  async registerDocument(
    params: Omit<RegisterDocumentParams, 'workflow_id' | 'profile_id' | 'organization_id'>,
    profileId: string,
    organizationId: string,
    workflowId: string = uuidv4()
  ): Promise<{ workflow_id: string; success: boolean; error?: string }> {
    try {
      // Ensure document hash has 0x prefix
      if (!params.document_hash.startsWith('0x')) {
        params.document_hash = `0x${params.document_hash}`;
      }
      
      // Ensure reference_id is an empty string if not provided
      // This is required by the blockchain API
      const fullParams: RegisterDocumentParams = {
        ...params,
        reference_id: params.reference_id || '',
        workflow_id: workflowId,
        profile_id: profileId,
        organization_id: organizationId
      };
      
      await this.executePost(
        V3WorkflowNames.REGISTER_DOCUMENT,
        fullParams,
        profileId,
        organizationId,
        workflowId
      );
      
      return {
        workflow_id: workflowId,
        success: true
      };
    } catch (error) {
      console.error('Error registering document:', error);
      
      return {
        workflow_id: workflowId,
        success: false,
        error: error instanceof Error ? error.message : 'An unexpected error occurred'
      };
    }
  }

  /**
   * Tokenize a document on the blockchain
   * 
   * @param params Document tokenization parameters
   * @param profileId Profile ID from auth context
   * @param organizationId Organization ID from auth context
   * @param workflowId Workflow ID (generated if not provided)
   * @returns Promise resolving to the workflow result
   */
  async tokenizeDocument(
    params: Omit<TokenizeDocumentParams, 'workflow_id' | 'profile_id' | 'organization_id'>,
    profileId: string,
    organizationId: string,
    workflowId: string = uuidv4()
  ): Promise<{ workflow_id: string; success: boolean; error?: string }> {
    try {
      // Ensure document hash has 0x prefix
      if (!params.document_hash.startsWith('0x')) {
        params.document_hash = `0x${params.document_hash}`;
      }
      
      // Ensure reference_id is an empty string if not provided
      // This is required by the blockchain API
      const fullParams: TokenizeDocumentParams = {
        ...params,
        reference_id: params.reference_id || '',
        workflow_id: workflowId,
        profile_id: profileId,
        organization_id: organizationId
      };
      
      await this.executePost(
        V3WorkflowNames.TOKENIZE_DOCUMENT,
        fullParams,
        profileId,
        organizationId,
        workflowId
      );
      
      return {
        workflow_id: workflowId,
        success: true
      };
    } catch (error) {
      console.error('Error tokenizing document:', error);
      
      return {
        workflow_id: workflowId,
        success: false,
        error: error instanceof Error ? error.message : 'An unexpected error occurred'
      };
    }
  }
  
  /**
   * Verify a document by document hash
   * 
   * @param documentHash Document hash
   * @param profileId Profile ID from auth context
   * @param organizationId Organization ID from auth context
   * @param workflowId Workflow ID (generated if not provided)
   * @returns Promise resolving to the document verification result
   */
  async verifyDocumentByHash(
    documentHash: string,
    profileId: string,
    organizationId: string,
    workflowId: string = uuidv4()
  ): Promise<{
    verified: boolean;
    integra_hash?: string;
    integra_id?: string;
    document_hash: string;
    transaction_hash?: string;
    registration_date?: string;
    reference_id?: string;
    metadata?: any;
    workflow_id: string;
    raw_response?: any;
    error?: string;
  }> {
    // Ensure document hash has 0x prefix
    if (!documentHash.startsWith('0x')) {
      documentHash = `0x${documentHash}`;
    }
    
    const params: GetDocumentsByDocumentHashParams = {
      document_hash: documentHash,
      workflow_id: workflowId,
      profile_id: profileId,
      organization_id: organizationId
    };
    
    try {
      console.log(`[V3BlockchainService] Verifying document by hash:`, documentHash);
      console.log(`[V3BlockchainService] Request params:`, params);
      
      // Start the verification workflow
      const workflowResponse = await this.executePostQuery(
        V3WorkflowNames.GET_DOCUMENTS_BY_DOCUMENTHASH,
        params,
        profileId,
        organizationId,
        workflowId
      );
      
      console.log(`[V3BlockchainService] Workflow started:`, workflowResponse);
      
      // Check if we have a workflow ID in the response
      if (!workflowResponse?.data?.workflow_id) {
        throw new Error('No workflow ID returned from API');
      }
      
      // Use the workflow ID from the response
      const responseWorkflowId = workflowResponse.data.workflow_id;
      
      // Poll for workflow completion
      console.log(`[V3BlockchainService] Polling for workflow completion: ${responseWorkflowId}`);
      
      // Wait for workflow to complete (with timeout)
      const maxAttempts = 20;
      const pollingInterval = 2000; // 2 seconds
      
      let workflowStatus: WorkflowStatus | null = null;
      let attempts = 0;
      
      while (attempts < maxAttempts) {
        attempts++;
        console.log(`[V3BlockchainService] Polling attempt ${attempts}/${maxAttempts}`);
        
        // Get workflow status
        workflowStatus = await this.getWorkflowStatus(responseWorkflowId);
        console.log(`[V3BlockchainService] Workflow status:`, workflowStatus);
        
        // Check if workflow is completed (case insensitive)
        if (workflowStatus.data && workflowStatus.data.status && workflowStatus.data.status.toUpperCase() === 'COMPLETED') {
          console.log(`[V3BlockchainService] Workflow completed:`, workflowStatus);
          break;
        }
        
        // Check if workflow failed (case insensitive)
        if (workflowStatus.data && workflowStatus.data.status && workflowStatus.data.status.toUpperCase() === 'FAILED') {
          console.log(`[V3BlockchainService] Workflow failed:`, workflowStatus);
          throw new Error(`Workflow failed: ${workflowStatus.data.result?.error || 'Unknown error'}`);
        }
        
        // Wait before next attempt
        await new Promise(resolve => setTimeout(resolve, pollingInterval));
      }
      
      // Check if we timed out
      if (attempts >= maxAttempts && (!workflowStatus || !workflowStatus.data || workflowStatus.data.status.toUpperCase() !== 'COMPLETED')) {
        throw new Error('Workflow verification timed out');
      }
      
      // Get the result from the workflow status
      console.log(`[V3BlockchainService] Processing workflow result:`, workflowStatus);
      
      // The result structure is nested inside the workflow status
      // data.result.query_result.documents.data contains the array of documents
      const result = workflowStatus?.data?.result;
      const queryResult = result?.query_result;
      const documents = queryResult?.documents?.data || [];
      const timestamp = queryResult?.documents?.timestamp || queryResult?.timestamp;
      
      console.log(`[V3BlockchainService] Extracted documents:`, documents);
      console.log(`[V3BlockchainService] Timestamp:`, timestamp);
      
      // Transform the API response into a more user-friendly format
      if (documents && Array.isArray(documents) && documents.length > 0) {
        console.log(`[V3BlockchainService] Document found:`, documents[0]);
        const document = documents[0];
        
        // Parse encrypted data if available
        let metadata = {};
        if (document.encryptedData) {
          try {
            metadata = JSON.parse(document.encryptedData);
          } catch (e) {
            console.error('Error parsing encrypted data:', e);
          }
        }
        
        const transformedResult = {
          verified: true,
          integra_hash: document.integraHash, // Add explicit integra_hash field
          integra_id: document.integraHash,
          document_hash: document.documentHash,
          transaction_hash: document.txHash,
          registration_date: timestamp,
          reference_id: document.referenceHash,
          metadata,
          workflow_id: responseWorkflowId,
          raw_response: workflowStatus // Include the raw response for debugging
        };
        
        console.log(`[V3BlockchainService] Transformed result:`, transformedResult);
        return transformedResult;
      } else {
        console.log(`[V3BlockchainService] No documents found for hash:`, documentHash);
        
        const notFoundResult = {
          verified: false,
          document_hash: documentHash,
          workflow_id: responseWorkflowId,
          raw_response: workflowStatus // Include the raw response for debugging
        };
        
        console.log(`[V3BlockchainService] Not found result:`, notFoundResult);
        return notFoundResult;
      }
    } catch (error) {
      console.error('Error verifying document:', error);
      
      const errorResult = {
        verified: false,
        error: error instanceof Error ? error.message : 'An unexpected error occurred',
        document_hash: documentHash,
        workflow_id: workflowId
      };
      
      console.log(`[V3BlockchainService] Error result:`, errorResult);
      return errorResult;
    }
  }
  
  /**
   * Get reserved tokens for a document by integra hash
   * 
   * @param integraHash Integra hash of the document
   * @param profileId Profile ID from auth context
   * @param organizationId Organization ID from auth context
   * @param workflowId Workflow ID (generated if not provided)
   * @returns Promise resolving to the reserved tokens result
   */
  async getReservedTokens(
    integraHash: string,
    profileId: string,
    organizationId: string,
    workflowId: string = uuidv4()
  ): Promise<{
    success: boolean;
    tokens?: Array<{
      label: string;
      description: string;
      integraHash: string;
      amount: string;
      tokenId: string;
    }>;
    workflow_id: string;
    error?: string;
  }> {
    try {
      console.log('Starting getReservedTokens workflow with hash:', integraHash);
      
      // Execute the POST query workflow
      const response = await this.executePostQuery<'get-reserved-tokens'>(
        V3WorkflowNames.GET_RESERVED_TOKENS,
        {
          integra_hash: integraHash
        },
        profileId,
        organizationId,
        workflowId
      );
      
      console.log('Reserved tokens response:', JSON.stringify(response, null, 2));
      
      // Check if we have a direct response with tokens
      if (response && response.data && 'tokens' in (response.data as any) && Array.isArray((response.data as any).tokens)) {
        console.log('Found tokens in direct response');
        
        // Map the response to our expected format
        const mappedTokens = (response.data as any).tokens.map((token: any) => ({
          label: token.label || 'Unknown',
          description: token.optionalData || token.description || '',
          integraHash: token.integraHash || integraHash,
          amount: token.amount || '1',
          tokenId: token.tokenId || token.id || 'unknown'
        }));
        
        return {
          success: true,
          tokens: mappedTokens,
          workflow_id: workflowId
        };
      }
      
      // If we don't have tokens in the direct response, check the workflow status
      console.log('No tokens in direct response, checking workflow status');
      
      // Wait for the workflow to complete (in a real app, you'd poll or use a webhook)
      await new Promise(resolve => setTimeout(resolve, 2000));
      
      // Get the workflow result
      const statusResponse = await this.getWorkflowStatus(workflowId);
      console.log('Workflow status response:', JSON.stringify(statusResponse, null, 2));
      
      // Try to extract tokens from various possible response structures
      let tokens: any[] = [];
      
      // Use a type assertion to access potential token properties
      const anyStatusResponse = statusResponse as any;
      
      // Check all possible paths where tokens might be found
      if (anyStatusResponse.data?.result?.query_result?.tokens?.reservedTokens) {
        tokens = anyStatusResponse.data.result.query_result.tokens.reservedTokens;
      } else if (anyStatusResponse.data?.result?.tokens) {
        tokens = anyStatusResponse.data.result.tokens;
      } else if (anyStatusResponse.data?.tokens) {
        tokens = anyStatusResponse.data.tokens;
      } else if ((response as any).tokens) {
        tokens = (response as any).tokens;
      } else if ((response as any).data?.result?.tokens) {
        tokens = (response as any).data.result.tokens;
      }
      
      if (tokens && tokens.length > 0) {
        console.log('Found tokens:', tokens);
        
        // Map the tokens to our expected format
        const mappedTokens = tokens.map((token: any) => ({
          label: token.label || 'Unknown',
          description: token.optionalData || token.description || '',
          integraHash: token.integraHash || integraHash,
          amount: token.amount || '1',
          tokenId: token.tokenId || token.id || 'unknown'
        }));
        
        return {
          success: true,
          tokens: mappedTokens,
          workflow_id: workflowId
        };
      }
      
      // If we still don't have tokens, return an empty result
      return {
        success: false,
        tokens: [],
        workflow_id: workflowId,
        error: 'No tokens found for this document'
      };
    } catch (error) {
      console.error('Error getting reserved tokens:', error);
      return {
        success: false,
        tokens: [],
        workflow_id: workflowId,
        error: error instanceof Error ? error.message : String(error)
      };
    }
  }
  
  /**
   * Synchronize documents by hash
   * 
   * This method synchronizes blockchain documents with the local database.
   * It takes the document hash and blockchain documents as input and returns
   * a list of all local documents with the matching hash.
   * 
   * @param documentHash Document hash
   * @param blockchainDocuments Blockchain documents from verification
   * @param profileId Profile ID from auth context
   * @param organizationId Organization ID from auth context
   * @param workflowId Workflow ID (generated if not provided)
   * @returns Promise with synchronization result
   */
  async syncDocumentsByHash(
    documentHash: string,
    blockchainDocuments: any[],
    profileId: string,
    organizationId: string,
    workflowId: string = uuidv4()
  ): Promise<{
    success: boolean;
    local_documents?: any[];
    workflow_id: string;
    error?: string;
  }> {
    try {
      console.log('Starting syncDocumentsByHash with hash:', documentHash);
      
      // Prepare the request payload
      const payload = {
        document_hash: documentHash,
        blockchain_documents: blockchainDocuments,
        profile_id: profileId,
        organization_id: organizationId,
        workflow_id: workflowId
      };
      
      // Make the API request to the document sync endpoint
      const response = await fetch(`${this.apiBaseUrl}/api/v1/document-sync`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Profile-ID': profileId,
          'X-Organization-ID': organizationId
        },
        body: JSON.stringify(payload)
      });
      
      // Parse the response
      const result = await response.json();
      
      console.log('Document sync response:', result);
      
      if (response.ok) {
        return {
          success: true,
          local_documents: result.local_documents || [],
          workflow_id: workflowId
        };
      } else {
        return {
          success: false,
          workflow_id: workflowId,
          error: result.detail || 'Failed to synchronize documents'
        };
      }
    } catch (error) {
      console.error('Error synchronizing documents:', error);
      return {
        success: false,
        workflow_id: workflowId,
        error: error instanceof Error ? error.message : String(error)
      };
    }
  }
  
  /**
   * Sync document record with backend
   * 
   * @param documentHash Document hash
   * @param payload Prepared payload with all necessary fields
   * @param profileId Profile ID
   * @param organizationId Organization ID
   * @param workflowId Workflow ID (generated if not provided)
   * @returns Promise resolving to sync result
   */
  async syncDocumentRecord(
    documentHash: string,
    payload: any,
    profileId: string,
    organizationId: string,
    workflowId: string = uuidv4()
  ): Promise<{
    success: boolean;
    local_documents?: any[];
    workflow_id: string;
    error?: string;
  }> {
    try {
      console.log('[V3BlockchainService] Starting syncDocumentRecord with hash:', documentHash);
      console.log('[V3BlockchainService] Using workflow ID:', workflowId);
      
      // Ensure the workflow parameters are correctly set
      const finalPayload: SyncDocumentRecordParams = {
        ...payload,
        document_hash: documentHash,
        profile_id: profileId,
        organization_id: organizationId,
        workflow_id: workflowId
      };
      
      console.log('[V3BlockchainService] Sync document record payload keys:', Object.keys(finalPayload));
      if (finalPayload.blockchain_documents) {
        console.log('[V3BlockchainService] Blockchain documents in payload:', finalPayload.blockchain_documents.length);
        if (finalPayload.blockchain_documents.length > 0) {
          console.log('[V3BlockchainService] First blockchain document:', JSON.stringify(finalPayload.blockchain_documents[0], null, 2));
        }
      } else {
        console.warn('[V3BlockchainService] No blockchain_documents in payload!');
      }
      
      // Execute the workflow
      console.log('[V3BlockchainService] Executing workflow:', V3WorkflowNames.SYNC_DOCUMENT_RECORD);
      const response = await this.executePostQuery(
        V3WorkflowNames.SYNC_DOCUMENT_RECORD,
        finalPayload,
        profileId,
        organizationId,
        workflowId
      );
      
      console.log('[V3BlockchainService] Initial workflow response:', {
        hasData: !!response?.data,
        workflowId: response?.data?.workflow_id,
        responseKeys: Object.keys(response || {})
      });
      
      // Check if we have a workflow ID in the response
      if (!response?.data?.workflow_id) {
        console.error('[V3BlockchainService] No workflow ID returned from API');
        throw new Error('No workflow ID returned from API');
      }
      
      // Use the workflow ID from the response
      const responseWorkflowId = response.data.workflow_id;
      
      // Poll for workflow completion
      console.log(`[V3BlockchainService] Polling for workflow completion: ${responseWorkflowId}`);
      
      // Wait for workflow to complete (with timeout)
      const maxAttempts = 20;
      const pollingInterval = 2000; // 2 seconds
      
      let workflowStatus: WorkflowStatus | null = null;
      let attempts = 0;
      
      while (attempts < maxAttempts) {
        attempts++;
        console.log(`[V3BlockchainService] Polling attempt ${attempts}/${maxAttempts} for workflow: ${responseWorkflowId}`);
        
        // Get workflow status
        workflowStatus = await this.getWorkflowStatus(responseWorkflowId);
        console.log(`[V3BlockchainService] Workflow status:`, {
          hasData: !!workflowStatus?.data,
          status: workflowStatus?.data?.status || 'unknown',
          hasResult: !!workflowStatus?.data?.result,
          resultKeys: workflowStatus?.data?.result ? Object.keys(workflowStatus.data.result) : []
        });
        
        // Check if workflow is completed (case insensitive)
        if (workflowStatus.data && workflowStatus.data.status && workflowStatus.data.status.toUpperCase() === 'COMPLETED') {
          console.log(`[V3BlockchainService] Workflow completed successfully after ${attempts} attempts`);
          break;
        }
        
        // Check if workflow failed (case insensitive)
        if (workflowStatus.data && workflowStatus.data.status && workflowStatus.data.status.toUpperCase() === 'FAILED') {
          console.log(`[V3BlockchainService] Workflow failed after ${attempts} attempts`);
          const errorMessage = workflowStatus.data.result?.error || 'Unknown error';
          console.error(`[V3BlockchainService] Workflow error: ${errorMessage}`);
          throw new Error(`Workflow failed: ${errorMessage}`);
        }
        
        // Wait before next attempt
        await new Promise(resolve => setTimeout(resolve, pollingInterval));
      }
      
      // Check if we timed out
      if (attempts >= maxAttempts && (!workflowStatus || !workflowStatus.data || workflowStatus.data.status.toUpperCase() !== 'COMPLETED')) {
        throw new Error('Workflow synchronization timed out');
      }
      
      // Get the result from the workflow status
      const result = workflowStatus?.data?.result;
      console.log('[V3BlockchainService] Processing workflow result:', {
        hasResult: !!result,
        resultKeys: result ? Object.keys(result) : [],
        hasQueryResult: !!result?.query_result,
        queryResultKeys: result?.query_result ? Object.keys(result.query_result) : []
      });
      
      let localDocuments: any[] = [];
      
      // Try to extract local_documents from various possible locations in the response
      if (result && 'local_documents' in result) {
        console.log('[V3BlockchainService] Found local_documents directly in result');
        localDocuments = (result as any).local_documents || [];
      } else if (result?.query_result && 'local_documents' in result.query_result) {
        console.log('[V3BlockchainService] Found local_documents in result.query_result');
        localDocuments = (result.query_result as any).local_documents || [];
      } else if (workflowStatus?.data && 'local_documents' in workflowStatus.data) {
        console.log('[V3BlockchainService] Found local_documents in workflowStatus.data');
        localDocuments = (workflowStatus.data as any).local_documents || [];
      } else if (result?.query_result?.documents?.data) {
        console.log('[V3BlockchainService] Found documents.data in result.query_result, mapping to local_documents');
        // If we don't have local_documents but have documents data, use that
        localDocuments = result.query_result.documents.data.map((doc: any) => ({
          document_id: doc.integraHash || '',
          organization_id: organizationId,
          profile_id: profileId,
          document_name: doc.documentHash || '',
          file_name: doc.documentHash || '',
          file_type: 'application/pdf',
          document_hash: doc.documentHash || '',
          blockchain: 'Polygon',
          blockchain_tx: doc.txHash || '',
          integra_id: doc.integraHash || '',
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        }));
      } else {
        console.warn('[V3BlockchainService] Could not find local_documents in any expected location in the response');
      }
      
      console.log('[V3BlockchainService] Final local documents count:', localDocuments.length);
      if (localDocuments.length > 0) {
        console.log('[V3BlockchainService] First document in local_documents:', {
          document_id: localDocuments[0].document_id,
          integra_id: localDocuments[0].integra_id,
          document_hash: localDocuments[0].document_hash
        });
      }
      
      return {
        success: true,
        local_documents: localDocuments,
        workflow_id: responseWorkflowId
      };
    } catch (error) {
      console.error('[V3BlockchainService] Error synchronizing document records:', error);
      
      // Provide more detailed error information
      let errorMessage = 'Failed to synchronize documents';
      if (error instanceof Error) {
        errorMessage = error.message;
      } else if (typeof error === 'string') {
        errorMessage = error;
      } else if (error && typeof error === 'object') {
        errorMessage = JSON.stringify(error);
      }
      
      console.error('[V3BlockchainService] Error details:', errorMessage);
      
      return {
        success: false,
        workflow_id: workflowId,
        error: errorMessage
      };
    }
  }
  
  /**
   * Get documents by document hash directly from the database
   * 
   * This method queries the database for documents with a matching document_hash
   * without going through the workflow process.
   * 
   * @param documentHash Document hash to query
   * @param profileId Profile ID from auth context
   * @param organizationId Organization ID from auth context
   * @returns Promise resolving to an array of document records
   */
  async getDocumentsByHash(
    documentHash: string,
    profileId: string,
    organizationId: string
  ): Promise<any[]> {
    try {
      console.log(`[V3BlockchainService] Fetching documents by hash: ${documentHash}`);
      
      // Ensure document hash has 0x prefix if it's a hex hash
      if (documentHash.match(/^[0-9a-f]{64}$/i) && !documentHash.startsWith('0x')) {
        documentHash = `0x${documentHash}`;
      }
      
      // Use the base URL without the v3 path for the document search endpoint
      // The document API is not part of the v3 API
      const baseUrl = this.apiBaseUrl.replace('/v3', '');
      const url = `${baseUrl}/api/documents/search/advanced?document_hash=${encodeURIComponent(documentHash)}`;
      
      // Set up the request headers
      const headers: Record<string, string> = {
        'Content-Type': 'application/json',
        'x-profile-id': profileId,
        'x-organization-id': organizationId
      };
      
      // Add Authorization header if we have an auth token
      if (this.authToken) {
        // Ensure the token is properly formatted as a Bearer token
        const formattedToken = this.authToken.startsWith('Bearer ') ? this.authToken : `Bearer ${this.authToken}`;
        headers['Authorization'] = formattedToken;
      }
      
      console.log(`[V3BlockchainService] Sending request to: ${url}`);
      console.log(`[V3BlockchainService] Headers:`, headers);
      
      // Make the API request
      const response = await this.fetchWithTimeout(url, {
        method: 'GET',
        headers,
        timeout: API_TIMEOUT
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error(`[V3BlockchainService] Error fetching documents: ${errorText}`);
        throw new Error(`API error: ${response.status} ${errorText}`);
      }
      
      const data = await response.json();
      console.log(`[V3BlockchainService] Documents response:`, data);
      
      if (data && data.documents && Array.isArray(data.documents)) {
        console.log(`[V3BlockchainService] Found ${data.documents.length} documents with hash: ${documentHash}`);
        return data.documents;
      }
      
      console.log(`[V3BlockchainService] No documents found with hash: ${documentHash}`);
      return [];
    } catch (error) {
      console.error(`[V3BlockchainService] Error getting documents by hash:`, error);
      throw error;
    }
  }
  
  /**
   * Fetch with timeout
   * 
   * @param url URL to fetch
   * @param options Fetch options
   * @returns Promise resolving to the response
   */
  private async fetchWithTimeout(
    url: string,
    options: RequestInit & { timeout?: number } = {}
  ): Promise<Response> {
    const { timeout = API_TIMEOUT, ...fetchOptions } = options;
    
    console.log(`[V3BlockchainService] Fetching URL: ${url}`);
    console.log(`[V3BlockchainService] Fetch options:`, fetchOptions);
    
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    
    try {
      const response = await fetch(url, {
        ...fetchOptions,
        signal: controller.signal
      });
      
      console.log(`[V3BlockchainService] Response status: ${response.status}`);
      console.log(`[V3BlockchainService] Response headers:`, Object.fromEntries([...response.headers.entries()]));
      
      // Clone the response to peek at the body without consuming it
      const clonedResponse = response.clone();
      const responseBody = await clonedResponse.text();
      console.log(`[V3BlockchainService] Response body:`, responseBody);
      
      return response;
    } catch (error) {
      if (error instanceof DOMException && error.name === 'AbortError') {
        throw new APIError(`Request timed out after ${timeout}ms`, 408);
      }
      throw error;
    } finally {
      clearTimeout(id);
    }
  }
}

// Create a singleton instance
export const v3BlockchainService = new V3BlockchainService();
export default v3BlockchainService;
