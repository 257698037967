import React, { useState, useRef } from 'react';
import { 
  Box, 
  Flex, 
  Heading, 
  Text, 
  VStack,
  Button,
  HStack,
  Icon,
  Image,
  IconButton,
  useColorMode,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useToast,
  Spinner,
  useColorModeValue
} from '@chakra-ui/react';
import { 
  FiFileText, 
  FiCheckCircle, 
  FiUpload, 
  FiMessageSquare,
  FiBell, 
  FiUser,
  FiSun,
  FiMoon,
  FiSettings,
  FiHelpCircle,
  FiLogOut,
  FiDatabase
} from 'react-icons/fi';
import { usePrivy } from '@privy-io/react-auth';
import { useEnhancedAuth } from '../providers/EnhancedPostgresAuthProvider';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import integraLogo from '../assets/Integra logo - new - white letters.svg';
import usePreventBrowserDragBehavior from '../hooks/usePreventBrowserDragBehavior';
import { calculateDocumentHash } from '../lib/hash';
import v3BlockchainService from '../services/V3BlockchainService';
import { usePDFStore } from '../store/pdfStore';
import pdfService from '../services/PDFService';

// Define workflow types
type WorkflowType = 'verify' | 'register' | 'tokenize' | 'communicate';

/**
 * Home Component
 * 
 * The main landing page for the application featuring a document drop zone
 * and quick action buttons for document management.
 */
const Home: React.FC = () => {
  const { authenticated, login, logout } = usePrivy();
  const { userProfile } = useEnhancedAuth();
  const { colorMode, toggleColorMode } = useColorMode();
  const { preventBrowserDragBehavior } = usePreventBrowserDragBehavior();
  const [isDragging, setIsDragging] = useState(false);
  const [selectedWorkflow, setSelectedWorkflow] = useState<WorkflowType>('verify');
  const [isProcessing, setIsProcessing] = useState(false);
  const [verificationResult, setVerificationResult] = useState<any>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const toast = useToast();
  
  // Get PDF store actions
  const { 
    setPDFFile, 
    setFileHash, 
    setFileName, 
    setFileDataUrl, 
    setVerificationResult: setPDFVerificationResult,
    reset
  } = usePDFStore();
  
  // Force dark mode for this page
  React.useEffect(() => {
    // Remove the automatic dark mode forcing to allow toggle to work
    // if (colorMode !== 'dark') {
    //   toggleColorMode();
    // }
  }, [colorMode, toggleColorMode]);
  
  // Colors - using theme colors
  const bgColor = useColorModeValue('white', '#000000');
  const textColor = useColorModeValue('#1A202C', 'white');
  const dropZoneBg = 'transparent';
  const dropZoneBorderColor = isDragging ? '#3182CE' : useColorModeValue('#E2E8F0', '#333333');
  const buttonBgColor = 'transparent';
  const buttonHoverBgColor = useColorModeValue('rgba(230, 230, 230, 0.5)', 'rgba(51, 51, 51, 0.5)');
  const integraBlue = '#0066FF';
  
  // Get time of day for greeting
  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return 'Good morning';
    if (hour < 18) return 'Good afternoon';
    return 'Good evening';
  };
  
  // Handle file drop
  const handleFileDrop = async (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    
    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      processFile(files[0]);
    }
  };
  
  // Handle file input change
  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      processFile(files[0]);
    }
  };
  
  // Process the selected file
  const processFile = async (file: File) => {
    try {
      console.log('Processing file with selected workflow:', selectedWorkflow);
      setIsProcessing(true);
      
      // Reset the PDF store to clear any previous document data
      reset();
      
      // Create a file data URL for PDF preview
      const fileDataUrl = URL.createObjectURL(file);
      
      // Calculate hash
      const hash = await calculateDocumentHash(file);
      
      // Store PDF data in the PDF store
      setPDFFile(file);
      setFileHash(hash);
      setFileName(file.name);
      setFileDataUrl(fileDataUrl);
      console.log('Document data stored in PDFStore');
      
      // Extract metadata if possible
      try {
        const metadata = await pdfService.extractMetadata(file);
        usePDFStore.getState().setMetadata(metadata);
      } catch (error) {
        console.error('Error extracting PDF metadata:', error);
        // Continue even if metadata extraction fails
      }
      
      // Navigate to verification page without verifying first
      // Let the VerificationContainer handle the verification
      navigate('/verification-result');
    } catch (error) {
      console.error('Error processing file:', error);
      toast({
        title: 'Error processing file',
        description: error instanceof Error ? error.message : 'An unknown error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsProcessing(false);
    } finally {
      setIsProcessing(false);
    }
  };
  
  // Handle drag events
  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };
  
  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };
  
  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };
  
  // Handle workflow selection
  const handleWorkflowSelect = (workflow: WorkflowType) => {
    console.log('Workflow selected:', workflow);
    setSelectedWorkflow(workflow);
  };
  
  // Handle login
  const handleLogin = () => {
    login();
  };
  
  // Handle logout
  const handleLogout = () => {
    logout();
  };
  
  // Get button style based on whether it's selected
  const getButtonStyle = (workflow: WorkflowType) => {
    if (workflow === selectedWorkflow) {
      return {
        bg: 'transparent',
        color: colorMode === 'light' ? '#1A202C' : 'white',
        borderColor: integraBlue,
        borderWidth: '2px',
        _hover: { bg: 'rgba(0, 102, 255, 0.2)' },
      };
    }
    return {
      bg: 'transparent',
      color: textColor,
      borderColor: colorMode === 'light' ? '#333333' : '#555555',
      borderWidth: '1px',
      _hover: { 
        bg: colorMode === 'light' ? 'rgba(230, 230, 230, 0.5)' : 'rgba(51, 51, 51, 0.5)', 
        borderColor: colorMode === 'light' ? '#555555' : '#777777' 
      },
    };
  };
  
  return (
    <Box position="relative" minH="100vh" bg={bgColor} {...preventBrowserDragBehavior}>
      {/* Hero Section */}
      <Box 
        bg={bgColor} 
        minH="80vh" 
        position="relative" 
        overflow="hidden"
        pt="60px" 
      >
        {/* Main Hero Content */}
        <Flex
          position="relative"
          zIndex={1}
          direction="column"
          align="center"
          justify="center"
          minH="80vh"
          px={4}
          color={textColor}
        >
          <VStack spacing={8} maxW="900px" w="100%" textAlign="center" mt="-40px">
            {/* Greeting */}
            <VStack spacing={2}>
              <Heading as="h1" size="xl">
                {getGreeting()}, {authenticated && userProfile?.user_name ? userProfile.user_name : 'Integra Ledger'}.
              </Heading>
              <Text fontSize="xl" opacity={0.8}>
                How can I help you today?
              </Text>
            </VStack>
            
            {/* Drop Zone */}
            <Box
              w="100%"
              maxW="780px"
              h="200px"
              bg={dropZoneBg}
              borderRadius="lg"
              border="2px dashed"
              borderColor={dropZoneBorderColor}
              display="flex"
              alignItems="center"
              justifyContent="center"
              cursor="pointer"
              transition="all 0.3s"
              mt={4}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleFileDrop}
              onClick={() => fileInputRef.current?.click()}
              transform={isDragging ? 'scale(1.02)' : 'scale(1)'}
              _hover={{ borderColor: '#3182CE' }}
            >
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileInputChange}
                accept=".pdf"
              />
              <VStack spacing={4}>
                {isProcessing ? (
                  <>
                    <Spinner size="xl" color="blue.500" thickness="4px" />
                    <Text fontSize="lg" color={colorMode === 'light' ? '#4A5568' : '#CBD5E0'}>
                      Processing document...
                    </Text>
                  </>
                ) : (
                  <>
                    <Icon as={FiFileText} boxSize={12} color={colorMode === 'light' ? '#4A5568' : '#CBD5E0'} />
                    <Text fontSize="lg" color={colorMode === 'light' ? '#4A5568' : '#CBD5E0'}>
                      Drag and drop a document, or click to select
                    </Text>
                    {selectedWorkflow !== 'verify' && (
                      <Text fontSize="sm" color={integraBlue}>
                        Document will be verified and then {selectedWorkflow === 'register' ? 'registered' : 
                          selectedWorkflow === 'tokenize' ? 'tokenized' : 'used for communication'}
                      </Text>
                    )}
                  </>
                )}
              </VStack>
            </Box>
            
            {/* Action Buttons */}
            <HStack spacing={6} mt={5} wrap="wrap" justify="center">
              <Button
                leftIcon={<Icon as={FiCheckCircle} boxSize={5} />}
                {...getButtonStyle('verify')}
                size="md"
                borderRadius="md"
                onClick={() => handleWorkflowSelect('verify')}
                px={6}
                py={5}
              >
                Verify
              </Button>
              
              <Button
                leftIcon={<Icon as={FiUpload} boxSize={5} />}
                {...getButtonStyle('register')}
                size="md"
                borderRadius="md"
                onClick={() => handleWorkflowSelect('register')}
                px={6}
                py={5}
              >
                Register
              </Button>
              
              <Button
                leftIcon={<Icon as={FiDatabase} boxSize={5} />}
                {...getButtonStyle('tokenize')}
                size="md"
                borderRadius="md"
                onClick={() => handleWorkflowSelect('tokenize')}
                px={6}
                py={5}
              >
                Tokenize
              </Button>
              
              <Button
                leftIcon={<Icon as={FiMessageSquare} boxSize={5} />}
                {...getButtonStyle('communicate')}
                size="md"
                borderRadius="md"
                onClick={() => handleWorkflowSelect('communicate')}
                px={6}
                py={5}
              >
                Communicate
              </Button>
            </HStack>
          </VStack>
        </Flex>
      </Box>
    </Box>
  );
};

export default Home;
