import { Box, Button, Flex, Heading, Text, VStack, useToast, Icon } from '@chakra-ui/react';
import { useLinkAccount } from '@privy-io/react-auth';
import { FaGoogle, FaEnvelope, FaPhone, FaWallet, FaGithub, FaTwitter } from 'react-icons/fa';

interface AccountLinkingProps {
  onComplete?: () => void;
  showHeading?: boolean;
}

/**
 * AccountLinking Component
 * 
 * This component provides a UI for users to link additional authentication methods
 * to their Privy account, preventing the creation of duplicate accounts when
 * users log in with different methods.
 */
export default function AccountLinking({ onComplete, showHeading = true }: AccountLinkingProps) {
  const toast = useToast();
  
  // Get account linking methods from Privy
  const {
    linkEmail,
    linkPhone,
    linkWallet,
    linkGoogle,
    linkGithub,
    linkTwitter
  } = useLinkAccount({
    onSuccess: (authMethod) => {
      toast({
        title: 'Account linked successfully',
        description: `You've successfully linked your ${authMethod} account.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      
      if (onComplete) {
        onComplete();
      }
    },
    onError: (error) => {
      toast({
        title: 'Error linking account',
        description: error.toString() || 'There was an error linking your account. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  });

  // Define the linking options with icons and labels
  const linkingOptions = [
    { 
      name: 'Email', 
      icon: FaEnvelope, 
      action: linkEmail, 
      description: 'Link your email address for password-free login via magic links'
    },
    { 
      name: 'Phone', 
      icon: FaPhone, 
      action: linkPhone, 
      description: 'Link your phone number for SMS verification login'
    },
    { 
      name: 'Google', 
      icon: FaGoogle, 
      action: linkGoogle, 
      description: 'Use your Google account for quick and secure login'
    },
    { 
      name: 'Wallet', 
      icon: FaWallet, 
      action: linkWallet, 
      description: 'Connect your crypto wallet for Web3 authentication'
    },
    { 
      name: 'GitHub', 
      icon: FaGithub, 
      action: linkGithub, 
      description: 'Link your GitHub account for developer-friendly login'
    },
    { 
      name: 'Twitter', 
      icon: FaTwitter, 
      action: linkTwitter, 
      description: 'Connect with your Twitter/X account'
    }
  ];

  return (
    <Box p={6} borderRadius="md" bg="white" boxShadow="md" width="100%" maxWidth="600px" mx="auto">
      {showHeading && (
        <VStack spacing={2} mb={6} textAlign="center">
          <Heading size="lg">Link Additional Login Methods</Heading>
          <Text color="gray.600">
            Add backup login methods to your account for easier access and enhanced security.
          </Text>
        </VStack>
      )}
      
      <VStack spacing={4} align="stretch">
        {linkingOptions.map((option) => (
          <Flex 
            key={option.name}
            p={4}
            borderRadius="md"
            border="1px solid"
            borderColor="gray.200"
            _hover={{ borderColor: 'blue.500', bg: 'blue.50' }}
            cursor="pointer"
            onClick={option.action}
            align="center"
            justify="space-between"
          >
            <Flex align="center">
              <Icon as={option.icon} boxSize={5} color="blue.500" mr={3} />
              <Box>
                <Text fontWeight="bold">{option.name}</Text>
                <Text fontSize="sm" color="gray.600">{option.description}</Text>
              </Box>
            </Flex>
            <Button size="sm" colorScheme="blue" variant="outline">
              Link
            </Button>
          </Flex>
        ))}
      </VStack>
      
      <Text mt={6} fontSize="sm" color="gray.500" textAlign="center">
        Linking multiple login methods helps prevent duplicate accounts and ensures you can always access your account.
      </Text>
    </Box>
  );
}
