import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { usePrivy } from '@privy-io/react-auth';
import postgresClient, { UserProfile, Organization, OrganizationInvitation, OrganizationMember } from '../lib/postgres-client';
import { V3BlockchainService } from '../services/V3BlockchainService';

// Create a singleton instance of V3BlockchainService
export const v3BlockchainService = new V3BlockchainService();

interface EnhancedAuthContextType {
  userProfile: UserProfile | null;
  organizations: Organization[];
  currentOrganizationId: string | null;
  loading: boolean;
  switchOrganization: (organizationId: string) => void;
  createOrganization: (name: string) => Promise<Organization | null>;
  // Organization members management
  getOrganizationMembers: (organizationId: string) => Promise<OrganizationMember[]>;
  updateMemberRole: (organizationId: string, profileId: string, role: string) => Promise<OrganizationMember | null>;
  removeMember: (organizationId: string, profileId: string) => Promise<boolean>;
  // Organization invitations
  createInvitation: (organizationId: string, email: string, role: string) => Promise<OrganizationInvitation | null>;
  getOrganizationInvitations: (organizationId: string, status?: string) => Promise<OrganizationInvitation[]>;
  getPendingInvitationsByEmail: (email: string) => Promise<OrganizationInvitation[]>;
  acceptInvitation: (invitationId: string) => Promise<boolean>;
  declineInvitation: (invitationId: string) => Promise<boolean>;
  cancelInvitation: (invitationId: string) => Promise<boolean>;
}

const EnhancedAuthContext = createContext<EnhancedAuthContextType>({
  userProfile: null,
  organizations: [],
  currentOrganizationId: null,
  loading: true,
  switchOrganization: () => {},
  createOrganization: async () => null,
  // Organization members management
  getOrganizationMembers: async () => [],
  updateMemberRole: async () => null,
  removeMember: async () => false,
  // Organization invitations
  createInvitation: async () => null,
  getOrganizationInvitations: async () => [],
  getPendingInvitationsByEmail: async () => [],
  acceptInvitation: async () => false,
  declineInvitation: async () => false,
  cancelInvitation: async () => false,
});

// Move the hook outside of the export statement to fix Fast Refresh
const useEnhancedAuth = () => useContext(EnhancedAuthContext);

// Export the hook separately
export { useEnhancedAuth };

interface EnhancedAuthProviderProps {
  children: ReactNode;
}

export function EnhancedAuthProvider({ children }: EnhancedAuthProviderProps) {
  const { user, authenticated, ready, getAccessToken } = usePrivy();
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [currentOrganizationId, setCurrentOrganizationId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  // Initialize the auth context when the user changes
  useEffect(() => {
    if (ready) {
      console.log('Privy ready state:', { authenticated, user });
      if (!authenticated || !user) {
        setUserProfile(null);
        setOrganizations([]);
        setCurrentOrganizationId(null);
        setLoading(false);
      }
    }
  }, [authenticated, user, ready]);

  // Initialize user profile and organizations when authenticated
  useEffect(() => {
    if (authenticated && user) {
      const initializeUser = async () => {
        setLoading(true);
        try {
          console.log('Initializing user in EnhancedAuthProvider');
          const profileId = user.id.toString();
          console.log('Privy Profile ID:', profileId);
          
          // Extract additional user data from Privy
          const userData = {
            email: user.email?.address || null,
            // Use optional chaining for all properties and fallback to display name or null
            name: user.google?.email || 
                  user.apple?.email || 
                  user.twitter?.username || 
                  user.discord?.username || 
                  user.linkedAccounts?.find(acc => acc.type === 'email')?.address || 
                  null,
            phone: user.phone?.number || null,
            wallet_address: user.wallet?.address || null,
            created_at_privy: user.createdAt ? new Date(user.createdAt).toISOString() : null
          };
          
          console.log('Additional Privy user data:', {
            hasEmail: !!userData.email,
            hasName: !!userData.name,
            hasPhone: !!userData.phone,
            hasWallet: !!userData.wallet_address
          });
          
          // Get the Privy token using the correct method
          console.log('Attempting to get Privy access token...');
          try {
            const token = await getAccessToken();
            console.log('Got Privy token for authentication:', token ? 'Token received' : 'No token received');
            
            if (!token) {
              console.error('Failed to get Privy access token - token is null or undefined');
              throw new Error('Failed to get Privy access token');
            }
            
            // Ensure the token is properly formatted as a Bearer token
            const formattedToken = token.startsWith('Bearer ') ? token : `Bearer ${token}`;
            console.log('EnhancedPostgresAuthProvider: Using formatted token', formattedToken.substring(0, 15) + '...');
            
            // Set the authentication token in the V3BlockchainService instance
            v3BlockchainService.setAuthToken(formattedToken);
            
            // Login with the Privy token
            console.log('Calling login endpoint with Privy token...');
            try {
              const authResponse = await postgresClient.login(formattedToken, userData);
              console.log('Login response received:', authResponse);
              
              // Set user profile from auth response
              const profile: UserProfile = {
                profile_id: authResponse.profile_id,
                user_name: authResponse.user_name,
                created_at: authResponse.created_at,
                updated_at: authResponse.updated_at,
                email: authResponse.email || userData.email,
                name: authResponse.name || userData.name,
                phone: authResponse.phone || userData.phone,
                wallet_address: authResponse.wallet_address || userData.wallet_address,
                created_at_privy: authResponse.created_at_privy || userData.created_at_privy
              };
              setUserProfile(profile);
              
              // Set the authentication profile ID for subsequent requests
              postgresClient.setAuthProfile(profile.profile_id);
              
              // Set organization from auth response
              const organization: Organization = {
                organization_id: authResponse.organization_id,
                organization_name: authResponse.organization_name
              };
              setOrganizations([organization]);
              
              // Set the current organization
              setCurrentOrganizationId(organization.organization_id);
              postgresClient.setOrganization(organization.organization_id);
              
              // Store current organization and user profile ID in localStorage for persistence across sessions
              localStorage.setItem('currentOrganizationId', organization.organization_id);
              
              // Store user profile ID in localStorage for API authentication
              console.log('Storing user profile ID in localStorage:', profile.profile_id);
              localStorage.setItem('currentProfileId', profile.profile_id);
              
              console.log('Login flow completed successfully');
              return;
            } catch (loginError) {
              console.error('Error calling login endpoint:', loginError);
              throw loginError;
            }
          } catch (tokenError) {
            console.error('Error getting Privy token:', tokenError);
            throw tokenError;
          }
        } catch (error) {
          console.error('Error in authentication flow:', error);
        } finally {
          setLoading(false);
        }
      };

      initializeUser();
    }
  }, [authenticated, user, getAccessToken]);

  // Update PostgreSQL client when organization changes
  useEffect(() => {
    if (currentOrganizationId) {
      postgresClient.setOrganization(currentOrganizationId);
    }
  }, [currentOrganizationId]);

  // Load saved organization ID from localStorage on initial load
  useEffect(() => {
    if (authenticated && !currentOrganizationId) {
      const savedOrgId = localStorage.getItem('currentOrganizationId');
      if (savedOrgId) {
        console.log('Restoring organization ID from localStorage:', savedOrgId);
        setCurrentOrganizationId(savedOrgId);
      }
    }
  }, [authenticated, currentOrganizationId]);

  // Switch to a different organization
  const switchOrganization = (organizationId: string) => {
    setCurrentOrganizationId(organizationId);
    postgresClient.setOrganization(organizationId);
    localStorage.setItem('currentOrganizationId', organizationId);
  };

  // Create a new organization
  const createOrganization = async (name: string): Promise<Organization | null> => {
    try {
      const organization = await postgresClient.createOrganization(name);
      
      if (organization) {
        // Add the current user to the organization as an admin
        if (userProfile) {
          await postgresClient.addUserToOrganization(
            organization.organization_id,
            userProfile.profile_id,
            'admin'
          );
        }
        
        // Update the organizations list
        setOrganizations(prev => [...prev, organization]);
        
        // Switch to the new organization
        switchOrganization(organization.organization_id);
      }
      
      return organization;
    } catch (error) {
      console.error('Error creating organization:', error);
      return null;
    }
  };

  // Get organization members
  const getOrganizationMembers = async (organizationId: string): Promise<OrganizationMember[]> => {
    try {
      const members = await postgresClient.getOrganizationMembers(organizationId);
      return members;
    } catch (error) {
      console.error('Error getting organization members:', error);
      return [];
    }
  };

  // Update member role
  const updateMemberRole = async (organizationId: string, profileId: string, role: string): Promise<OrganizationMember | null> => {
    try {
      const member = await postgresClient.updateMemberRole(organizationId, profileId, role);
      return member;
    } catch (error) {
      console.error('Error updating member role:', error);
      return null;
    }
  };

  // Remove member
  const removeMember = async (organizationId: string, profileId: string): Promise<boolean> => {
    try {
      await postgresClient.removeMember(organizationId, profileId);
      return true;
    } catch (error) {
      console.error('Error removing member:', error);
      return false;
    }
  };

  // Create invitation
  const createInvitation = async (organizationId: string, email: string, role: string): Promise<OrganizationInvitation | null> => {
    try {
      const invitation = await postgresClient.createInvitation(organizationId, email, role);
      return invitation;
    } catch (error) {
      console.error('Error creating invitation:', error);
      return null;
    }
  };

  // Get organization invitations
  const getOrganizationInvitations = async (organizationId: string, status?: string): Promise<OrganizationInvitation[]> => {
    try {
      const invitations = await postgresClient.getOrganizationInvitations(organizationId, status);
      return invitations;
    } catch (error) {
      console.error('Error getting organization invitations:', error);
      return [];
    }
  };

  // Get pending invitations by email
  const getPendingInvitationsByEmail = async (email: string): Promise<OrganizationInvitation[]> => {
    try {
      const invitations = await postgresClient.getPendingInvitationsByEmail(email);
      return invitations;
    } catch (error) {
      console.error('Error getting pending invitations by email:', error);
      return [];
    }
  };

  // Accept invitation
  const acceptInvitation = async (invitationId: string): Promise<boolean> => {
    try {
      const member = await postgresClient.acceptInvitation(invitationId);
      return !!member;
    } catch (error) {
      console.error('Error accepting invitation:', error);
      return false;
    }
  };

  // Decline invitation
  const declineInvitation = async (invitationId: string): Promise<boolean> => {
    try {
      const invitation = await postgresClient.declineInvitation(invitationId);
      return !!invitation;
    } catch (error) {
      console.error('Error declining invitation:', error);
      return false;
    }
  };

  // Cancel invitation
  const cancelInvitation = async (invitationId: string): Promise<boolean> => {
    try {
      await postgresClient.cancelInvitation(invitationId);
      return true;
    } catch (error) {
      console.error('Error canceling invitation:', error);
      return false;
    }
  };

  return (
    <EnhancedAuthContext.Provider
      value={{
        userProfile,
        organizations,
        currentOrganizationId,
        loading,
        switchOrganization,
        createOrganization,
        getOrganizationMembers,
        updateMemberRole,
        removeMember,
        createInvitation,
        getOrganizationInvitations,
        getPendingInvitationsByEmail,
        acceptInvitation,
        declineInvitation,
        cancelInvitation,
      }}
    >
      {children}
    </EnhancedAuthContext.Provider>
  );
}
