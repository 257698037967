/**
 * Document Sync Service
 * 
 * This service is responsible for synchronizing document records between
 * the blockchain and the local database.
 */

import { v4 as uuidv4 } from 'uuid';
import v3BlockchainService from './V3BlockchainService';
import { usePDFStore } from '../store/pdfStore';
import { extractBackendFormattedDocuments } from '../utils/blockchainResultParser';

/**
 * Result of a document sync operation
 */
export interface SyncResult {
  success: boolean;
  local_documents?: any[];
  workflow_id: string;
  error?: string;
}

/**
 * Document Sync Service
 * 
 * This service is responsible for synchronizing document records between
 * the blockchain and the local database. It handles the preparation of
 * document data for sync and makes API calls to the backend.
 */
export class DocumentSyncService {
  /**
   * Transform verification result into the format expected by the sync_document_record_workflow
   * 
   * @param verificationResult The verification result from the blockchain API
   * @param documentHash The document hash
   * @returns Array of blockchain documents in the correct format
   */
  private transformVerificationResult(verificationResult: any, documentHash: string): any[] {
    console.log(`[DocumentSyncService] Transforming verification result for hash: ${documentHash}`);
    
    let blockchainDocuments: any[] = [];
    
    if (!verificationResult) {
      console.warn(`[DocumentSyncService] No verification result provided`);
      return [];
    }
    
    try {
      // Check if verificationResult is already an array of documents
      if (Array.isArray(verificationResult)) {
        console.log(`[DocumentSyncService] Verification result is an array, processing directly`);
        blockchainDocuments = verificationResult.map(doc => ({
          integra_hash: doc.integraHash || doc.integra_hash || '',
          document_hash: doc.documentHash || doc.document_hash || documentHash,
          tx_hash: doc.txHash || doc.tx_hash || '',
          reference_hash: doc.referenceHash || doc.reference_hash || '',
          encrypted_data: doc.encryptedData || doc.encrypted_data || ''
        }));
        
        console.log(`[DocumentSyncService] Transformed ${blockchainDocuments.length} documents from array`);
        return blockchainDocuments;
      }
      
      // Handle the specific structure from the API response
      if (verificationResult.query_result && 
          verificationResult.query_result.documents && 
          verificationResult.query_result.documents.data) {
        
        console.log(`[DocumentSyncService] Found documents in query_result.documents.data`);
        const documentsData = verificationResult.query_result.documents.data;
        
        if (Array.isArray(documentsData)) {
          blockchainDocuments = documentsData.map(doc => ({
            integra_hash: doc.integraHash || '',
            document_hash: doc.documentHash || documentHash,
            tx_hash: doc.txHash || '',
            reference_hash: doc.referenceHash || '',
            encrypted_data: doc.encryptedData || ''
          }));
          
          console.log(`[DocumentSyncService] Transformed ${blockchainDocuments.length} documents from query_result.documents.data`);
        }
      } 
      // Fallback to other possible structures
      else if (verificationResult.documents && Array.isArray(verificationResult.documents)) {
        console.log(`[DocumentSyncService] Found documents array in verificationResult`);
        blockchainDocuments = verificationResult.documents.map((doc: any) => ({
          integra_hash: doc.integraHash || doc.integra_hash || '',
          document_hash: doc.documentHash || doc.document_hash || documentHash,
          tx_hash: doc.txHash || doc.tx_hash || '',
          reference_hash: doc.referenceHash || doc.reference_hash || '',
          encrypted_data: doc.encryptedData || doc.encrypted_data || ''
        }));
      } else if (verificationResult.raw_response && verificationResult.raw_response.documents) {
        console.log(`[DocumentSyncService] Found documents in raw_response`);
        blockchainDocuments = verificationResult.raw_response.documents.map((doc: any) => ({
          integra_hash: doc.integraHash || doc.integra_hash || '',
          document_hash: doc.documentHash || doc.document_hash || documentHash,
          tx_hash: doc.txHash || doc.tx_hash || '',
          reference_hash: doc.referenceHash || doc.reference_hash || '',
          encrypted_data: doc.encryptedData || doc.encrypted_data || ''
        }));
      }
      // Handle the case where data is directly in the verification result
      else if (verificationResult.data && Array.isArray(verificationResult.data)) {
        console.log(`[DocumentSyncService] Found documents in data array`);
        blockchainDocuments = verificationResult.data.map((doc: any) => ({
          integra_hash: doc.integraHash || doc.integra_hash || '',
          document_hash: doc.documentHash || doc.document_hash || documentHash,
          tx_hash: doc.txHash || doc.tx_hash || '',
          reference_hash: doc.referenceHash || doc.reference_hash || '',
          encrypted_data: doc.encryptedData || doc.encrypted_data || ''
        }));
      }
      
      // If no documents found, create a minimal document
      if (blockchainDocuments.length === 0) {
        console.warn(`[DocumentSyncService] No blockchain documents found in verification result`);
      }
      
      return blockchainDocuments;
    } catch (error) {
      console.error(`[DocumentSyncService] Error transforming verification result:`, error);
      return [];
    }
  }

  /**
   * Synchronize a document record with the backend
   * 
   * @param documentHash Document hash to sync
   * @param verificationResult Verification result from blockchain
   * @param profileId User profile ID
   * @param organizationId Organization ID
   * @param workflowId Optional workflow ID
   * @returns Promise with sync result
   */
  async syncDocumentRecord(
    documentHash: string,
    verificationResult: any,
    profileId: string,
    organizationId: string,
    workflowId: string = uuidv4()
  ): Promise<any> {
    try {
      console.log(`[DocumentSyncService] Starting syncDocumentRecord for hash: ${documentHash}`);
      console.log(`[DocumentSyncService] Verification result type:`, typeof verificationResult);
      
      if (Array.isArray(verificationResult)) {
        console.log(`[DocumentSyncService] Verification result is an array with ${verificationResult.length} items`);
      } else if (verificationResult && typeof verificationResult === 'object') {
        console.log(`[DocumentSyncService] Verification result keys:`, Object.keys(verificationResult));
      }
      
      // Get document name and file name from PDFStore
      const { fileName } = usePDFStore.getState();
      const documentName = usePDFStore.getState().metadata?.title || fileName || 'Untitled Document';
      const fileType = fileName?.split('.').pop() || 'pdf';
      
      // Transform the verification result to get blockchain documents
      let blockchainDocuments = this.transformVerificationResult(verificationResult, documentHash);
      
      // If no documents found in verification result, try PDFStore
      if (blockchainDocuments.length === 0) {
        console.log(`[DocumentSyncService] No documents found in verification result, checking PDFStore`);
        blockchainDocuments = usePDFStore.getState().blockchainDocuments || [];
      }
      
      console.log(`[DocumentSyncService] Retrieved ${blockchainDocuments.length} blockchain documents`);
      
      // If still no blockchain documents found, create a minimal document with just the document hash
      if (!blockchainDocuments || blockchainDocuments.length === 0) {
        console.warn(`[DocumentSyncService] No blockchain documents found, creating minimal document`);
        blockchainDocuments = [{
          integra_hash: '',
          document_hash: documentHash,
          tx_hash: '',
          reference_hash: '',
          encrypted_data: ''
        }];
        console.log(`[DocumentSyncService] Created minimal document for sync:`, blockchainDocuments[0]);
      } else {
        console.log(`[DocumentSyncService] Using ${blockchainDocuments.length} blockchain documents`);
        // Ensure all documents have the required fields in the correct format
        blockchainDocuments = blockchainDocuments.map((doc: any) => ({
          integra_hash: doc.integra_hash || '',
          document_hash: doc.document_hash || documentHash,
          tx_hash: doc.tx_hash || '',
          reference_hash: doc.reference_hash || '',
          encrypted_data: doc.encrypted_data || ''
        }));
      }
      
      // Create the payload for the API call
      const payload = {
        workflow_id: workflowId,
        organization_id: organizationId,
        profile_id: profileId,
        document_hash: documentHash,
        verification_result: verificationResult,
        blockchain_documents: blockchainDocuments,
        document_name: documentName,
        file_name: fileName,
        file_type: fileType,
        blockchain: "Polygon"
      };
      
      console.log(`[DocumentSyncService] Final payload for sync:`, {
        workflowId,
        documentHash,
        blockchainDocumentsCount: blockchainDocuments.length,
        documentName,
        fileName,
        fileType,
        hasVerificationResult: !!verificationResult
      });
      
      // Log the full blockchain documents for debugging
      console.log(`[DocumentSyncService] Full blockchain documents in payload:`, JSON.stringify(blockchainDocuments, null, 2));
      
      // Call the V3BlockchainService to execute the sync workflow
      return await v3BlockchainService.syncDocumentRecord(
        documentHash,
        payload,
        profileId,
        organizationId,
        workflowId
      );
    } catch (error) {
      console.error(`[DocumentSyncService] Error in syncDocumentRecord:`, error);
      throw error;
    }
  }
}

// Create a singleton instance
export const documentSyncService = new DocumentSyncService();
export default documentSyncService;
