import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Box,
  Flex,
  Text,
  Heading,
  Divider,
  Badge,
  IconButton,
  VStack,
  HStack,
  Link,
  useColorModeValue,
  Tooltip,
  useClipboard
} from '@chakra-ui/react';
import { FiDownload, FiCopy, FiShare2, FiExternalLink } from 'react-icons/fi';
import { Document } from '../hooks/useDocuments';

// Status badge color mapping
const statusColorMap: Record<string, string> = {
  pending: 'yellow',
  registered: 'green',
  verified: 'blue',
  failed: 'red',
};

interface DocumentDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  document: Document | null;
  onCopyId?: () => void;
  onDelete?: () => void;
}

/**
 * Document Drawer Component
 * 
 * Displays detailed information about a document in a right drawer
 */
const DocumentDrawer: React.FC<DocumentDrawerProps> = ({
  isOpen,
  onClose,
  document,
  onCopyId,
  onDelete
}) => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const sectionBgColor = useColorModeValue('gray.50', 'gray.700');
  
  // Always use a stable value for the clipboard hooks
  const integraId = document?.integra_id || '';
  const documentHash = document?.document_hash || '';
  
  // Setup clipboard for Integra ID
  const { hasCopied, onCopy } = useClipboard(integraId);
  
  // Setup clipboard for document hash
  const { 
    hasCopied: hashCopied, 
    onCopy: copyHash 
  } = useClipboard(documentHash);
  
  // Handle copy Integra ID
  const handleCopyIntegraId = () => {
    onCopy();
    if (onCopyId) {
      onCopyId();
    }
  };
  
  // Format date for display
  const formatDate = (dateString?: string) => {
    if (!dateString) return 'N/A';
    
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };
  
  // Truncate text with ellipsis
  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;
    return `${text.substring(0, maxLength)}...`;
  };
  
  if (!document) {
    return null;
  }
  
  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      size="md"
    >
      <DrawerOverlay />
      <DrawerContent bg={bgColor}>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">
          <Heading size="md" noOfLines={1} title={document.document_name}>
            {document.document_name || 'Unnamed Document'}
          </Heading>
          <Flex mt={2} align="center">
            <Badge colorScheme={statusColorMap[document.status] || 'gray'} mr={2}>
              {document.status?.toUpperCase() || 'UNKNOWN'}
            </Badge>
            <Text fontSize="sm" color="gray.500">
              {document.document_type || 'Document'}
            </Text>
          </Flex>
        </DrawerHeader>

        <DrawerBody>
          <VStack spacing={6} align="stretch">
            {/* Integra ID Section */}
            <Box>
              <Flex justify="space-between" align="center" mb={2}>
                <Text fontWeight="bold" fontSize="sm" color="gray.500">
                  INTEGRA ID
                </Text>
                <Tooltip label={hasCopied ? "Copied!" : "Copy ID"}>
                  <IconButton
                    aria-label="Copy Integra ID"
                    icon={<FiCopy />}
                    size="sm"
                    variant="ghost"
                    onClick={handleCopyIntegraId}
                  />
                </Tooltip>
              </Flex>
              <Text fontFamily="mono" fontSize="sm" p={2} bg={sectionBgColor} borderRadius="md">
                {document.integra_id}
              </Text>
            </Box>
            
            {/* Document Hash Section */}
            <Box>
              <Flex justify="space-between" align="center" mb={2}>
                <Text fontWeight="bold" fontSize="sm" color="gray.500">
                  DOCUMENT HASH
                </Text>
                <Tooltip label={hashCopied ? "Copied!" : "Copy Hash"}>
                  <IconButton
                    aria-label="Copy Document Hash"
                    icon={<FiCopy />}
                    size="sm"
                    variant="ghost"
                    onClick={copyHash}
                  />
                </Tooltip>
              </Flex>
              <Text fontFamily="mono" fontSize="xs" p={2} bg={sectionBgColor} borderRadius="md" wordBreak="break-all">
                {document.document_hash}
              </Text>
            </Box>
            
            <Divider />
            
            {/* Blockchain Information */}
            <Box>
              <Text fontWeight="bold" fontSize="sm" color="gray.500" mb={4}>
                BLOCKCHAIN INFORMATION
              </Text>
              
              {document.blockchain_tx ? (
                <VStack spacing={4} align="stretch">
                  {/* Transaction Hash */}
                  <Box>
                    <Text fontSize="sm" fontWeight="medium" mb={1}>
                      Transaction Hash
                    </Text>
                    <Flex align="center">
                      <Text fontFamily="mono" fontSize="xs" isTruncated>
                        {truncateText(document.blockchain_tx, 20)}
                      </Text>
                      <Tooltip label="View on Explorer">
                        <IconButton
                          aria-label="View on Explorer"
                          icon={<FiExternalLink />}
                          size="xs"
                          variant="ghost"
                          ml={2}
                          as={Link}
                          href={`https://polygonscan.com/tx/${document.blockchain_tx}`}
                          isExternal
                        />
                      </Tooltip>
                    </Flex>
                  </Box>
                  
                  {/* Block Number */}
                  {document.block_number && (
                    <Box>
                      <Text fontSize="sm" fontWeight="medium" mb={1}>
                        Block Number
                      </Text>
                      <Flex align="center">
                        <Text fontFamily="mono" fontSize="sm">
                          {document.block_number.toLocaleString()}
                        </Text>
                      </Flex>
                    </Box>
                  )}
                  
                  {/* Transaction Timestamp */}
                  {document.tx_timestamp && (
                    <Box>
                      <Text fontSize="sm" fontWeight="medium" mb={1}>
                        Transaction Time
                      </Text>
                      <Text fontSize="sm">
                        {formatDate(document.tx_timestamp)}
                      </Text>
                    </Box>
                  )}
                </VStack>
              ) : (
                <Text fontSize="sm" color="gray.500" fontStyle="italic">
                  {document.status === 'pending' 
                    ? 'Document is pending blockchain registration' 
                    : 'No blockchain information available'}
                </Text>
              )}
            </Box>
            
            <Divider />
            
            {/* Additional Metadata */}
            <Box>
              <Text fontWeight="bold" fontSize="sm" color="gray.500" mb={4}>
                ADDITIONAL INFORMATION
              </Text>
              
              <VStack spacing={4} align="stretch">
                {/* Reference ID */}
                {document.reference_id && (
                  <Box>
                    <Text fontSize="sm" fontWeight="medium" mb={1}>
                      Reference ID
                    </Text>
                    <Text fontSize="sm" fontFamily="mono">
                      {document.reference_id}
                    </Text>
                  </Box>
                )}
                
                {/* Workflow ID */}
                {document.workflow_id && (
                  <Box>
                    <Text fontSize="sm" fontWeight="medium" mb={1}>
                      Workflow ID
                    </Text>
                    <Text fontSize="sm" fontFamily="mono">
                      {document.workflow_id}
                    </Text>
                  </Box>
                )}
                
                {/* Created At */}
                <Box>
                  <Text fontSize="sm" fontWeight="medium" mb={1}>
                    Created At
                  </Text>
                  <Text fontSize="sm">
                    {formatDate(document.created_at)}
                  </Text>
                </Box>
                
                {/* Updated At */}
                <Box>
                  <Text fontSize="sm" fontWeight="medium" mb={1}>
                    Last Updated
                  </Text>
                  <Text fontSize="sm">
                    {formatDate(document.updated_at)}
                  </Text>
                </Box>
              </VStack>
            </Box>
          </VStack>
        </DrawerBody>

        <DrawerFooter borderTopWidth="1px">
          <HStack spacing={4} width="100%">
            <Button 
              leftIcon={<FiDownload />}
              colorScheme="blue"
              isDisabled={!document.document_url}
              flex="1"
            >
              Download
            </Button>
            <Button
              leftIcon={<FiShare2 />}
              variant="outline"
              flex="1"
            >
              Share
            </Button>
          </HStack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default DocumentDrawer;
