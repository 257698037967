/**
 * DocumentActivityService
 * 
 * This service handles document activity data retrieval from the API.
 */

import axios from 'axios';

// API Configuration
const API_URL = import.meta.env.VITE_API_URL;

// Document Activity Type
export interface DocumentActivity {
  id: string;
  integra_id: string;
  activity_type: string;
  activity_details: any;
  created_at: string;
  document_hash?: string;
  reference_id?: string;
  workflow_id?: string;
  organization_id?: string;
  profile_id?: string;
  blockchain?: string;
  transaction_hash?: string;
  block_number?: number;
}

/**
 * DocumentActivityService
 */
export class DocumentActivityService {
  private baseUrl: string;
  private maxRetries: number;
  private retryDelay: number;
  
  constructor(baseUrl: string = API_URL, maxRetries: number = 2, retryDelay: number = 1000) {
    this.baseUrl = baseUrl;
    this.maxRetries = maxRetries;
    this.retryDelay = retryDelay;
  }
  
  /**
   * Get document activities for a profile and organization
   * 
   * @param profileId Profile ID
   * @param organizationId Organization ID
   * @returns Promise resolving to an array of document activities
   */
  async getDocumentActivities(
    profileId: string,
    organizationId: string
  ): Promise<DocumentActivity[]> {
    if (!profileId || !organizationId) {
      console.error('Missing required parameters for getDocumentActivities:', { profileId, organizationId });
      return [];
    }

    let retries = 0;
    
    while (retries <= this.maxRetries) {
      try {
        console.log(`Attempt ${retries + 1} to fetch document activities for:`, { 
          profileId, 
          organizationId,
          endpoint: `${this.baseUrl}/api/document-activities`
        });
        
        // Use the correct API endpoint structure and add headers for profile and organization IDs
        const response = await axios.get(`${this.baseUrl}/api/document-activities`, {
          headers: {
            'x-profile-id': profileId,
            'x-organization-id': organizationId
          },
          params: {
            limit: 100,
            offset: 0
          },
          timeout: 10000 // 10 second timeout
        });
        
        // The API returns activities in a nested structure
        if (response.data && response.data.activities) {
          console.log('API Response (nested structure):', {
            status: response.status,
            total: response.data.total || 0,
            activities: response.data.activities
          });
          return response.data.activities;
        }
        
        console.log('API Response (flat structure):', {
          status: response.status,
          dataType: typeof response.data,
          isArray: Array.isArray(response.data),
          length: Array.isArray(response.data) ? response.data.length : 0
        });
        
        return response.data || [];
      } catch (error: any) {
        console.error(`Error fetching document activities (attempt ${retries + 1}):`, error);
        
        // If we've reached max retries, or if it's not a 500 error (which might be temporary)
        // then throw the error or return empty array
        if (retries >= this.maxRetries || 
            (error.response && error.response.status !== 500)) {
          if (error.response && error.response.status === 500) {
            console.warn('Server error (500) when fetching document activities. Returning empty array as fallback.');
            return []; // Return empty array instead of throwing for 500 errors
          }
          throw error;
        }
        
        // Wait before retrying
        await new Promise(resolve => setTimeout(resolve, this.retryDelay));
        retries++;
      }
    }
    
    // This should never be reached due to the logic above, but TypeScript needs a return
    return [];
  }
  
  /**
   * Create a document activity
   * 
   * @param activity Activity data
   * @param profileId Profile ID
   * @param organizationId Organization ID
   * @returns Promise resolving to the created activity
   */
  async createDocumentActivity(
    activity: {
      document_id?: string;
      integra_id?: string;
      workflow_id?: string;
      activity_type: string;
      details?: Record<string, any>;
      related_entities?: Record<string, any>;
      parent_activity_id?: string;
      visibility?: string;
    },
    profileId: string,
    organizationId: string
  ): Promise<DocumentActivity> {
    try {
      const response = await axios.post(`${this.baseUrl}/api/document-activities`, 
        {
          ...activity,
          organization_id: organizationId,
          profile_id: profileId
        },
        {
          headers: {
            'x-profile-id': profileId,
            'x-organization-id': organizationId
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error creating document activity:', error);
      throw error;
    }
  }
}

// Create a singleton instance
export const documentActivityService = new DocumentActivityService();
export default documentActivityService;
