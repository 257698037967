/**
 * Blockchain Result Parser
 * 
 * This utility provides functions for parsing and formatting blockchain verification results
 * to ensure consistent handling of document data across the application.
 */

import { v4 as uuidv4 } from 'uuid';

/**
 * Document structure from blockchain verification
 */
export interface BlockchainDocument {
  integraHash?: string;
  documentHash?: string;
  referenceHash?: string;
  issuer?: string;
  encryptedData?: string;
  txHash?: string;
  // Alternative field names that might be present in responses
  integra_id?: string;
  integra_hash?: string;
  document_hash?: string;
  reference_id?: string;
  transaction_hash?: string;
  tx_hash?: string;
  encrypted_data?: string;
  [key: string]: any;
}

/**
 * Standardized document format for application use
 */
export interface StandardizedDocument {
  // Standard fields
  integraId: string | undefined;
  documentHash: string | undefined;
  transactionHash: string | undefined;
  referenceHash: string | undefined;
  issuer: string | undefined;
  
  // Additional metadata
  encryptedData: string | null;
  documentName: string | null;
  documentType: string | null;
  registrationDate: string | null;
  
  // Original document object
  rawDocument: BlockchainDocument;
}

/**
 * Parse encrypted data from a document
 * @param encryptedData Encrypted data string from blockchain
 * @returns Parsed object or null if parsing fails
 */
export function parseEncryptedData(encryptedData: string | undefined): Record<string, any> | null {
  if (!encryptedData) return null;
  
  try {
    // Check if the string starts with "Data extra" which is not valid JSON
    if (encryptedData.startsWith('Data extra')) {
      console.log('[BlockchainResultParser] Found non-JSON "Data extra" format, returning empty object');
      // Return an empty object instead of null to prevent downstream errors
      return {};
    }
    
    return JSON.parse(encryptedData);
  } catch (error) {
    console.error('[BlockchainResultParser] Error parsing encrypted data:', error);
    // Return an empty object instead of null to prevent downstream errors
    return {};
  }
}

/**
 * Extract documents array from verification result
 * @param verificationResult Verification result from blockchain
 * @returns Array of blockchain documents
 */
export function extractDocumentsFromVerificationResult(verificationResult: any): BlockchainDocument[] {
  if (!verificationResult) return [];
  
  console.log('[BlockchainResultParser] Extracting documents from verification result:', verificationResult);
  
  // Case 1: Direct documents array
  if (Array.isArray(verificationResult.documents)) {
    console.log('[BlockchainResultParser] Found documents in direct array');
    return verificationResult.documents as BlockchainDocument[];
  }
  
  // Case 2: Documents in verification_result.documents
  if (verificationResult.verification_result?.documents) {
    console.log('[BlockchainResultParser] Found documents in verification_result.documents');
    return Array.isArray(verificationResult.verification_result.documents) 
      ? verificationResult.verification_result.documents as BlockchainDocument[] 
      : [verificationResult.verification_result.documents as BlockchainDocument];
  }
  
  // Case 3: Documents in raw_response.data.result.query_result.documents.data
  if (verificationResult.raw_response?.data?.result?.query_result?.documents?.data) {
    console.log('[BlockchainResultParser] Found documents in raw_response.data.result.query_result.documents.data');
    return verificationResult.raw_response.data.result.query_result.documents.data as BlockchainDocument[];
  }
  
  // Case 4: Documents in query_result.documents.data (direct workflow result)
  if (verificationResult.query_result?.documents?.data) {
    console.log('[BlockchainResultParser] Found documents in query_result.documents.data');
    return verificationResult.query_result.documents.data as BlockchainDocument[];
  }
  
  // Case 5: Documents in result.query_result.documents.data (nested workflow result)
  if (verificationResult.result?.query_result?.documents?.data) {
    console.log('[BlockchainResultParser] Found documents in result.query_result.documents.data');
    return verificationResult.result.query_result.documents.data as BlockchainDocument[];
  }
  
  // Case 6: Documents directly in data array
  if (Array.isArray(verificationResult.data)) {
    console.log('[BlockchainResultParser] Found documents in data array');
    return verificationResult.data as BlockchainDocument[];
  }
  
  // Case 7: Single document object
  if (verificationResult.document && typeof verificationResult.document === 'object') {
    console.log('[BlockchainResultParser] Found single document object');
    return [verificationResult.document as BlockchainDocument];
  }
  
  // Case 8: Single document directly in verification result
  if (verificationResult.integraHash || verificationResult.documentHash || 
      verificationResult.integra_id || verificationResult.integra_hash || 
      verificationResult.document_hash) {
    console.log('[BlockchainResultParser] Found document fields directly in result');
    return [verificationResult as BlockchainDocument];
  }
  
  // No documents found
  console.warn('[BlockchainResultParser] No documents found in verification result:', verificationResult);
  return [];
}

/**
 * Get the registration timestamp from a verification result
 * @param verificationResult Verification result from blockchain
 * @returns Timestamp string or null
 */
export function extractTimestampFromVerificationResult(verificationResult: any): string | null {
  if (!verificationResult) return null;
  
  // Try different paths to find the timestamp
  return verificationResult.query_result?.documents?.timestamp ||
         verificationResult.result?.query_result?.documents?.timestamp ||
         verificationResult.raw_response?.data?.result?.query_result?.documents?.timestamp ||
         verificationResult.timestamp ||
         verificationResult.created_at ||
         null;
}

/**
 * Standardize field names in a blockchain document
 * @param document Blockchain document with potentially inconsistent field names
 * @returns Document with standardized field names
 */
export function normalizeDocumentFields(document: BlockchainDocument): BlockchainDocument {
  // Create a normalized copy of the document
  const normalizedDoc: BlockchainDocument = { ...document };
  
  // Normalize integra hash/id fields
  normalizedDoc.integraHash = (document.integraHash || 
                             document.integra_id || 
                             document.integra_hash || 
                             '') as string;
  
  // Normalize document hash field
  normalizedDoc.documentHash = (document.documentHash || 
                              document.document_hash || 
                              '') as string;
  
  // Normalize transaction hash field
  normalizedDoc.txHash = (document.txHash || 
                        document.tx_hash || 
                        document.transaction_hash || 
                        '') as string;
  
  // Normalize reference hash/id field
  normalizedDoc.referenceHash = (document.referenceHash || 
                               document.reference_id || 
                               document.reference_hash || 
                               '') as string;
  
  // Normalize encrypted data field
  normalizedDoc.encryptedData = (document.encryptedData || 
                               document.encrypted_data || 
                               '') as string;
  
  return normalizedDoc;
}

/**
 * Standardize a blockchain document to a consistent format
 * @param document Blockchain document
 * @param verificationResult Full verification result (for additional context)
 * @returns Standardized document
 */
export function standardizeDocument(
  document: BlockchainDocument,
  verificationResult?: any
): StandardizedDocument {
  // Normalize field names
  const normalizedDoc = normalizeDocumentFields(document);
  
  // Extract encrypted data if available
  const parsedEncryptedData = parseEncryptedData(normalizedDoc.encryptedData);
  
  // Get registration date from verification result
  const registrationDate = extractTimestampFromVerificationResult(verificationResult);
  
  return {
    // Standard fields with fallbacks
    integraId: normalizedDoc.integraHash,
    documentHash: normalizedDoc.documentHash,
    transactionHash: normalizedDoc.txHash,
    referenceHash: normalizedDoc.referenceHash,
    issuer: normalizedDoc.issuer || '',
    
    // Additional metadata
    encryptedData: normalizedDoc.encryptedData || null,
    documentName: parsedEncryptedData?.document_name || null,
    documentType: parsedEncryptedData?.document_type || null,
    registrationDate: registrationDate,
    
    // Original document
    rawDocument: document
  };
}

/**
 * Parse verification result and return standardized documents
 * @param verificationResult Verification result from blockchain
 * @returns Array of standardized documents
 */
export function parseVerificationResult(verificationResult: any): StandardizedDocument[] {
  // Extract documents from verification result
  const documents = extractDocumentsFromVerificationResult(verificationResult);
  
  console.log(`[BlockchainResultParser] Found ${documents.length} documents in verification result`);
  
  // Standardize each document
  return documents.map(doc => standardizeDocument(doc, verificationResult));
}

/**
 * Check if a verification result indicates a successful verification
 * @param verificationResult Verification result from blockchain
 * @returns Boolean indicating if document was verified
 */
export function isDocumentVerified(verificationResult: any): boolean {
  if (!verificationResult) return false;
  
  // Check for explicit verification status
  if (typeof verificationResult.verified === 'boolean') {
    return verificationResult.verified;
  }
  
  // Check workflow success
  const workflowSuccessful = 
    (verificationResult.status === "COMPLETED" && verificationResult.success === true) ||
    (verificationResult.result?.status === "COMPLETED" && verificationResult.result?.success === true);
  
  // Check query success
  const querySuccessful = 
    verificationResult.query_result?.success === true ||
    verificationResult.result?.query_result?.success === true;
  
  // Check if documents were found
  const documents = extractDocumentsFromVerificationResult(verificationResult);
  const documentFound = documents.length > 0;
  
  // Document is verified if workflow was successful, query was successful, and documents were found
  return workflowSuccessful && querySuccessful && documentFound;
}

/**
 * Prepare document data for sync workflow
 * @param documentHash Document hash
 * @param verificationResult Verification result
 * @returns Enhanced verification result with explicit fields
 */
export function prepareDocumentForSync(documentHash: string, verificationResult: any): any {
  console.log('[blockchainResultParser] Starting prepareDocumentForSync with hash:', documentHash);
  console.log('[blockchainResultParser] Original verification result structure:', {
    keys: Object.keys(verificationResult || {}),
    hasDocuments: Array.isArray(verificationResult?.documents) && verificationResult.documents.length > 0,
    documentsCount: Array.isArray(verificationResult?.documents) ? verificationResult.documents.length : 0,
    hasVerificationResult: !!verificationResult?.verification_result,
    hasRawResponse: !!verificationResult?.raw_response,
    hasQueryResult: !!verificationResult?.query_result
  });
  
  if (verificationResult?.query_result?.documents?.data) {
    console.log('[blockchainResultParser] Found documents in query_result.documents.data:', 
      verificationResult.query_result.documents.data);
  }
  
  // Extract and standardize documents
  const standardizedDocuments = parseVerificationResult(verificationResult);
  
  // Use the first document as the primary document (if available)
  const primaryDocument = standardizedDocuments[0] || {};
  
  // Log the standardized documents for debugging
  console.log('[blockchainResultParser] Standardized documents count:', standardizedDocuments.length);
  if (standardizedDocuments.length > 0) {
    console.log('[blockchainResultParser] First standardized document:', primaryDocument);
  }
  
  // Extract the integra hash and transaction hash from the primary document
  const integraId = primaryDocument.integraId || '';
  const transactionHash = primaryDocument.transactionHash || '';
  
  // Create blockchain_documents array in the exact format expected by the workflow
  // This is the critical part that needs to be robust for backend compatibility
  let blockchainDocuments: any[] = [];
  
  // First try to use standardized documents
  if (standardizedDocuments.length > 0) {
    console.log('[blockchainResultParser] Creating blockchain_documents from standardized documents');
    blockchainDocuments = standardizedDocuments.map(doc => ({
      // Only include snake_case field names as expected by the backend
      integra_hash: doc.integraId || '',
      document_hash: doc.documentHash || documentHash,
      tx_hash: doc.transactionHash || '',
      reference_hash: doc.referenceHash || '',
      encrypted_data: doc.encryptedData || ''
    }));
  }
  
  // If no documents were extracted, try different paths to find raw document data
  if (blockchainDocuments.length === 0) {
    console.log('[blockchainResultParser] No standardized documents found, searching for raw documents');
    
    // Try to find documents in various locations
    let rawDocuments: any[] = [];
    
    // Case 1: Direct documents array
    if (Array.isArray(verificationResult?.documents) && verificationResult.documents.length > 0) {
      console.log('[blockchainResultParser] Found documents in verificationResult.documents');
      rawDocuments = verificationResult.documents;
    }
    // Case 2: Documents in verification_result.documents
    else if (Array.isArray(verificationResult?.verification_result?.documents)) {
      console.log('[blockchainResultParser] Found documents in verificationResult.verification_result.documents');
      rawDocuments = verificationResult.verification_result.documents;
    }
    // Case 3: Documents in query_result.documents.data
    else if (Array.isArray(verificationResult?.query_result?.documents?.data)) {
      console.log('[blockchainResultParser] Found documents in verificationResult.query_result.documents.data');
      rawDocuments = verificationResult.query_result.documents.data;
    }
    // Case 4: Documents in raw_response.data.result.query_result.documents.data
    else if (Array.isArray(verificationResult?.raw_response?.data?.result?.query_result?.documents?.data)) {
      console.log('[blockchainResultParser] Found documents in verificationResult.raw_response.data.result.query_result.documents.data');
      rawDocuments = verificationResult.raw_response.data.result.query_result.documents.data;
    }
    
    // Case 5: Documents in result.query_result.documents.data (nested workflow result)
    else if (Array.isArray(verificationResult?.result?.query_result?.documents?.data)) {
      console.log('[blockchainResultParser] Found documents in verificationResult.result.query_result.documents.data');
      rawDocuments = verificationResult.result.query_result.documents.data;
    }
  
    // Map raw documents to the expected format
    if (rawDocuments.length > 0) {
      console.log('[blockchainResultParser] Creating blockchain_documents from raw documents');
      blockchainDocuments = rawDocuments.map(doc => ({
        // Use snake_case field names as expected by the backend
        integra_hash: doc.integra_hash || doc.integra_id || doc.integraHash || doc.integraId || '',
        document_hash: doc.document_hash || doc.documentHash || documentHash,
        tx_hash: doc.tx_hash || doc.transaction_hash || doc.txHash || doc.transactionHash || '',
        reference_hash: doc.reference_hash || doc.reference_id || doc.referenceHash || doc.referenceId || '',
        encrypted_data: doc.encrypted_data || doc.encryptedData || ''
      }));
    }
  }
  
  // Log the final blockchain documents
  console.log('[blockchainResultParser] Final blockchain_documents array:', blockchainDocuments);
  
  // Return the enhanced verification result with explicit fields
  const simplifiedResult = {
    documentHash: documentHash,
    integraId: integraId || null,
    blockchainTx: transactionHash || null,
    documentName: verificationResult?.document_name || null,
    fileName: verificationResult?.file_name || null,
    fileType: verificationResult?.file_type || null,
    blockchain: 'Polygon', // Default to Polygon
    blockchainDocuments: blockchainDocuments.length > 0 ? blockchainDocuments : null
  };
  
  return simplifiedResult;
}

/**
 * Extract blockchain documents in the exact format needed by the backend
 * @param verificationResult Verification result from blockchain
 * @returns Array of blockchain documents in the format expected by the backend
 */
export function extractBackendFormattedDocuments(verificationResult: any): any[] {
  console.log('[blockchainResultParser] Extracting backend-formatted documents');
  
  if (!verificationResult) {
    console.warn('[blockchainResultParser] Verification result is null or undefined');
    return [];
  }
  
  // Check if verificationResult is already an array of documents
  if (Array.isArray(verificationResult)) {
    console.log(`[blockchainResultParser] Verification result is an array with ${verificationResult.length} items`);
    
    const formattedDocs = verificationResult.map(doc => ({
      integra_hash: doc.integraHash || doc.integra_hash || '',
      document_hash: doc.documentHash || doc.document_hash || '',
      tx_hash: doc.txHash || doc.tx_hash || doc.transactionHash || doc.transaction_hash || '',
      reference_hash: doc.referenceHash || doc.reference_hash || '',
      encrypted_data: doc.encryptedData || doc.encrypted_data || ''
    }));
    
    console.log('[blockchainResultParser] Formatted documents from array:', JSON.stringify(formattedDocs, null, 2));
    return formattedDocs;
  }
  
  // Handle the specific structure we're receiving
  if (verificationResult?.query_result?.documents?.data) {
    const documents = verificationResult.query_result.documents.data;
    console.log(`[blockchainResultParser] Found ${documents.length} documents in query_result.documents.data`);
    
    if (documents.length > 0) {
      console.log('[blockchainResultParser] First document fields:', Object.keys(documents[0]));
    }
    
    // Format the documents in the exact format needed by the backend
    const formattedDocs = documents.map((doc: any) => ({
      // Convert camelCase to snake_case as expected by the backend
      integra_hash: doc.integraHash || doc.integra_hash || '',
      document_hash: doc.documentHash || doc.document_hash || '',
      tx_hash: doc.txHash || doc.tx_hash || doc.transactionHash || doc.transaction_hash || '',
      reference_hash: doc.referenceHash || doc.reference_hash || '',
      encrypted_data: doc.encryptedData || doc.encrypted_data || ''
    }));
    
    console.log('[blockchainResultParser] Formatted documents:', JSON.stringify(formattedDocs, null, 2));
    return formattedDocs;
  }
  
  // Try alternative structure: result.query_result.documents.data
  if (verificationResult?.result?.query_result?.documents?.data) {
    const documents = verificationResult.result.query_result.documents.data;
    console.log(`[blockchainResultParser] Found ${documents.length} documents in result.query_result.documents.data`);
    
    if (documents.length > 0) {
      console.log('[blockchainResultParser] First document fields:', Object.keys(documents[0]));
    }
    
    const formattedDocs = documents.map((doc: any) => ({
      integra_hash: doc.integraHash || doc.integra_hash || '',
      document_hash: doc.documentHash || doc.document_hash || '',
      tx_hash: doc.txHash || doc.tx_hash || doc.transactionHash || doc.transaction_hash || '',
      reference_hash: doc.referenceHash || doc.reference_hash || '',
      encrypted_data: doc.encryptedData || doc.encrypted_data || ''
    }));
    
    console.log('[blockchainResultParser] Formatted documents:', JSON.stringify(formattedDocs, null, 2));
    return formattedDocs;
  }
  
  // Check for documents directly in the verification result
  if (verificationResult.documents) {
    const documents = Array.isArray(verificationResult.documents) 
      ? verificationResult.documents 
      : [verificationResult.documents];
    
    console.log(`[blockchainResultParser] Found ${documents.length} documents directly in verification result`);
    
    const formattedDocs = documents.map((doc: any) => ({
      integra_hash: doc.integraHash || doc.integra_hash || '',
      document_hash: doc.documentHash || doc.document_hash || '',
      tx_hash: doc.txHash || doc.tx_hash || doc.transactionHash || doc.transaction_hash || '',
      reference_hash: doc.referenceHash || doc.reference_hash || '',
      encrypted_data: doc.encryptedData || doc.encrypted_data || ''
    }));
    
    console.log('[blockchainResultParser] Formatted documents:', JSON.stringify(formattedDocs, null, 2));
    return formattedDocs;
  }
  
  // Check for documents in raw_response
  if (verificationResult.raw_response?.documents) {
    const documents = Array.isArray(verificationResult.raw_response.documents) 
      ? verificationResult.raw_response.documents 
      : [verificationResult.raw_response.documents];
    
    console.log(`[blockchainResultParser] Found ${documents.length} documents in raw_response`);
    
    const formattedDocs = documents.map((doc: any) => ({
      integra_hash: doc.integraHash || doc.integra_hash || '',
      document_hash: doc.documentHash || doc.document_hash || '',
      tx_hash: doc.txHash || doc.tx_hash || doc.transactionHash || doc.transaction_hash || '',
      reference_hash: doc.referenceHash || doc.reference_hash || '',
      encrypted_data: doc.encryptedData || doc.encrypted_data || ''
    }));
    
    console.log('[blockchainResultParser] Formatted documents:', JSON.stringify(formattedDocs, null, 2));
    return formattedDocs;
  }
  
  // Check for data array
  if (verificationResult.data && Array.isArray(verificationResult.data)) {
    console.log(`[blockchainResultParser] Found ${verificationResult.data.length} documents in data array`);
    
    const formattedDocs = verificationResult.data.map((doc: any) => ({
      integra_hash: doc.integraHash || doc.integra_hash || '',
      document_hash: doc.documentHash || doc.document_hash || '',
      tx_hash: doc.txHash || doc.tx_hash || doc.transactionHash || doc.transaction_hash || '',
      reference_hash: doc.referenceHash || doc.reference_hash || '',
      encrypted_data: doc.encryptedData || doc.encrypted_data || ''
    }));
    
    console.log('[blockchainResultParser] Formatted documents from data array:', JSON.stringify(formattedDocs, null, 2));
    return formattedDocs;
  }
  
  // If we couldn't find documents in the expected structure, try the standardized approach
  const standardizedDocuments = parseVerificationResult(verificationResult);
  if (standardizedDocuments.length > 0) {
    console.log(`[blockchainResultParser] Found ${standardizedDocuments.length} documents using standardized parser`);
    
    const formattedDocs = standardizedDocuments.map(doc => ({
      integra_hash: doc.integraId || '',
      document_hash: doc.documentHash || '',
      tx_hash: doc.transactionHash || '',
      reference_hash: doc.referenceHash || '',
      encrypted_data: doc.encryptedData || ''
    }));
    
    console.log('[blockchainResultParser] Formatted documents from standardized parser:', JSON.stringify(formattedDocs, null, 2));
    return formattedDocs;
  }
  
  console.warn('[blockchainResultParser] No documents found in verification result');
  console.log('[blockchainResultParser] Verification result structure:', JSON.stringify(verificationResult, null, 2));
  return [];
}
