import { useState } from 'react';
import { Box, Button, Container, Flex, Heading, Text, VStack, useColorModeValue } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { usePrivy } from '@privy-io/react-auth';
import AccountLinking from '../components/auth/AccountLinking';

/**
 * Onboarding Page
 * 
 * This page is shown to users after their first login to encourage them
 * to link additional authentication methods to their account, preventing
 * the creation of duplicate accounts when users log in with different methods.
 */
export default function Onboarding() {
  const [linkingComplete, setLinkingComplete] = useState(false);
  const navigate = useNavigate();
  const { user } = usePrivy();
  
  // Background colors
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const cardBgColor = useColorModeValue('white', 'gray.800');
  
  // Handle completion of account linking
  const handleLinkingComplete = () => {
    setLinkingComplete(true);
  };
  
  // Handle skipping the onboarding process
  const handleSkip = () => {
    navigate('/');
  };
  
  // Handle completion of onboarding
  const handleComplete = () => {
    navigate('/');
  };
  
  return (
    <Box minH="100vh" bg={bgColor} py={10}>
      <Container maxW="container.md">
        <VStack spacing={8} align="stretch">
          <Box textAlign="center">
            <Heading size="xl" mb={2}>Welcome to Integra</Heading>
            <Text fontSize="lg" color="gray.600">
              Let's set up your account for secure access
            </Text>
          </Box>
          
          <Box bg={cardBgColor} p={6} borderRadius="lg" boxShadow="md">
            {!linkingComplete ? (
              <VStack spacing={6}>
                <Box textAlign="center" mb={4}>
                  <Heading size="md" mb={2}>
                    You're logged in with {getAuthMethodName(user)}
                  </Heading>
                  <Text>
                    We recommend adding at least one more login method to ensure you can always access your account.
                  </Text>
                </Box>
                
                <AccountLinking 
                  onComplete={handleLinkingComplete} 
                  showHeading={false} 
                />
                
                <Button variant="ghost" onClick={handleSkip}>
                  Skip for now
                </Button>
              </VStack>
            ) : (
              <VStack spacing={6} align="center">
                <Heading size="md">Great job!</Heading>
                <Text textAlign="center">
                  You've successfully linked an additional authentication method to your account.
                  This will help ensure you always have access to your Integra account.
                </Text>
                <Button colorScheme="blue" onClick={handleComplete}>
                  Continue to Integra
                </Button>
              </VStack>
            )}
          </Box>
        </VStack>
      </Container>
    </Box>
  );
}

/**
 * Helper function to get a user-friendly name for the authentication method
 */
function getAuthMethodName(user: any): string {
  if (!user) return 'an unknown method';
  
  if (user.email) return 'Email';
  if (user.google) return 'Google';
  if (user.twitter) return 'Twitter';
  if (user.github) return 'GitHub';
  if (user.wallet) return 'Wallet';
  if (user.sms) return 'Phone';
  
  return 'your account';
}
