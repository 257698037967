/**
 * Verification Success
 * 
 * This component displays the success state when a document is verified on the blockchain.
 * It shows the verification details and provides options for next steps.
 */

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  VStack,
  Badge,
  useColorModeValue,
  Divider,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Link,
  HStack,
  Icon,
  Spinner,
  Code
} from '@chakra-ui/react';
import { 
  FiCheckCircle, 
  FiExternalLink,
  FiRefreshCw,
  FiDatabase,
  FiCheck,
  FiX
} from 'react-icons/fi';

// Import components
import NextStepsSelector from './NextStepsSelector';
import DocumentTable from './DocumentTable';
import { ContentLayout } from '../../layouts';

// Import services
import workflowRoutingService from '../../services/WorkflowRoutingService';
import { documentSyncService } from '../../services/DocumentSyncService';
// Import the V3BlockchainService singleton from the provider
import { v3BlockchainService } from '../../providers/EnhancedPostgresAuthProvider';

// Import stores
import { usePDFStore } from '../../store/pdfStore';
import { useWorkflowStore, WorkflowType } from '../../store/workflowStore';

// Import authentication
import { useEnhancedAuth } from '../../providers/EnhancedPostgresAuthProvider';

// Define component props
interface VerificationSuccessProps {
  onWorkflowSelect: (workflow: WorkflowType) => void;
  isAuthenticated: boolean;
}

/**
 * VerificationSuccess Component
 * 
 * Displays the successful verification result with options to continue
 * to other workflows or sync the document with the local database.
 */
const VerificationSuccess: React.FC<VerificationSuccessProps> = ({
  onWorkflowSelect,
  isAuthenticated
}) => {
  // State
  type SyncStatusType = 'idle' | 'syncing' | 'synced' | 'error';
  const [syncStatus, setSyncStatus] = useState<SyncStatusType>('idle');
  const [syncError, setSyncError] = useState<string | null>(null);
  const [localDocuments, setLocalDocuments] = useState<any[]>([]);
  
  // Hooks
  const navigate = useNavigate();
  const { userProfile, currentOrganizationId } = useEnhancedAuth();
  
  // Get PDF store state
  const { 
    fileName, 
    fileHash, 
    verificationResult,
    blockchainDocuments,
    setVerificationResult
  } = usePDFStore();
  
  // Get workflow store state
  const { selectedWorkflow } = useWorkflowStore();
  
  // We'll extract information from the first document in localDocuments instead
  const primaryDocument = localDocuments[0] || {};
  
  // Extract specific fields from the primary document
  const integraId = primaryDocument.integra_id || '';
  const transactionHash = primaryDocument.blockchain_tx || '';
  const issuer = primaryDocument.source_organization_id || '';
  const registrationDate = primaryDocument.created_at || '';
  
  // Format registration date
  const formattedDate = registrationDate ? new Date(registrationDate).toLocaleString() : 'Not available';
  
  // Theme colors
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('green.200', 'green.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const badgeBgColor = useColorModeValue('green.100', 'green.700');
  const badgeTextColor = useColorModeValue('green.800', 'white');
  
  // Effects
  const syncAttemptedRef = useRef(false);
  
  useEffect(() => {
    // Automatically fetch document records when component mounts
    if (isAuthenticated && fileHash && userProfile?.profile_id && currentOrganizationId && !syncAttemptedRef.current) {
      syncAttemptedRef.current = true;
      setSyncStatus('syncing');
      
      console.log('[VerificationSuccess] Starting document fetch for hash:', fileHash);
      
      // Directly fetch documents by hash from the database
      v3BlockchainService.getDocumentsByHash(
        fileHash,
        userProfile.profile_id,
        currentOrganizationId
      )
        .then((documents) => {
          console.log('[VerificationSuccess] Document fetch result:', documents);
          
          if (documents && documents.length > 0) {
            setSyncStatus('synced');
            setLocalDocuments(documents);
            
            console.log(`Found ${documents.length} document records.`);
          } else {
            // If no documents found, then try the sync workflow as a fallback
            console.log('[VerificationSuccess] No documents found, trying sync workflow');
            
            // Trigger document sync workflow with the verification result
            documentSyncService.syncDocumentRecord(
              fileHash,
              verificationResult,
              userProfile.profile_id,
              currentOrganizationId
            )
              .then((result) => {
                console.log('[VerificationSuccess] Document sync result:', result);
                
                if (result.success) {
                  setSyncStatus('synced');
                  setLocalDocuments(result.local_documents || []);
                  
                  console.log(`Successfully synced ${result.recordsProcessed || 0} document records.`);
                } else {
                  setSyncStatus('error');
                  setSyncError(result.error || 'Unknown error occurred during document sync');
                  
                  console.error('Sync failed:', result.error || 'Unknown error occurred during document sync');
                }
              })
              .catch((error) => {
                console.error('[VerificationSuccess] Document sync error:', error);
                setSyncStatus('error');
                setSyncError(error.message || 'Unknown error occurred during document sync');
                
                console.error('Sync error:', error.message || 'Unknown error occurred during document sync');
              });
          }
        })
        .catch((error) => {
          console.error('[VerificationSuccess] Document fetch error:', error);
          setSyncStatus('error');
          setSyncError(error.message || 'Unknown error occurred while fetching document records');
          
          console.error('Fetch error:', error.message || 'Unknown error occurred while fetching document records');
        });
    }
  }, [isAuthenticated, fileHash, userProfile, currentOrganizationId, verificationResult]);
  
  // Handle workflow selection
  const handleWorkflowSelect = (workflow: WorkflowType) => {
    onWorkflowSelect(workflow);
  };
  
  // Render sync status badge
  const renderSyncStatus = () => {
    switch (syncStatus) {
      case 'syncing':
        return (
          <HStack spacing={2}>
            <Spinner size="xs" />
            <Text fontSize="sm">Syncing with database...</Text>
          </HStack>
        );
      case 'synced':
        return (
          <Badge colorScheme="green" variant="subtle">
            <HStack spacing={1}>
              <Icon as={FiCheck} />
              <Text>Synced with database</Text>
            </HStack>
          </Badge>
        );
      case 'error':
        return (
          <Badge colorScheme="red" variant="subtle">
            <HStack spacing={1}>
              <Icon as={FiX} />
              <Text>Sync failed</Text>
            </HStack>
          </Badge>
        );
      default:
        return null;
    }
  };
  
  return (
    <ContentLayout>
      <Box 
        bg={bgColor} 
        borderRadius="md" 
        borderWidth="1px" 
        borderColor={borderColor}
        p={6}
        mb={6}
        boxShadow="md"
      >
        <VStack align="stretch" spacing={6}>
          {/* Success Header */}
          <Flex alignItems="center" justifyContent="space-between">
            <HStack>
              <Icon as={FiCheckCircle} color="green.500" boxSize={6} />
              <Heading as="h2" size="md" color={textColor}>
                Document Verified
              </Heading>
            </HStack>
            <Badge 
              colorScheme="green" 
              fontSize="sm" 
              px={2} 
              py={1} 
              borderRadius="md"
              bg={badgeBgColor}
              color={badgeTextColor}
            >
              Verified on Blockchain
            </Badge>
          </Flex>
          
          {/* Verification Summary */}
          <Box>
            <Text>
              The document <strong>{fileName || 'Document'}</strong> with hash <Code fontSize="sm">{fileHash?.substring(0, 10)}...{fileHash?.substring(fileHash.length - 10)}</Code> has been verified on the blockchain.
            </Text>
            <Text mt={2}>
              {localDocuments.length > 1 
                ? `${localDocuments.length} document records were found for this file.` 
                : 'One document record was found for this file.'}
            </Text>
          </Box>
          
          {/* Sync Document Section */}
          {isAuthenticated && (
            <Box mt={4}>
              {syncStatus === 'syncing' ? (
                <Box textAlign="center" py={4}>
                  <Spinner size="md" color="blue.500" mr={2} />
                  <Text display="inline-block">Retrieving document records...</Text>
                </Box>
              ) : syncStatus === 'error' ? (
                <Alert status="error" borderRadius="md">
                  <AlertIcon />
                  <Box>
                    <AlertTitle>Error Retrieving Document Records</AlertTitle>
                    <AlertDescription>
                      {syncError || 'An unknown error occurred while retrieving document records.'}
                    </AlertDescription>
                  </Box>
                </Alert>
              ) : (
                <Box>
                  <Heading as="h3" size="sm" mb={3}>
                    <HStack>
                      <Icon as={FiDatabase} />
                      <Text>Document Records</Text>
                    </HStack>
                  </Heading>
                  <DocumentTable documents={localDocuments} />
                </Box>
              )}
            </Box>
          )}
          
          {/* Next Steps */}
          <Box mt={4}>
            <Heading as="h3" size="sm" mb={4}>
              Next Steps
            </Heading>
            <NextStepsSelector 
              onWorkflowSelect={onWorkflowSelect}
              isAuthenticated={isAuthenticated}
              isVerified={true}
            />
          </Box>
        </VStack>
      </Box>
    </ContentLayout>
  );
};

export default VerificationSuccess;
