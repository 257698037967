import React from 'react';
import PrivyAuthTest from '../components/test/PrivyAuthTest';
import { usePrivy } from '@privy-io/react-auth';
import { useEnhancedAuth } from '../providers/EnhancedPostgresAuthProvider';

/**
 * Test page for authentication
 */
const AuthTest: React.FC = () => {
  const { authenticated: privyAuthenticated, ready: privyReady, login } = usePrivy();
  const { userProfile, loading: authLoading } = useEnhancedAuth();

  // Check if user is authenticated according to our pattern
  const isAuthenticated = !!userProfile || (privyAuthenticated && !authLoading);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-6">Authentication Test Page</h1>
      
      <div className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Authentication Status</h2>
        <div className="p-4 bg-gray-100 rounded">
          <p><strong>Privy Ready:</strong> {privyReady ? 'Yes' : 'No'}</p>
          <p><strong>Privy Authenticated:</strong> {privyAuthenticated ? 'Yes' : 'No'}</p>
          <p><strong>Enhanced Auth Loading:</strong> {authLoading ? 'Yes' : 'No'}</p>
          <p><strong>User Profile:</strong> {userProfile ? 'Loaded' : 'Not Loaded'}</p>
          <p><strong>Is Authenticated:</strong> {isAuthenticated ? 'Yes' : 'No'}</p>
        </div>
      </div>
      
      {!privyAuthenticated && (
        <div className="mb-6">
          <button 
            onClick={login}
            className="px-4 py-2 bg-blue-500 text-white rounded"
          >
            Login with Privy
          </button>
        </div>
      )}
      
      {privyAuthenticated && (
        <PrivyAuthTest />
      )}
    </div>
  );
};

export default AuthTest;
