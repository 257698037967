/**
 * usePreventBrowserDragBehavior Hook
 * 
 * A custom hook that prevents the browser's default behavior when files are dragged onto the page.
 * This is especially useful for drag and drop file uploaders to prevent the browser from opening
 * the file when it's dropped.
 * 
 * This hook is part of the V3 architecture that separates concerns and improves maintainability.
 */

import { useEffect } from 'react';

/**
 * Hook to prevent browser's default drag and drop behavior
 * @returns Object with preventBrowserDragBehavior property that can be spread onto a component
 */
export function usePreventBrowserDragBehavior() {
  useEffect(() => {
    // Prevent default behavior for dragover events
    const handleDragOver = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
    };

    // Prevent default behavior for drop events
    const handleDrop = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
    };

    // Add event listeners to the document
    document.addEventListener('dragover', handleDragOver);
    document.addEventListener('drop', handleDrop);

    // Clean up event listeners when the component unmounts
    return () => {
      document.removeEventListener('dragover', handleDragOver);
      document.removeEventListener('drop', handleDrop);
    };
  }, []);
  
  // Return an object with preventBrowserDragBehavior property
  // This contains event handlers that can be spread onto a component
  return {
    preventBrowserDragBehavior: {
      onDragOver: (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
      },
      onDrop: (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
      }
    }
  };
}

export default usePreventBrowserDragBehavior;
