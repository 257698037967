/**
 * PDF Store
 * 
 * A Zustand store for managing PDF-related state:
 * - Current PDF file
 * - File hash
 * - Metadata
 * - Processing state
 * - Blockchain verification data
 * - Registration data
 * 
 * This store is part of the V3 architecture that separates concerns
 * and improves maintainability.
 */

import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { PDFMetadata } from '../services/PDFService';
import { v4 as uuidv4 } from 'uuid';

// Define blockchain verification result interface
export interface BlockchainVerificationResult {
  verified: boolean;
  integra_id?: string;
  workflow_id?: string;
  registration_date?: string;
  blockchain_data?: any;
  [key: string]: any;
}

// Define blockchain document interface
export interface BlockchainDocument {
  integra_hash?: string;
  document_hash?: string;
  tx_hash?: string;
  reference_hash?: string;
  encrypted_data?: string;
  [key: string]: any;
}

// Define registration data interface
export interface RegistrationData {
  documentName?: string;
  documentType?: string;
  documentDescription?: string;
  registrationType?: string;
  qrCodePlacement?: 'right' | 'left' | 'cover';
  [key: string]: any;
}

// Define token set interface
export interface TokenSet {
  id: string;
  tokenName: string;
  tokenSymbol: string;
  amount: number;
}

// Define verification status type
export type VerificationStatus = 'idle' | 'verifying' | 'verified' | 'not_found' | 'error';

// Define PDF store state interface
export interface PDFState {
  // PDF file data
  pdfFile: File | null;
  fileHash: string | null;
  originalHash: string | null;
  smartDocHash: string | null;
  fileName: string | null;
  fileDataUrl: string | null;
  metadata: PDFMetadata | null;
  integraId: string | null;
  smartDocumentUrl: string | null;
  smartDocumentFile: File | null;
  
  // Blockchain verification data
  verificationResult: BlockchainVerificationResult | null;
  verificationStatus: VerificationStatus;
  verificationError: string | null;
  blockchainDocuments: BlockchainDocument[] | null;
  
  // Registration data
  registrationData: RegistrationData | null;
  
  // Token sets for tokenization
  tokenSets: TokenSet[];
  
  // Processing state
  isProcessing: boolean;
  processingStep: 'idle' | 'extracting' | 'hashing' | 'creating' | 'signing' | 'verifying' | 'registering' | 'creating-smart-document' | 'registering-blockchain';
  error: string | null;
  
  // Actions
  setPDFFile: (file: File | null) => void;
  setFileHash: (hash: string | null) => void;
  setOriginalHash: (hash: string | null) => void;
  setSmartDocHash: (hash: string | null) => void;
  setFileName: (name: string | null) => void;
  setFileDataUrl: (url: string | null) => void;
  setMetadata: (metadata: PDFMetadata | null) => void;
  setIntegraId: (id: string | null) => void;
  setSmartDocumentUrl: (url: string | null) => void;
  setSmartDocumentFile: (file: File | null) => void;
  setVerificationResult: (result: BlockchainVerificationResult | null) => void;
  setVerificationStatus: (status: VerificationStatus) => void;
  setVerificationError: (error: string | null) => void;
  setBlockchainDocuments: (documents: BlockchainDocument[] | null) => void;
  setRegistrationData: (data: RegistrationData | null) => void;
  updateRegistrationData: (partialData: Partial<RegistrationData>) => void;
  setTokenSets: (tokenSets: TokenSet[]) => void;
  addTokenSet: (tokenName: string, tokenSymbol: string) => void;
  updateTokenSet: (id: string, updates: Partial<Omit<TokenSet, 'id'>>) => void;
  removeTokenSet: (id: string) => void;
  setProcessing: (isProcessing: boolean, step?: 'idle' | 'extracting' | 'hashing' | 'creating' | 'signing' | 'verifying' | 'registering' | 'creating-smart-document' | 'registering-blockchain') => void;
  setError: (error: string | null) => void;
  updateMetadata: (partialMetadata: Partial<PDFMetadata>) => void;
  reset: () => void;
}

// Create the PDF store
export const usePDFStore = create<PDFState>()(
  devtools(
    persist(
      (set, get) => ({
        // Initial state
        pdfFile: null,
        fileHash: null,
        originalHash: null,
        smartDocHash: null,
        fileName: null,
        fileDataUrl: null,
        metadata: null,
        integraId: null,
        smartDocumentUrl: null,
        smartDocumentFile: null,
        verificationResult: null,
        verificationStatus: 'idle',
        verificationError: null,
        blockchainDocuments: null,
        registrationData: null,
        tokenSets: [],
        isProcessing: false,
        processingStep: 'idle',
        error: null,
        
        // Actions
        setPDFFile: (file) => set({ pdfFile: file }),
        setFileHash: (hash) => set({ fileHash: hash }),
        setOriginalHash: (hash) => set({ originalHash: hash }),
        setSmartDocHash: (hash) => set({ smartDocHash: hash }),
        setFileName: (name) => set({ fileName: name }),
        setFileDataUrl: (url) => set({ fileDataUrl: url }),
        setMetadata: (metadata) => set({ metadata }),
        setIntegraId: (id) => set({ integraId: id }),
        setSmartDocumentUrl: (url) => set({ smartDocumentUrl: url }),
        setSmartDocumentFile: (file) => set({ smartDocumentFile: file }),
        setVerificationResult: (result) => set({ verificationResult: result }),
        setVerificationStatus: (status) => set({ verificationStatus: status }),
        setVerificationError: (error) => set({ verificationError: error }),
        setBlockchainDocuments: (documents) => set({ blockchainDocuments: documents }),
        setRegistrationData: (data) => set({ registrationData: data }),
        updateRegistrationData: (partialData) => set((state) => ({
          registrationData: state.registrationData ? { ...state.registrationData, ...partialData } : partialData
        })),
        setTokenSets: (tokenSets) => set({ tokenSets }),
        addTokenSet: (tokenName, tokenSymbol) => set((state) => ({
          tokenSets: [...state.tokenSets, { id: uuidv4(), tokenName, tokenSymbol, amount: 0 }]
        })),
        updateTokenSet: (id, updates) => set((state) => ({
          tokenSets: state.tokenSets.map(ts => ts.id === id ? { ...ts, ...updates } : ts)
        })),
        removeTokenSet: (id) => set((state) => ({
          tokenSets: state.tokenSets.filter(ts => ts.id !== id)
        })),
        setProcessing: (isProcessing, step = 'idle') => set((state) => ({
          isProcessing,
          processingStep: step,
          error: isProcessing ? null : state.error
        })),
        setError: (error) => set((state) => ({
          error,
          isProcessing: error ? false : state.isProcessing,
          processingStep: error ? 'idle' : state.processingStep
        })),
        updateMetadata: (partialMetadata) => set((state) => ({
          metadata: state.metadata ? { ...state.metadata, ...partialMetadata } : partialMetadata as PDFMetadata
        })),
        reset: () => set({
          pdfFile: null,
          fileHash: null,
          originalHash: null,
          smartDocHash: null,
          fileName: null,
          fileDataUrl: null,
          metadata: null,
          integraId: null,
          smartDocumentUrl: null,
          smartDocumentFile: null,
          verificationResult: null,
          verificationStatus: 'idle',
          verificationError: null,
          blockchainDocuments: null,
          registrationData: null,
          tokenSets: [],
          isProcessing: false,
          processingStep: 'idle',
          error: null
        })
      }),
      {
        name: 'pdf-store',
        // Only persist these fields
        partialize: (state) => ({
          fileHash: state.fileHash,
          originalHash: state.originalHash,
          smartDocHash: state.smartDocHash,
          fileName: state.fileName,
          fileDataUrl: state.fileDataUrl,
          metadata: state.metadata,
          integraId: state.integraId,
          smartDocumentUrl: state.smartDocumentUrl,
          verificationResult: state.verificationResult,
          verificationStatus: state.verificationStatus,
          verificationError: state.verificationError,
          blockchainDocuments: state.blockchainDocuments,
          registrationData: state.registrationData,
          tokenSets: state.tokenSets
        })
      }
    )
  )
);

export default usePDFStore;
