// src/createQrCode.ts
import QRCode from 'qrcode';
import { createCanvas, loadImage, Canvas } from 'canvas';

interface QrCodeConfig {
  url: string;
  size?: number;
  logoUrl?: string | Uint8Array | null;
  logoScale?: number;
}

const createQrCode = async ({
  url,
  size = 84,
  logoUrl = null,
  logoScale = 0.2,
}: QrCodeConfig): Promise<Uint8Array> => {
  // Check if running in browser or Node.js environment
  const isBrowser = typeof window !== 'undefined';
  
  if (isBrowser) {
    // Browser implementation
    const canvas = document.createElement('canvas');
    canvas.width = size;
    canvas.height = size;
    
    await QRCode.toCanvas(canvas, url, {
      width: size,
      margin: 1,
      errorCorrectionLevel: 'H',
    });
    
    if (logoUrl) {
      const ctx = canvas.getContext('2d');
      if (!ctx) throw new Error('Could not get canvas context');
      
      const logoImage = new Image();
      
      // Create a promise to handle image loading
      await new Promise((resolve, reject) => {
        logoImage.onload = resolve;
        logoImage.onerror = reject;
        
        if (typeof logoUrl === 'string') {
          logoImage.src = logoUrl;
        } else {
          // Convert Uint8Array to data URL
          const blob = new Blob([logoUrl]);
          logoImage.src = URL.createObjectURL(blob);
        }
      });
      
      const logoSize = size * logoScale;
      const logoX = (size - logoSize) / 2;
      const logoY = (size - logoSize) / 2;
      ctx.drawImage(logoImage, logoX, logoY, logoSize, logoSize);
    }
    
    // Convert canvas to Uint8Array
    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          throw new Error('Failed to convert canvas to blob');
        }
        
        const reader = new FileReader();
        reader.onloadend = () => {
          const arrayBuffer = reader.result as ArrayBuffer;
          resolve(new Uint8Array(arrayBuffer));
        };
        reader.readAsArrayBuffer(blob);
      }, 'image/png');
    });
  } else {
    // Node.js implementation
    const qrCanvas: Canvas = createCanvas(size, size);
    await QRCode.toCanvas(qrCanvas, url, {
      width: size,
      margin: 1,
      errorCorrectionLevel: 'H',
    });

    if (logoUrl) {
      const ctx = qrCanvas.getContext('2d');
      // Convert Uint8Array to Buffer if needed
      const logoBuffer = typeof logoUrl === 'string' 
        ? logoUrl 
        : Buffer.from(logoUrl.buffer, logoUrl.byteOffset, logoUrl.byteLength);
      const logoImage = await loadImage(logoBuffer);
      const logoSize = size * logoScale;
      const logoX = (size - logoSize) / 2;
      const logoY = (size - logoSize) / 2;
      ctx.drawImage(logoImage, logoX, logoY, logoSize, logoSize);
    }

    // Convert Buffer to Uint8Array
    const buffer = qrCanvas.toBuffer('image/png');
    return new Uint8Array(buffer.buffer, buffer.byteOffset, buffer.byteLength);
  }
};

export default createQrCode;