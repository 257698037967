/**
 * App Routes
 * 
 * This component defines the application routes and handles
 * authentication requirements for protected routes.
 */

import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { usePrivy } from '@privy-io/react-auth';

// Import services
import workflowManagerService from '../services/WorkflowManagerService';

// Import pages
import Home from '../pages/Home';
import VerificationResult from '../pages/VerificationResult';
import DocumentRegistration from '../pages/DocumentRegistration';
import TokenizeDocument from '../pages/TokenizeDocument';
import DocumentActivities from '../pages/DocumentActivities';
import Dashboard from '../pages/Dashboard';
import Documents from '../pages/Documents';
import UserProfile from '../pages/UserProfile';
import Settings from '../pages/Settings';
import PDFTools from '../pages/PDFTools';
import QrVerify from '../pages/QrVerify';
import NotFound from '../pages/NotFound';
import Onboarding from '../pages/Onboarding';
import OrganizationSettings from '../pages/OrganizationSettings';
import InvitationAccept from '../pages/InvitationAccept';
import NetworkStatus from '../pages/NetworkStatus';
import AuthTest from '../pages/AuthTest';

// Import stores
import { useWorkflowStore } from '../store/workflowStore';
import { usePDFStore, type BlockchainVerificationResult } from '../store/pdfStore';

/**
 * Helper function to convert BlockchainVerificationResult to a format compatible with WorkflowManagerService
 */
const convertToVerificationResult = (
  blockchainResult: BlockchainVerificationResult | null
) => {
  if (!blockchainResult) return null;
  
  return {
    verified: blockchainResult.verified,
    documentHash: blockchainResult.document_hash || '',
    workflowId: blockchainResult.workflow_id || '',
    documents: blockchainResult.blockchain_data ? [blockchainResult.blockchain_data] : [],
    status: blockchainResult.verified ? 'SUCCESS' : 'FAILED',
    message: '',
    rawResponse: blockchainResult
  };
};

/**
 * Protected Route Component
 * 
 * Wraps routes that require authentication
 */
const ProtectedRoute: React.FC<{
  element: React.ReactElement;
  workflow: string | null;
}> = ({ element, workflow }) => {
  const { authenticated, login } = usePrivy();
  const location = useLocation();
  const { verificationResult: pdfStoreVerificationResult } = usePDFStore();
  
  // Convert PDF store verification result to the format expected by WorkflowManagerService
  const verificationResult = pdfStoreVerificationResult ? 
    convertToVerificationResult(pdfStoreVerificationResult) : null;
  
  // Check if the workflow requires authentication
  const requiresAuth = workflow ? workflowManagerService.requiresAuthentication(workflow as any) : true;
  
  // If authentication is required but user is not authenticated, redirect to login
  if (requiresAuth && !authenticated) {
    // Prompt the user to log in
    login();
    
    // Return a message indicating authentication is required
    return (
      <Navigate 
        to="/" 
        state={{ 
          from: location,
          authRequired: true 
        }} 
        replace 
      />
    );
  }
  
  // For workflows that require verification first
  if (workflow && workflow !== 'verify') {
    console.log('Checking workflow transition validity:', {
      currentWorkflow: 'verify',
      nextWorkflow: workflow,
      verificationResult
    });
    
    const isValidTransition = workflowManagerService.isValidWorkflowTransition(
      'verify',
      workflow as any,
      verificationResult
    );
    
    if (!isValidTransition) {
      console.log('Invalid workflow transition, redirecting to home');
      return <Navigate to="/" replace />;
    }
  }
  
  // If authenticated or authentication not required, render the element
  return element;
};

/**
 * App Routes Component
 */
const AppRoutes: React.FC = () => {
  const { selectedWorkflow } = useWorkflowStore();
  const { authenticated, user } = usePrivy();
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  
  // Check if this is the user's first login and redirect to onboarding if needed
  useEffect(() => {
    // Skip if not authenticated or if already on the onboarding page
    if (!authenticated || location.pathname === '/onboarding') {
      return;
    }
    
    // Check if the user has only one login method
    const hasOnlyOneLoginMethod = user && 
      Object.keys(user).filter(key => 
        ['email', 'google', 'twitter', 'github', 'wallet', 'sms'].includes(key) && 
        user[key as keyof typeof user]
      ).length <= 1;
    
    // Check localStorage for onboarding completion flag
    const hasCompletedOnboarding = localStorage.getItem('onboardingComplete') === 'true';
    
    if (hasOnlyOneLoginMethod && !hasCompletedOnboarding) {
      setIsFirstLogin(true);
      navigate('/onboarding');
      
      // Set flag in localStorage to prevent showing onboarding again
      localStorage.setItem('onboardingComplete', 'true');
    }
  }, [authenticated, user, navigate, location.pathname]);
  
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<Home />} />
      <Route path="/verify" element={<Home />} />
      <Route path="/verification-result" element={<VerificationResult />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/qr-verify/:integraId" element={<QrVerify />} />
      <Route path="/auth-test" element={<AuthTest />} />
      
      {/* Onboarding Route */}
      <Route 
        path="/onboarding" 
        element={
          <ProtectedRoute 
            element={<Onboarding />} 
            workflow={null}
          />
        } 
      />
      
      {/* Protected Routes */}
      <Route 
        path="/register" 
        element={
          <ProtectedRoute 
            element={<DocumentRegistration />} 
            workflow="register"
          />
        } 
      />
      <Route 
        path="/tokenize" 
        element={
          <ProtectedRoute 
            element={<TokenizeDocument />} 
            workflow="tokenize"
          />
        } 
      />
      <Route 
        path="/activities" 
        element={
          <ProtectedRoute 
            element={<DocumentActivities />} 
            workflow="communicate"
          />
        } 
      />
      <Route 
        path="/documents" 
        element={
          <ProtectedRoute 
            element={<Documents />} 
            workflow={null}
          />
        } 
      />
      <Route 
        path="/organization-settings" 
        element={
          <ProtectedRoute 
            element={<OrganizationSettings />} 
            workflow={null}
          />
        } 
      />
      <Route 
        path="/invitation/:token" 
        element={
          <ProtectedRoute 
            element={<InvitationAccept />} 
            workflow={null}
          />
        } 
      />
      <Route 
        path="/profile" 
        element={
          <ProtectedRoute 
            element={<UserProfile />} 
            workflow={null}
          />
        } 
      />
      <Route 
        path="/settings" 
        element={
          <ProtectedRoute 
            element={<Settings />} 
            workflow={null}
          />
        } 
      />
      <Route 
        path="/pdf-tools" 
        element={
          <ProtectedRoute 
            element={<PDFTools />} 
            workflow={null}
          />
        } 
      />
      <Route 
        path="/network-status" 
        element={
          <ProtectedRoute 
            element={<NetworkStatus />} 
            workflow={null}
          />
        } 
      />
      
      {/* Catch-all route */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
