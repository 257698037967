/**
 * Encryption Constants
 * 
 * This file contains constants used for encryption across the application.
 */

/**
 * Standard nonce used for ChaCha20 encryption
 * This is the ASCII representation of "IntegraLedgr" - exactly 12 bytes
 */
export const STANDARD_CHACHA20_NONCE = new Uint8Array([
  73, 110, 116, 101, 103, 114, 97, 76, 101, 100, 103, 114
]);
