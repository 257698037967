import { useState, useEffect } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  IconButton,
  Button,
  useToast,
  Heading,
  Text,
  Flex,
  Spinner,
  Select,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { useEnhancedAuth } from '../providers/EnhancedPostgresAuthProvider';
import { OrganizationMember, UserProfile } from '../lib/postgres-client';
import postgresClient from '../lib/postgres-client';

interface OrganizationMembersProps {
  organizationId: string;
}

interface MemberWithProfile extends OrganizationMember {
  profile?: UserProfile;
}

const OrganizationMembers = ({ organizationId }: OrganizationMembersProps) => {
  const { getOrganizationMembers, updateMemberRole, removeMember, userProfile } = useEnhancedAuth();
  const [members, setMembers] = useState<MemberWithProfile[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedMember, setSelectedMember] = useState<MemberWithProfile | null>(null);
  const [newRole, setNewRole] = useState<string>('');
  const [actionType, setActionType] = useState<'edit' | 'remove'>('edit');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const fetchMembers = async () => {
    if (!organizationId) return;
    
    setLoading(true);
    try {
      const fetchedMembers = await getOrganizationMembers(organizationId);
      
      // Fetch user profiles for each member
      const membersWithProfiles = await Promise.all(
        fetchedMembers.map(async (member) => {
          try {
            const profile = await postgresClient.getProfile(member.profile_id);
            return { ...member, profile };
          } catch (error) {
            console.error(`Error fetching profile for ${member.profile_id}:`, error);
            return member;
          }
        })
      );
      
      setMembers(membersWithProfiles);
    } catch (error) {
      console.error('Error fetching members:', error);
      toast({
        title: 'Error fetching members',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMembers();
  }, [organizationId]);

  const openEditModal = (member: MemberWithProfile) => {
    setSelectedMember(member);
    setNewRole(member.role);
    setActionType('edit');
    onOpen();
  };

  const openRemoveModal = (member: MemberWithProfile) => {
    setSelectedMember(member);
    setActionType('remove');
    onOpen();
  };

  const handleUpdateRole = async () => {
    if (!selectedMember || !newRole) return;
    
    try {
      const updatedMember = await updateMemberRole(organizationId, selectedMember.profile_id, newRole);
      if (updatedMember) {
        toast({
          title: 'Role updated',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        fetchMembers();
      }
    } catch (error) {
      toast({
        title: 'Error updating role',
        description: error instanceof Error ? error.message : 'Unknown error',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      onClose();
    }
  };

  const handleRemoveMember = async () => {
    if (!selectedMember) return;
    
    try {
      const success = await removeMember(organizationId, selectedMember.profile_id);
      if (success) {
        toast({
          title: 'Member removed',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        fetchMembers();
      }
    } catch (error) {
      toast({
        title: 'Error removing member',
        description: error instanceof Error ? error.message : 'Unknown error',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      onClose();
    }
  };

  const handleConfirmAction = () => {
    if (actionType === 'edit') {
      handleUpdateRole();
    } else {
      handleRemoveMember();
    }
  };

  const isCurrentUser = (profileId: string) => {
    return userProfile?.profile_id === profileId;
  };

  if (loading) {
    return (
      <Flex justify="center" align="center" p={4}>
        <Spinner />
      </Flex>
    );
  }

  return (
    <Box>
      <Heading size="md" mb={4}>
        Organization Members
      </Heading>

      {members.length > 0 ? (
        <Box overflowX="auto">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Email</Th>
                <Th>Role</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {members.map((member) => (
                <Tr key={member.id}>
                  <Td>{member.profile?.name || 'Unknown'}</Td>
                  <Td>{member.profile?.email || 'Unknown'}</Td>
                  <Td>
                    <Badge colorScheme={member.role === 'admin' ? 'purple' : 'blue'}>
                      {member.role}
                    </Badge>
                  </Td>
                  <Td>
                    <Flex gap={2}>
                      {!isCurrentUser(member.profile_id) && (
                        <>
                          <IconButton
                            aria-label="Edit role"
                            icon={<EditIcon />}
                            size="sm"
                            colorScheme="blue"
                            variant="ghost"
                            onClick={() => openEditModal(member)}
                          />
                          <IconButton
                            aria-label="Remove member"
                            icon={<DeleteIcon />}
                            size="sm"
                            colorScheme="red"
                            variant="ghost"
                            onClick={() => openRemoveModal(member)}
                          />
                        </>
                      )}
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      ) : (
        <Text>No members found</Text>
      )}

      {/* Edit/Remove Member Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {actionType === 'edit' ? 'Update Member Role' : 'Remove Member'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {actionType === 'edit' ? (
              <Box>
                <Text mb={4}>
                  Update role for <strong>{selectedMember?.profile?.name || 'Unknown'}</strong>
                </Text>
                <Select value={newRole} onChange={(e) => setNewRole(e.target.value)}>
                  <option value="member">Member</option>
                  <option value="admin">Admin</option>
                </Select>
              </Box>
            ) : (
              <Text>
                Are you sure you want to remove <strong>{selectedMember?.profile?.name || 'Unknown'}</strong> from this organization?
              </Text>
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme={actionType === 'edit' ? 'blue' : 'red'}
              onClick={handleConfirmAction}
            >
              {actionType === 'edit' ? 'Update' : 'Remove'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default OrganizationMembers;
