import React from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';

/**
 * AppLayout Component
 * 
 * Base layout component for all pages in the application.
 * Provides consistent width, padding, and background.
 * 
 * This is the primary layout container that should be used on all pages
 * to ensure a consistent look and feel throughout the application.
 */
interface AppLayoutProps {
  children: React.ReactNode;
  fullHeight?: boolean;
}

const AppLayout: React.FC<AppLayoutProps> = ({ 
  children, 
  fullHeight = true 
}) => {
  // Use Integra's custom theme colors
  const bgColor = useColorModeValue('integra.light.background', 'integra.dark.background');
  
  return (
    <Box 
      bg={bgColor} 
      minH={fullHeight ? "calc(100vh - 80px)" : "auto"}
      py={4}
      width="100%"
      display="flex"
      justifyContent="center"
    >
      <Box 
        maxW={{ base: "100%", md: "90%", lg: "1200px" }}
        width="100%" 
        px={{ base: 2, sm: 4, md: 6 }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default AppLayout;
