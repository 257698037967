/**
 * QRCodeGenerator Component
 * 
 * A component for generating and displaying QR codes:
 * - Renders QR codes with customizable options
 * - Supports embedding logos
 * - Handles loading and error states
 * - Provides download functionality
 * 
 * This component is part of the V3 architecture that separates concerns
 * and improves maintainability.
 */

import { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Image,
  VStack,
  Spinner,
  Text,
  Button,
  IconButton,
  useToast,
  Tooltip,
  HStack,
  Flex
} from '@chakra-ui/react';
import { DownloadIcon, CopyIcon, CheckIcon } from '@chakra-ui/icons';
import qrCodeService, { ErrorCorrectionLevel } from '../services/QRCodeService';

// QR code generator props interface
export interface QRCodeGeneratorProps {
  /** Data to encode in the QR code */
  data: string;
  
  /** Size of the QR code in pixels */
  size?: number;
  
  /** URL of the logo to embed in the center */
  logoUrl?: string;
  
  /** Scale of the logo relative to the QR code size */
  logoScale?: number;
  
  /** Error correction level */
  errorCorrection?: ErrorCorrectionLevel;
  
  /** Background color of the QR code */
  backgroundColor?: string;
  
  /** Foreground color of the QR code */
  foregroundColor?: string;
  
  /** Margin around the QR code */
  margin?: number;
  
  /** Whether to show download button */
  showDownloadButton?: boolean;
  
  /** Whether to show copy button */
  showCopyButton?: boolean;
  
  /** Whether to show the encoded data */
  showData?: boolean;
  
  /** Custom filename for download */
  downloadFilename?: string;
  
  /** Callback when QR code is generated */
  onGenerated?: (dataUrl: string) => void;
  
  /** Callback when QR code fails to generate */
  onError?: (error: Error) => void;
}

/**
 * QR Code Generator Component
 */
export function QRCodeGenerator({
  data,
  size = 200,
  logoUrl,
  logoScale = 0.2,
  errorCorrection = 'H',
  backgroundColor = '#FFFFFF',
  foregroundColor = '#000000',
  margin = 1,
  showDownloadButton = true,
  showCopyButton = true,
  showData = false,
  downloadFilename = 'qrcode.png',
  onGenerated,
  onError
}: QRCodeGeneratorProps) {
  // State
  const [qrCodeUrl, setQrCodeUrl] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [copied, setCopied] = useState<boolean>(false);
  
  // Toast for notifications
  const toast = useToast();
  
  /**
   * Generate QR code
   */
  const generateQRCode = useCallback(async () => {
    if (!data) {
      setError('No data provided');
      setLoading(false);
      return;
    }
    
    try {
      setLoading(true);
      setError(null);
      
      let dataUrl: string;
      
      if (logoUrl) {
        // Generate QR code with logo
        dataUrl = await qrCodeService.generateQRCodeWithLogo({
          data,
          size,
          logo: logoUrl,
          logoScale,
          errorCorrection,
          backgroundColor,
          foregroundColor,
          margin
        });
      } else {
        // Generate QR code without logo
        dataUrl = await qrCodeService.generateQRCodeDataURL({
          data,
          size,
          errorCorrection,
          backgroundColor,
          foregroundColor,
          margin
        });
      }
      
      setQrCodeUrl(dataUrl);
      setLoading(false);
      onGenerated?.(dataUrl);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Unknown error generating QR code';
      setError(errorMessage);
      setLoading(false);
      onError?.(error instanceof Error ? error : new Error(errorMessage));
      
      // Show toast notification
      toast({
        title: 'Error generating QR code',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }, [data, size, logoUrl, logoScale, errorCorrection, backgroundColor, foregroundColor, margin, onGenerated, onError, toast]);
  
  // Generate QR code when props change
  useEffect(() => {
    generateQRCode();
  }, [generateQRCode]);
  
  /**
   * Download QR code
   */
  const downloadQRCode = useCallback(() => {
    if (!qrCodeUrl) return;
    
    const link = document.createElement('a');
    link.href = qrCodeUrl;
    link.download = downloadFilename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
    toast({
      title: 'QR Code Downloaded',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  }, [qrCodeUrl, downloadFilename, toast]);
  
  /**
   * Copy QR code to clipboard
   */
  const copyQRCode = useCallback(async () => {
    if (!qrCodeUrl) return;
    
    try {
      // Fetch the image as a blob
      const response = await fetch(qrCodeUrl);
      const blob = await response.blob();
      
      // Copy to clipboard
      await navigator.clipboard.write([
        new ClipboardItem({
          [blob.type]: blob
        })
      ]);
      
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
      
      toast({
        title: 'QR Code Copied',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error copying QR code:', error);
      toast({
        title: 'Failed to Copy',
        description: 'Could not copy QR code to clipboard',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  }, [qrCodeUrl, toast]);

  return (
    <VStack spacing={4} align="center">
      {/* QR Code Container */}
      <Box
        position="relative"
        width={`${size}px`}
        height={`${size}px`}
        bg="gray.100"
        borderRadius="md"
        overflow="hidden"
      >
        {/* Loading Spinner */}
        {loading && (
          <Flex
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            align="center"
            justify="center"
            bg="whiteAlpha.800"
            zIndex={1}
          >
            <Spinner size="xl" color="blue.500" thickness="4px" />
          </Flex>
        )}

        {/* QR Code Image */}
        {qrCodeUrl && !loading && (
          <Image
            src={qrCodeUrl}
            alt="QR Code"
            width="100%"
            height="100%"
            objectFit="contain"
          />
        )}

        {/* Error Display */}
        {error && (
          <Flex
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            align="center"
            justify="center"
            bg="red.50"
            p={4}
          >
            <Text color="red.500" fontSize="sm" textAlign="center">
              {error}
            </Text>
          </Flex>
        )}
      </Box>

      {/* Data Display */}
      {showData && data && (
        <Text
          fontSize="sm"
          color="gray.600"
          maxWidth={`${size}px`}
          isTruncated
          title={data}
        >
          {data}
        </Text>
      )}

      {/* Action Buttons */}
      <HStack spacing={2}>
        {/* Download Button */}
        {showDownloadButton && qrCodeUrl && (
          <Tooltip label="Download QR Code">
            <IconButton
              aria-label="Download QR Code"
              icon={<DownloadIcon />}
              onClick={downloadQRCode}
              isDisabled={loading || !!error}
              colorScheme="blue"
              size="sm"
            />
          </Tooltip>
        )}

        {/* Copy Button */}
        {showCopyButton && qrCodeUrl && (
          <Tooltip label={copied ? 'Copied!' : 'Copy to Clipboard'}>
            <IconButton
              aria-label="Copy QR Code"
              icon={copied ? <CheckIcon /> : <CopyIcon />}
              onClick={copyQRCode}
              isDisabled={loading || !!error}
              colorScheme={copied ? 'green' : 'blue'}
              size="sm"
            />
          </Tooltip>
        )}

        {/* Regenerate Button */}
        {error && (
          <Button
            size="sm"
            colorScheme="blue"
            onClick={generateQRCode}
            isLoading={loading}
          >
            Retry
          </Button>
        )}
      </HStack>
    </VStack>
  );
}

export default QRCodeGenerator;
