import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  Heading,
  Text,
  Badge,
  Flex,
  Spinner,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useEnhancedAuth } from '../providers/EnhancedPostgresAuthProvider';
import { OrganizationInvitation } from '../lib/postgres-client';

interface PendingInvitationsProps {
  email: string;
  onAcceptInvitation?: () => void;
}

const PendingInvitations = ({ email, onAcceptInvitation }: PendingInvitationsProps) => {
  const { getPendingInvitationsByEmail, acceptInvitation, declineInvitation } = useEnhancedAuth();
  const [invitations, setInvitations] = useState<OrganizationInvitation[]>([]);
  const [loading, setLoading] = useState(true);
  const [processingInvitation, setProcessingInvitation] = useState<string | null>(null);
  const [selectedInvitation, setSelectedInvitation] = useState<OrganizationInvitation | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [actionType, setActionType] = useState<'accept' | 'decline'>('accept');
  const toast = useToast();

  const fetchInvitations = async () => {
    if (!email) return;
    
    setLoading(true);
    try {
      const fetchedInvitations = await getPendingInvitationsByEmail(email);
      setInvitations(fetchedInvitations);
    } catch (error) {
      console.error('Error fetching invitations:', error);
      toast({
        title: 'Error fetching invitations',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvitations();
  }, [email]);

  const handleAcceptInvitation = async () => {
    if (!selectedInvitation) return;
    
    setProcessingInvitation(selectedInvitation.invitation_id);
    try {
      const success = await acceptInvitation(selectedInvitation.invitation_id);
      if (success) {
        toast({
          title: 'Invitation accepted',
          description: `You have joined ${selectedInvitation.organization_name}`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        fetchInvitations();
        if (onAcceptInvitation) {
          onAcceptInvitation();
        }
      }
    } catch (error) {
      toast({
        title: 'Error accepting invitation',
        description: error instanceof Error ? error.message : 'Unknown error',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setProcessingInvitation(null);
      onClose();
    }
  };

  const handleDeclineInvitation = async () => {
    if (!selectedInvitation) return;
    
    setProcessingInvitation(selectedInvitation.invitation_id);
    try {
      const success = await declineInvitation(selectedInvitation.invitation_id);
      if (success) {
        toast({
          title: 'Invitation declined',
          status: 'info',
          duration: 3000,
          isClosable: true,
        });
        fetchInvitations();
      }
    } catch (error) {
      toast({
        title: 'Error declining invitation',
        description: error instanceof Error ? error.message : 'Unknown error',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setProcessingInvitation(null);
      onClose();
    }
  };

  const openConfirmationModal = (invitation: OrganizationInvitation, action: 'accept' | 'decline') => {
    setSelectedInvitation(invitation);
    setActionType(action);
    onOpen();
  };

  const handleConfirmAction = () => {
    if (actionType === 'accept') {
      handleAcceptInvitation();
    } else {
      handleDeclineInvitation();
    }
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString();
  };

  if (loading) {
    return (
      <Flex justify="center" align="center" p={4}>
        <Spinner />
      </Flex>
    );
  }

  if (invitations.length === 0) {
    return null;
  }

  return (
    <Box mb={6}>
      <Heading size="md" mb={4}>
        Pending Organization Invitations
      </Heading>

      <Box overflowX="auto">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Organization</Th>
              <Th>Role</Th>
              <Th>Sent</Th>
              <Th>Expires</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {invitations.map((invitation) => (
              <Tr key={invitation.invitation_id}>
                <Td>{invitation.organization_name}</Td>
                <Td>
                  <Badge colorScheme={invitation.role === 'admin' ? 'purple' : 'blue'}>
                    {invitation.role}
                  </Badge>
                </Td>
                <Td>{formatDate(invitation.created_at)}</Td>
                <Td>{formatDate(invitation.expires_at)}</Td>
                <Td>
                  <Flex gap={2}>
                    <Button
                      size="sm"
                      colorScheme="green"
                      isLoading={processingInvitation === invitation.invitation_id}
                      onClick={() => openConfirmationModal(invitation, 'accept')}
                    >
                      Accept
                    </Button>
                    <Button
                      size="sm"
                      colorScheme="red"
                      variant="outline"
                      isLoading={processingInvitation === invitation.invitation_id}
                      onClick={() => openConfirmationModal(invitation, 'decline')}
                    >
                      Decline
                    </Button>
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      {/* Confirmation Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {actionType === 'accept' ? 'Accept Invitation' : 'Decline Invitation'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {actionType === 'accept' ? (
              <Text>
                Are you sure you want to join <strong>{selectedInvitation?.organization_name}</strong> as a{' '}
                <strong>{selectedInvitation?.role}</strong>?
              </Text>
            ) : (
              <Text>
                Are you sure you want to decline the invitation to join{' '}
                <strong>{selectedInvitation?.organization_name}</strong>?
              </Text>
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme={actionType === 'accept' ? 'green' : 'red'}
              onClick={handleConfirmAction}
            >
              {actionType === 'accept' ? 'Yes, Join' : 'Yes, Decline'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default PendingInvitations;
