/**
 * PDFUploader Component
 * 
 * A component for uploading PDF files:
 * - Handles file selection via drag-and-drop or file browser
 * - Validates file types
 * - Provides feedback on upload status
 * 
 * This component is part of the V3 architecture that separates concerns
 * and improves maintainability.
 */

import { useState, useRef, useCallback } from 'react';
import {
  Box,
  VStack,
  Text,
  Button,
  Input,
  Icon,
  Flex,
  useColorModeValue,
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton
} from '@chakra-ui/react';
import { DocumentIcon, ArrowUpTrayIcon } from '@heroicons/react/24/outline';

// PDF uploader props interface
export interface PDFUploaderProps {
  /** Callback when a file is selected */
  onFileSelected: (file: File) => void;
  
  /** Allowed file types */
  acceptedFileTypes?: string[];
  
  /** Maximum file size in bytes */
  maxFileSize?: number;
  
  /** Whether the uploader is in a loading state */
  isLoading?: boolean;
  
  /** Error message to display */
  error?: string | null;
  
  /** Success message to display */
  successMessage?: string | null;
  
  /** Whether to clear the file input after selection */
  clearAfterSelection?: boolean;
  
  /** Custom label text */
  labelText?: string;
  
  /** Custom button text */
  buttonText?: string;
}

/**
 * PDF Uploader Component
 */
export function PDFUploader({
  onFileSelected,
  acceptedFileTypes = ['application/pdf'],
  maxFileSize = 10 * 1024 * 1024, // 10MB
  isLoading = false,
  error = null,
  successMessage = null,
  clearAfterSelection = true,
  labelText = 'Drag and drop your PDF file here, or click to browse',
  buttonText = 'Select PDF'
}: PDFUploaderProps) {
  // State
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>('');
  const [fileSize, setFileSize] = useState<number>(0);
  const [internalError, setInternalError] = useState<string | null>(null);
  
  // Refs
  const fileInputRef = useRef<HTMLInputElement>(null);
  
  // Colors
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const bgColor = useColorModeValue('gray.50', 'gray.700');
  const dragBgColor = useColorModeValue('blue.50', 'blue.900');
  const dragBorderColor = useColorModeValue('blue.300', 'blue.600');
  
  /**
   * Handle file selection
   * @param file Selected file
   */
  const handleFileSelection = useCallback((file: File) => {
    // Clear previous errors
    setInternalError(null);
    
    // Validate file type
    if (!acceptedFileTypes.includes(file.type)) {
      setInternalError(`Invalid file type. Please upload a PDF file.`);
      return;
    }
    
    // Validate file size
    if (file.size > maxFileSize) {
      setInternalError(`File size exceeds the maximum limit of ${maxFileSize / (1024 * 1024)}MB.`);
      return;
    }
    
    // Set file info
    setFileName(file.name);
    setFileSize(file.size);
    
    // Call callback
    onFileSelected(file);
    
    // Clear file input if needed
    if (clearAfterSelection && fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }, [acceptedFileTypes, maxFileSize, onFileSelected, clearAfterSelection]);
  
  /**
   * Handle file input change
   * @param event Change event
   */
  const handleFileInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      handleFileSelection(files[0]);
    }
  }, [handleFileSelection]);
  
  /**
   * Handle drag events
   * @param event Drag event
   * @param isDraggingState Whether the drag state is active
   */
  const handleDrag = useCallback((event: React.DragEvent, isDraggingState: boolean) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(isDraggingState);
  }, []);
  
  /**
   * Handle file drop
   * @param event Drop event
   */
  const handleDrop = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
    
    const files = event.dataTransfer.files;
    if (files && files.length > 0) {
      handleFileSelection(files[0]);
    }
  }, [handleFileSelection]);
  
  /**
   * Format file size
   * @param bytes File size in bytes
   * @returns Formatted file size string
   */
  const formatFileSize = useCallback((bytes: number): string => {
    if (bytes === 0) return '0 Bytes';
    
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
  }, []);
  
  /**
   * Clear the current file
   */
  const clearFile = useCallback(() => {
    setFileName('');
    setFileSize(0);
    setInternalError(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }, []);
  
  /**
   * Open file browser
   */
  const openFileBrowser = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);

  // Display error message (either internal or from props)
  const displayError = internalError || error;
  
  return (
    <VStack spacing={4} width="100%">
      {/* File Input (hidden) */}
      <Input
        type="file"
        accept={acceptedFileTypes.join(',')}
        ref={fileInputRef}
        onChange={handleFileInputChange}
        display="none"
      />
      
      {/* Drag and Drop Area */}
      <Box
        width="100%"
        border="2px dashed"
        borderColor={isDragging ? dragBorderColor : borderColor}
        borderRadius="md"
        bg={isDragging ? dragBgColor : bgColor}
        p={6}
        transition="all 0.2s"
        cursor="pointer"
        onClick={openFileBrowser}
        onDragEnter={(e) => handleDrag(e, true)}
        onDragOver={(e) => handleDrag(e, true)}
        onDragLeave={(e) => handleDrag(e, false)}
        onDrop={handleDrop}
        data-testid="pdf-dropzone"
      >
        <VStack spacing={3}>
          {/* Icon */}
          <Icon
            as={fileName ? DocumentIcon : ArrowUpTrayIcon}
            boxSize={12}
            color={isDragging ? 'blue.500' : 'gray.400'}
          />
          
          {/* Content */}
          {!fileName ? (
            <>
              <Text textAlign="center" fontWeight="medium">
                {labelText}
              </Text>
              <Button
                colorScheme="blue"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  openFileBrowser();
                }}
                isLoading={isLoading}
                loadingText="Processing..."
              >
                {buttonText}
              </Button>
            </>
          ) : (
            <VStack spacing={1}>
              <Text fontWeight="bold">{fileName}</Text>
              <Text fontSize="sm" color="gray.500">
                {formatFileSize(fileSize)}
              </Text>
              {isLoading ? (
                <Flex align="center" mt={2}>
                  <Spinner size="sm" mr={2} />
                  <Text>Processing file...</Text>
                </Flex>
              ) : (
                <Button
                  size="sm"
                  variant="outline"
                  colorScheme="red"
                  mt={2}
                  onClick={(e) => {
                    e.stopPropagation();
                    clearFile();
                  }}
                >
                  Clear
                </Button>
              )}
            </VStack>
          )}
        </VStack>
      </Box>
      
      {/* Error Message */}
      {displayError && (
        <Alert status="error" borderRadius="md">
          <AlertIcon />
          <Box flex="1">
            <AlertTitle>Error</AlertTitle>
            <AlertDescription display="block">
              {displayError}
            </AlertDescription>
          </Box>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setInternalError(null)}
          />
        </Alert>
      )}
      
      {/* Success Message */}
      {successMessage && (
        <Alert status="success" borderRadius="md">
          <AlertIcon />
          <Box flex="1">
            <AlertDescription display="block">
              {successMessage}
            </AlertDescription>
          </Box>
        </Alert>
      )}
    </VStack>
  );
}

export default PDFUploader;
