/**
 * Utility functions for data type conversions
 */

/**
 * Convert an ArrayBuffer to a hexadecimal string
 * @param buffer The ArrayBuffer to convert
 * @returns Hexadecimal string representation
 */
export function arrayBufferToHex(buffer: ArrayBuffer): string {
  // Create a Uint8Array from the buffer
  const bytes = new Uint8Array(buffer);
  
  // Convert each byte to a hex string and join them
  return Array.from(bytes)
    .map(byte => byte.toString(16).padStart(2, '0'))
    .join('');
}

/**
 * Convert a hexadecimal string to an ArrayBuffer
 * @param hex The hexadecimal string to convert (with or without 0x prefix)
 * @returns ArrayBuffer representation
 */
export function hexToArrayBuffer(hex: string): ArrayBuffer {
  // Remove 0x prefix if present
  const cleanHex = hex.startsWith('0x') ? hex.slice(2) : hex;
  
  // Ensure even length
  const paddedHex = cleanHex.length % 2 === 0 ? cleanHex : `0${cleanHex}`;
  
  // Create a Uint8Array from the hex string
  const bytes = new Uint8Array(paddedHex.length / 2);
  
  for (let i = 0; i < paddedHex.length; i += 2) {
    bytes[i / 2] = parseInt(paddedHex.substring(i, i + 2), 16);
  }
  
  return bytes.buffer;
}

/**
 * Convert a string to an ArrayBuffer using UTF-8 encoding
 * @param str The string to convert
 * @returns ArrayBuffer representation
 */
export function stringToArrayBuffer(str: string): ArrayBuffer {
  return new TextEncoder().encode(str).buffer;
}

/**
 * Convert an ArrayBuffer to a string using UTF-8 encoding
 * @param buffer The ArrayBuffer to convert
 * @returns String representation
 */
export function arrayBufferToString(buffer: ArrayBuffer): string {
  return new TextDecoder().decode(buffer);
}
