import React from 'react';
import { IconButton, useColorMode, Tooltip } from '@chakra-ui/react';
import { FiMoon, FiSun } from 'react-icons/fi';

interface ColorModeToggleProps {
  variant?: string;
  size?: string;
  iconSize?: string;
  showTooltip?: boolean;
}

/**
 * ColorModeToggle Component
 * 
 * A reusable toggle button for switching between light and dark modes.
 * This component uses the Chakra UI's useColorMode hook to manage the color mode state.
 */
const ColorModeToggle: React.FC<ColorModeToggleProps> = ({
  variant = 'ghost',
  size = 'md',
  iconSize = '1em',
  showTooltip = true
}) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const isLight = colorMode === 'light';
  
  const button = (
    <IconButton
      aria-label={`Switch to ${isLight ? 'dark' : 'light'} mode`}
      icon={isLight ? <FiMoon size={iconSize} /> : <FiSun size={iconSize} />}
      onClick={toggleColorMode}
      variant={variant}
      size={size}
      color={isLight ? 'integra.light.text.primary' : 'integra.dark.text.primary'}
    />
  );
  
  if (showTooltip) {
    return (
      <Tooltip label={`Switch to ${isLight ? 'dark' : 'light'} mode`} placement="bottom">
        {button}
      </Tooltip>
    );
  }
  
  return button;
};

export default ColorModeToggle;
