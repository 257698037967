/**
 * Hash Calculation Utilities
 * 
 * This module provides functions for calculating cryptographic hashes of files and strings.
 * All hash calculations are performed locally in the browser for privacy and security.
 */

import { keccak256, toUtf8Bytes } from 'ethers';

// Define progress tracking options
export interface HashCalculationOptions {
  /** Chunk size in bytes for processing large files */
  chunkSize?: number;
  
  /** Callback for tracking progress (0-100) */
  onProgress?: (progress: number) => void;
}

/**
 * Calculate Keccak256 hash of a string
 * 
 * @param input String to hash
 * @returns Hex string of the hash with 0x prefix
 */
export function calculateKeccak256String(input: string): string {
  return keccak256(toUtf8Bytes(input));
}

/**
 * Calculate Keccak256 hash of a file
 * 
 * @param file File to hash
 * @param options Hash calculation options
 * @returns Promise resolving to hex string of the hash with 0x prefix
 */
export async function calculateKeccak256File(
  file: File, 
  options: HashCalculationOptions = {}
): Promise<string> {
  const { chunkSize = 2 * 1024 * 1024, onProgress } = options; // Default 2MB chunks
  
  return new Promise<string>((resolve, reject) => {
    const fileReader = new FileReader();
    const fileSize = file.size;
    let offset = 0;
    
    // Use ethers.js to create a hash object
    let bytes = new Uint8Array(0);
    
    // Handle file read error
    fileReader.onerror = () => {
      reject(new Error('Error reading file'));
    };
    
    // Handle chunk read completion
    fileReader.onload = (e) => {
      try {
        // Get chunk data
        const chunk = new Uint8Array(e.target?.result as ArrayBuffer);
        
        // Combine with previous bytes
        const newBytes = new Uint8Array(bytes.length + chunk.length);
        newBytes.set(bytes);
        newBytes.set(chunk, bytes.length);
        bytes = newBytes;
        
        // Update offset and calculate progress
        offset += chunk.length;
        const progress = Math.min(Math.round((offset / fileSize) * 100), 100);
        
        // Report progress
        if (onProgress) {
          onProgress(progress);
        }
        
        // Continue reading if not finished
        if (offset < fileSize) {
          readNextChunk();
        } else {
          // Calculate final hash
          const hash = keccak256(bytes);
          resolve(hash);
        }
      } catch (error) {
        reject(error);
      }
    };
    
    // Read file in chunks
    const readNextChunk = () => {
      const slice = file.slice(offset, offset + chunkSize);
      fileReader.readAsArrayBuffer(slice);
    };
    
    // Start reading
    readNextChunk();
  });
}

/**
 * Calculate document hash with progress tracking
 * 
 * @param file Document file to hash
 * @param progressCallback Optional callback for tracking progress (0-1)
 * @returns Promise resolving to hex string of the hash with 0x prefix
 */
export async function calculateDocumentHash(
  file: File,
  progressCallback?: (progress: number) => void
): Promise<string> {
  try {
    // Calculate hash with progress tracking
    const hash = await calculateKeccak256File(file, {
      onProgress: progressCallback ? (progress) => progressCallback(progress / 100) : undefined
    });
    return hash;
  } catch (error) {
    console.error('Error calculating document hash:', error);
    throw new Error('Failed to calculate document hash');
  }
}

/**
 * Calculate hash of any input (string or file)
 * 
 * @param input String or File to hash
 * @param options Hash calculation options
 * @returns Promise resolving to hex string of the hash with 0x prefix
 */
export async function calculateHash(
  input: string | File,
  options: HashCalculationOptions = {}
): Promise<string> {
  if (typeof input === 'string') {
    return calculateKeccak256String(input);
  } else {
    return calculateKeccak256File(input, options);
  }
}

export default {
  calculateKeccak256String,
  calculateKeccak256File,
  calculateDocumentHash,
  calculateHash
};
