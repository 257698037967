/**
 * Next Steps Selector
 * 
 * This component provides options for the next steps after document verification,
 * such as registering, tokenizing, or communicating about the document.
 */

import React from 'react';
import {
  Box,
  Flex,
  Text,
  VStack,
  HStack,
  SimpleGrid,
  Button,
  Icon,
  useColorModeValue,
  Card,
  CardBody,
  Alert,
  AlertIcon
} from '@chakra-ui/react';
import { 
  FiUpload, 
  FiCodesandbox,
  FiChevronRight,
  FiRefreshCw
} from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

// Import services
import workflowManagerService from '../../services/WorkflowManagerService';
import workflowRoutingService from '../../services/WorkflowRoutingService';
import documentSyncService from '../../services/DocumentSyncService';

// Import stores
import { WorkflowType, useWorkflowStore } from '../../store/workflowStore';
import { usePDFStore } from '../../store/pdfStore';
import { useEnhancedAuth } from '../../providers/EnhancedPostgresAuthProvider';

// Define workflow option interface
interface WorkflowOption {
  id: string;
  title: string;
  description: string;
  icon: React.ComponentType;
  color: string;
  workflow: WorkflowType;
  requiresAuth: boolean;
}

// Define component props
interface NextStepsSelectorProps {
  onWorkflowSelect: (workflow: WorkflowType) => void;
  isAuthenticated: boolean;
  isVerified: boolean;
}

/**
 * Next Steps Selector Component
 */
const NextStepsSelector: React.FC<NextStepsSelectorProps> = ({
  onWorkflowSelect,
  isAuthenticated,
  isVerified
}) => {
  // Hooks
  const navigate = useNavigate();
  
  // Get PDF store state
  const { verificationResult, fileHash } = usePDFStore();
  
  // Get workflow store state and actions
  const { setSelectedWorkflow } = useWorkflowStore();
  
  // Get auth context
  const { userProfile, currentOrganizationId } = useEnhancedAuth();
  
  // Theme colors
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const hoverBgColor = useColorModeValue('gray.50', 'gray.700');
  const selectedBgColor = useColorModeValue('blue.50', 'blue.900');
  const selectedBorderColor = useColorModeValue('blue.500', 'blue.400');
  const textColor = useColorModeValue('gray.800', 'white');
  
  // Define workflow options based on verification status
  const workflowOptions: WorkflowOption[] = [];
  
  // Options for unverified documents
  if (!isVerified) {
    // Register option
    workflowOptions.push({
      id: 'register',
      title: 'Register Document',
      description: 'Register this document on the blockchain to create a permanent record.',
      icon: FiUpload,
      color: 'blue',
      workflow: 'register',
      requiresAuth: true
    });
    
    // Tokenize option
    workflowOptions.push({
      id: 'tokenize',
      title: 'Tokenize Document',
      description: 'Create tokens representing ownership or rights to this document.',
      icon: FiCodesandbox,
      color: 'purple',
      workflow: 'tokenize',
      requiresAuth: true
    });
  } 
  // For verified documents, we don't add any workflow options as per the request
  
  // Handle workflow selection
  const handleSelect = (workflow: WorkflowType) => {
    if (!isAuthenticated) {
      console.log("Authentication Required");
      return;
    }
    
    // For verified documents, trigger document sync workflow
    if (isVerified) {
      // Get document ID from verification result
      const documentId = verificationResult?.integra_id || verificationResult?.documents?.[0]?.integra_id;
      
      if (documentId && fileHash && userProfile?.profile_id && currentOrganizationId) {
        // Trigger document sync workflow
        documentSyncService.syncDocumentRecord(
          fileHash,
          verificationResult,
          userProfile.profile_id,
          currentOrganizationId
        )
          .then((result) => {
            console.log('Document sync workflow triggered successfully', result);
          })
          .catch((error) => {
            console.error('Error triggering document sync workflow:', error);
          });
      } else {
        console.error('Missing required data for document sync', {
          documentId,
          fileHash,
          profileId: userProfile?.profile_id,
          organizationId: currentOrganizationId
        });
      }
    }
    
    // Update the selected workflow in the store
    setSelectedWorkflow(workflow);
    
    // Call the onWorkflowSelect callback
    onWorkflowSelect(workflow);
    
    // Navigate directly to the workflow route
    const route = workflowRoutingService.getWorkflowRoute(workflow);
    console.log(`Navigating to workflow route: ${route}`);
    navigate(route);
  };
  
  return (
    <Box>
      {!isAuthenticated && (
        <Alert status="warning" mb={4} borderRadius="md">
          <AlertIcon />
          You need to log in to continue with any of these options.
        </Alert>
      )}
      
      <SimpleGrid columns={{ base: 1, md: workflowOptions.length }} spacing={4} mb={4}>
        {workflowOptions.map((option) => (
          <Card
            key={option.id}
            variant="outline"
            borderColor={borderColor}
            bg={bgColor}
            _hover={{ 
              bg: hoverBgColor,
              borderColor: option.color,
              transform: 'translateY(-2px)',
              transition: 'all 0.2s'
            }}
            cursor={isAuthenticated ? 'pointer' : 'not-allowed'}
            onClick={() => handleSelect(option.workflow)}
            opacity={isAuthenticated ? 1 : 0.7}
          >
            <CardBody>
              <Flex direction="column" height="100%">
                <Flex align="center" mb={2}>
                  <Icon 
                    as={option.icon} 
                    boxSize={5} 
                    color={`${option.color}.500`}
                    mr={2}
                  />
                  <Text as="h4" size="sm" color={textColor}>
                    {option.title}
                  </Text>
                </Flex>
                <Text fontSize="sm" color="gray.500" flex="1">
                  {option.description}
                </Text>
              </Flex>
            </CardBody>
          </Card>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default NextStepsSelector;
