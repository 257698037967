/**
 * Workflow Manager Service
 * 
 * This service manages workflow selection and progression.
 * It provides a clean interface for determining the next workflow
 * based on the current state and user actions.
 */

import { WorkflowType } from '../store/workflowStore';
import { BlockchainVerificationResult } from '../store/pdfStore';

// Define a type alias for compatibility with existing code
type VerificationResult = BlockchainVerificationResult;

/**
 * Workflow Manager Service
 */
class WorkflowManagerService {
  /**
   * Determine the next workflow based on verification result
   * 
   * @param verificationResult - The result of document verification
   * @returns The recommended next workflow
   */
  determineNextWorkflow(verificationResult: VerificationResult | null): WorkflowType {
    if (!verificationResult) {
      return 'verify';
    }
    
    if (verificationResult.verified) {
      // If the document is verified, the default next step is registration
      return 'register';
    } else {
      // If the document is not verified, the default next step is verification
      return 'verify';
    }
  }
  
  /**
   * Check if a workflow transition is valid
   * 
   * @param currentWorkflow - The current workflow
   * @param nextWorkflow - The next workflow
   * @param verificationResult - The result of document verification
   * @returns Whether the transition is valid
   */
  isValidWorkflowTransition(
    currentWorkflow: WorkflowType,
    nextWorkflow: WorkflowType,
    verificationResult: VerificationResult | null
  ): boolean {
    // If no verification result, allow verify, register, and tokenize workflows
    if (!verificationResult) {
      return ['verify', 'register', 'tokenize'].includes(nextWorkflow as string);
    }
    
    // Even if document is not verified, allow register and tokenize workflows
    // This is important for the "not found" case where users need to register the document
    if (!verificationResult.verified) {
      return ['verify', 'register', 'tokenize'].includes(nextWorkflow as string);
    }
    
    // If document is verified, all workflows are valid
    return true;
  }
  
  /**
   * Get available workflows based on verification result
   * 
   * @param verificationResult - The result of document verification
   * @returns Array of available workflows
   */
  getAvailableWorkflows(verificationResult: VerificationResult | null): WorkflowType[] {
    if (!verificationResult) {
      return ['verify', 'register', 'tokenize'] as WorkflowType[];
    }
    
    if (verificationResult.verified) {
      // If the document is verified, all workflows are available
      return ['verify', 'register', 'tokenize', 'communicate'] as WorkflowType[];
    } else {
      // If the document is not verified, allow verify, register, and tokenize workflows
      return ['verify', 'register', 'tokenize'] as WorkflowType[];
    }
  }
  
  /**
   * Check if a workflow requires authentication
   * 
   * @param workflow - The workflow to check
   * @returns Whether the workflow requires authentication
   */
  requiresAuthentication(workflow: WorkflowType): boolean {
    // All workflows except verify require authentication
    return workflow !== 'verify' && workflow !== null;
  }
  
  /**
   * Get workflow display information
   * 
   * @param workflow - The workflow to get information for
   * @returns Display information for the workflow
   */
  getWorkflowInfo(workflow: WorkflowType): {
    title: string;
    description: string;
    requiresAuth: boolean;
  } {
    switch (workflow) {
      case 'verify':
        return {
          title: 'Verify Document',
          description: 'Check if this document exists on the blockchain',
          requiresAuth: false
        };
      case 'register':
        return {
          title: 'Register Document',
          description: 'Create a new registration record for this document',
          requiresAuth: true
        };
      case 'tokenize':
        return {
          title: 'Tokenize Document',
          description: 'Create tokens associated with this document',
          requiresAuth: true
        };
      case 'communicate':
        return {
          title: 'Communicate',
          description: 'Send this document to others',
          requiresAuth: true
        };
      default:
        return {
          title: 'Unknown Workflow',
          description: 'Unknown workflow',
          requiresAuth: true
        };
    }
  }
}

// Create and export a singleton instance
const workflowManagerService = new WorkflowManagerService();
export default workflowManagerService;
