import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  Input,
  InputGroup,
  InputLeftElement,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Link,
  useColorModeValue,
  Skeleton,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  HStack,
  VStack,
  useDisclosure,
  CloseButton
} from '@chakra-ui/react';
import { FiSearch, FiMoreVertical, FiDownload, FiTrash2, FiEye, FiRefreshCw, FiPlus } from 'react-icons/fi';
import { useEnhancedAuth } from '../providers/EnhancedPostgresAuthProvider';
import { useDocuments, Document } from '../hooks/useDocuments';
import LoadingSpinner from '../components/LoadingSpinner';
import DocumentDrawer from '../components/DocumentDrawer';
import StandardPageLayout from '../components/StandardPageLayout';

// Status badge color mapping
const statusColorMap: Record<string, string> = {
  pending: 'yellow',
  registered: 'green',
  verified: 'blue',
  failed: 'red',
};

/**
 * Documents Page Component
 * 
 * Displays a list of documents for the current organization
 */
const Documents: React.FC = () => {
  const { userProfile, currentOrganizationId } = useEnhancedAuth();
  const { documents, loading, error, refreshDocuments, deleteDocument } = useDocuments();
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredDocuments, setFilteredDocuments] = useState<Document[]>([]);
  const [isDeleting, setIsDeleting] = useState<Record<string, boolean>>({});
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  
  // Colors
  const tableBgColor = useColorModeValue('integra.light.surface', 'integra.dark.surface');
  const tableHeaderBgColor = useColorModeValue('integra.light.surface.secondary', 'integra.dark.surface.secondary');
  const hoverBgColor = useColorModeValue('integra.light.hover', 'integra.dark.hover');
  const textColor = useColorModeValue('integra.light.text.primary', 'integra.dark.text.primary');
  const borderColor = useColorModeValue('integra.light.border', 'integra.dark.border');
  
  // Filter documents based on search query
  useEffect(() => {
    console.log('Documents state:', {
      documentsCount: documents?.length || 0,
      documents,
      searchQuery,
      loading,
      error: error?.message
    });
    
    if (!documents || documents.length === 0) {
      // If we have an error and no documents, keep the previous filtered documents
      // This prevents the UI from suddenly showing no documents when an API call fails
      if (error && filteredDocuments.length > 0) {
        console.log('API error occurred but keeping existing filtered documents', {
          errorMessage: error.message,
          filteredCount: filteredDocuments.length
        });
        return;
      }
      setFilteredDocuments([]);
      return;
    }
    
    if (!searchQuery.trim()) {
      setFilteredDocuments(documents);
      return;
    }
    
    const query = searchQuery.toLowerCase();
    const filtered = documents.filter(doc => 
      (doc.document_name || '').toLowerCase().includes(query) ||
      (doc.integra_id || '').toLowerCase().includes(query) ||
      (doc.document_hash || '').toLowerCase().includes(query) ||
      (doc.document_type || '').toLowerCase().includes(query)
    );
    
    console.log('Filtered documents:', {
      filteredCount: filtered.length,
      query
    });
    
    setFilteredDocuments(filtered);
  }, [documents, searchQuery]);
  
  // Handle document deletion
  const handleDeleteDocument = async (documentId: string) => {
    setIsDeleting(prev => ({ ...prev, [documentId]: true }));
    
    try {
      const success = await deleteDocument(documentId);
      
      if (success) {
        console.log("Document deleted successfully");
      } else {
        throw new Error("Failed to delete document");
      }
    } catch (error) {
      console.error("Error deleting document:", error instanceof Error ? error.message : "Unknown error");
    } finally {
      setIsDeleting(prev => ({ ...prev, [documentId]: false }));
    }
  };
  
  // Handle opening document details
  const handleViewDocument = (document: Document) => {
    setSelectedDocument(document);
    onOpen();
  };
  
  // Format date for display
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };
  
  return (
    <StandardPageLayout>
      <Flex justify="space-between" align="center" mb={6}>
        <Heading size="lg" color={textColor}>My Documents</Heading>
        
        <HStack spacing={3}>
          <Button
            leftIcon={<FiRefreshCw />}
            onClick={refreshDocuments}
            isLoading={loading}
            colorScheme="blue"
            variant="outline"
            size="sm"
          >
            Refresh
          </Button>
          <Button
            as={RouterLink}
            to="/register"
            leftIcon={<FiPlus />}
            colorScheme="blue"
            size="sm"
          >
            New Document
          </Button>
        </HStack>
      </Flex>
      
      {error && (
        <Alert status="error" mb={4} borderRadius="md">
          <AlertIcon />
          <Box flex="1">
            <AlertTitle>Error loading documents</AlertTitle>
            <AlertDescription display="block">
              {error.message}
              {filteredDocuments.length > 0 && (
                <Text mt={1}>Showing previously loaded documents. Click Refresh to try again.</Text>
              )}
            </AlertDescription>
          </Box>
          <CloseButton position="absolute" right="8px" top="8px" />
        </Alert>
      )}
      
      {/* Search and filter */}
      <Box mb={6}>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <FiSearch color="gray.300" />
          </InputLeftElement>
          <Input
            placeholder="Search documents..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            bg={tableBgColor}
            borderColor={borderColor}
          />
        </InputGroup>
      </Box>
      
      {/* Loading state */}
      {loading && !error && (
        <VStack spacing={4} align="stretch" mb={6}>
          {[...Array(5)].map((_, i) => (
            <Skeleton key={i} height="60px" borderRadius="md" />
          ))}
        </VStack>
      )}
      
      {/* No documents state */}
      {!loading && !error && filteredDocuments.length === 0 && (
        <Box 
          p={8} 
          textAlign="center" 
          borderRadius="md" 
          borderWidth="1px"
          borderColor={borderColor}
          bg={tableBgColor}
        >
          <Text mb={4} fontSize="lg">No documents found</Text>
          <Button
            as={RouterLink}
            to="/register"
            colorScheme="blue"
          >
            Register Your First Document
          </Button>
        </Box>
      )}
      
      {/* Documents table */}
      {!loading && !error && filteredDocuments.length > 0 && (
        <Box 
          borderRadius="md" 
          borderWidth="1px" 
          borderColor={borderColor}
          overflow="hidden"
        >
          <Table variant="simple">
            <Thead bg={tableHeaderBgColor}>
              <Tr>
                <Th>Document Name</Th>
                <Th>Integra Hash</Th>
                <Th>Type</Th>
                <Th>Status</Th>
                <Th>Date</Th>
              </Tr>
            </Thead>
            <Tbody bg={tableBgColor}>
              {filteredDocuments.map((doc) => (
                <Tr 
                  key={doc.document_id} 
                  _hover={{ bg: hoverBgColor }}
                  cursor="pointer"
                  onClick={() => handleViewDocument(doc)}
                >
                  <Td>{doc.document_name}</Td>
                  <Td>
                    <Text 
                      maxW="150px" 
                      overflow="hidden" 
                      textOverflow="ellipsis" 
                      whiteSpace="nowrap"
                    >
                      {doc.integra_id}
                    </Text>
                  </Td>
                  <Td>{doc.document_type}</Td>
                  <Td>
                    <Badge colorScheme={statusColorMap[doc.status || 'unknown'] || 'gray'}>
                      {doc.status || 'unknown'}
                    </Badge>
                  </Td>
                  <Td>{formatDate(doc.created_at)}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
      
      {/* Document details drawer */}
      {selectedDocument && (
        <DocumentDrawer
          isOpen={isOpen}
          onClose={onClose}
          document={selectedDocument}
          onDelete={() => {
            handleDeleteDocument(selectedDocument.document_id);
            onClose();
          }}
        />
      )}
    </StandardPageLayout>
  );
};

export default Documents;
