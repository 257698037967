/**
 * PDFMetadataViewer Component
 * 
 * A component for viewing PDF metadata:
 * - Displays standard and custom metadata
 * - Supports copying values
 * - Provides a clean, organized interface
 * 
 * This component is part of the V3 architecture that separates concerns
 * and improves maintainability.
 */

import React, { useState } from 'react';
import {
  Box,
  VStack,
  Text,
  Heading,
  Divider,
  Grid,
  GridItem,
  Badge,
  useToast,
  IconButton,
  Tooltip,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon
} from '@chakra-ui/react';
import { CopyIcon, CheckIcon } from '@chakra-ui/icons';
import { PDFMetadata } from '../services/PDFService';

// PDF metadata viewer props interface
export interface PDFMetadataViewerProps {
  /** PDF metadata to display */
  metadata: PDFMetadata | null;
  
  /** Whether to show all metadata fields */
  showAllFields?: boolean;
  
  /** Whether to show empty fields */
  showEmptyFields?: boolean;
  
  /** Title for the metadata section */
  title?: string;
}

/**
 * Helper function to format file size
 * @param bytes File size in bytes
 * @returns Formatted file size string
 */
function formatFileSize(bytes: number | undefined): string {
  if (bytes === undefined) return 'Unknown';
  
  const units = ['B', 'KB', 'MB', 'GB'];
  let size = bytes;
  let unitIndex = 0;
  
  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }
  
  return `${size.toFixed(2)} ${units[unitIndex]}`;
}

/**
 * Helper function to format date string
 * @param dateStr Date string
 * @returns Formatted date string
 */
function formatDate(dateStr: string | undefined): string {
  if (!dateStr) return 'Unknown';
  
  try {
    // Try to parse PDF date format (D:YYYYMMDDHHmmSSOHH'mm')
    if (dateStr.startsWith('D:')) {
      const year = dateStr.substring(2, 6);
      const month = dateStr.substring(6, 8);
      const day = dateStr.substring(8, 10);
      const hour = dateStr.substring(10, 12);
      const minute = dateStr.substring(12, 14);
      
      return `${year}-${month}-${day} ${hour}:${minute}`;
    }
    
    // Try to parse as ISO date
    const date = new Date(dateStr);
    return date.toLocaleString();
  } catch (error) {
    return dateStr;
  }
}

/**
 * Helper function to truncate text
 * @param text Text to truncate
 * @param maxLength Maximum length
 * @returns Truncated text
 */
function truncateText(text: string, maxLength: number): string {
  if (text.length <= maxLength) return text;
  return `${text.substring(0, maxLength)}...`;
}

/**
 * PDF Metadata Viewer Component
 */
export function PDFMetadataViewer({
  metadata,
  showAllFields = false,
  showEmptyFields = false,
  title = 'Document Metadata'
}: PDFMetadataViewerProps) {
  // State for copied fields
  const [copiedField, setCopiedField] = useState<string | null>(null);
  
  // Toast for notifications
  const toast = useToast();
  
  /**
   * Copy value to clipboard
   * @param value Value to copy
   * @param fieldName Field name for notification
   */
  const copyToClipboard = (value: string, fieldName: string) => {
    navigator.clipboard.writeText(value);
    setCopiedField(fieldName);
    
    toast({
      title: 'Copied to clipboard',
      description: `${fieldName} has been copied`,
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
    
    setTimeout(() => {
      setCopiedField(null);
    }, 2000);
  };
  
  // If no metadata is provided, show a message
  if (!metadata) {
    return (
      <Box p={4} bg="gray.50" borderRadius="md">
        <Text color="gray.500">No metadata available</Text>
      </Box>
    );
  }
  
  // Define metadata fields to display
  const standardFields = [
    { key: 'documentName', label: 'Document Name' },
    { key: 'documentType', label: 'Document Type' },
    { key: 'author', label: 'Author' },
    { key: 'creationDate', label: 'Creation Date', format: formatDate },
    { key: 'modificationDate', label: 'Modification Date', format: formatDate },
    { key: 'subject', label: 'Subject' },
    { key: 'pageCount', label: 'Page Count' },
    { key: 'fileSize', label: 'File Size', format: formatFileSize },
    { key: 'fileName', label: 'File Name' },
    { key: 'fileType', label: 'File Type' },
  ];
  
  const integraFields = [
    { key: 'integraId', label: 'Integra ID' },
    { key: 'fileHash', label: 'Document Hash' },
    { key: 'organizationId', label: 'Organization ID' },
    { key: 'createdBy', label: 'Created By' },
  ];
  
  // Filter fields based on props
  const filteredStandardFields = standardFields.filter(field => 
    showAllFields || 
    (showEmptyFields || metadata[field.key] !== undefined && metadata[field.key] !== null && metadata[field.key] !== '')
  );
  
  const filteredIntegraFields = integraFields.filter(field => 
    showAllFields || 
    (showEmptyFields || metadata[field.key] !== undefined && metadata[field.key] !== null && metadata[field.key] !== '')
  );
  
  // Check if we have any custom metadata
  const hasCustomMetadata = Object.keys(metadata).some(key => 
    !standardFields.some(field => field.key === key) && 
    !integraFields.some(field => field.key === key) &&
    key !== 'keywords' &&
    key !== 'integraData' &&
    key !== 'integraXMP'
  );
  
  // Check if we have Integra data
  const hasIntegraData = metadata.integraData !== undefined && metadata.integraData !== null;

  return (
    <VStack spacing={4} align="stretch" width="100%">
      {/* Title */}
      <Heading size="md">{title}</Heading>
      <Divider />
      
      {/* Standard Metadata */}
      <Box>
        <Heading size="sm" mb={2}>Standard Metadata</Heading>
        <Grid templateColumns="1fr 2fr" gap={2}>
          {filteredStandardFields.map(field => {
            const value = field.format 
              ? field.format(metadata[field.key] as any) 
              : metadata[field.key]?.toString() || '';
            
            return (
              <React.Fragment key={field.key}>
                <GridItem>
                  <Text fontWeight="medium" color="gray.600">
                    {field.label}
                  </Text>
                </GridItem>
                <GridItem>
                  <Flex align="center">
                    <Text isTruncated title={value}>
                      {value || '-'}
                    </Text>
                    {value && (
                      <Tooltip label="Copy to clipboard">
                        <IconButton
                          aria-label={`Copy ${field.label}`}
                          icon={copiedField === field.key ? <CheckIcon /> : <CopyIcon />}
                          size="xs"
                          variant="ghost"
                          colorScheme={copiedField === field.key ? 'green' : 'gray'}
                          ml={1}
                          onClick={() => copyToClipboard(value, field.label)}
                        />
                      </Tooltip>
                    )}
                  </Flex>
                </GridItem>
              </React.Fragment>
            );
          })}
          
          {/* Keywords */}
          {(showAllFields || (metadata.keywords && metadata.keywords.length > 0)) && (
            <>
              <GridItem>
                <Text fontWeight="medium" color="gray.600">
                  Keywords
                </Text>
              </GridItem>
              <GridItem>
                <Flex wrap="wrap" gap={1}>
                  {metadata.keywords && metadata.keywords.length > 0 ? (
                    metadata.keywords.map((keyword, index) => (
                      <Badge key={index} colorScheme="blue">
                        {keyword}
                      </Badge>
                    ))
                  ) : (
                    <Text>-</Text>
                  )}
                </Flex>
              </GridItem>
            </>
          )}
        </Grid>
      </Box>
      
      {/* Integra Metadata */}
      {filteredIntegraFields.length > 0 && (
        <Box>
          <Heading size="sm" mb={2}>Integra Metadata</Heading>
          <Grid templateColumns="1fr 2fr" gap={2}>
            {filteredIntegraFields.map(field => {
              const value = metadata[field.key]?.toString() || '';
              const displayValue = field.key === 'fileHash' || field.key === 'integraId'
                ? truncateText(value, 20)
                : value;
              
              return (
                <React.Fragment key={field.key}>
                  <GridItem>
                    <Text fontWeight="medium" color="gray.600">
                      {field.label}
                    </Text>
                  </GridItem>
                  <GridItem>
                    <Flex align="center">
                      <Text isTruncated title={value}>
                        {displayValue || '-'}
                      </Text>
                      {value && (
                        <Tooltip label="Copy to clipboard">
                          <IconButton
                            aria-label={`Copy ${field.label}`}
                            icon={copiedField === field.key ? <CheckIcon /> : <CopyIcon />}
                            size="xs"
                            variant="ghost"
                            colorScheme={copiedField === field.key ? 'green' : 'gray'}
                            ml={1}
                            onClick={() => copyToClipboard(value, field.label)}
                          />
                        </Tooltip>
                      )}
                    </Flex>
                  </GridItem>
                </React.Fragment>
              );
            })}
          </Grid>
        </Box>
      )}
      
      {/* Custom Metadata */}
      {hasCustomMetadata && (
        <Box>
          <Heading size="sm" mb={2}>Custom Metadata</Heading>
          <Grid templateColumns="1fr 2fr" gap={2}>
            {Object.entries(metadata)
              .filter(([key]) => 
                !standardFields.some(field => field.key === key) && 
                !integraFields.some(field => field.key === key) &&
                key !== 'keywords' &&
                key !== 'integraData' &&
                key !== 'integraXMP'
              )
              .map(([key, value]) => {
                const displayValue = value?.toString() || '';
                
                return (
                  <React.Fragment key={key}>
                    <GridItem>
                      <Text fontWeight="medium" color="gray.600">
                        {key}
                      </Text>
                    </GridItem>
                    <GridItem>
                      <Flex align="center">
                        <Text isTruncated title={displayValue}>
                          {displayValue || '-'}
                        </Text>
                        {displayValue && (
                          <Tooltip label="Copy to clipboard">
                            <IconButton
                              aria-label={`Copy ${key}`}
                              icon={copiedField === key ? <CheckIcon /> : <CopyIcon />}
                              size="xs"
                              variant="ghost"
                              colorScheme={copiedField === key ? 'green' : 'gray'}
                              ml={1}
                              onClick={() => copyToClipboard(displayValue, key)}
                            />
                          </Tooltip>
                        )}
                      </Flex>
                    </GridItem>
                  </React.Fragment>
                );
              })}
          </Grid>
        </Box>
      )}
      
      {/* Integra Data (JSON) */}
      {hasIntegraData && (
        <Accordion allowToggle>
          <AccordionItem border="none">
            <AccordionButton px={0}>
              <Box flex="1" textAlign="left">
                <Heading size="sm">Integra JSON Data</Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4} px={0}>
              <Box 
                bg="gray.50" 
                p={3} 
                borderRadius="md" 
                fontFamily="mono" 
                fontSize="sm"
                overflow="auto"
                maxHeight="300px"
              >
                <pre>
                  {JSON.stringify(metadata.integraData, null, 2)}
                </pre>
              </Box>
              <Flex justify="flex-end" mt={2}>
                <Tooltip label="Copy JSON data">
                  <IconButton
                    aria-label="Copy JSON data"
                    icon={copiedField === 'integraData' ? <CheckIcon /> : <CopyIcon />}
                    size="sm"
                    colorScheme={copiedField === 'integraData' ? 'green' : 'blue'}
                    onClick={() => copyToClipboard(
                      JSON.stringify(metadata.integraData, null, 2),
                      'Integra JSON Data'
                    )}
                  />
                </Tooltip>
              </Flex>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
    </VStack>
  );
}

export default PDFMetadataViewer;
