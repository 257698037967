import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useEnhancedAuth } from '../providers/EnhancedPostgresAuthProvider';

// Get API URL from environment variables, ensure it doesn't end with a slash
const API_URL = import.meta.env.VITE_API_URL ? import.meta.env.VITE_API_URL.replace(/\/$/, '') : '';

// Log the API URL being used
console.log('Documents API URL:', API_URL);

/**
 * Document Interface
 */
export interface Document {
  document_id: string;
  integra_id: string;
  document_hash: string;
  document_name?: string;
  document_type?: string;
  status?: string;
  created_at: string;
  updated_at?: string;
  organization_id: string;
  user_id: string;
  blockchain_tx?: string;
  block_number?: number;
  tx_timestamp?: string;
  reference_id?: string;
  document_url?: string;
  metadata?: Record<string, any>;
  workflow_id?: string;
}

/**
 * Documents Hook Result
 */
export interface UseDocumentsResult {
  documents: Document[];
  loading: boolean;
  error: Error | null;
  refreshDocuments: () => Promise<void>;
  getDocumentById: (id: string) => Promise<Document | null>;
  deleteDocument: (id: string) => Promise<boolean>;
  searchDocuments: (query: string) => Promise<Document[]>;
}

/**
 * useDocuments Hook
 * 
 * @returns Hook result with documents, loading state, error, and refresh function
 */
export function useDocuments(): UseDocumentsResult {
  const { userProfile, currentOrganizationId, loading: authLoading } = useEnhancedAuth();
  const [documents, setDocuments] = useState<Document[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  /**
   * Fetch documents
   */
  const fetchDocuments = useCallback(async () => {
    if (authLoading || !userProfile || !currentOrganizationId) {
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      console.log('Fetching documents for:', { 
        profileId: userProfile.profile_id, 
        organizationId: currentOrganizationId 
      });
      
      const response = await axios.get(`${API_URL}/api/documents`, {
        headers: {
          'x-profile-id': userProfile.profile_id,
          'x-organization-id': currentOrganizationId
        },
        params: {
          limit: 100,
          offset: 0
        },
        timeout: 10000 // 10 second timeout
      });
      
      console.log('Documents API response:', {
        status: response.status,
        statusText: response.statusText,
        dataType: typeof response.data,
        isArray: Array.isArray(response.data),
        dataLength: Array.isArray(response.data) ? response.data.length : 'N/A',
        data: response.data
      });
      
      // Check if the response data is nested in a 'documents' property
      if (response.data && typeof response.data === 'object' && response.data.documents) {
        console.log('Found nested documents array, using response.data.documents');
        setDocuments(response.data.documents);
      } else if (Array.isArray(response.data)) {
        console.log('Using direct array from response.data');
        setDocuments(response.data);
      } else {
        console.warn('Unexpected response format, setting empty documents array');
        setDocuments([]);
      }
    } catch (err) {
      console.error('Error fetching documents:', err);
      setError(err instanceof Error ? err : new Error(String(err)));
      setDocuments([]);
    } finally {
      setLoading(false);
    }
  }, [userProfile, currentOrganizationId, authLoading]);

  /**
   * Refresh documents
   */
  const refreshDocuments = useCallback(async () => {
    await fetchDocuments();
  }, [fetchDocuments]);

  /**
   * Get document by ID
   * 
   * @param id Document ID
   * @returns Promise resolving to a document or null if not found
   */
  const getDocumentById = useCallback(async (id: string): Promise<Document | null> => {
    if (!userProfile || !currentOrganizationId) {
      return null;
    }
    
    try {
      console.log(`Fetching document with ID: ${id}`);
      
      const response = await axios.get(`${API_URL}/api/documents/${id}`, {
        headers: {
          'x-profile-id': userProfile.profile_id,
          'x-organization-id': currentOrganizationId
        },
        timeout: 10000 // 10 second timeout
      });
      
      console.log(`Document API response for ID ${id}:`, {
        status: response.status,
        statusText: response.statusText,
        dataType: typeof response.data,
        data: response.data
      });
      
      // Check if the response data is nested in a 'document' property
      if (response.data && typeof response.data === 'object' && response.data.document) {
        console.log('Found nested document object, using response.data.document');
        return response.data.document;
      } else if (response.data && typeof response.data === 'object' && response.data.document_id) {
        console.log('Using direct document object from response.data');
        return response.data;
      } else {
        console.warn('Unexpected response format, returning null');
        return null;
      }
    } catch (err) {
      console.error(`Error fetching document with ID ${id}:`, err);
      throw err instanceof Error ? err : new Error(String(err));
    }
  }, [userProfile, currentOrganizationId]);

  /**
   * Delete a document
   * 
   * @param id Document ID
   * @returns Promise resolving to a boolean indicating success
   */
  const deleteDocument = useCallback(async (id: string): Promise<boolean> => {
    if (!userProfile || !currentOrganizationId) {
      return false;
    }
    
    try {
      console.log(`Deleting document with ID: ${id}`);
      
      const response = await axios.delete(`${API_URL}/api/documents/${id}`, {
        headers: {
          'x-profile-id': userProfile.profile_id,
          'x-organization-id': currentOrganizationId
        },
        timeout: 10000 // 10 second timeout
      });
      
      console.log(`Delete document API response for ID ${id}:`, {
        status: response.status,
        statusText: response.statusText,
        dataType: typeof response.data,
        data: response.data
      });
      
      // Update the local state after successful deletion
      setDocuments(prev => prev.filter(doc => doc.document_id !== id));
      
      return true;
    } catch (err) {
      console.error(`Error deleting document with ID ${id}:`, err);
      setError(err instanceof Error ? err : new Error(String(err)));
      return false;
    }
  }, [userProfile, currentOrganizationId]);

  /**
   * Search documents
   * 
   * @param query Search query
   * @returns Promise resolving to an array of documents
   */
  const searchDocuments = useCallback(async (query: string): Promise<Document[]> => {
    if (!userProfile || !currentOrganizationId) {
      return [];
    }
    
    try {
      console.log(`Searching documents with query: ${query}`);
      
      const response = await axios.get(`${API_URL}/api/documents/search`, {
        headers: {
          'x-profile-id': userProfile.profile_id,
          'x-organization-id': currentOrganizationId
        },
        params: {
          query
        },
        timeout: 10000 // 10 second timeout
      });
      
      console.log('Search documents API response:', {
        status: response.status,
        statusText: response.statusText,
        dataType: typeof response.data,
        isArray: Array.isArray(response.data),
        dataLength: Array.isArray(response.data) ? response.data.length : 'N/A',
        data: response.data
      });
      
      // Check if the response data is nested in a 'documents' property
      if (response.data && typeof response.data === 'object' && response.data.documents) {
        console.log('Found nested documents array, using response.data.documents');
        return response.data.documents;
      } else if (Array.isArray(response.data)) {
        console.log('Using direct array from response.data');
        return response.data;
      } else {
        console.warn('Unexpected response format, returning empty array');
        return [];
      }
    } catch (err) {
      console.error(`Error searching documents with query "${query}":`, err);
      setError(err instanceof Error ? err : new Error(String(err)));
      return [];
    }
  }, [userProfile, currentOrganizationId]);

  // Fetch documents on mount and when dependencies change
  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  return {
    documents,
    loading,
    error,
    refreshDocuments,
    getDocumentById,
    deleteDocument,
    searchDocuments
  };
}

export default useDocuments;
