/**
 * QRCodeService
 * 
 * A service for generating QR codes with various options:
 * - Basic QR code generation
 * - QR codes with embedded logos
 * - Customizable QR code options
 * 
 * This service is part of the V3 architecture that separates concerns
 * and improves maintainability.
 */

import QRCode from 'qrcode';

// QR code error correction levels
export type ErrorCorrectionLevel = 'L' | 'M' | 'Q' | 'H';

// QR code configuration interface
export interface QRCodeConfig {
  /** URL or data to encode in the QR code */
  data: string;
  
  /** Size of the QR code in pixels */
  size?: number;
  
  /** Logo URL or image data to embed in the center */
  logo?: string | Uint8Array | null;
  
  /** Scale of the logo relative to the QR code size */
  logoScale?: number;
  
  /** Error correction level (L: 7%, M: 15%, Q: 25%, H: 30%) */
  errorCorrection?: ErrorCorrectionLevel;
  
  /** Background color of the QR code */
  backgroundColor?: string;
  
  /** Foreground color of the QR code */
  foregroundColor?: string;
  
  /** Margin around the QR code */
  margin?: number;
}

/**
 * QR Code Service class for generating QR codes
 */
export class QRCodeService {
  /**
   * Generate a QR code as a data URL with an embedded logo
   * @param config QR code configuration
   * @returns Promise resolving to the QR code as data URL
   */
  async generateQRCodeWithLogo({
    data,
    size = 200,
    logo = null,
    logoScale = 0.2,
    errorCorrection = 'H',
    backgroundColor = '#FFFFFF',
    foregroundColor = '#000000',
    margin = 1,
  }: QRCodeConfig): Promise<string> {
    // Generate the QR code as a data URL
    const qrDataURL = await QRCode.toDataURL(data, {
      width: size,
      margin,
      errorCorrectionLevel: errorCorrection,
      color: {
        dark: foregroundColor,
        light: backgroundColor
      }
    });

    // If no logo is provided, return the QR code as is
    if (!logo) {
      return qrDataURL;
    }

    // Create a canvas to manipulate the QR code
    const canvas = document.createElement('canvas');
    canvas.width = size;
    canvas.height = size;
    const ctx = canvas.getContext('2d');
    
    if (!ctx) {
      throw new Error('Could not get canvas context');
    }

    // Load the QR code onto the canvas
    const qrImage = new Image();
    await new Promise<void>((resolve, reject) => {
      qrImage.onload = () => resolve();
      qrImage.onerror = () => reject(new Error('Failed to load QR code image'));
      qrImage.src = qrDataURL;
    });
    ctx.drawImage(qrImage, 0, 0, size, size);

    // Load and draw the logo
    const logoImage = new Image();
    await new Promise<void>((resolve, reject) => {
      logoImage.onload = () => resolve();
      logoImage.onerror = () => reject(new Error('Failed to load logo image'));
      
      // Set the source of the logo image
      if (typeof logo === 'string') {
        logoImage.src = logo;
      } else {
        // Convert Uint8Array to data URL
        const blob = new Blob([logo], { type: 'image/png' });
        logoImage.src = URL.createObjectURL(blob);
      }
    });

    // Calculate logo position and size
    const logoSize = size * logoScale;
    const logoX = (size - logoSize) / 2;
    const logoY = (size - logoSize) / 2;
    
    // Draw a white background for the logo
    ctx.fillStyle = backgroundColor;
    ctx.fillRect(logoX, logoY, logoSize, logoSize);
    
    // Draw the logo
    ctx.drawImage(logoImage, logoX, logoY, logoSize, logoSize);

    // Convert canvas to data URL
    return canvas.toDataURL('image/png');
  }

  /**
   * Generate a QR code as a data URL
   * @param config QR code configuration
   * @returns Promise resolving to the QR code as data URL
   */
  async generateQRCodeDataURL({
    data,
    size = 200,
    errorCorrection = 'H',
    backgroundColor = '#FFFFFF',
    foregroundColor = '#000000',
    margin = 1,
  }: Omit<QRCodeConfig, 'logo' | 'logoScale'>): Promise<string> {
    return await QRCode.toDataURL(data, {
      width: size,
      margin,
      errorCorrectionLevel: errorCorrection,
      color: {
        dark: foregroundColor,
        light: backgroundColor
      }
    });
  }

  /**
   * Generate a QR code and render it to a canvas element
   * @param canvas Canvas element to render to
   * @param config QR code configuration
   */
  async renderQRCodeToCanvas(
    canvas: HTMLCanvasElement,
    {
      data,
      size = 200,
      errorCorrection = 'H',
      backgroundColor = '#FFFFFF',
      foregroundColor = '#000000',
      margin = 1,
    }: Omit<QRCodeConfig, 'logo' | 'logoScale'>
  ): Promise<void> {
    await QRCode.toCanvas(canvas, data, {
      width: size,
      margin,
      errorCorrectionLevel: errorCorrection,
      color: {
        dark: foregroundColor,
        light: backgroundColor
      }
    });
  }
}

// Create a singleton instance for use throughout the application
const qrCodeService = new QRCodeService();
export default qrCodeService;
