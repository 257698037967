import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Heading,
  Text,
  Button,
  VStack,
  HStack,
  Divider,
  Flex,
  Badge,
  Link,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Stack,
  StackDivider,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Spinner,
  Center,
  useToast,
  Code,
  List,
  ListItem,
  useColorModeValue,
} from "@chakra-ui/react";
import { ExternalLinkIcon, CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import { useEnhancedAuth } from '../providers/EnhancedPostgresAuthProvider';
import LoadingSpinner from '../components/LoadingSpinner';
import MainLayout from '../components/Layout/MainLayout';

// Define proper types for document data
interface DocumentData {
  document_id: string;
  document_name?: string;
  document_url?: string;
  document_hash?: string;
  blockchain_tx?: string;
  organization_id?: string;
  user_id?: string;
  created_at?: string;
  integra_id?: string;
  status?: string;
  metadata?: Record<string, any>;
}

/**
 * QrVerify Component
 * 
 * This component handles the verification of documents via QR code.
 * It extracts the document ID from the URL parameters and verifies
 * the document against the blockchain.
 */
const QrVerify: React.FC = () => {
  const { integraId } = useParams<{ integraId: string }>();
  const navigate = useNavigate();
  const toast = useToast();
  const { userProfile } = useEnhancedAuth();
  
  // State
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [document, setDocument] = useState<DocumentData | null>(null);
  const [verifying, setVerifying] = useState(false);
  const [verificationResult, setVerificationResult] = useState<any>(null);
  
  // Colors
  const cardBgColor = useColorModeValue('integra.light.surface', 'integra.dark.surface');
  const borderColor = useColorModeValue('integra.light.border', 'integra.dark.border');
  const textColor = useColorModeValue('integra.light.text.primary', 'integra.dark.text.primary');
  
  // Fetch document data
  useEffect(() => {
    const fetchDocument = async () => {
      if (!integraId) {
        setError('No document ID provided');
        setLoading(false);
        return;
      }
      
      try {
        // In a real implementation, this would call your API
        const response = await fetch(`${import.meta.env.VITE_API_URL}/api/documents/integra/${integraId}`);
        
        if (!response.ok) {
          throw new Error('Document not found');
        }
        
        const data = await response.json();
        setDocument(data);
      } catch (err) {
        console.error('Error fetching document:', err);
        setError('Document not found or could not be loaded');
        
        toast({
          title: 'Error',
          description: 'Failed to load document information',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };
    
    fetchDocument();
  }, [integraId, toast]);
  
  // Verify document against blockchain
  const verifyDocumentOnChain = useCallback(async () => {
    if (!document) return;
    
    setVerifying(true);
    setError(null);
    
    try {
      // In a real implementation, this would call your blockchain verification API
      const response = await fetch(`${import.meta.env.VITE_API_URL}/api/verify-document`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          document_id: document.document_id,
          document_hash: document.document_hash,
        }),
      });
      
      if (!response.ok) {
        throw new Error('Verification failed');
      }
      
      const result = await response.json();
      setVerificationResult(result);
      
      toast({
        title: 'Verification complete',
        description: result.verified ? 'Document verified successfully!' : 'Document verification failed.',
        status: result.verified ? 'success' : 'error',
        duration: 5000,
        isClosable: true,
      });
    } catch (err) {
      console.error('Error verifying document:', err);
      setError('Failed to verify document. Please try again later.');
      
      toast({
        title: 'Verification error',
        description: 'An error occurred during verification.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setVerifying(false);
    }
  }, [document, toast]);
  
  // Handle document download
  const handleDownload = useCallback(() => {
    if (!document?.document_url) {
      toast({
        title: 'Download error',
        description: 'Document URL is not available',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    
    window.open(document.document_url, '_blank');
  }, [document, toast]);
  
  // Loading state
  if (loading) {
    return (
      <MainLayout>
        <Box minH="100vh" display="flex" alignItems="center" justifyContent="center">
          <LoadingSpinner text="Loading document information..." />
        </Box>
      </MainLayout>
    );
  }
  
  // Error state
  if (error || !document) {
    return (
      <MainLayout>
        <Container maxW="container.md" pt="6" pb="8" color={textColor}>
          <Alert status="error" borderRadius="md" mb={6}>
            <AlertIcon />
            <AlertTitle>Error!</AlertTitle>
            <AlertDescription>{error || 'Document not found'}</AlertDescription>
          </Alert>
          
          <Button onClick={() => navigate('/')} colorScheme="blue">
            Return to Home
          </Button>
        </Container>
      </MainLayout>
    );
  }
  
  return (
    <MainLayout>
      <Container maxW="container.md" pt="6" pb="8" color={textColor}>
        <Heading size="lg" mb={4}>Document Verification</Heading>
        
        <Card bg={cardBgColor} shadow="md" mb={6} borderColor={borderColor} borderWidth="1px">
          <CardHeader>
            <Heading size="md">Document Information</Heading>
          </CardHeader>
          <CardBody>
            <Stack divider={<StackDivider borderColor={borderColor} />} spacing="4">
              <Box>
                <Heading size="xs" textTransform="uppercase">Document Name</Heading>
                <Text pt="2">{document.document_name || 'Unnamed Document'}</Text>
              </Box>
              <Box>
                <Heading size="xs" textTransform="uppercase">Document ID</Heading>
                <Text pt="2" fontFamily="mono">{document.document_id}</Text>
              </Box>
              {document.integra_id && (
                <Box>
                  <Heading size="xs" textTransform="uppercase">Integra ID</Heading>
                  <Text pt="2" fontFamily="mono">{document.integra_id}</Text>
                </Box>
              )}
              <Box>
                <Heading size="xs" textTransform="uppercase">Status</Heading>
                <HStack pt="2">
                  <Badge colorScheme={document.status === 'verified' ? 'green' : document.status === 'registered' ? 'blue' : 'yellow'}>
                    {document.status || 'pending'}
                  </Badge>
                </HStack>
              </Box>
              {document.created_at && (
                <Box>
                  <Heading size="xs" textTransform="uppercase">Created</Heading>
                  <Text pt="2">{new Date(document.created_at).toLocaleString()}</Text>
                </Box>
              )}
            </Stack>
          </CardBody>
        </Card>
        
        {verificationResult ? (
          <Card bg={cardBgColor} shadow="md" mb={6} borderColor={borderColor} borderWidth="1px">
            <CardHeader>
              <Heading size="md">Verification Result</Heading>
            </CardHeader>
            <CardBody>
              <Alert
                status={verificationResult.verified ? 'success' : 'error'}
                variant="subtle"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                borderRadius="md"
                py={6}
              >
                <AlertIcon boxSize="40px" mr={0} />
                <AlertTitle mt={4} mb={1} fontSize="lg">
                  {verificationResult.verified ? 'Document Verified' : 'Verification Failed'}
                </AlertTitle>
                <AlertDescription maxWidth="sm">
                  {verificationResult.verified
                    ? 'This document has been successfully verified on the blockchain.'
                    : 'This document could not be verified. It may have been tampered with.'}
                </AlertDescription>
              </Alert>
              
              {verificationResult.verified && verificationResult.blockchain_tx && (
                <Box mt={4}>
                  <Heading size="xs" mb={2}>Transaction Hash</Heading>
                  <Code p={2} borderRadius="md" w="100%" fontSize="sm">
                    {verificationResult.blockchain_tx}
                  </Code>
                  {verificationResult.explorer_url && (
                    <Link href={verificationResult.explorer_url} isExternal color="blue.500" mt={2} display="inline-block">
                      View on Blockchain Explorer <ExternalLinkIcon mx="2px" />
                    </Link>
                  )}
                </Box>
              )}
            </CardBody>
          </Card>
        ) : (
          <Card bg={cardBgColor} shadow="md" mb={6} borderColor={borderColor} borderWidth="1px">
            <CardHeader>
              <Heading size="md">Verify Document</Heading>
            </CardHeader>
            <CardBody>
              <Text mb={4}>
                Verify this document against the blockchain to ensure its authenticity and integrity.
              </Text>
              <Button
                colorScheme="blue"
                onClick={verifyDocumentOnChain}
                isLoading={verifying}
                loadingText="Verifying"
                leftIcon={<CheckCircleIcon />}
              >
                Verify Document
              </Button>
            </CardBody>
          </Card>
        )}
        
        <HStack spacing={4} mt={6}>
          {document.document_url && (
            <Button onClick={handleDownload} colorScheme="blue" variant="outline">
              Download Document
            </Button>
          )}
          <Button onClick={() => navigate('/')} variant="ghost">
            Return to Home
          </Button>
        </HStack>
      </Container>
    </MainLayout>
  );
};

export default QrVerify;
