import React, { Component, ErrorInfo, ReactNode } from 'react';
import {
  Box,
  Button,
  Container,
  Heading,
  Text,
  VStack,
  Code,
  Collapse,
  useDisclosure,
  Icon,
  Flex
} from '@chakra-ui/react';
import { FiAlertTriangle, FiChevronDown, FiChevronUp, FiRefreshCw } from 'react-icons/fi';

interface ErrorBoundaryProps {
  children: ReactNode;
  fallback?: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

/**
 * ErrorBoundary Component
 * 
 * A component that catches JavaScript errors anywhere in its child component tree,
 * logs those errors, and displays a fallback UI instead of the component tree that crashed.
 */
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null
    };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI
    return {
      hasError: true,
      error,
      errorInfo: null
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can also log the error to an error reporting service
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
    this.setState({
      error,
      errorInfo
    });
  }

  resetErrorBoundary = (): void => {
    this.setState({
      hasError: false,
      error: null,
      errorInfo: null
    });
  };

  render(): ReactNode {
    if (this.state.hasError) {
      // Custom fallback UI
      if (this.props.fallback) {
        return this.props.fallback;
      }

      // Default fallback UI
      return <ErrorFallback 
        error={this.state.error} 
        errorInfo={this.state.errorInfo}
        resetErrorBoundary={this.resetErrorBoundary}
      />;
    }

    return this.props.children;
  }
}

interface ErrorFallbackProps {
  error: Error | null;
  errorInfo: ErrorInfo | null;
  resetErrorBoundary: () => void;
}

/**
 * ErrorFallback Component
 * 
 * A fallback UI component that displays error information
 */
const ErrorFallback: React.FC<ErrorFallbackProps> = ({ 
  error, 
  errorInfo, 
  resetErrorBoundary 
}) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Container maxW="container.md" py={10}>
      <VStack spacing={6} align="stretch">
        <Box 
          p={6} 
          borderRadius="lg" 
          bg="red.50" 
          borderWidth="1px" 
          borderColor="red.200"
        >
          <VStack spacing={4} align="stretch">
            <Flex align="center">
              <Icon as={FiAlertTriangle} color="red.500" boxSize={6} mr={2} />
              <Heading as="h2" size="lg" color="red.500">
                Something went wrong
              </Heading>
            </Flex>
            
            <Text>
              An error occurred in the application. You can try refreshing the page or clicking the button below to reset the application.
            </Text>
            
            {error && (
              <Text fontWeight="bold" color="red.600">
                {error.toString()}
              </Text>
            )}
            
            <Button 
              leftIcon={<FiRefreshCw />} 
              colorScheme="red" 
              onClick={resetErrorBoundary}
            >
              Try Again
            </Button>
          </VStack>
        </Box>
        
        {/* Technical details (collapsible) */}
        {(error || errorInfo) && (
          <Box>
            <Button 
              variant="link" 
              rightIcon={isOpen ? <FiChevronUp /> : <FiChevronDown />}
              onClick={onToggle}
              mb={2}
            >
              {isOpen ? 'Hide' : 'Show'} technical details
            </Button>
            
            <Collapse in={isOpen} animateOpacity>
              <Box 
                p={4} 
                borderRadius="md" 
                bg="gray.50" 
                borderWidth="1px"
                overflowX="auto"
              >
                {error && (
                  <Box mb={4}>
                    <Text fontWeight="bold" mb={2}>Error:</Text>
                    <Code p={2} borderRadius="md" display="block" whiteSpace="pre-wrap">
                      {error.toString()}
                      {error.stack && `\n\n${error.stack}`}
                    </Code>
                  </Box>
                )}
                
                {errorInfo && errorInfo.componentStack && (
                  <Box>
                    <Text fontWeight="bold" mb={2}>Component Stack:</Text>
                    <Code p={2} borderRadius="md" display="block" whiteSpace="pre-wrap">
                      {errorInfo.componentStack}
                    </Code>
                  </Box>
                )}
              </Box>
            </Collapse>
          </Box>
        )}
      </VStack>
    </Container>
  );
};

export default ErrorBoundary;
