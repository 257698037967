import React from 'react';

// Import components
import PDFPreview from '../components/PDFPreview';
import VerificationContainer from '../components/verification/VerificationContainer';
import { SplitPageLayout } from '../layouts';

/**
 * Verification Result Page
 * 
 * This page displays the result of document verification and provides
 * options for next steps based on the verification outcome.
 * 
 * The page is split into two sections:
 * 1. PDF Preview: Shows the document being verified
 * 2. Verification Container: Manages the verification process and displays results
 * 
 * This component has been refactored to use the standardized layout system
 * to ensure consistent look and feel across the application.
 */
const VerificationResult: React.FC = () => {
  return (
    <SplitPageLayout
      leftContent={<PDFPreview />}
      rightContent={<VerificationContainer />}
    />
  );
};

export default VerificationResult;
