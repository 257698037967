/**
 * Verification In Progress
 * 
 * This component displays the loading state during document verification.
 * It shows a progress spinner and information about the document being verified.
 */

import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  VStack,
  HStack,
  Spinner,
  Icon,
  useColorModeValue
} from '@chakra-ui/react';
import { FiFileText } from 'react-icons/fi';

// Import stores
import { usePDFStore } from '../../store/pdfStore';

/**
 * Verification In Progress Component
 */
const VerificationInProgress: React.FC = () => {
  // Get document store state from PDFStore
  const { fileName, fileHash } = usePDFStore();
  
  // Theme colors
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('blue.200', 'blue.700');
  const textColor = useColorModeValue('gray.800', 'white');
  
  return (
    <Box
      p={6}
      borderWidth="1px"
      borderRadius="lg"
      borderColor={borderColor}
      bg={bgColor}
      boxShadow="md"
      width="100%"
    >
      <VStack spacing={6} align="stretch">
        <Flex
          width="100%"
          direction="column"
          align="center"
          justify="center"
          textAlign="center"
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
            mb={6}
          />
          
          <Heading as="h2" size="lg" mb={2}>
            Verifying Document
          </Heading>
          
          <Text color={textColor} fontSize="md" maxWidth="600px" mx="auto">
            We're checking if this document exists on the blockchain. This may take a moment...
          </Text>
        </Flex>
        
        {fileName && (
          <Box
            mt={6}
            p={4}
            borderWidth="1px"
            borderRadius="md"
            borderColor={useColorModeValue('gray.200', 'gray.700')}
          >
            <HStack spacing={4} align="flex-start">
              <Icon as={FiFileText} fontSize="2xl" mt={1} />
              <VStack align="stretch" spacing={1}>
                <Text fontWeight="bold">
                  {fileName}
                </Text>
                {fileHash && (
                  <Text fontSize="sm" fontFamily="monospace" color={useColorModeValue('gray.600', 'gray.400')} isTruncated>
                    Hash: {fileHash.substring(0, 16)}...
                  </Text>
                )}
              </VStack>
            </HStack>
          </Box>
        )}
      </VStack>
    </Box>
  );
};

export default VerificationInProgress;
