import { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useToast,
  Flex,
  Spinner,
} from '@chakra-ui/react';
import { useEnhancedAuth } from '../providers/EnhancedPostgresAuthProvider';
import OrganizationMembers from '../components/OrganizationMembers';
import OrganizationInvitations from '../components/OrganizationInvitations';
import { Navigate } from 'react-router-dom';

const OrganizationSettings = () => {
  const { userProfile, organizations, currentOrganizationId, getOrganizationMembers } = useEnhancedAuth();
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (!userProfile || !currentOrganizationId) {
        setLoading(false);
        return;
      }

      try {
        // Check if the user is an admin of the current organization
        const members = await getOrganizationMembers(currentOrganizationId);
        const currentUserMember = members.find(member => member.profile_id === userProfile.profile_id);
        
        if (currentUserMember) {
          setIsAdmin(currentUserMember.role === 'admin');
        } else {
          setIsAdmin(false);
        }
      } catch (error) {
        console.error('Error checking admin status:', error);
        toast({
          title: 'Error checking permissions',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        setIsAdmin(false);
      } finally {
        setLoading(false);
      }
    };

    checkAdminStatus();
  }, [userProfile, currentOrganizationId]);

  // Show loading spinner if userProfile is still being loaded
  if (loading) {
    return (
      <Flex justify="center" align="center" h="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  // If userProfile is not available after loading is complete, redirect to dashboard instead of login
  if (!userProfile) {
    return (
      <Container maxW="container.xl" py={8}>
        <Heading mb={4}>Organization Settings</Heading>
        <Text>Unable to load user profile. Please try refreshing the page.</Text>
      </Container>
    );
  }

  if (!currentOrganizationId) {
    return <Navigate to="/dashboard" />;
  }

  if (!isAdmin) {
    return (
      <Container maxW="container.xl" py={8}>
        <Heading mb={4}>Organization Settings</Heading>
        <Text>You do not have permission to access organization settings. Only organization administrators can view this page.</Text>
      </Container>
    );
  }

  const currentOrganization = organizations.find(org => org.organization_id === currentOrganizationId);

  return (
    <Container maxW="container.xl" py={8}>
      <Heading mb={2}>{currentOrganization?.organization_name || 'Organization'} Settings</Heading>
      <Text mb={6} color="gray.500">Manage your organization members and invitations</Text>

      <Tabs colorScheme="blue" isLazy>
        <TabList>
          <Tab>Members</Tab>
          <Tab>Invitations</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <OrganizationMembers organizationId={currentOrganizationId} />
          </TabPanel>
          <TabPanel>
            <OrganizationInvitations organizationId={currentOrganizationId} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  );
};

export default OrganizationSettings;
