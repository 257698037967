import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  Heading,
  Text,
  Flex,
  Badge,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { useEnhancedAuth } from '../providers/EnhancedPostgresAuthProvider';
import { OrganizationInvitation } from '../lib/postgres-client';

interface OrganizationInvitationsProps {
  organizationId: string;
}

const OrganizationInvitations = ({ organizationId }: OrganizationInvitationsProps) => {
  const { createInvitation, getOrganizationInvitations, cancelInvitation } = useEnhancedAuth();
  const [invitations, setInvitations] = useState<OrganizationInvitation[]>([]);
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('member');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedInvitation, setSelectedInvitation] = useState<OrganizationInvitation | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const fetchInvitations = async () => {
    if (organizationId) {
      const fetchedInvitations = await getOrganizationInvitations(organizationId);
      setInvitations(fetchedInvitations);
    }
  };

  useEffect(() => {
    fetchInvitations();
  }, [organizationId]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email) {
      toast({
        title: 'Email is required',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsSubmitting(true);
    try {
      const invitation = await createInvitation(organizationId, email, role);
      if (invitation) {
        toast({
          title: 'Invitation sent',
          description: `Invitation sent to ${email}`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setEmail('');
        fetchInvitations();
      }
    } catch (error) {
      toast({
        title: 'Error sending invitation',
        description: error instanceof Error ? error.message : 'Unknown error',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancelInvitation = async () => {
    if (!selectedInvitation) return;
    
    try {
      const success = await cancelInvitation(selectedInvitation.invitation_id);
      if (success) {
        toast({
          title: 'Invitation canceled',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        fetchInvitations();
        onClose();
      }
    } catch (error) {
      toast({
        title: 'Error canceling invitation',
        description: error instanceof Error ? error.message : 'Unknown error',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const openCancelModal = (invitation: OrganizationInvitation) => {
    setSelectedInvitation(invitation);
    onOpen();
  };

  const getStatusBadge = (status: string) => {
    switch (status) {
      case 'pending':
        return <Badge colorScheme="yellow">Pending</Badge>;
      case 'accepted':
        return <Badge colorScheme="green">Accepted</Badge>;
      case 'declined':
        return <Badge colorScheme="red">Declined</Badge>;
      case 'canceled':
        return <Badge colorScheme="gray">Canceled</Badge>;
      default:
        return <Badge>{status}</Badge>;
    }
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString();
  };

  return (
    <Box>
      <Heading size="md" mb={4}>
        Invitations
      </Heading>

      <Box as="form" onSubmit={handleSubmit} mb={6}>
        <Flex gap={4} direction={{ base: 'column', md: 'row' }}>
          <FormControl isRequired>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter email address"
            />
          </FormControl>

          <FormControl>
            <FormLabel>Role</FormLabel>
            <Select value={role} onChange={(e) => setRole(e.target.value)}>
              <option value="member">Member</option>
              <option value="admin">Admin</option>
            </Select>
          </FormControl>

          <Button
            type="submit"
            colorScheme="blue"
            isLoading={isSubmitting}
            alignSelf={{ md: 'flex-end' }}
            mt={{ base: 4, md: 0 }}
          >
            Send Invitation
          </Button>
        </Flex>
      </Box>

      {invitations.length > 0 ? (
        <Box overflowX="auto">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Email</Th>
                <Th>Role</Th>
                <Th>Status</Th>
                <Th>Sent</Th>
                <Th>Expires</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {invitations.map((invitation) => (
                <Tr key={invitation.invitation_id}>
                  <Td>{invitation.invitee_email}</Td>
                  <Td>{invitation.role}</Td>
                  <Td>{getStatusBadge(invitation.status)}</Td>
                  <Td>{formatDate(invitation.created_at)}</Td>
                  <Td>{formatDate(invitation.expires_at)}</Td>
                  <Td>
                    {invitation.status === 'pending' && (
                      <IconButton
                        aria-label="Cancel invitation"
                        icon={<DeleteIcon />}
                        size="sm"
                        colorScheme="red"
                        variant="ghost"
                        onClick={() => openCancelModal(invitation)}
                      />
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      ) : (
        <Text>No invitations found</Text>
      )}

      {/* Cancel Invitation Confirmation Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cancel Invitation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to cancel the invitation to{' '}
            <strong>{selectedInvitation?.invitee_email}</strong>?
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              No
            </Button>
            <Button colorScheme="red" onClick={handleCancelInvitation}>
              Yes, Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default OrganizationInvitations;
