import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  Select,
  SimpleGrid,
  Text,
  Textarea,
  VStack,
  HStack,
  useSteps,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useColorModeValue,
  Radio,
  RadioGroup,
  Stack,
  Progress,
  Badge,
  IconButton,
} from '@chakra-ui/react';
import { FiArrowLeft, FiArrowRight, FiCheck, FiFileText, FiHome } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { usePDFStore } from '../store/pdfStore';
import StandardPageLayout from '../components/StandardPageLayout';
import { usePrivy } from '@privy-io/react-auth';
import { useEnhancedAuth } from '../providers/EnhancedPostgresAuthProvider';
import v3BlockchainService from '../services/V3BlockchainService';
import pdfService from '../services/PDFService';
import { PDFViewer } from '../components/PDFViewer';
import createQrCode from '../assets/utils/pdf/createQrCode';
import { placeQRCodeOnDocument } from '../utils/qrCodeUtils';
import integraQrIcon from '../assets/Integra QR icon.png';
import { calculateKeccak256String } from '../lib/hash';
import { encryptString, bytesToBase64 } from '../lib/chacha20';
import { calculateSHA256 } from '../lib/encryption';
import { STANDARD_CHACHA20_NONCE } from '../lib/encryption-constants';

/**
 * Document Registration Page
 * 
 * Allows users to register documents on the blockchain
 */
const DocumentRegistration: React.FC = () => {
  // Hooks
  const { activeStep, setActiveStep } = useSteps({ index: 0, count: 3 });
  const navigate = useNavigate();
  const { authenticated } = usePrivy();
  const { userProfile, currentOrganizationId } = useEnhancedAuth();
  
  // Color mode values
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const documentBorderColor = useColorModeValue("gray.200", "gray.700");
  const contentBgColor = useColorModeValue("white", "gray.800");
  
  // Get PDF store actions
  const {
    pdfFile,
    fileHash,
    fileName,
    fileDataUrl,
    metadata,
    registrationData,
    originalHash,
    setRegistrationData,
    setSmartDocHash,
    setSmartDocumentUrl,
    setSmartDocumentFile,
    setIntegraId,
    setFileHash,
    setProcessing,
    setError
  } = usePDFStore();
  
  // State for document registration
  const [isRegistering, setIsRegistering] = useState<boolean>(false);
  const [registrationResult, setRegistrationResult] = useState<any>(null);
  const [registrationError, setRegistrationError] = useState<string | null>(null);
  const [hashProgress, setHashProgress] = useState<number>(0);
  const [isCalculatingHash, setIsCalculatingHash] = useState<boolean>(false);
  const [showQrCodeOptions, setShowQrCodeOptions] = useState(false);
  const [registrationComplete, setRegistrationComplete] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [registeredIntegraId, setRegisteredIntegraId] = useState<string>('');
  const [processingState, setProcessingState] = useState<'idle' | 'registering' | 'creating-smart-document' | 'registering-blockchain'>('idle');
  
  // State for smart document
  const [smartDocumentBlob, setSmartDocumentBlob] = useState<Blob | null>(null);
  const [localSmartDocumentUrl, setLocalSmartDocumentUrl] = useState<string | null>(null);
  
  // Redirect to home if no document data is available
  useEffect(() => {
    console.log('DocumentRegistration mounted, checking data:', {
      pdfFile: !!pdfFile,
      fileHash
    });
    
    // Check if we have document data
    if (!pdfFile && !fileHash) {
      console.log('No document data, redirecting to home');
      navigate('/');
      return;
    }
    
    // Initialize registration data
    setRegistrationData({
      documentName: metadata?.title || '',
      documentType: 'General',
      documentDescription: '',
      registrationType: 'standard'
    });
    
    console.log('Registration data initialized');
  }, [pdfFile, fileHash, navigate, metadata, setRegistrationData]);
  
  // Calculate document hash if file is available but hash is not
  useEffect(() => {
    const calculateHash = async () => {
      if (pdfFile && !fileHash) {
        try {
          setIsCalculatingHash(true);
          
          // Calculate hash with progress updates
          const hash = await pdfService.calculateFileHash(pdfFile);
          
          // Update hash
          setFileHash(hash);
          
          setIsCalculatingHash(false);
        } catch (error) {
          console.error('Error calculating hash:', error);
          setIsCalculatingHash(false);
        }
      }
    };
    
    calculateHash();
  }, [pdfFile, fileHash]);
  
  // Handle next step
  const handleNextStep = () => {
    setActiveStep(activeStep + 1);
  };
  
  // Handle previous step
  const handlePrevStep = () => {
    setActiveStep(activeStep - 1);
  };
  
  // Handle registration type change
  const handleRegistrationTypeChange = (value: string) => {
    setRegistrationData({
      ...registrationData,
      registrationType: value
    });
    setShowQrCodeOptions(value === 'smart');
  };
  
  // Handle QR code placement change
  const handleQrCodePlacementChange = (value: 'right' | 'left' | 'cover') => {
    setRegistrationData({
      ...registrationData,
      qrCodePlacement: value
    });
  };
  
  // Handle document name change
  const handleDocumentNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRegistrationData({
      ...registrationData,
      documentName: e.target.value
    });
  };
  
  // Handle document type change
  const handleDocumentTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRegistrationData({
      ...registrationData,
      documentType: e.target.value
    });
  };
  
  // Handle document description change
  const handleDocumentDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRegistrationData({
      ...registrationData,
      documentDescription: e.target.value
    });
  };
  
  // Handle document registration
  const handleRegisterDocument = async () => {
    try {
      // Validate required data
      if (!pdfFile || !fileHash || !registrationData) {
        console.error('Missing required document data');
        return;
      }

      // Set processing state
      setIsRegistering(true);
      setRegistrationError(null);
      setProcessingState('registering');
      
      // 1) Generate a unique Integra ID and store it in the PDFStore
      const integraId = uuidv4();
      setIntegraId(integraId);
      console.log('Generated Integra ID:', integraId);
      
      // 2) Save all user-entered metadata in the PDFStore
      console.log('Registration data:', registrationData);
      
      // Save the original hash if not already saved
      if (!originalHash) {
        console.log('Using fileHash as originalHash:', fileHash);
      }
      
      // Variable to hold the hash that will be registered
      let documentHashToRegister = fileHash;
      
      // 3) If Smart Document creation was selected, process the PDF
      if (registrationData.registrationType === 'smart') {
        try {
          setProcessingState('creating-smart-document');
          
          // Create QR code URL with the hash of the Integra ID
          const integraIdHash = calculateKeccak256String(integraId);
          const qrCodeUrl = `https://console.integraledger.com/qr/${integraIdHash}`;
          
          // Generate QR code
          const qrCodeBytes = await createQrCode({
            url: qrCodeUrl,
            size: 150,
            logoUrl: integraQrIcon,
            logoScale: 0.23
          });
          
          // Get the PDF bytes
          const fileArrayBuffer = await pdfService.readAsArrayBuffer(pdfFile);
          const pdfBytes = new Uint8Array(fileArrayBuffer);
          
          // Prepare JSON data for embedding
          const jsonData = {
            document_name: registrationData.documentName,
            document_type: registrationData.documentType,
            document_description: registrationData.documentDescription || '',
            integra_id: integraIdHash,
            original_hash: fileHash,
            registration_date: new Date().toISOString()
          };
          
          // Create the verification URL for QR code
          const verificationUrl = `https://integraledger.com/qr/${originalHash}`;
          
          // First add QR code to the document
          const docWithQrCode = await placeQRCodeOnDocument(
            pdfBytes,
            qrCodeBytes,
            registrationData.qrCodePlacement as 'right' | 'left' | 'cover',
            qrCodeUrl
          );
          
          // Then embed metadata into the document
          const smartDocBytes = await pdfService.createSmartDoc({
            pdfBytes: docWithQrCode,
            jsonData,
            qrCodeBytes: undefined, // QR code already added
            integraEndpoint: verificationUrl,
            customEndpoint: '',
            version: '1.0',
            uuid: integraId,
          });
          
          // 4) Calculate hash of the modified document and save it in the PDFStore
          const smartDocBlob = new Blob([smartDocBytes], { type: 'application/pdf' });
          const smartDocFile = new File([smartDocBlob], `${pdfFile.name.replace('.pdf', '')}_smart.pdf`, { type: 'application/pdf' });
          const smartDocHash = await pdfService.calculateFileHash(smartDocFile);
          
          // Update the hash in the store
          setSmartDocHash(smartDocHash);
          console.log('Smart document hash calculated:', smartDocHash);
          
          // Save the smart document URL and file to the store
          const smartDocUrl = URL.createObjectURL(smartDocBlob);
          setLocalSmartDocumentUrl(smartDocUrl);
          setSmartDocumentUrl(smartDocUrl); // Update the PDFStore
          setSmartDocumentFile(smartDocFile);
          
          // Use the smart document hash for registration
          documentHashToRegister = smartDocHash;
          
          // 5) Trigger automatic download of the Smart Document
          const downloadLink = document.createElement('a');
          downloadLink.href = smartDocUrl;
          
          // Get the filename without extension
          const fileNameParts = pdfFile.name.split('.');
          const extension = fileNameParts.pop() || 'pdf';
          const baseName = fileNameParts.join('.');
          
          // Create the new filename with _Smart_Doc suffix
          downloadLink.download = `${baseName}_Smart_Doc.${extension}`;
          
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        } catch (error) {
          console.error('Error creating smart document:', error);
          setRegistrationError('Failed to create smart document. Please try again.');
          setProcessingState('idle');
          setIsRegistering(false);
          return;
        }
      }

      // Create the URL to encrypt
      const connectUrl = `https://comms.integraledger.com/connect/${currentOrganizationId}`;
      
      // 6) Register the document with the blockchain using Integra ID and hash
      setProcessingState('registering-blockchain');
      console.log('Registering document with hash:', documentHashToRegister);
      console.log('Using Integra ID:', integraId);
      
      const result = await v3BlockchainService.registerDocument(
        {
          document_hash: documentHashToRegister,
          integra_id: integraId,
          encrypted_data: bytesToBase64(encryptString(connectUrl, documentHashToRegister, STANDARD_CHACHA20_NONCE).encrypted),
        },
        userProfile?.profile_id || 'anonymous',
        currentOrganizationId || ''
      );

      // Update result
      setRegistrationResult(result);
      console.log('Registration result:', result);

      // 7) Display success message instead of navigating
      setProcessingState('idle');
      setIsRegistering(false);
      setRegistrationComplete(true);
      setRegistrationSuccess(true);
      setRegisteredIntegraId(integraId);
      
    } catch (error) {
      console.error('Error registering document:', error);
      setRegistrationError('Failed to register document. Please try again.');
      setProcessingState('idle');
      setIsRegistering(false);
    }
  };
  
  return (
    <StandardPageLayout>
      <Heading as="h1" size="xl" mb={6}>
        Document Registration
      </Heading>
      
      {/* Stepper */}
      <Stepper index={activeStep} mb={8} size="sm">
        <Step>
          <StepIndicator>
            <StepStatus
              complete={<StepIcon />}
              incomplete={<StepNumber />}
              active={<StepNumber />}
            />
          </StepIndicator>
          
          <Box flexShrink="0">
            <StepTitle>Registration Type</StepTitle>
            <StepDescription>Select type</StepDescription>
          </Box>
          
          <StepSeparator />
        </Step>
        
        <Step>
          <StepIndicator>
            <StepStatus
              complete={<StepIcon />}
              incomplete={<StepNumber />}
              active={<StepNumber />}
            />
          </StepIndicator>
          
          <Box flexShrink="0">
            <StepTitle>Metadata</StepTitle>
            <StepDescription>Add details</StepDescription>
          </Box>
          
          <StepSeparator />
        </Step>
        
        <Step>
          <StepIndicator>
            <StepStatus
              complete={<StepIcon />}
              incomplete={<StepNumber />}
              active={<StepNumber />}
            />
          </StepIndicator>
          
          <Box flexShrink="0">
            <StepTitle>Register</StepTitle>
            <StepDescription>Confirm & register</StepDescription>
          </Box>
        </Step>
      </Stepper>
      
      {/* Main content */}
      <Flex 
        direction={{ base: "column", md: "row" }} 
        gap={6}
        justify="space-between"
        align="stretch"
        width="100%"
      >
        {/* Left column - Document Preview */}
        <Box 
          width={{ base: "100%", md: "48%" }}
          borderRadius="md" 
          overflow="hidden" 
          height={{ base: "400px", md: "600px" }}
          border="1px solid" 
          borderColor={borderColor}
          flexShrink={0}
          display="flex"
          alignItems="flex-start"
          justifyContent="center"
          bg={bgColor}
          p={0}
          position="relative"
        >
          {isCalculatingHash && (
            <Flex 
              position="absolute" 
              top="0" 
              left="0" 
              right="0" 
              bottom="0" 
              bg="rgba(0, 0, 0, 0.7)" 
              zIndex="1" 
              align="center" 
              justify="center"
              direction="column"
              p={6}
            >
              <Text color="white" mb={4}>Calculating document hash...</Text>
              <Box w="80%" maxW="300px">
                <Progress value={hashProgress} size="sm" colorScheme="green" />
              </Box>
              <Text color="white" fontSize="sm" mt={2}>
                {hashProgress}%
              </Text>
            </Flex>
          )}
          
          {/* PDF Viewer */}
          {pdfFile && (
            <PDFViewer
              file={pdfFile}
              height="100%"
              width="100%"
              showControls={true}
              renderTextLayer={false}
              scale={0.7}
              qrCodePlacement={registrationData?.registrationType === 'smart' ? registrationData?.qrCodePlacement || null : null}
            />
          )}
          
          {!pdfFile && (
            <Flex 
              height="100%" 
              width="100%"
              alignItems="center" 
              justifyContent="center" 
              bg="transparent"
              direction="column"
            >
              <VStack spacing={4}>
                <Icon as={FiFileText} boxSize={16} color="gray.400" />
                <Text color="gray.500">No document preview available</Text>
              </VStack>
            </Flex>
          )}
        </Box>
        
        {/* Right column - Registration Content */}
        <Box 
          width={{ base: "100%", md: "48%" }}
          borderRadius="md"
          border="1px solid"
          borderColor={borderColor}
          p={6}
          flexShrink={0}
          bg={contentBgColor}
        >
          {/* Step content */}
          {activeStep === 0 && (
            <VStack spacing={6} align="stretch">
              <Heading as="h3" size="md" mb={2}>
                Registration Type
              </Heading>
              
              <VStack spacing={4} align="stretch" mt={2}>
                {(pdfFile) ? (
                  <Alert 
                    status="success" 
                    borderRadius="md" 
                    variant="left-accent"
                    mb={2}
                  >
                    <AlertIcon />
                    <Box>
                      <AlertTitle>Document ready for registration</AlertTitle>
                      <AlertDescription>
                        {registrationData?.documentName && <Text>Document: {registrationData.documentName}</Text>}
                        {fileHash && (
                          <Text fontSize="sm" fontFamily="mono" mt={1} isTruncated>
                            Hash: {fileHash}
                          </Text>
                        )}
                      </AlertDescription>
                    </Box>
                  </Alert>
                ) : null}
                
                {!showQrCodeOptions ? (
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6} mt={2}>
                    <Box
                      borderWidth={2}
                      borderRadius="md"
                      borderColor="gray.200"
                      _hover={{
                        borderColor: 'blue.500',
                        boxShadow: '0 0 0 1px var(--chakra-colors-blue-500)',
                        transition: 'all 0.2s'
                      }}
                      cursor="pointer"
                      onClick={() => {
                        handleRegistrationTypeChange('standard');
                        handleNextStep();
                      }}
                      p={8}
                      textAlign="center"
                      transition="all 0.2s"
                    >
                      <VStack spacing={4}>
                        <Icon as={FiFileText} boxSize={10} color="blue.500" />
                        <Text fontWeight="bold" fontSize="lg">Register As Is</Text>
                        <Text fontSize="sm" textAlign="center">
                          Standard document registration
                        </Text>
                      </VStack>
                    </Box>
                    
                    <Box
                      borderWidth={2}
                      borderRadius="md"
                      borderColor="gray.200"
                      _hover={{
                        borderColor: 'blue.500',
                        boxShadow: '0 0 0 1px var(--chakra-colors-blue-500)',
                        transition: 'all 0.2s'
                      }}
                      cursor="pointer"
                      onClick={() => {
                        handleRegistrationTypeChange('smart');
                        setShowQrCodeOptions(true);
                      }}
                      p={8}
                      textAlign="center"
                      transition="all 0.2s"
                    >
                      <VStack spacing={4}>
                        <Icon as={FiCheck} boxSize={10} color="blue.500" />
                        <Text fontWeight="bold" fontSize="lg">Create Smart Document</Text>
                        <Text fontSize="sm" textAlign="center">
                          Enhanced document with smart features
                        </Text>
                      </VStack>
                    </Box>
                  </SimpleGrid>
                ) : (
                  <VStack spacing={6} mt={6} align="stretch">
                    <Heading as="h3" size="md" mb={2}>
                      QR Code Placement
                    </Heading>
                    
                    <RadioGroup onChange={(val) => handleQrCodePlacementChange(val as 'right' | 'left' | 'cover')} value={registrationData?.qrCodePlacement}>
                      <Stack direction="row" spacing={5} justify="center">
                        <Radio value="right" colorScheme="blue">
                          <VStack>
                            <Box 
                              borderWidth={1} 
                              borderColor="gray.300" 
                              p={4} 
                              borderRadius="md"
                              width="120px"
                              height="160px"
                              position="relative"
                            >
                              <Box 
                                position="absolute" 
                                right="10px" 
                                top="10px" 
                                width="40px" 
                                height="40px" 
                                bg="gray.200" 
                                borderRadius="md"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Box as="img" src="/sample-qr.png" alt="QR Code" width="30px" height="30px" />
                              </Box>
                              <Box 
                                width="80%" 
                                height="10px" 
                                bg="gray.200" 
                                mb={2} 
                                mt={8}
                              />
                              <Box 
                                width="90%" 
                                height="10px" 
                                bg="gray.200" 
                                mb={2}
                              />
                              <Box 
                                width="70%" 
                                height="10px" 
                                bg="gray.200"
                              />
                            </Box>
                            <Text>Right Corner</Text>
                          </VStack>
                        </Radio>
                        
                        <Radio value="left" colorScheme="blue">
                          <VStack>
                            <Box 
                              borderWidth={1} 
                              borderColor="gray.300" 
                              p={4} 
                              borderRadius="md"
                              width="120px"
                              height="160px"
                              position="relative"
                            >
                              <Box 
                                position="absolute" 
                                left="10px" 
                                top="10px" 
                                width="40px" 
                                height="40px" 
                                bg="gray.200" 
                                borderRadius="md"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Box as="img" src="/sample-qr.png" alt="QR Code" width="30px" height="30px" />
                              </Box>
                              <Box 
                                width="80%" 
                                height="10px" 
                                bg="gray.200" 
                                mb={2} 
                                mt={8}
                              />
                              <Box 
                                width="90%" 
                                height="10px" 
                                bg="gray.200" 
                                mb={2}
                              />
                              <Box 
                                width="70%" 
                                height="10px" 
                                bg="gray.200"
                              />
                            </Box>
                            <Text>Left Corner</Text>
                          </VStack>
                        </Radio>
                        
                        <Radio value="cover" colorScheme="blue">
                          <VStack>
                            <Box 
                              borderWidth={1} 
                              borderColor="gray.300" 
                              p={4} 
                              borderRadius="md"
                              width="120px"
                              height="160px"
                              position="relative"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Box 
                                position="absolute" 
                                top="50%" 
                                left="50%" 
                                transform="translate(-50%, -50%)" 
                                width="60px" 
                                height="60px" 
                                bg="gray.200" 
                                borderRadius="md"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Box as="img" src="/sample-qr.png" alt="QR Code" width="50px" height="50px" />
                              </Box>
                            </Box>
                            <Text>Cover Page</Text>
                          </VStack>
                        </Radio>
                      </Stack>
                    </RadioGroup>
                    
                    <Box textAlign="center" mt={4}>
                      <Button 
                        colorScheme="blue" 
                        rightIcon={<FiArrowRight />}
                        onClick={() => {
                          handleNextStep();
                          setShowQrCodeOptions(false);
                        }}
                      >
                        Continue to Metadata
                      </Button>
                    </Box>
                  </VStack>
                )}
              </VStack>
            </VStack>
          )}
          
          {activeStep === 1 && (
            <VStack spacing={6} align="stretch">
              <Heading as="h3" size="md" mb={2}>
                Document Metadata
              </Heading>
              
              <FormControl isRequired>
                <FormLabel>Document Name</FormLabel>
                <Input 
                  value={registrationData?.documentName}
                  onChange={handleDocumentNameChange}
                  placeholder="Enter document name"
                />
              </FormControl>
              
              <FormControl>
                <FormLabel>Document Type</FormLabel>
                <Select 
                  value={registrationData?.documentType}
                  onChange={handleDocumentTypeChange}
                >
                  <option value="General">General</option>
                  <option value="Contract">Contract</option>
                  <option value="Agreement">Agreement</option>
                  <option value="Certificate">Certificate</option>
                  <option value="Legal">Legal Document</option>
                  <option value="Financial">Financial Document</option>
                  <option value="Other">Other</option>
                </Select>
              </FormControl>
              
              <FormControl>
                <FormLabel>Document Description</FormLabel>
                <Textarea 
                  value={registrationData?.documentDescription}
                  onChange={handleDocumentDescriptionChange}
                  placeholder="Enter a brief description of the document"
                  rows={3}
                />
              </FormControl>
              
              <HStack spacing={4} pt={4}>
                <Button 
                  onClick={handlePrevStep}
                  variant="outline"
                >
                  Back
                </Button>
                <Button 
                  colorScheme="green"
                  onClick={handleNextStep}
                  isDisabled={!registrationData?.documentName || !fileHash}
                >
                  Continue to Registration
                </Button>
              </HStack>
            </VStack>
          )}
          
          {activeStep === 2 && !registrationComplete && !registrationSuccess && (
            <Box>
              <Heading as="h3" size="md" mb={4}>
                Registration Confirmation
              </Heading>
              
              <Text mb={4}>
                Please review your document details before registration:
              </Text>
              
              <Box 
                border="1px solid" 
                borderColor={borderColor} 
                borderRadius="md" 
                p={4} 
                mb={6}
              >
                <VStack spacing={3} align="stretch">
                  <HStack>
                    <Text fontWeight="bold" w="140px">Document Name:</Text>
                    <Text>{registrationData?.documentName}</Text>
                  </HStack>
                  <HStack>
                    <Text fontWeight="bold" w="140px">Document Type:</Text>
                    <Text>{registrationData?.documentType}</Text>
                  </HStack>
                  {registrationData?.documentDescription && (
                    <HStack>
                      <Text fontWeight="bold" w="140px">Description:</Text>
                      <Text>{registrationData?.documentDescription}</Text>
                    </HStack>
                  )}
                  <HStack>
                    <Text fontWeight="bold" w="140px">Registration Type:</Text>
                    <Text>
                      {registrationData?.registrationType === 'standard' ? 'Standard Registration' : 'Smart Document'}
                    </Text>
                  </HStack>
                </VStack>
              </Box>
              
              {registrationError && (
                <Alert status="error" mb={4}>
                  <AlertIcon />
                  <Box>
                    <AlertTitle>Registration Failed</AlertTitle>
                    <AlertDescription>
                      {registrationError}
                    </AlertDescription>
                  </Box>
                </Alert>
              )}
              
              <HStack spacing={4} justify="space-between">
                <Button 
                  onClick={() => setActiveStep(1)} 
                  leftIcon={<FiArrowLeft />}
                  variant="outline"
                >
                  Back
                </Button>
                <Button 
                  colorScheme="blue" 
                  onClick={handleRegisterDocument}
                  isLoading={isRegistering}
                  loadingText={
                    processingState === 'registering' ? 'Registering...' : 
                    processingState === 'creating-smart-document' ? 'Creating Smart Document...' :
                    processingState === 'registering-blockchain' ? 'Registering on Blockchain...' :
                    'Processing...'
                  }
                  rightIcon={<FiArrowRight />}
                >
                  Register Document
                </Button>
              </HStack>
            </Box>
          )}
          
          {/* Registration Complete */}
          {registrationComplete && registrationSuccess && (
            <Box textAlign="center" py={6} bg="gray.900" color="white" borderRadius="md" w="100%">
              <VStack spacing={6}>
                <Icon as={FiCheck} boxSize={12} color="green.400" />
                <Heading size="lg">Document Registration Initiated</Heading>
                <Text>
                  Your document has been successfully submitted for registration on the blockchain.
                </Text>
                <Text>
                  The registration process has been initiated and will be completed shortly.
                </Text>
                
                {registrationResult && (
                  <Box 
                    mt={4} 
                    p={4} 
                    bg="gray.800" 
                    borderRadius="md"
                    width="100%"
                  >
                    <VStack spacing={3} align="stretch">
                      <HStack>
                        <Text fontWeight="bold" w="140px">Workflow ID:</Text>
                        <Text fontFamily="mono">{registrationResult.workflow_id}</Text>
                      </HStack>
                      <HStack>
                        <Text fontWeight="bold" w="140px">Integra ID:</Text>
                        <Text fontFamily="mono">{registeredIntegraId}</Text>
                      </HStack>
                    </VStack>
                  </Box>
                )}
                
                <HStack spacing={4} mt={4}>
                  <Button
                    colorScheme="blue"
                    onClick={() => navigate('/')}
                    leftIcon={<FiHome />}
                  >
                    Return to Home
                  </Button>
                  <Button
                    variant="outline"
                    onClick={() => navigate('/documents')}
                    leftIcon={<FiFileText />}
                    borderColor="blue.400"
                    color="blue.400"
                    _hover={{
                      bg: "rgba(66, 153, 225, 0.1)"
                    }}
                  >
                    View My Documents
                  </Button>
                </HStack>
              </VStack>
            </Box>
          )}
          
          {/* Registration Error */}
          {registrationError && (
            <Box textAlign="center" py={6}>
              <VStack spacing={6}>
                <Icon as={FiFileText} boxSize={12} color="red.500" />
                <Heading as="h3" size="md">
                  Registration Failed
                </Heading>
                <Text>
                  An error occurred while registering your document.
                </Text>
                <Text>
                  Please try again or contact support for assistance.
                </Text>
                
                <HStack spacing={4} mt={4}>
                  <Button
                    colorScheme="blue"
                    onClick={() => navigate('/')}
                    leftIcon={<FiHome />}
                  >
                    Return to Home
                  </Button>
                  <Button
                    variant="outline"
                    onClick={() => navigate('/documents')}
                    leftIcon={<FiFileText />}
                  >
                    View My Documents
                  </Button>
                </HStack>
              </VStack>
            </Box>
          )}
        </Box>
      </Flex>
    </StandardPageLayout>
  );
}

export default DocumentRegistration;
