import React from 'react';
import { 
  Box, 
  Flex, 
  IconButton, 
  useColorMode, 
  Button, 
  Menu, 
  MenuButton, 
  MenuList, 
  MenuItem, 
  MenuDivider,
  HStack,
  Image,
  useColorModeValue,
  Tooltip
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { 
  FiMoon, 
  FiSun, 
  FiDatabase, 
  FiUser, 
  FiSettings, 
  FiHelpCircle, 
  FiLogOut,
  FiActivity,
  FiServer 
} from 'react-icons/fi';
import { usePrivy } from '@privy-io/react-auth';
import integraLogo from '../../assets/Integra logo - new - white letters.svg';
import ColorModeToggle from '../ColorModeToggle';

interface MainLayoutProps {
  children: React.ReactNode;
}

/**
 * MainLayout Component
 * 
 * A layout component that includes the persistent top navigation bar
 * and wraps the main content of the application.
 */
const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const { authenticated, login, logout, ready } = usePrivy();
  
  // Background color for the navigation bar - using theme colors
  const navBgColor = useColorModeValue("integra.light.background", "integra.dark.background");
  const navTextColor = useColorModeValue("integra.light.text.primary", "integra.dark.text.primary");
  
  // Handle login
  const handleLogin = () => {
    login();
  };
  
  // Handle logout
  const handleLogout = () => {
    logout();
  };
  
  return (
    <Box minH="100vh" position="relative">
      {/* Top Navigation Bar */}
      <Flex
        as="nav"
        bg={navBgColor}
        color={navTextColor}
        position="fixed" 
        top={0} 
        left={0} 
        right={0} 
        p={4} 
        justifyContent="space-between" 
        alignItems="center"
        zIndex="1000"
      >
        <Box>
          <RouterLink to="/">
            <Image src={integraLogo} alt="Integra Logo" h="30px" />
          </RouterLink>
        </Box>
        <Flex alignItems="center">
          {/* Color Mode Toggle */}
          <ColorModeToggle />
          
          {/* Documents Button (only if logged in) */}
          {authenticated && (
            <Tooltip label="Documents" placement="bottom">
              <IconButton
                as={RouterLink}
                to="/documents"
                aria-label="Documents"
                icon={<FiDatabase />}
                variant="ghost"
                color={navTextColor}
                mr={2}
              />
            </Tooltip>
          )}
          
          {/* Activities Button (only if logged in) */}
          {authenticated && (
            <Tooltip label="Document Activities" placement="bottom">
              <IconButton
                as={RouterLink}
                to="/activities"
                aria-label="Document Activities"
                icon={<FiActivity />}
                variant="ghost"
                color={navTextColor}
                mr={2}
              />
            </Tooltip>
          )}
          
          {/* User Menu or Login/Signup */}
          {authenticated ? (
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="User menu"
                icon={<FiUser />}
                variant="ghost"
                color={navTextColor}
              />
              <MenuList 
                bg={colorMode === 'dark' ? 'integra.dark.surface' : 'integra.light.surface'} 
                borderColor={colorMode === 'dark' ? 'integra.dark.border' : 'integra.light.border'}
              >
                <MenuItem 
                  as={RouterLink} 
                  to="/settings" 
                  icon={<FiSettings />}
                  _hover={{ bg: colorMode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)' }}
                >
                  User Settings
                </MenuItem>
                <MenuItem 
                  as={RouterLink}
                  to="/organization-settings" 
                  icon={<FiDatabase />}
                  _hover={{ bg: colorMode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)' }}
                >
                  Organization Settings
                </MenuItem>
                <MenuItem 
                  as={RouterLink}
                  to="/network-status" 
                  icon={<FiServer />}
                  _hover={{ bg: colorMode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)' }}
                >
                  Network Status
                </MenuItem>
                <MenuItem 
                  icon={<FiHelpCircle />}
                  _hover={{ bg: colorMode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)' }}
                >
                  Help & Feedback
                </MenuItem>
                <MenuDivider borderColor={colorMode === 'dark' ? 'integra.dark.border' : 'integra.light.border'} />
                <MenuItem 
                  icon={<FiLogOut />} 
                  onClick={handleLogout}
                  _hover={{ bg: colorMode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)' }}
                >
                  Sign Out
                </MenuItem>
              </MenuList>
            </Menu>
          ) : (
            <HStack spacing={2}>
              <Button
                variant="outline"
                size="sm"
                onClick={handleLogin}
                color={navTextColor}
                borderColor={colorMode === 'dark' ? 'integra.dark.border' : 'integra.light.border'}
                _hover={{
                  bg: colorMode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)'
                }}
              >
                Sign Up
              </Button>
              <Button
                leftIcon={<FiUser />}
                variant="solid"
                size="sm"
                onClick={handleLogin}
                colorScheme="blue"
              >
                Sign In
              </Button>
            </HStack>
          )}
        </Flex>
      </Flex>
      
      {/* Main Content */}
      <Box 
        as="main" 
        pt="70px" // Add padding to account for fixed navbar
        minH="calc(100vh - 70px)"
        w="100%"
        maxW="100vw"
        overflowX="hidden"
      >
        {children}
      </Box>
    </Box>
  );
};

export default MainLayout;
