/**
 * PDFService
 * 
 * A service for handling PDF-related operations:
 * - PDF creation with metadata
 * - PDF signing
 * - PDF metadata extraction
 * 
 * This service is part of the V3 architecture that separates concerns
 * and improves maintainability.
 */

import { PDFDocument, PDFName, PDFString, PDFPage, PDFDict } from 'pdf-lib';
import * as pdfjs from 'pdfjs-dist';
import escape from 'xml-escape';
import { pdflibAddPlaceholder } from '@signpdf/placeholder-pdf-lib';
import { P12Signer } from '@signpdf/signer-p12';
import signpdf from '@signpdf/signpdf';
import { calculateKeccak256File } from '../lib/hash';

// Initialize PDF.js worker
if (typeof window !== 'undefined' && !pdfjs.GlobalWorkerOptions.workerSrc) {
  pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';
}

// Define metadata interfaces
export interface PDFMetadata {
  /** Document title or name */
  documentName?: string;
  
  /** Document type (e.g., contract, agreement) */
  documentType?: string;
  
  /** Document author */
  author?: string;
  
  /** Document creation date */
  creationDate?: string;
  
  /** Document modification date */
  modificationDate?: string;
  
  /** Document subject */
  subject?: string;
  
  /** Document keywords */
  keywords?: string[];
  
  /** Document description */
  description?: string;
  
  /** Document language */
  language?: string;
  
  /** Document page count */
  pageCount?: number;
  
  /** Document file size in bytes */
  fileSize?: number;
  
  /** Document file name */
  fileName?: string;
  
  /** Document file type */
  fileType?: string;
  
  /** Document hash */
  fileHash?: string;
  
  /** Integra ID for the document */
  integraId?: string;
  
  /** Organization ID */
  organizationId?: string;
  
  /** User ID who created the document */
  createdBy?: string;
  
  /** Additional metadata */
  [key: string]: any;
}

// Smart document configuration interface
export interface SmartDocConfig {
  /** PDF bytes to modify, or create new if not provided */
  pdfBytes?: Uint8Array;
  
  /** JSON data to embed in the document */
  jsonData: Record<string, any> | string;
  
  /** QR code image bytes to embed */
  qrCodeBytes?: Uint8Array;
  
  /** Integra endpoint URL */
  integraEndpoint: string;
  
  /** Custom endpoint URL */
  customEndpoint: string;
  
  /** Version string */
  version: string;
  
  /** First page configuration */
  firstPageConfig?: {
    /** Image bytes for the first page */
    imageBytes?: Uint8Array;
    /** Page width */
    width?: number;
    /** Page height */
    height?: number;
    /** Custom content function */
    customContent?: (page: PDFPage, pdfDoc: PDFDocument) => Promise<void> | void;
  } | null;
  
  /** Unique identifier for the document */
  uuid: string;
  
  /** Whether to enable signing */
  signingEnabled?: boolean;
  
  /** Whether to show a visible signature */
  visibleSignature?: boolean;
}

// PDF signing configuration interface
export interface SignPDFConfig {
  /** PDF bytes to sign */
  pdfBytes: Uint8Array;
  
  /** P12 certificate buffer */
  p12Buffer: Buffer;
  
  /** P12 certificate password */
  p12Password?: string;
}

/**
 * PDF Service class for handling PDF operations
 */
export class PDFService {
  /**
   * Create a smart document with embedded metadata and QR code
   * @param config Smart document configuration
   * @returns Promise resolving to the PDF bytes
   */
  async createSmartDoc({
    pdfBytes,
    jsonData,
    qrCodeBytes,
    integraEndpoint,
    customEndpoint,
    version,
    firstPageConfig = null,
    uuid,
    signingEnabled = false,
    visibleSignature = false,
  }: SmartDocConfig): Promise<Uint8Array> {
    const pdfDoc = pdfBytes ? await PDFDocument.load(pdfBytes) : await PDFDocument.create();

    let firstPage: PDFPage;
    if (firstPageConfig) {
      firstPage = await this.createFirstPage({
        pdfDoc,
        imageBytes: firstPageConfig.imageBytes,
        width: firstPageConfig.width,
        height: firstPageConfig.height,
        customContent: firstPageConfig.customContent,
      });
    } else {
      firstPage = pdfDoc.getPages()[0];
    }

    const xmpMetadata = `
      <x:xmpmeta xmlns:x="adobe:ns:meta/">
        <rdf:RDF xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                 xmlns:integra="http://example.com/integra/ns/">
          <rdf:Description rdf:about="">
            <integra:IntegraID>${escape(uuid)}</integra:IntegraID>
            <integra:IntegraEndpoint>${escape(integraEndpoint)}</integra:IntegraEndpoint>
            <integra:CustomEndpoint>${escape(customEndpoint)}</integra:CustomEndpoint>
            <integra:Version>${escape(version)}</integra:Version>
          </rdf:Description>
        </rdf:RDF>
      </x:xmpmeta>
    `;
    
    // Set document metadata using the correct pdf-lib API
    pdfDoc.setTitle(`Integra Smart Document - ${uuid}`);
    pdfDoc.setSubject(`Integra Smart Document with ID: ${uuid}`);
    pdfDoc.setKeywords([`Integra`, `Smart Document`, `${uuid}`]);
    pdfDoc.setProducer('Integra Ledger');
    pdfDoc.setCreator('Integra Smart Document Creator');
    
    // Add custom XMP metadata using the PDF dictionary directly
    let info = pdfDoc.context.lookup(pdfDoc.context.trailerInfo.Info);
    if (!(info instanceof PDFDict)) {
      info = pdfDoc.context.obj({});
      pdfDoc.context.trailerInfo.Info = pdfDoc.context.register(info);
    }
    
    // Add type assertion to ensure TypeScript recognizes info as PDFDict
    const infoDict = info as PDFDict;
    
    // Add XMP metadata as a custom field
    infoDict.set(PDFName.of('IntegraXMP'), PDFString.of(xmpMetadata));
    
    // Add JSON data
    const jsonString = typeof jsonData === 'string' ? jsonData : JSON.stringify(jsonData);
    infoDict.set(PDFName.of('IntegraData'), PDFString.of(jsonString));

    if (qrCodeBytes) {
      const qrImage = await pdfDoc.embedPng(qrCodeBytes);
      const qrSize = 84;
      const padding = 20;

      const qrX = firstPage.getWidth() - qrSize - padding;
      const qrY = firstPage.getHeight() - qrSize - padding;

      firstPage.drawImage(qrImage, {
        x: qrX,
        y: qrY,
        width: qrSize,
        height: qrSize,
      });

      const linkDict = pdfDoc.context.obj({
        Type: 'Annot',
        Subtype: 'Link',
        Rect: [qrX, qrY + qrSize, qrX + qrSize, qrY],
        A: {
          Type: 'Action',
          S: 'URI',
          URI: PDFString.of(integraEndpoint),
        },
      });
      const linkRef = pdfDoc.context.register(linkDict);
      firstPage.node.set(PDFName.of('Annots'), pdfDoc.context.obj([linkRef]));
    }

    if (signingEnabled) {
      const widgetOptions = visibleSignature ? {
        x: firstPage.getWidth() - 150 - 20,
        y: 20,
        width: 150,
        height: 50,
        text: 'Digitally Signed by Integra',
      } : undefined;

      // Create a widgetRect array from the widget options
      const widgetRect = visibleSignature && widgetOptions ? [
        widgetOptions.x,
        widgetOptions.y,
        widgetOptions.x + widgetOptions.width,
        widgetOptions.y + widgetOptions.height
      ] : [0, 0, 0, 0];

      pdflibAddPlaceholder({
        pdfDoc,
        reason: 'Document authenticity and integrity',
        contactInfo: 'support@integra.com',
        name: 'Integra Creator',
        location: 'Integra System',
        signatureLength: 8192,
        widgetRect,
      });
    }

    return await pdfDoc.save();
  }

  /**
   * Create the first page of a PDF document
   * @param config First page configuration
   * @returns Promise resolving to the PDF page
   */
  async createFirstPage({
    pdfDoc,
    imageBytes,
    width = 612,
    height = 792,
    customContent,
  }: {
    pdfDoc: PDFDocument;
    imageBytes?: Uint8Array;
    width?: number;
    height?: number;
    customContent?: (page: PDFPage, pdfDoc: PDFDocument) => Promise<void> | void;
  }): Promise<PDFPage> {
    // Insert a new page at the beginning of the document
    const firstPage = pdfDoc.insertPage(0, [width, height]);
    
    // If image bytes are provided, embed and draw the image
    if (imageBytes) {
      const image = await pdfDoc.embedPng(imageBytes);
      const { width: imgWidth, height: imgHeight } = image.size();
      
      // Calculate scaling to fit the page while maintaining aspect ratio
      const scale = Math.min(width / imgWidth, height / imgHeight);
      const scaledWidth = imgWidth * scale;
      const scaledHeight = imgHeight * scale;
      
      // Calculate position to center the image on the page
      const x = (width - scaledWidth) / 2;
      const y = (height - scaledHeight) / 2;
      
      // Draw the image
      firstPage.drawImage(image, {
        x,
        y,
        width: scaledWidth,
        height: scaledHeight,
      });
    }
    
    // Execute custom content function if provided
    if (customContent) {
      await Promise.resolve(customContent(firstPage, pdfDoc));
    }
    
    return firstPage;
  }

  /**
   * Sign a PDF document with a P12 certificate
   * @param config Signing configuration
   * @returns Promise resolving to the signed PDF bytes
   */
  async signPDF({
    pdfBytes,
    p12Buffer,
    p12Password = '',
  }: SignPDFConfig): Promise<Uint8Array> {
    const signer = new P12Signer(p12Buffer, { passphrase: p12Password });
    // Type assertion due to lack of official types
    const signedPdf = await (signpdf as any).sign(pdfBytes, signer) as Uint8Array;
    return signedPdf;
  }

  /**
   * Extract metadata from a PDF file
   * @param file PDF file
   * @returns Promise resolving to the PDF metadata
   */
  async extractMetadata(file: File): Promise<PDFMetadata> {
    try {
      // Read file as array buffer
      const arrayBuffer = await file.arrayBuffer();
      
      // Load PDF document
      const pdf = await pdfjs.getDocument({ data: arrayBuffer }).promise;
      
      // Get metadata
      const metadataObj = await pdf.getMetadata();
      
      // Extract info from metadata
      const { info, metadata: pdfMetadata } = metadataObj;
      
      // Create metadata object
      const extractedMetadata: PDFMetadata = {
        documentName: (info as any)?.Title || file.name.replace(/\.[^/.]+$/, ''),
        author: (info as any)?.Author || '',
        creationDate: (info as any)?.CreationDate || '',
        modificationDate: (info as any)?.ModDate || '',
        subject: (info as any)?.Subject || '',
        keywords: (info as any)?.Keywords ? (info as any).Keywords.split(',').map((k: string) => k.trim()) : [],
        pageCount: pdf.numPages,
        fileSize: file.size,
        fileName: file.name,
        fileType: file.type
      };
      
      // Add additional metadata from PDF metadata if available
      if (pdfMetadata) {
        try {
          // Handle custom metadata fields
          const metadata = pdfMetadata as any;
          const allMetadata = metadata.getAll();
          
          if (allMetadata) {
            Object.keys(allMetadata).forEach(key => {
              if (key.startsWith('integra:')) {
                const metaKey = key.replace('integra:', '');
                extractedMetadata[metaKey] = allMetadata[key];
              }
            });
          }
          
          // Extract Integra custom fields from the PDF info dictionary
          if ((info as any)?.IntegraXMP) {
            extractedMetadata.integraXMP = (info as any).IntegraXMP;
          }
          
          if ((info as any)?.IntegraData) {
            try {
              extractedMetadata.integraData = JSON.parse((info as any).IntegraData);
            } catch (e) {
              extractedMetadata.integraData = (info as any).IntegraData;
            }
          }
        } catch (error) {
          console.warn('Error extracting custom PDF metadata:', error);
          // Continue without custom metadata
        }
      }
      
      return extractedMetadata;
    } catch (error) {
      console.error('Error extracting PDF metadata:', error);
      
      // Provide more detailed error information
      let errorMessage = 'Failed to extract PDF metadata';
      
      if (error instanceof Error) {
        errorMessage = `${errorMessage}: ${error.message}`;
        
        // Check for version mismatch errors
        if (error.message.includes('version') && error.message.includes('does not match')) {
          errorMessage = `PDF.js version mismatch. Please check that the API and Worker versions match.`;
        }
      }
      
      throw new Error(errorMessage);
    }
  }

  /**
   * Calculate a hash for a file
   * @param file File to hash
   * @returns Promise resolving to the file hash
   */
  async calculateFileHash(file: File): Promise<string> {
    try {
      // Use Keccak256 for hashing to ensure compatibility with blockchain verification
      return await calculateKeccak256File(file);
    } catch (error) {
      console.error('Error calculating file hash:', error);
      throw new Error('Failed to calculate file hash');
    }
  }

  /**
   * Read a file as an ArrayBuffer
   * @param file The file to read
   * @returns Promise resolving to the file contents as ArrayBuffer
   */
  readAsArrayBuffer(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      
      reader.onload = () => {
        if (reader.result instanceof ArrayBuffer) {
          resolve(reader.result);
        } else {
          reject(new Error('Failed to read file as ArrayBuffer'));
        }
      };
      
      reader.onerror = () => {
        reject(new Error('Error reading file'));
      };
      
      reader.readAsArrayBuffer(file);
    });
  }
}

// Create a singleton instance for use throughout the application
const pdfService = new PDFService();
export default pdfService;
