/**
 * Verification Container
 * 
 * This component orchestrates the document verification flow.
 * It handles the verification process and renders the appropriate
 * components based on the verification result.
 */

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

// Import components
import VerificationInProgress from './VerificationInProgress';
import VerificationSuccess from './VerificationSuccess';
import VerificationNotFound from './VerificationNotFound';
import VerificationError from './VerificationError';

// Import services
import documentVerificationService from '../../services/DocumentVerificationService';
import workflowManagerService from '../../services/WorkflowManagerService';
import workflowRoutingService from '../../services/WorkflowRoutingService';
import { documentSyncService } from '../../services/DocumentSyncService';

// Import stores
import { usePDFStore } from '../../store/pdfStore';
import { useWorkflowStore, WorkflowType } from '../../store/workflowStore';

// Import authentication
import { useEnhancedAuth } from '../../providers/EnhancedPostgresAuthProvider';
import { usePrivy } from '@privy-io/react-auth';

/**
 * Verification Container Component
 */
const VerificationContainer: React.FC = () => {
  // Hooks
  const navigate = useNavigate();
  
  // Track verification attempts to prevent infinite loops
  const verificationAttemptedRef = useRef(false);
  
  // Get PDF store state and actions
  const { 
    fileHash, 
    verificationStatus,
    verificationResult,
    verificationError,
    setVerificationStatus, 
    setVerificationResult, 
    setVerificationError 
  } = usePDFStore();
  
  // Get workflow store state and actions
  const { 
    selectedWorkflow, 
    setSelectedWorkflow 
  } = useWorkflowStore();
  
  // Get authentication state
  const { userProfile, currentOrganizationId, loading: authLoading } = useEnhancedAuth();
  const { authenticated: privyAuthenticated, ready: privyReady } = usePrivy();
  
  // A user is authenticated if they're authenticated in Privy AND we're not still loading their profile
  // OR if we have a userProfile (which means they're fully authenticated in our system)
  const authenticated = (privyAuthenticated && !authLoading) || !!userProfile;
  
  /**
   * Verify document on component mount or when fileHash changes
   */
  useEffect(() => {
    // Skip if no file hash or verification already attempted
    if (!fileHash || verificationAttemptedRef.current) {
      return;
    }
    
    // Set verification attempt flag to prevent multiple attempts
    verificationAttemptedRef.current = true;
    
    // Log verification attempt
    console.log(`Attempting to verify document with hash: ${fileHash}`);
    console.log(`Current verification status: ${verificationStatus}`);
    
    // Start verification
    const verifyDocumentByHash = async () => {
      try {
        // Start verification timer
        const startTime = performance.now();
        
        // Set verifying state
        setVerificationStatus('verifying');
        
        // Verify document
        console.log(`Verifying document with hash: ${fileHash}`);
        const result = await documentVerificationService.verifyDocumentByHash(
          fileHash,
          userProfile?.profile_id || '',
          currentOrganizationId || ''
        );
        
        // Log verification duration
        const duration = performance.now() - startTime;
        console.log(`Document verification completed in ${duration.toFixed(2)}ms`);
        
        // Log detailed verification result
        console.log('Verification result:', {
          verified: result.verified,
          status: result.status,
          documentsCount: result.documents?.length || 0,
          documents: result.documents,
          message: result.message,
          rawResponse: result.rawResponse
        });
        
        // Log the full raw verification result for debugging
        console.log('FULL RAW VERIFICATION RESULT:', JSON.stringify(result, null, 2));
        
        // Always store the verification result regardless of verification status
        setVerificationResult(result);
        
        // Log the blockchain documents being saved to PDFStore
        console.log('[VerificationContainer] Blockchain documents from verification:', 
          usePDFStore.getState().blockchainDocuments);
        
        // Check if document was found
        if (result.verified) {
          console.log('Document found on blockchain:', result);
          setVerificationStatus('verified');
        } else {
          console.log('Document not found on blockchain:', result);
          setVerificationStatus('not_found');
        }
      } catch (error) {
        // Log error
        console.error('Error verifying document:', error);
        
        // Set error state
        setVerificationStatus('error');
        setVerificationError(error instanceof Error ? error.message : String(error));
      }
    };
    
    // Execute verification
    verifyDocumentByHash();
    
    // Cleanup function
    return () => {
      console.log('Verification container unmounting');
    };
  }, [fileHash]); // Only re-run when fileHash changes
  
  /**
   * Handle workflow selection
   */
  const handleWorkflowSelect = (workflow: WorkflowType) => {
    console.log(`Selected workflow: ${workflow}`);
    setSelectedWorkflow(workflow);
  };
  
  /**
   * Handle continue button click
   */
  const handleContinue = () => {
    if (!selectedWorkflow) {
      console.log('No workflow selected');
      return;
    }
    
    // Navigate to the selected workflow route
    const route = workflowRoutingService.getWorkflowRoute(selectedWorkflow);
    console.log(`Navigating to route: ${route}`);
    navigate(route);
  };
  
  /**
   * Handle retry verification
   */
  const handleRetryVerification = () => {
    // Reset verification attempt tracking
    verificationAttemptedRef.current = false;
    
    // Reset verification state
    setVerificationStatus('idle');
    setVerificationResult(null);
    setVerificationError(null);
  };
  
  /**
   * Render verification component based on status
   */
  const renderVerificationComponent = () => {
    // If no file hash, show not found
    if (!fileHash) {
      return (
        <VerificationNotFound 
          onWorkflowSelect={handleWorkflowSelect}
          onContinue={handleContinue}
          isAuthenticated={authenticated}
        />
      );
    }
    
    // Render based on verification status
    switch (verificationStatus) {
      case 'verifying':
        return <VerificationInProgress />;
      case 'verified':
        return (
          <VerificationSuccess 
            onWorkflowSelect={handleWorkflowSelect}
            isAuthenticated={authenticated}
          />
        );
      case 'not_found':
        return (
          <VerificationNotFound 
            onWorkflowSelect={handleWorkflowSelect}
            onContinue={handleContinue}
            isAuthenticated={authenticated}
          />
        );
      case 'error':
        return (
          <VerificationError 
            errorMessage={verificationError || 'An unknown error occurred'} 
            onRetry={handleRetryVerification}
          />
        );
      default:
        return (
          <VerificationNotFound 
            onWorkflowSelect={handleWorkflowSelect}
            onContinue={handleContinue}
            isAuthenticated={authenticated}
          />
        );
    }
  };
  
  return (
    <div>
      {renderVerificationComponent()}
    </div>
  );
};

export default VerificationContainer;
